import { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';

interface CheckboxProps {
    className?: string;
    onChange?: Function;
    defaultChecked?: boolean;
    label?: string | ReactNode;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({ className, onChange, defaultChecked = false, label }) => {
    const [isChecked, setChecked] = useState(defaultChecked);

    const change = (): void => {
        setChecked(!isChecked);
        onChange ? onChange(!isChecked) : null;
    };

    return (
        <StyledCheckbox className={`checkbox ${className ?? ''} ${label ? 'with-label' : ''}`.trim()}>
            <label className="checkbox-wrapper">
                <input type="checkbox" checked={isChecked} onChange={change} />
                <span className="check"></span>
            </label>
            {label ? <p>{label}</p> : <></>}
        </StyledCheckbox>
    );
};

const StyledCheckbox = styled.div`
    display: flex;
    align-items: center;
    &.with-label {
        gap: 5px;
    }
    .checkbox-wrapper {
        display: block;
        position: relative;
        cursor: pointer;
        width: 12.75px;
        height: 12.75px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked ~ .check {
            background-color: var(--primary-300);
            border: 1px solid var(--primary-300);
        }

        input:checked ~ .check:after {
            display: block;
        }

        .check {
            position: absolute;
            top: 0;
            left: 0;
            width: 12.75px;
            height: 12.75px;
            background: white;
            border: 1px solid var(--black-75);
            box-sizing: border-box;
            border-radius: 2px;
            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 3.5px;
                top: 1px;
                width: 4px;
                height: 7px;
                border: solid white;
                border-width: 0 1.5px 1.5px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
`;

export default Checkbox;
