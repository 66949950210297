import { FunctionComponent, useState, SetStateAction, Dispatch } from 'react';
import Modal from '../../../components/modal';
import { UserDto } from '@Global/dtos/user.dto';
import Input, { InputComponentType, InputComponentTheme } from '../../../components/input';
import Button, { ButtonTheme } from '../../../components/button';
import Communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import { StyledInviteUserModal } from '@Components/editor/base/styled';
import SubscriptionModal from '@/pages/subscriptionPlans/subscriptionModal';
import { isValidEmail } from '@/functions/regex';

interface Props {
    className?: string;
    onSelect?: Function;
    searchTerm?: string;
    visibility: boolean;
    formManipulation: Function;
    setUsersList: Dispatch<SetStateAction<UserDto[] | null>>;
}

const InviteUserForm: FunctionComponent<Props> = ({ visibility, formManipulation, setUsersList }) => {
    const [formModal, setFormModal] = useState<boolean>(visibility);
    const { addToast } = useToasts();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [subscriptionModalVisibility, setSubscriptionModalVisibility] = useState(false);
    const [emailError, setEmailError] = useState<boolean>(false);

    const inviteUser = async (): Promise<void> => {
        setLoading(true);
        setEmailError(true);
        if (isValidEmail(email) && email !== '') {
            const pushComm = Communication.inviteUser({
                email,
            });

            await pushComm
                .then(() => {
                    setFormModal(false);
                    Communication.getAllUsers(true).then((res: any) => {
                        setUsersList && setUsersList(res.data);
                    });
                    setEmailError(false);
                    addToast('An email has been sent to the user!', {
                        appearance: 'success',
                    });
                })
                .catch((error: any) => {
                    const subscriptionError = error.response.data?.code === 1035;
                    if (subscriptionError) {
                        setFormModal(false);
                        setSubscriptionModalVisibility(true);
                    } else {
                        setEmailError(false);
                        setEmail('');
                        addToast('Ops, something happened during invite process. Please contact administrator.', {
                            appearance: 'error',
                        });
                    }
                });
        }
        setLoading(false);
    };

    const closeForm = (): void => {
        setFormModal(!formModal);
        formManipulation();
    };

    return (
        <>
            <SubscriptionModal
                modalVisible={subscriptionModalVisibility}
                setModalVisible={setSubscriptionModalVisibility}
                closeModal={() => {
                    setSubscriptionModalVisibility(false);
                }}
            ></SubscriptionModal>
            <Modal modalVisible={formModal} closeModal={() => closeForm()} size={550} title="Invite new user">
                <StyledInviteUserModal>
                    <div>
                        <p>
                            Enter the email address to invite users to your company. An invitation email will be sent
                            where the user has to confirm and setup their user profile.
                        </p>
                    </div>
                    <Input
                        type={InputComponentType.Email}
                        theme={InputComponentTheme.Light}
                        label={'Email address'}
                        placeholder={'Email address'}
                        value={email}
                        size="100%"
                        onChange={(value: string) => setEmail(value)}
                        onEnter={() => inviteUser()}
                        errorMessage={
                            emailError ? (isValidEmail(email) || email === '' ? '' : 'Please provide valid email!') : ''
                        }
                    />
                    <Button
                        className="copy-button"
                        theme={ButtonTheme.primary}
                        size={'117px'}
                        onClick={() => inviteUser()}
                        isLoading={loading}
                    >
                        Invite user
                    </Button>
                </StyledInviteUserModal>
            </Modal>
        </>
    );
};

export default InviteUserForm;
