import { FunctionComponent } from 'react';
import styled from 'styled-components';
import ErrorIcon from '@Assets/icons/error.svg';
interface Props {
    content: any;
    className?: string;
}
const NoItems: FunctionComponent<Props> = ({ content, className }) => {
    return (
        <StyledWrapper className={className ?? className}>
            <img src={ErrorIcon} alt="info icon" />
            <div>{content}</div>
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    text-align: center;

    img {
        margin-bottom: 8px;
    }
    p {
        color: var(--black-60);
        font-size: 16px;
        line-height: 22.4px;
    }
    button {
        font-weight: 600;
        padding: 0;
        font-size: 16px;
        margin-left: 5px;
        height: auto;
    }
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 768px) {
                flex-direction: column;
            }
        }
    }
`;
export default NoItems;
