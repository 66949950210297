import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { ContactsDetailsType } from '@/types/workspace';
import LinkedinLogo from '@Assets/icons/sharedView/linkedin.svg';
import Email from '@Assets/icons/sharedView/email.svg';
import Phone from '@Assets/icons/sharedView/call.svg';
import Chat from '@Assets/icons/sharedView/chat.svg';
import Calendar from '@Assets/icons/sharedView/calendar.svg';
import './pointsOfContact.scss';
import ArrowDropdownActive from '@Assets/icons/sharedView/arrow-drop-down-active.svg';
import ArrowDropdownInactive from '@Assets/icons/sharedView/arrow-drop-down-inactive.svg';
import Tooltip from '@Components/tooltip';
import { useToasts } from 'react-toast-notifications';
import { dispatchEvent, EventName } from '@Helpers/analytics';
import UserIcon from '@Assets/icons/navbar/profile-circle.svg';

interface Props {
    contacts?: ContactsDetailsType[] | null;
    workspaceId: string | null;
    isProtected?: boolean;
}

const PointsOfContact: FunctionComponent<Props> = ({ contacts, workspaceId, isProtected }) => {
    const { addToast, removeAllToasts } = useToasts();

    const swToken = localStorage.getItem('sharedViewToken') ?? undefined;
    const token = localStorage.getItem('token') ?? undefined;

    const createValidUrl = (url: string): string => {
        if (!/^https?:\/\//i.test(url)) {
            return (url = 'http://' + url);
        }
        return url;
    };

    const copyText = (text: string, type: string): void => {
        removeAllToasts();
        navigator.clipboard.writeText(text).then();
        addToast(type === 'email' ? 'Email is successfully copied.' : 'Phone number is successfully copied.', {
            appearance: 'success',
        });
        dispatchEvent(EventName.SharedPageClick, workspaceId, { page: 'sharedView', isProtected }, swToken, token);
    };

    const [isCollapsed, setCollapsed] = useState(false);
    return (
        <StyledContactsContainer>
            {contacts && contacts.length > 0 && (
                <StyledHeaderContainer
                    onClick={() => {
                        dispatchEvent(
                            EventName.SharedPageClick,
                            workspaceId,
                            { page: 'sharedView', isProtected },
                            swToken,
                            token,
                        );
                        setCollapsed(!isCollapsed);
                    }}
                    className={`${isCollapsed ? '' : 'shadow'}`}
                >
                    <StyledDropdownContainer>
                        <StyledDropdownIcon src={isCollapsed ? ArrowDropdownInactive : ArrowDropdownActive} />
                    </StyledDropdownContainer>
                    <span className={`${isCollapsed ? '' : 'active-color'}`}>Contacts</span>
                </StyledHeaderContainer>
            )}
            {!isCollapsed && (
                <StyledContactsList>
                    {contacts &&
                        contacts.map((contact: any) => {
                            return (
                                <div key={contact.id} className="contact-list-container">
                                    <StyledContactPicture
                                        crossOrigin="anonymous"
                                        src={
                                            contact?.properties?.profilePicture
                                                ? `${process.env.REACT_APP_API_URL}/users/me/picture/${contact?.properties?.profilePicture}`
                                                : UserIcon
                                        }
                                    />

                                    <StyledContactDetails>
                                        <p className="contact-list-name">
                                            {contact?.name !== ' ' ? contact?.name : contact?.email}
                                        </p>
                                        {contact?.properties?.title && (
                                            <p className="contact-list-position">{contact?.properties?.title}</p>
                                        )}
                                        <StyledContactMethodContainer>
                                            {contact?.email && (
                                                <Tooltip tooltipText="Copy email" tooltipPositionBottom>
                                                    <StyledSpan
                                                        onClick={() => {
                                                            dispatchEvent(
                                                                EventName.SharedPageClick,
                                                                workspaceId,
                                                                {
                                                                    page: 'sharedView',
                                                                    isProtected,
                                                                },
                                                                swToken,
                                                                token,
                                                            );
                                                            copyText(contact?.email, 'email');
                                                        }}
                                                    >
                                                        <StyledSocialLogo src={Email} />
                                                    </StyledSpan>
                                                </Tooltip>
                                            )}
                                            {contact?.properties?.contact?.chatLink && (
                                                <Tooltip tooltipText="Send message" tooltipPositionBottom>
                                                    <StyledLink
                                                        onClick={() =>
                                                            dispatchEvent(
                                                                EventName.SharedPageClick,
                                                                workspaceId,
                                                                {
                                                                    page: 'sharedView',
                                                                    isProtected,
                                                                },
                                                                swToken,
                                                                token,
                                                            )
                                                        }
                                                        href={createValidUrl(contact?.properties?.contact?.chatLink)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <StyledSocialLogo src={Chat} />
                                                    </StyledLink>
                                                </Tooltip>
                                            )}
                                            {contact?.properties?.contact?.phone && (
                                                <Tooltip tooltipText="Copy number" tooltipPositionBottom>
                                                    <StyledSpan
                                                        onClick={() => {
                                                            dispatchEvent(EventName.SharedPageClick, workspaceId, {
                                                                page: 'sharedView',
                                                                isProtected,
                                                            });
                                                            copyText(contact?.properties?.contact?.phone, 'phone');
                                                        }}
                                                    >
                                                        <StyledSocialLogo src={Phone} />
                                                    </StyledSpan>
                                                </Tooltip>
                                            )}
                                            {contact?.properties?.contact?.linkedIn && (
                                                <Tooltip tooltipText="LinkedIn" tooltipPositionBottom>
                                                    <StyledLink
                                                        onClick={() => {
                                                            dispatchEvent(
                                                                EventName.SharedPageClick,
                                                                workspaceId,
                                                                {
                                                                    page: 'sharedView',
                                                                    isProtected,
                                                                },
                                                                swToken,
                                                                token,
                                                            );
                                                        }}
                                                        href={createValidUrl(contact?.properties?.contact?.linkedIn)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <StyledSocialLogo src={LinkedinLogo} />
                                                    </StyledLink>
                                                </Tooltip>
                                            )}
                                            {contact?.properties?.contact?.calendarLink && (
                                                <Tooltip tooltipText="Schedule a call" tooltipPositionBottom>
                                                    <StyledLink
                                                        onClick={() =>
                                                            dispatchEvent(
                                                                EventName.SharedPageClick,
                                                                workspaceId,
                                                                {
                                                                    page: 'sharedView',
                                                                    isProtected,
                                                                },
                                                                swToken,
                                                                token,
                                                            )
                                                        }
                                                        href={contact?.properties?.contact?.calendarLink}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <StyledSocialLogo src={Calendar} />
                                                    </StyledLink>
                                                </Tooltip>
                                            )}
                                        </StyledContactMethodContainer>
                                    </StyledContactDetails>
                                </div>
                            );
                        })}
                </StyledContactsList>
            )}
        </StyledContactsContainer>
    );
};

const StyledContactDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const StyledContactsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    border-radius: 8px;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
`;

const StyledContactsList = styled.div`
    margin-left: 5px;
    padding-left: 24px;
`;

const StyledContactMethodContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const StyledContactPicture = styled.img`
    margin-right: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    object-fit: cover;
`;

const StyledSocialLogo = styled.img`
    margin-right: 9px;
    width: 20px;
    height: 20px;
    object-fit: cover;
`;

const StyledLink = styled.a`
    color: var(--primary-400);
    text-decoration: none;
`;

const StyledSpan = styled.span`
    color: var(--primary-400);
`;

const StyledHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 12px 0;

    &.shadow {
        box-shadow: 0px 2px 0px rgba(216.75, 216.75, 216.75, 0.15);
        padding-bottom: 10px;
    }

    span {
        font-size: 18px;
        font-weight: 600;
        word-wrap: break-word;
        &.active-color {
            color: var(--black-60);
        }
        color: var(--black-75);
    }
`;

const StyledDropdownContainer = styled.div`
    margin: 0 24px;
`;

const StyledDropdownIcon = styled.img`
    height: 28px;
    width: 28px;
`;

export default PointsOfContact;
