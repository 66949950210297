import { FunctionComponent } from 'react';
import styled from 'styled-components';
import CheckIcon from '@Assets/icons/subscription/check.svg';
import Button, { ButtonTheme } from '@Components/button';
import communication from '@/communication';
import { useToasts } from 'react-toast-notifications';

interface Props {
    title: string;
    planName: string;
    listTitle?: string;
    benefits: string[];
    description: string;
    disabled: boolean;
    duration: string;
    setPlanName: Function;
}
const SubscriptionPlansCard: FunctionComponent<Props> = ({
    title,
    planName,
    description,
    listTitle,
    benefits,
    disabled = false,
    duration = 'month',
    setPlanName,
}) => {
    const { addToast } = useToasts();

    const changeSubscription = async (): Promise<any> => {
        if (planName !== 'premium') {
            await communication
                .upgradeSubscription({ name: planName, duration })
                .then(() => {
                    setPlanName(planName);
                    addToast('Subscription successfully changed', {
                        appearance: 'success',
                    });
                })
                .catch(() => {
                    addToast('Something went wrong! Please try again.', {
                        appearance: 'error',
                    });
                });
        } else {
            window.open('https://syncpage.io/contact', '_blank');
        }
    };
    return (
        <StyledContainer>
            <h3>{title}</h3>
            <h4>{planName}</h4>
            <p>{description}</p>
            {listTitle && <h5>{listTitle}</h5>}
            <StyledBenefitsContainer>
                {benefits.map((benefit, index) => {
                    return (
                        <StyledBenefitItem key={index}>
                            <img src={CheckIcon}></img>
                            <p>{benefit}</p>
                        </StyledBenefitItem>
                    );
                })}
            </StyledBenefitsContainer>
            <Button
                theme={ButtonTheme.primary}
                onClick={() => {
                    changeSubscription();
                }}
                disabled={disabled}
                size={254}
            >
                {disabled
                    ? 'Current plan'
                    : planName === 'basic'
                    ? 'Choose this plan'
                    : planName === 'business'
                    ? 'Upgrade'
                    : 'Talk to sales'}
            </Button>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    border-radius: 8px;
    background: var(--background-grey);
    padding: 21px 25px 25px 25px;

    @media only screen and (max-width: 900px) {
        min-width: 300px;
    }

    h3 {
        color: var(--black-300);
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: -0.56px;
        padding-bottom: 18px;
    }

    h4 {
        color: var(--black-300);
        font-size: 15.839px;
        font-style: normal;
        font-weight: 600;
        line-height: 23.758px;
    }

    h5 {
        color: var(--black-100);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 184.615% */
    }

    p {
        color: var(--black-100);
        font-size: 12.671px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.007px;
    }
`;

const StyledBenefitsContainer = styled.div`
    padding: 30px 0;
    gap: 13px;
    display: flex;
    flex-direction: column;
    gap: 13px;
`;
const StyledBenefitItem = styled.div`
    display: flex;
    gap: 10px;
`;

export default SubscriptionPlansCard;
