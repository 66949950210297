import React from 'react';
import Modal from '@Components/modal';
import SubscriptionPng from '@Assets/subscription-modal.png';
import CloseIcon from '@Assets/icons/close.svg';
import CheckboxIcon from '@Assets/check-box.svg';
import styled from 'styled-components';
import Button from '@Components/button';

interface Props {
    modalVisible: boolean;
    setModalVisible: Function;
    closeModal: Function;
}

const SubscriptionModal: React.FunctionComponent<Props> = ({ modalVisible, closeModal, setModalVisible }) => {
    return (
        <Modal
            modalVisible={modalVisible}
            closeModal={() => {
                closeModal(false);
            }}
            size={800}
            title="Test"
            withHeader={false}
        >
            <StyledSubscriptionModal>
                <div className="subscription-modal-content">
                    <div>
                        <img height={404} src={SubscriptionPng}></img>
                    </div>
                    <div className="subscription-modal-content-column">
                        <div className="subscription-modal-header">
                            <p>UPGRADE TO PRO</p>
                        </div>
                        <div className="subscription-modal-content-list">
                            <div className="subscription-modal-content-1">You have reached the limit </div>
                            <div className="subscription-modal-content-1">of your current plan</div>
                            <div className="subscription-modal-content-2">Upgrade your plan and get access to:</div>
                            <div className="subscription-modal-content-3">
                                <img src={CheckboxIcon}></img>
                                <p>
                                    Unlimited <b>Workspaces</b>
                                </p>
                            </div>
                            <div className="subscription-modal-content-3">
                                <img src={CheckboxIcon}></img>
                                <p>
                                    Unlimited <b>Info rooms</b>
                                </p>
                            </div>
                            <div className="subscription-modal-content-3">
                                <img src={CheckboxIcon}></img>
                                <p>
                                    Unlimited <b>file uploads</b>
                                </p>
                            </div>
                            <div className="subscription-modal-content-3">
                                <img src={CheckboxIcon}></img>
                                <p>
                                    Unlimited <b>users</b>
                                </p>
                            </div>
                        </div>

                        <div className="subscription-modal-content-button">
                            <Button
                                onClick={() => {
                                    window.open('https://syncpage.io/contact', '_blank');
                                }}
                            >
                                Upgrade now
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            alt="close button"
                            className="cursor-pointer"
                            onClick={() => setModalVisible(false)}
                            src={CloseIcon}
                        />
                    </div>
                </div>
            </StyledSubscriptionModal>
        </Modal>
    );
};

const StyledSubscriptionModal = styled.div`
    .subscription-modal-content {
        display: flex;
        margin-left: -27px;
        padding: 48px 0;

        .subscription-modal-content-column {
            display: flex;
            flex-direction: column;

            .subscription-modal-header {
                background: #afafb7;
                color: white;
                text-align: center;
                border-radius: 14px;
                width: 125px;
                margin-bottom: 50px;

                p {
                    line-height: 24px;
                    font-size: 10px;
                    font-weight: bold;
                }
            }
            .subscription-modal-content-list {
                width: 382px;

                .subscription-modal-content-1 {
                    font-weight: 600;
                    font-size: 28px;
                }
                .subscription-modal-content-2 {
                    font-size: 20px;
                    color: #afafb7;
                    margin: 10px 0 20px 0;
                    font-weight: 400;
                }
                .subscription-modal-content-3 {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    font-size: 16px;
                    margin: 7px 0;
                }
            }
            .subscription-modal-content-button {
                margin-top: auto;
            }
        }
    }
`;

export default SubscriptionModal;
