import { FunctionComponent, useState, useEffect } from 'react';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import { MainWrapper, PositionStyle, StyledHeader, StyledTitle, TwoButtonsStyle } from '../styledComponents';
import useWorkspaceStore from '@Store/workspace';
import Button, { ButtonTheme } from '@Components/button';
import Input, { InputComponentType } from '@Components/input';
import styled from 'styled-components';
import communication from '@/communication';
import useEditorStore from '@Store/editor';
import AddIcon from '@Assets/icons/workspaces/add-circle-black.svg';
import FactCheck from '@Assets/icons/settings/fact-check.svg';
import nanogen from '@/functions/nanogen';
import deleteIcon from '@Assets/icons/settings/delete.svg';
import { useToasts } from 'react-toast-notifications';
import deepEqual from 'deep-equal';
import Checkbox from '@/components/input/checkbox';

interface Props {
    isActive: boolean;
    changePage: Function;
}

const ActionPlan: FunctionComponent<Props> = ({ isActive, changePage }) => {
    const { associatedActionPlan } = useWorkspaceStore();
    const { id } = useEditorStore();
    const [name, setName] = useState(associatedActionPlan?.name);
    const [contentItems, setContentItems] = useState(associatedActionPlan?.content?.items ?? []);
    const [title, setTitle] = useState(associatedActionPlan?.content?.title);
    const { addToast, removeAllToasts } = useToasts();
    const [hasChanges, setHasChanges] = useState(false);

    const updateListItemlabel = ({ text, listItemId }: { text: string; listItemId: string }): void => {
        if (contentItems) {
            setContentItems(
                contentItems.map((listItem) => (listItem.id === listItemId ? { ...listItem, label: text } : listItem)),
            );
        }
    };

    const updateListItemChecked = ({ checked, listItemId }: { checked: boolean; listItemId: string }): void => {
        if (contentItems) {
            setContentItems(
                contentItems.map((listItem) => (listItem.id === listItemId ? { ...listItem, checked } : listItem)),
            );
        }
    };

    const addListItem = (): void => {
        if (contentItems) {
            const contentTmp = [...contentItems];
            setContentItems([
                ...contentTmp,
                {
                    id: nanogen(),
                    label: '',
                    checked: false,
                },
            ]);
        }
    };

    const updateActionPlan = async (): Promise<void> => {
        removeAllToasts();
        try {
            if (associatedActionPlan?.id) {
                const res = await communication.updateActionPlan(associatedActionPlan?.id, {
                    name,
                    content: {
                        title: title ?? '',
                        items: contentItems ?? [],
                    },
                });
                useWorkspaceStore.setState({ associatedActionPlan: res?.data });
                setHasChanges(false);
                addToast('Changes saved!', {
                    appearance: 'success',
                });
            } else {
                const res = await communication.createActionPlan({
                    name: `actionPlan-${id}`,
                    content: {
                        title: title ?? '',
                        items: contentItems ?? [],
                    },
                    workspaceId: id,
                });
                useWorkspaceStore.setState({ associatedActionPlan: res?.data });
                setContentItems(res?.data.content.items);
            }
        } catch (e: any) {
            if (e) {
                addToast('Something went wrong! Please check entered data.', {
                    appearance: 'error',
                });
            }
        }
    };

    const removeActionPlan = async (): Promise<void> => {
        removeAllToasts();
        try {
            if (associatedActionPlan?.id) {
                await communication.deleteActionPlan(associatedActionPlan?.id);
                useWorkspaceStore.setState({ associatedActionPlan: null });
                setName('');
                setTitle('');
                setContentItems([]);
                setHasChanges(false);
                addToast('Action plan deleted!', {
                    appearance: 'success',
                });
            }
        } catch (e: any) {
            if (e) {
                addToast('Changes saved!', {
                    appearance: 'success',
                });
            }
        }
    };

    const removeItem = (itemId: string): void => {
        if (contentItems) {
            const contentTmp = [...contentItems];
            const index = contentTmp.findIndex((item) => item.id === itemId);
            contentTmp.splice(index, 1);
            setContentItems([...contentTmp]);
        }
    };

    useEffect(() => {
        if (
            (title !== '' && title !== associatedActionPlan?.content.title) ||
            (contentItems.length > 0 && !deepEqual(contentItems, associatedActionPlan?.content.items))
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [title, contentItems]);

    return (
        <MainWrapper>
            {isActive ? (
                <>
                    <StyledHeader onClick={() => changePage(0)}>
                        <img src={arrowLeft} alt="icon"></img>
                        Settings
                    </StyledHeader>
                    <StyledTitle>
                        <img src={FactCheck} alt="icon" />
                        Action plan
                    </StyledTitle>
                    <PositionStyle>
                        <StyledBody>
                            <div>
                                <Input
                                    label="Title"
                                    placeholder="Add title (e.g. “Next steps” or Action plan”)"
                                    type={InputComponentType.Text}
                                    value={title}
                                    onChange={(value: string) => setTitle(value)}
                                    size="100%"
                                />
                                <LabelWrapper>Action plan items: </LabelWrapper>
                                <div>
                                    {contentItems &&
                                        contentItems.map((item) => {
                                            return (
                                                <StyledWrapper key={item.id}>
                                                    <InputCheckWrapper>
                                                        <Input
                                                            placeholder="Item title"
                                                            type={InputComponentType.Text}
                                                            value={item.label}
                                                            onChange={(value: string) =>
                                                                updateListItemlabel({
                                                                    text: value,
                                                                    listItemId: item.id,
                                                                })
                                                            }
                                                            size="100%"
                                                        />
                                                        <Checkbox
                                                            defaultChecked={item.checked}
                                                            onChange={(value: boolean) => {
                                                                updateListItemChecked({
                                                                    checked: value,
                                                                    listItemId: item.id,
                                                                });
                                                            }}
                                                        />
                                                    </InputCheckWrapper>
                                                    <Button
                                                        className="clickable delete-btn"
                                                        theme={ButtonTheme.link}
                                                        onClick={() => {
                                                            removeItem(item.id);
                                                        }}
                                                        leftIcon={deleteIcon}
                                                    ></Button>
                                                </StyledWrapper>
                                            );
                                        })}
                                </div>
                                <Button
                                    theme={ButtonTheme.link}
                                    leftIcon={AddIcon}
                                    className="add-item-btn"
                                    onClick={() => {
                                        addListItem();
                                    }}
                                >
                                    Add new item
                                </Button>
                            </div>
                        </StyledBody>
                    </PositionStyle>
                    {hasChanges && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                onClick={() => {
                                    updateActionPlan();
                                }}
                            >
                                Save
                            </Button>
                            <Button className="clickable" theme={ButtonTheme.naked} onClick={() => changePage(0)}>
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                    <Button
                        className="clickable"
                        theme={ButtonTheme.link}
                        onClick={() => {
                            removeActionPlan();
                        }}
                        leftIcon={deleteIcon}
                    >
                        Remove action plan
                    </Button>
                </>
            ) : (
                <></>
            )}
        </MainWrapper>
    );
};

const StyledBody = styled.div`
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    overflow: auto;
    .button {
        &.add-item-btn {
            padding: 5px 0;
        }
    }
`;
const StyledWrapper = styled.div`
    display: flex;
    margin: 10px 0;
    align-items: center;
    button {
        &.delete-btn {
            padding: 5px 0px;
            margin-left: 12px;
        }
    }
`;

const LabelWrapper = styled.p`
    margin: 10px 0;
    font-size: 12px;
    color: var(--black-75);
`;
const InputCheckWrapper = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: var(--black-20);
    width: 300px;
    height: 50px;
    .checkbox {
        padding: 10px 16px 10px 10px;
        border-left: 1px solid var(--white);
        margin: 8px auto;
    }
    input {
        background: transparent;
        outline-color: transparent;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 13px 15px;
        width: 99%;
        border: 1px solid transparent;
        &:focus {
            filter: none;
            outline-color: transparent;
            border-color: transparent;
        }
    }
    &:has(input:focus) {
        filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0.5px 3px);
        background-color: var(--white);
        outline-color: var(--white);

        .checkbox {
            border-left: 1px solid var(--black-20);
        }
    }
`;

export default ActionPlan;
