import { AssociatedCompanyPayloadDto, CompanyCreateDto, CompanyUpdateDto } from '@/global/dtos/company.dto';
import { AssociatedContactPayloadDto, ContactCreateDto, ContactUpdateDto } from '@/global/dtos/contact.dto';
import { AssociatedWorkspacesPayloadDto } from '@Global/dtos/workspace.dto';
import fetch from './fetch';
import routes from './routes';

export default class Account {
    public getAllContacts(): any {
        return fetch({
            method: 'get',
            url: routes.account.allContacts,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getAllCompanies(): any {
        return fetch({
            method: 'get',
            url: routes.account.allCompanies,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public createCompany(data: CompanyCreateDto): any {
        return fetch({
            method: 'post',
            url: routes.account.allCompanies,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public updateCompany(id: string, data: CompanyUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.account.singleCompany(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public deleteCompany(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.account.singleCompany(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public createContact(data: ContactCreateDto): any {
        return fetch({
            method: 'post',
            url: routes.account.allContacts,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public updateContact(id: string, data: ContactUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.account.singleContact(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public deleteContact(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.account.singleContact(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public uploadContactProfilePicture(id: string, formData: FormData): any {
        return fetch({
            method: 'patch',
            url: routes.account.contactPicture(id),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }

    public removeContactProfilePicture(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.account.contactPicture(id),
        });
    }

    public uploadCompanyProfilePicture(id: string, formData: FormData): any {
        return fetch({
            method: 'patch',
            url: routes.account.companyPicture(id),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }

    public removeCompanyProfilePicture(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.account.companyPicture(id),
        });
    }

    public updateAssociatedContactWorkspaces(id: string, data: AssociatedWorkspacesPayloadDto): any {
        return fetch({
            method: 'patch',
            url: routes.account.accountWorkspaces(id),
            data: data,
        });
    }

    public updateAssociatedCompanyWorkspaces(id: string, data: AssociatedWorkspacesPayloadDto): any {
        return fetch({
            method: 'patch',
            url: routes.account.accountWorkspaces(id),
            data: data,
        });
    }

    public updateAssociatedContact(id: string, data: AssociatedContactPayloadDto): any {
        return fetch({
            method: 'patch',
            url: routes.account.associatedContactsCompanies(id),
            data: data,
        });
    }

    public updateAssociatedCompany(id: string, data: AssociatedCompanyPayloadDto): any {
        return fetch({
            method: 'patch',
            url: routes.account.associatedContactsCompanies(id),
            data: data,
        });
    }
}
