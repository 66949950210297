import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const LibrarySkeleton: FunctionComponent = () => {
    return (
        <>
            <StyledSkeletonWrapper>
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                </div>
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                </div>
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                    <Skeleton style={{ marginBottom: '10px' }} height={138} width={138} />
                </div>
            </StyledSkeletonWrapper>
        </>
    );
};

const StyledSkeletonWrapper = styled.div`
    > div {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
        gap: 20px;
        > span {
            width: 100%;
        }
    }
`;

export default LibrarySkeleton;
