import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useGlobalState from '@Store/global';
import Button, { ButtonTheme } from '@Components/button';
import arrowRight from '@Assets/icons/settings/arrow-right.svg';
import workspace from '@Assets/icons/accountsSidebar/workspace.svg';
import profile from '@Assets/icons/accountsSidebar/user.svg';
import contacts from '@Assets/icons/accountsSidebar/users.svg';
import companies from '@Assets/icons/accountsSidebar/organization.svg';
import hamburgerIcon from '@Assets/icons/settings/hamburger.svg';
import logo from '@Assets/icons/accountsSidebar/companies/logo.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import ManageSearch from '@Assets/icons/accountsSidebar/details.svg';
import CloseIcon from '@Assets/icons/close.svg';
import { ContactSidebarSections, CompanySidebarSections, AccountSidebarTabs } from '../sidebar';
import GlobalHotkeys from '../../../../global/keypress';
import { OptionsWrapper } from '../styledComponents';
import { StyledHeader, ContextMenu, StyledOptions } from '../../../workspaces/editor/sidebar/settings';
import ImageName from '../accountsImageName';
interface AccountsSidebarProps {
    sidebarVisible?: boolean;
    closeSidebar: Function;
    activeTab: number;
    setSection: Function;
    deleteContact: Function;
    deleteCompany: Function;
    loading?: boolean;
    setLoading?: Function;
}

const AccountMainSidebar: FunctionComponent<AccountsSidebarProps> = ({
    activeTab,
    setSection,
    deleteContact,
    deleteCompany,
}) => {
    useEffect(() => {
        GlobalHotkeys.add('workspaces/sidebar/escape', 'Escape', () => setSection(0));
    }, []);

    const { accountContextMenu } = useGlobalState();

    const [hoveredBtn, setHoveredBtn] = useState(false);

    return (
        <StyledFrame className={`${accountContextMenu ? 'overlay-visible' : ''}`.trim()}>
            <div className="title">
                <p>{activeTab === AccountSidebarTabs.CONTACT_SIDEBAR ? 'Contact' : 'Company'} settings</p>
                <img
                    src={hamburgerIcon}
                    alt="icon"
                    className="cursor-pointer"
                    onClick={() => useGlobalState.setState({ accountContextMenu: true })}
                />
            </div>
            <ImageName activeTab={activeTab} />
            <ContextMenu className={`account-context-settings ${accountContextMenu ? '' : 'hidden'}`}>
                <StyledHeader>
                    <div>Settings</div>
                    <img
                        src={CloseIcon}
                        alt="icon"
                        className="cursor-pointer"
                        onClick={() => useGlobalState.setState({ accountContextMenu: false })}
                    />
                </StyledHeader>
                <Button
                    theme={ButtonTheme.link}
                    leftIcon={hoveredBtn ? DeleteIconActive : DeleteIconInactive}
                    onMouseEnter={() => setHoveredBtn(true)}
                    onMouseLeave={() => setHoveredBtn(false)}
                    onClick={() => {
                        activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                            ? deleteContact()
                            : deleteCompany();
                    }}
                >
                    {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                        ? 'Delete contact'
                        : 'Delete company'}
                </Button>
            </ContextMenu>
            <>
                {activeTab === AccountSidebarTabs.CONTACT_SIDEBAR ? (
                    <OptionsWrapper>
                        <StyledOptions onClick={() => setSection(ContactSidebarSections.CONTACT_DETAILS)}>
                            <div>
                                <img src={ManageSearch} className="front" alt="icon" />
                                Contact details
                            </div>
                            <img src={arrowRight} className="back" alt="icon" />
                        </StyledOptions>
                        <StyledOptions onClick={() => setSection(ContactSidebarSections.CONTACT_PROFILE_PICTURE)}>
                            <div>
                                <img src={profile} className="front" alt="icon" />
                                Profile picture
                            </div>
                            <img src={arrowRight} className="back" alt="icon" />
                        </StyledOptions>
                        <StyledOptions onClick={() => setSection(ContactSidebarSections.CONTACT_ASSOCIATED_WORKSPACES)}>
                            <div>
                                <img src={workspace} className="front" alt="icon" />
                                Associated workspaces
                            </div>
                            <img src={arrowRight} className="back" alt="icon" />
                        </StyledOptions>
                        <StyledOptions onClick={() => setSection(ContactSidebarSections.CONTACT_ASSOCIATED_COMPANIES)}>
                            <div>
                                <img src={companies} className="front" alt="icon" />
                                Associated companies
                            </div>
                            <img src={arrowRight} className="back" alt="icon" />
                        </StyledOptions>
                    </OptionsWrapper>
                ) : (
                    <OptionsWrapper>
                        <StyledOptions onClick={() => setSection(CompanySidebarSections.COMPANY_DETAILS)}>
                            <div>
                                <img src={ManageSearch} className="front" alt="icon"></img>
                                Company details
                            </div>
                            <img src={arrowRight} className="back" alt="icon"></img>
                        </StyledOptions>
                        <StyledOptions onClick={() => setSection(CompanySidebarSections.COMPANY_LOGO)}>
                            <div>
                                <img src={logo} className="front" alt="icon"></img>
                                Logo
                            </div>
                            <img src={arrowRight} className="back" alt="icon"></img>
                        </StyledOptions>
                        <StyledOptions onClick={() => setSection(CompanySidebarSections.COMPANY_ASSOCIATED_WORKSPACES)}>
                            <div>
                                <img src={workspace} className="front" alt="icon"></img>
                                Associated workspaces
                            </div>
                            <img src={arrowRight} className="back" alt="icon"></img>
                        </StyledOptions>
                        <StyledOptions onClick={() => setSection(CompanySidebarSections.COMPANY_ASSOCIATED_CONTACTS)}>
                            <div>
                                <img src={contacts} className="front" alt="icon"></img>
                                Associated contacts
                            </div>
                            <img src={arrowRight} className="back" alt="icon"></img>
                        </StyledOptions>
                    </OptionsWrapper>
                )}
            </>
        </StyledFrame>
    );
};
export const StyledFrame = styled.div`
    &:before {
        opacity: 0;
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(170, 170, 170, 0.9);
        z-index: 1;
        transition: all 0.3s ease-in-out;
    }

    &.overlay-visible {
        overflow: hidden;

        &:before {
            opacity: 1;
            pointer-events: all;
        }
    }
`;

export default AccountMainSidebar;
