import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import communication from '@Communication';
import { BlockType } from '@/types/workspace';
import { isValidUrl } from '@Helpers/regex';
import WorkspaceIcon from '@Assets/icons/analytics/no-workspace.svg';
import UserIcon from '@Assets/icons/analytics/account-circle.svg';
import ArrowIcon from '@Assets/icons/analytics/arrow-selector.svg';
import CopyIcon from '@Assets/icons/analytics/content-copy.svg';
import ShareIcon from '@Assets/icons/analytics/share-small.svg';
import EyeIcon from '@Assets/icons/analytics/visibility.svg';
import DownloadIcon from '@Assets/icons/analytics/download-small.svg';
import DefaultIcon from '@Assets/icons/files/other.svg';
import VideoIcon from '@Assets/icons/files/video.svg';
import LinkIcon from '@Assets/icons/files/link.svg';
import PlayIconSmall from '@Assets/icons/play-circle-small.svg';
import NotFoundIcon from '@Assets/icons/analytics/not-found.svg';
interface Props {
    cardType: string;
    userTitle?: string;
    userImage?: any;
    cratedAt: string;
    workspaceName: string;
    content: any;
    workspaceId?: string;
    hasDetailsLink?: boolean;
}
const ActivityCard: FunctionComponent<Props> = ({
    userTitle,
    userImage,
    cardType,
    cratedAt,
    workspaceName,
    content,
    workspaceId,
    hasDetailsLink,
}) => {
    const navigate = useNavigate();

    const getLinkActivity = (linkType: string): { icon: any; text: string } => {
        switch (linkType) {
            case 'SHARED_PAGE_SHARE':
                return { icon: ShareIcon, text: 'Sent share request' };
            case 'COPIED_SHARE_LINK':
                return { icon: CopyIcon, text: 'Copied share link' };
            case 'LINK_OPEN':
                return { icon: ArrowIcon, text: 'Clicked on a link' };
            case 'FILE_DOWNLOAD':
                return { icon: DownloadIcon, text: 'Downloaded a file' };
            case 'FILE_OPEN':
                return { icon: EyeIcon, text: 'Viewed a file' };
            case 'SHARED_PAGE_VISIT':
                return { icon: EyeIcon, text: 'Opened a workspace' };
            default:
                return { icon: '', text: '' };
        }
    };

    const isVideo = (videoType: string): boolean => {
        return ['MP4', 'MOV', 'AVI'].includes(videoType?.toUpperCase());
    };

    const handleOpenFile = (fileLink: string): void => {
        communication.downloadFile(fileLink).then((res: any) => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], {
                type,
            });
            const objectURL = URL.createObjectURL(blob);
            window.open(`${objectURL}` as string, '_blank');
        });
    };

    return (
        <StyledActivityCard>
            <ActivityCardContent>
                <TopRow>
                    <OneLineDiv>
                        <img src={userImage ? userImage : UserIcon} alt="icon" /> <h5>{userTitle}</h5>
                    </OneLineDiv>
                    {getLinkActivity(cardType).icon !== '' ? (
                        <OneLineDiv className="card-type">
                            <img src={getLinkActivity(cardType).icon} alt="icon" />
                            <p>{getLinkActivity(cardType).text}</p>
                        </OneLineDiv>
                    ) : (
                        <></>
                    )}
                </TopRow>
                <LeftBorderDiv>
                    <MiddleRow>
                        {JSON.stringify(content) !== '{}' ? (
                            content?.link ? (
                                <Link to={content?.link} rel="noreferrer" target="_blank">
                                    <p>{content?.link}</p>
                                </Link>
                            ) : (
                                <div
                                    className="file-info cursor-pointer"
                                    onClick={() => content.available !== false && handleOpenFile(content.fileLink)}
                                >
                                    <div className="thumbnail-wrapper">
                                        <div className="tile-thumbnail">
                                            {content.available !== false ? (
                                                <>
                                                    <div className="tile-extension">.{content.fileType}</div>
                                                    {content.fileThumbnail ? (
                                                        <div
                                                            className={`${
                                                                isVideo(content.fileType) ? 'video' : ''
                                                            }`.trim()}
                                                        >
                                                            <img
                                                                className="tile-image"
                                                                src={
                                                                    content.fileType === BlockType.Link &&
                                                                    isValidUrl(content.fileThumbnail as string)
                                                                        ? content.fileThumbnail
                                                                        : `${process.env.REACT_APP_API_URL}/files/thumbnail/${content.fileThumbnail}`
                                                                }
                                                                crossOrigin="anonymous"
                                                                alt="tile"
                                                            />
                                                            {isVideo(content.fileType) ? (
                                                                <img
                                                                    src={PlayIconSmall}
                                                                    alt="play icon"
                                                                    className="play-icon cursor-pointer"
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <img
                                                            alt="tile"
                                                            className="placeholder-image"
                                                            src={
                                                                isVideo(content.fileType)
                                                                    ? VideoIcon
                                                                    : content.fileType === 'link'
                                                                    ? LinkIcon
                                                                    : DefaultIcon
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <img
                                                    alt="not found photo"
                                                    className="placeholder-image"
                                                    src={NotFoundIcon}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {content.available !== false ? (
                                            <>
                                                <p className="file-name">{content.fileName}</p>
                                                <p>{`${process.env.REACT_APP_API_URL}/files/thumbnail/${content.fileLink}`}</p>
                                            </>
                                        ) : (
                                            <p>File not found, it may have been deleted.</p>
                                        )}
                                    </div>
                                </div>
                            )
                        ) : (
                            <></>
                        )}
                    </MiddleRow>
                    <BottomRow>
                        <OneLineDiv
                            className="cursor-pointer"
                            onClick={() =>
                                workspaceId !== undefined ? navigate(`/workspaces/${workspaceId}`) : navigate('/404')
                            }
                        >
                            <img src={WorkspaceIcon} alt="icon" />{' '}
                            <p>
                                {workspaceId !== undefined
                                    ? workspaceName
                                    : 'Workspace not found, it may have been deleted.'}
                            </p>
                        </OneLineDiv>
                        <div>
                            <span>{moment(cratedAt).fromNow()}</span>
                        </div>
                    </BottomRow>
                </LeftBorderDiv>
            </ActivityCardContent>
            {hasDetailsLink ? (
                <StyledView>
                    <StyledLink to={`/analytics/workspace/${workspaceId}?name=${workspaceName}`}>Details</StyledLink>
                </StyledView>
            ) : (
                <></>
            )}
        </StyledActivityCard>
    );
};
const StyledActivityCard = styled.div`
    margin-bottom: 18px;
`;
const ActivityCardContent = styled.div`
    border-radius: 8px;
    background: rgba(249, 249, 250, 0.5);
    margin-bottom: 8px;
    padding: 18px;
`;
const TopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Media Queries */
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: start;
        div {
            margin-bottom: 15px;
        }
    }
`;
const LeftBorderDiv = styled.div`
    padding-left: 44px;
    position: relative;
    &:before {
        content: '';
        background: #afafb7;
        position: absolute;
        bottom: 0;
        left: 16px;
        height: 100%;
        width: 1px;
    }
`;
const MiddleRow = styled.div`
    margin: 17px 0;
    font-size: 14px;
    > p {
        color: #afafb7;
    }
    a {
        text-decoration: none;
        > p {
            color: #afafb7;
        }
    }
    .file-info {
        display: flex;
        align-items: center;
        a,
        p {
            color: #afafb7;
            line-height: 19.6px;
            &.file-name {
                font-weight: 700;
            }
        }
        /* Media Queries */
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: start;
        }
    }
    .thumbnail-wrapper {
        cursor: pointer;
        display: flex;
        position: relative;
        width: 90px;
        border-radius: 8px;
        margin-right: 12px;
        .tile-thumbnail {
            width: 90px;
            height: 90px;
            background-color: var(--background-grey);
            border-radius: 8px;

            .tile-image {
                width: 90px;
                height: 90px;
                border-radius: 8px;
                object-fit: cover;
            }

            .placeholder-image {
                width: 90px;
                height: 90px;
                object-fit: scale-down;
            }
            .video {
                position: relative;
                height: 90px;
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 8px;
                    background: rgba(0, 0, 0, 0.18);
                    z-index: 0;
                }
                img {
                    &.play-icon {
                        width: 30px;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 50%);
                        bottom: 50%;
                        z-index: 1;
                    }
                }
            }
        }

        .tile-extension {
            background-color: var(--black-250);
            color: white;
            position: absolute;
            top: 8px;
            left: 8px;
            font-size: 8px;
            padding: 3px 5px;
            border-radius: 5px;
            font-weight: 700;
            text-transform: uppercase;
            z-index: 1;
        }
    }
`;
const BottomRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        color: #afafb7;
        font-size: 12px;
    }
`;
const OneLineDiv = styled.div`
    display: flex;
    align-items: center;
    h5 {
        font-weight: 400;
    }
    img {
        margin-right: 12px;
    }
    p {
        color: var(--black-60);
    }
    &.card-type {
        p {
            font-size: 12px;
            font-weight: 600;
        }
        img {
            margin-right: 4px;
        }
    }
`;

const StyledView = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:before {
        content: '';
        flex: 1;
        background: var(--black-20);
        height: 1px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    border-radius: 15px;
    background: #afafb7;
    color: var(--white);
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 2px 16px;
`;
export default ActivityCard;
