const isHtmlEmpty = (html: string): boolean => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const elements = doc.querySelectorAll('*');

    let containsText = false;
    elements.forEach(function (element) {
        if (element.textContent !== '') {
            containsText = true;
        }
    });
    return !containsText;
};

export default isHtmlEmpty;
