export const EmbedTypes = [
    'anything',
    'google-docs',
    'google-sheets',
    'google-slides',
    'google-forms',
    'youtube',
    'vimeo',
    'loom',
    'airtable',
    'typeform',
    'figma',
];
