type HotkeysMappingType = {
    id: string;
    callback: Function;
    key: string;
};
abstract class GlobalHotkeys {
    private static functions: HotkeysMappingType[] = [];

    public static init(): any {
        document.onkeydown = (e) => {
            for (const map of this.functions) {
                if (e.key === map.key) map.callback();
            }
        };
    }

    public static add(id: string, key: string, fn: Function): void {
        const existing = this.functions.find((f) => f.id === id);
        if (existing) {
            // Function with the same ID already exists
            return;
        }
        this.functions.push({ id, callback: fn, key });
    }

    public static getList(): Pick<HotkeysMappingType, 'id' | 'key'>[] {
        return this.functions.map((f) => {
            return {
                id: f.id,
                key: f.key,
            };
        });
    }
}

export default GlobalHotkeys;
