import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RequestDto, RequestType, ShareTypeData } from '@Global/dtos/request.dto';
import colors from '@Global/colors';
import Button, { ButtonTheme } from '@Components/button';
import ArrowIcon from '@Assets/icons/requests/arrow.svg';
interface Props {
    requestData: RequestDto;
}

const RequestActionButton: React.FunctionComponent<Props> = ({ requestData }) => {
    const navigate = useNavigate();

    switch (requestData.type) {
        case RequestType.share:
            return (
                <Button
                    theme={ButtonTheme.link}
                    color={colors.primary400}
                    className="go-to-btn"
                    rightIcon={ArrowIcon}
                    onClick={() => navigate(`/workspaces/${(requestData.data as ShareTypeData).workspaceId}`)}
                >
                    Go to workspace
                </Button>
            );
    }
};

export default RequestActionButton;
