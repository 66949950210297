export default {
    white: '#FFFFFF',
    black: '#00000',

    primary50: '#e6f6f5',
    primary100: '#6bcac6',
    primary200: '#2bb3ad',
    primary300: '#00a39c',
    primary400: '#00726d',
    primary500: '#00635f',

    red150: '#FF7E7F',

    grey: '#eff1f9',
    grey10: '#f4f5fa',
    darkGrey: '#53545C',
    backgroundGrey: '#F9F9FA',
    inputGrey: '#F3F3F3',

    black20: '#F9F9FA',
    black50: '#EAEAEA',
    black60: '#45464E',
    black75: '#AAAAAA',
    black100: '#868686',
    black200: '#525252',
    black250: '#45464E',
    black300: '#2F2F2F',

    boxShadow: '0 2px 8px 0 rgba(82, 82, 82, 0.16)',
};
