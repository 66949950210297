import { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import attachSize from '@Helpers/size';

interface Props {
    className?: string | '';
    isVisible: boolean;
    closePopup?: Function;
    children: React.ReactNode;
    size?: number | string;
    timer?: number;
}

const Popup: FunctionComponent<Props> = ({
    className,
    isVisible,
    closePopup = () => {
        return;
    },
    children,
    size,
    timer,
}) => {
    useEffect(() => {
        if (isVisible && timer) {
            setTimeout(() => {
                closePopup();
            }, timer * 1000);
        }
    }, [isVisible]);

    return (
        <StyledPopup
            className={`${className ? className : ''} ${isVisible ? 'visible' : ''}`}
            closePopup={closePopup}
            size={size}
            isVisible={isVisible}
        >
            <div className="modal-backdrop" />
            <div className="modal-wrapper">{children}</div>
        </StyledPopup>
    );
};

const StyledPopup = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    div {
        &.modal-backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(170, 170, 170, 0.5);
            backdrop-filter: blur(2px);
        }
    }

    .modal-wrapper {
        background-color: white;
        border: 1px solid var(--grey);
        box-shadow: 0 1px 12px rgb(0 0 0 / 34%);
        border-radius: 5px;
        z-index: 1;
        padding: 17px 27px 30px 27px;
        width: ${(props) => attachSize(props.size)};

        @media only screen and (max-width: 768px) {
            margin: 0 10px;
            width: 100%;
        }
    }
`;
export default Popup;
