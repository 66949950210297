import styled from 'styled-components';

export const StyledHeader = styled.div`
    display: flex;
    padding-bottom: 25px;
    cursor: pointer;
    align-items: center;
    img {
        margin-right: 4px;
        &.front {
            margin-right: 10px;
            position: relative;
            /* Adjust these values accordingly */
            top: 5px;
        }
        &.back {
            padding-right: 10px;
            float: right;
            position: relative;
            /* Adjust these values accordingly */
            top: 5px;
            left: 5px;
        }
    }
`;
export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    margin: 18px 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;

    img {
        margin-right: 12px;
    }
`;
export const ButtonStyle = styled.div`
    margin: 20px 0 10px 0;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

export const TwoButtonsStyle = styled.div`
    margin: 20px 0;
    display: flex;
    gap: 12px;
    .button {
        width: 100%;
    }
`;
export const PositionStyle = styled.div`
    flex: 1;

    textarea {
        height: 200px;
    }
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
