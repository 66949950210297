import { FunctionComponent, SetStateAction, Dispatch } from 'react';
import Button, { ButtonTheme } from '@Components/button';
import UploadFile from '../../../uploadFile';
interface Props {
    file: File | null;
    isLoading: boolean;
    changeFileThumbnail: () => void;
    setFile: Dispatch<SetStateAction<File | null>>;
}

const ChangeThumbnailModal: FunctionComponent<Props> = ({ file, isLoading, changeFileThumbnail, setFile }) => {
    return (
        <>
            <UploadFile file={file} isLoading={isLoading} setFile={setFile} />
            <Button
                theme={ButtonTheme.primary}
                onClick={changeFileThumbnail}
                disabled={!file}
                isLoading={isLoading}
                size={160}
            >
                Change
            </Button>
        </>
    );
};
export default ChangeThumbnailModal;
