import React, { useState, useEffect } from 'react';
import Button, { ButtonTheme } from '@Components/button';
import communication from '@Communication';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Modal from '@Components/modal';
import useWindowSize from '@Hooks/useResize';
import Input, { InputComponentIconPosition, InputComponentType, InputComponentTheme } from '@Components/input';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';
import TemplatesTable from './table';
import useUserStore from '@Store/user';
import { useToasts } from 'react-toast-notifications';
import SelectComponent, { SelectOptionType } from '@/components/select';
import { TemplateDto } from '@/global/dtos/template.dto';
import SearchIcon from '@Assets/icons/search-normal.svg';
import { StyledSearch, StyledFilters, StyledWrapper } from '@/pages/workspaces';

const Templates: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { id } = useUserStore();
    const size = useWindowSize();
    const { addToast, removeAllToasts } = useToasts();

    const [createTemplateModalVisibility, setCreateTemplateModalVisibility] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [allTemplates, setAllTemplates] = useState<TemplateDto[] | null>(null);
    const [categories, setCategories] = useState<SelectOptionType[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [filterCategory, setFilterCategory] = useState<SelectOptionType | null>(null);
    const [filteredTemplates, setFilteredTemplates] = useState<TemplateDto[] | null>(null);
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    const createNewTemplate = async (): Promise<void> => {
        removeAllToasts();
        try {
            const response = await communication.createTemplate({
                name: templateName,
                creatorId: id as string,
                editorId: id as string,
            });
            setTemplateName('');
            setCreateTemplateModalVisibility(false);
            navigate(`/templates/${response.data.id}`);
            addToast('Template successfully created!', {
                appearance: 'success',
            });
        } catch (e: any) {
            const isExisting = e.response.data?.code === 1022;
            addToast(
                isExisting ? 'Please create a unique template name!' : 'Unable to create template. Please try again',
                {
                    appearance: 'error',
                },
            );
        }
    };

    useEffect(() => {
        communication.getAllTemplates().then((res: any) => {
            setFilteredTemplates(res?.data);
            setAllTemplates(res?.data);
        });
    }, []);

    useEffect(() => {
        if (allTemplates) {
            const categoriesOptions: SelectOptionType[] = [];
            for (const template of allTemplates) {
                if (
                    template.category &&
                    !categoriesOptions.find((category) => category.value === template.category?.id)
                ) {
                    categoriesOptions.push({ value: template.category.id, label: template.category.name });
                }
            }
            setCategories(categoriesOptions);
        }
    }, [allTemplates]);

    useEffect(() => {
        if (allTemplates) {
            if (filterCategory) {
                setFilteredTemplates(
                    allTemplates.filter((template) =>
                        filterCategory ? template.category?.id === filterCategory.value : true,
                    ),
                );
            } else {
                setFilteredTemplates(allTemplates);
            }
        }
    }, [filterCategory]);

    return (
        <>
            <Modal
                modalVisible={createTemplateModalVisibility}
                closeModal={() => {
                    setCreateTemplateModalVisibility(false);
                    setTemplateName('');
                }}
                size={500}
                title="Create new template"
            >
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Input
                            type={InputComponentType.Text}
                            theme={InputComponentTheme.Light}
                            size="100%"
                            value={templateName}
                            onChange={(value: string) => setTemplateName(value)}
                            placeholder="Template name"
                            onEnter={
                                templateName !== ''
                                    ? createNewTemplate
                                    : () => {
                                          return;
                                      }
                            }
                        />
                        <Button
                            theme={ButtonTheme.primary}
                            onClick={createNewTemplate}
                            disabled={templateName === ''}
                            size={160}
                        >
                            Create
                        </Button>
                    </div>
                </StyledNewBlockModal>
            </Modal>
            <div className="page workspaces">
                <div className="top-bar">
                    <h3>Templates</h3>

                    <Button
                        theme={ButtonTheme.primary}
                        rightIcon={AddIcon}
                        size={isMobileWidth ? 80 : 200}
                        onClick={() => {
                            setCreateTemplateModalVisibility(true);
                        }}
                    >
                        {isMobileWidth ? 'New' : '  New template'}
                    </Button>
                </div>
                <StyledWrapper>
                    <StyledFilters>
                        <StyledSearch
                            placeholder="Search"
                            type={InputComponentType.Text}
                            value={searchValue}
                            icon={SearchIcon}
                            size={366}
                            iconPosition={InputComponentIconPosition.Right}
                            onChange={(value: string) => setSearchValue(value)}
                        ></StyledSearch>
                        <StyledSelectComponent
                            optionList={categories}
                            value={filterCategory}
                            placeholder="Filter by category"
                            isClearable={true}
                            isSearchable={false}
                            onChange={(selectedOption) => {
                                setFilterCategory(selectedOption as SelectOptionType);
                            }}
                        ></StyledSelectComponent>
                    </StyledFilters>
                    <TemplatesTable
                        templates={filteredTemplates?.filter((t) =>
                            t.name.toLowerCase().includes(searchValue.toLowerCase()),
                        )}
                        setCreateTemplateModalVisibility={setCreateTemplateModalVisibility}
                        setAllTemplates={setAllTemplates}
                        setFilteredTemplates={setFilteredTemplates}
                    />
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledSelectComponent = styled(SelectComponent)`
    margin-left: 12px;
`;

const StyledNewBlockModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        .input {
            margin: 10px 0;
        }
        .button {
            margin-top: 26px;
        }
    }
`;

export default Templates;
