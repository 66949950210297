import React, { useEffect, useState } from 'react';
import Button, { ButtonTheme } from '@Components/button';
import WorkspaceTable from './table';
import communication from '@Communication';
import useUserStore from '@Store/user';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Modal from '@Components/modal';
import Input, { InputComponentIconPosition, InputComponentType, InputComponentTheme } from '@Components/input';
import SelectComponent, { SelectOptionType } from '@Components/select';
import { CreateWorkspaceDto, WorkspaceDto } from '@Global/dtos/workspace.dto';
import useWindowSize from '@Hooks/useResize';
import NoteIcon from '@Assets/icons/workspaces/note.svg';
import LayersIcon from '@Assets/icons/workspaces/layers.svg';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';
import { useToasts } from 'react-toast-notifications';
import SearchIcon from '@Assets/icons/search-normal.svg';
import NewWorkspaceCard from './newWorkspaceCard';
import FromTemplate from './fromTemplate';
import TemplatePreview from './templatePreview';
import ArrowLeftIcon from '@Assets/icons/navbar/arrow-left.svg';

const Workspaces: React.FunctionComponent = () => {
    const { id } = useUserStore();
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const size = useWindowSize();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);
    const [isCreateNewVisible, setIsCreateNewVisible] = useState(false);
    const [isCreateFromTemplateVisible, setCreateFromTemplateVisible] = useState(false);
    const [isChoiceModalVisible, setIsChoiceModalVisible] = useState(false);
    const [isTemplatePreviewVisible, setIsTemplatePreviewVisible] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [workspaceName, setWorkspaceName] = useState('');

    const [filterCategory, setFilterCategory] = useState<SelectOptionType | null>(null);
    const [filterOwner, setFilterOwner] = useState<SelectOptionType | null>(null);
    const [filteredWorkspaces, setFilteredWorkspaces] = useState<WorkspaceDto[] | null>(null);
    const [allWorkspaces, setAllWorkspaces] = useState<WorkspaceDto[] | null>(null);
    const [categories, setCategories] = useState<SelectOptionType[]>([]);
    const [owners, setOwners] = useState<SelectOptionType[]>([]);
    const [activeTab, setActiveTab] = useState(1);
    const [previewTemplateId, setPreviewTemplateId] = useState<string | null>(null);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    const createNewWorkspace = async (templateId = ''): Promise<void> => {
        try {
            const dataToSend: CreateWorkspaceDto = {
                name: workspaceName === '' ? 'Untitled workspace' : workspaceName,
                // category: workspaceCategory,
                owner: id as string,
            };
            if (templateId) {
                dataToSend.templateId = templateId;
            }
            const response = await communication.createWorkspace(dataToSend);
            setIsCreateNewVisible(false);
            setWorkspaceName('');
            navigate(`/workspaces/${response.data.id}`);
        } catch (e) {
            setWorkspaceName('');
            addToast('Unable to create new workspace. Please try again.', {
                appearance: 'error',
            });
        }
    };

    useEffect(() => {
        activeTab === 1
            ? communication.getAllWorkspaces().then((res: any) => {
                  setAllWorkspaces(res?.data);
                  setFilteredWorkspaces(res?.data);
              })
            : communication.getAllArchivedWorkspaces().then((res: any) => {
                  setAllWorkspaces(res?.data);
                  setFilteredWorkspaces(res?.data);
              });
    }, [activeTab]);

    useEffect(() => {
        if (allWorkspaces) {
            const categoriesOptions: SelectOptionType[] = [];
            const ownersOptions: SelectOptionType[] = [];
            for (const workspace of allWorkspaces) {
                if (
                    workspace.category &&
                    !categoriesOptions.find((category) => category.value === workspace.category?.id)
                ) {
                    categoriesOptions.push({ value: workspace.category!.id, label: workspace.category!.name });
                }
                if (workspace.owner && !ownersOptions.find((owner) => owner.value === workspace.owner)) {
                    ownersOptions.push({
                        value: workspace.owner,
                        label:
                            workspace.user?.firstName && workspace.user?.lastName
                                ? workspace.user?.firstName + ' ' + workspace.user?.lastName
                                : workspace.user?.firstName ?? '',
                    });
                }
            }
            setCategories(categoriesOptions);
            setOwners(ownersOptions);
        }
    }, [allWorkspaces]);

    useEffect(() => {
        if (allWorkspaces) {
            if (filterCategory || filterOwner) {
                setFilteredWorkspaces(
                    allWorkspaces
                        .filter((workspace) =>
                            filterCategory ? workspace.category?.id === filterCategory.value : true,
                        )
                        .filter((workspace) => (filterOwner ? workspace.owner === filterOwner.value : true)),
                );
            } else {
                setFilteredWorkspaces(allWorkspaces);
            }
        }
    }, [filterCategory, filterOwner]);

    return (
        <>
            <Modal
                modalVisible={isCreateNewVisible}
                closeModal={() => {
                    setIsCreateNewVisible(false);
                    setWorkspaceName('');
                }}
                size={500}
                title="Create new workspace"
            >
                <StyledModal>
                    <div className="modal-content">
                        <Input
                            type={InputComponentType.Text}
                            theme={InputComponentTheme.Light}
                            size="100%"
                            value={workspaceName}
                            onChange={(value: string) => setWorkspaceName(value)}
                            placeholder="Add workspace name"
                            onEnter={() =>
                                workspaceName !== ''
                                    ? createNewWorkspace()
                                    : () => {
                                          return;
                                      }
                            }
                        />
                        <div className="action-block">
                            <Button
                                theme={ButtonTheme.primary}
                                onClick={() => createNewWorkspace()}
                                disabled={!workspaceName}
                                size={160}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </StyledModal>
            </Modal>

            <Modal
                modalVisible={isCreateFromTemplateVisible}
                closeModal={() => {
                    setCreateFromTemplateVisible(false);
                }}
                size={730}
                title="Create new workspace"
            >
                <StyledModal>
                    <div className="modal-content">
                        <FromTemplate
                            createNewWorkspace={createNewWorkspace}
                            setIsTemplatePreviewVisible={setIsTemplatePreviewVisible}
                            setCreateFromTemplateVisible={setCreateFromTemplateVisible}
                            setPreviewTemplateId={setPreviewTemplateId}
                        />
                    </div>
                </StyledModal>
            </Modal>

            <Modal
                modalVisible={isChoiceModalVisible}
                closeModal={() => setIsChoiceModalVisible(false)}
                title="Create new workspace"
            >
                <StyledNewWorkspaceModal>
                    <div className="modal-content new-block">
                        <NewWorkspaceCard
                            onClick={() => {
                                setIsCreateNewVisible(true);
                                setIsChoiceModalVisible(false);
                            }}
                            imageSrc={NoteIcon}
                            title="Empty"
                            paragraph="Create new workspace from scratch."
                        />
                        <NewWorkspaceCard
                            imageSrc={LayersIcon}
                            onClick={() => {
                                setCreateFromTemplateVisible(true);
                                setIsChoiceModalVisible(false);
                            }}
                            title="From template"
                            paragraph="Create new workspace from template."
                        />
                    </div>
                </StyledNewWorkspaceModal>
            </Modal>

            <Modal
                modalVisible={isTemplatePreviewVisible}
                closeModal={() => {
                    setIsTemplatePreviewVisible(false);
                }}
                size={730}
                title={
                    <div
                        className="cursor-pointer"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                            setCreateFromTemplateVisible(true);
                            setIsTemplatePreviewVisible(false);
                        }}
                    >
                        <img style={{ paddingRight: '5px' }} src={ArrowLeftIcon} alt="back" />
                        <span>Back to templates</span>
                    </div>
                }
            >
                <StyledModal>
                    <div className="modal-content">
                        <TemplatePreview
                            templateId={previewTemplateId}
                            setIsTemplatePreviewVisible={setIsTemplatePreviewVisible}
                            createNewWorkspace={createNewWorkspace}
                        />
                    </div>
                </StyledModal>
            </Modal>

            <div className="page workspaces">
                <div className="top-bar">
                    <h3>Workspaces</h3>
                    <Button
                        theme={ButtonTheme.primary}
                        rightIcon={AddIcon}
                        size={isMobileWidth ? 80 : 200}
                        onClick={() => setIsChoiceModalVisible(true)}
                    >
                        {isMobileWidth ? 'New' : 'New workspace'}
                    </Button>
                </div>
                <div className="tabs">
                    <StyledTabsHeader
                        className={`tab ${activeTab === 1 ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab(1);
                        }}
                    >
                        <p>Active</p>
                    </StyledTabsHeader>
                    <StyledTabsHeader
                        className={`tab ${activeTab === 0 ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab(0);
                        }}
                    >
                        <p>Archived</p>
                    </StyledTabsHeader>
                </div>
                <StyledWrapper>
                    <StyledFilters>
                        <StyledSearch
                            placeholder="Search"
                            type={InputComponentType.Text}
                            value={searchValue}
                            icon={SearchIcon}
                            size={366}
                            iconPosition={InputComponentIconPosition.Right}
                            onChange={(value: string) => setSearchValue(value)}
                        ></StyledSearch>
                        <StyledSelectWrapper>
                            <StyledSelectComponent
                                optionList={categories}
                                value={filterCategory}
                                placeholder="Filter by category"
                                isClearable={true}
                                isSearchable={false}
                                onChange={(selectedOption) => {
                                    setFilterCategory(selectedOption as SelectOptionType);
                                }}
                            ></StyledSelectComponent>
                            <StyledSelectComponent
                                optionList={owners}
                                value={filterOwner}
                                placeholder="Filter by owner"
                                isClearable={true}
                                isSearchable={false}
                                onChange={(selectedOption) => {
                                    setFilterOwner(selectedOption as SelectOptionType);
                                }}
                            ></StyledSelectComponent>
                        </StyledSelectWrapper>
                    </StyledFilters>
                    <WorkspaceTable
                        workspaces={filteredWorkspaces?.filter((w) =>
                            w.name.toLowerCase().includes(searchValue.toLowerCase()),
                        )}
                        setIsChoiceModalVisible={setIsChoiceModalVisible}
                        isArchived={activeTab === 0 ? true : false}
                        setAllWorkspaces={setAllWorkspaces}
                        setFilteredWorkspaces={setFilteredWorkspaces}
                        activeTab={activeTab}
                    />
                </StyledWrapper>
            </div>
        </>
    );
};

export const StyledFilters = styled.div`
    border-bottom: 1px solid var(--black-50);
    padding: 13px 0;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Media Queries */
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;
export const StyledSelectWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const StyledWrapper = styled.div`
    border-radius: 8px 8px 0px 0px;
    background: var(--white);
    max-width: 1600px;
    margin: 0 auto 30px;
`;
const StyledSelectComponent = styled(SelectComponent)`
    margin-left: 12px;
`;

export const StyledSearch = styled(Input)`
    .input-container {
        /* Media Queries */
        @media only screen and (max-width: 900px) {
            width: auto;
        }
        @media only screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
        input {
            height: 50px;
        }
        &:after {
            content: '';
            width: 1px;
            height: 34px;
            background: var(--white);
            position: absolute;
            right: 42px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:has(input:focus) {
            &:after {
                background: var(--black-20);
            }
        }
    }
`;

const StyledModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        .input {
            margin: 10px 0;
        }

        .button {
            &.see-all {
                font-weight: 600;
                padding: 0;
            }
        }
        .action-block {
            display: flex;
            .button {
                margin-top: 26px;
            }
        }
    }
`;

const StyledNewWorkspaceModal = styled(StyledModal)`
    .modal-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    }
`;

export const StyledTabsHeader = styled.div`
    padding: 9px 20px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    border-bottom: none;
    user-select: none;
    font-weight: 600;
    background-color: #cfcfd7;
    color: var(--white);
    font-size: 16px;

    &.active {
        color: var(--black-75);
        background-color: white;
    }

    &:first-of-type {
        border-radius: 8px 8px 0px 0px;
    }
    &:last-of-type {
        border-radius: 0px 8px 0px 0px;
    }
`;

export default Workspaces;
