import { FunctionComponent, useState, useEffect, MouseEvent, ChangeEvent } from 'react';
import Chat from '@Assets/icons/settings/chat.svg';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import { TwoButtonsStyle, MainWrapper, PositionStyle, StyledHeader, StyledTitle } from '../styledComponents';
import useWorkspaceStore from '@Store/workspace';
import communication from '@Communication';
import Communication from '@Communication';
import useLoading from '@Hooks/useLoading';
import useEditorStore from '@Store/editor';
import { useToasts } from 'react-toast-notifications';
import VideoIcon from '@Assets/icons/files/video.svg';
import styled from 'styled-components';
import Button, { ButtonTheme } from '@Components/button';
import TextArea from '@Components/input/textArea';
import Video from '@Components/video';

interface Props {
    isActive: boolean;
    changePage: Function;
}

const WelcomeMessage: FunctionComponent<Props> = ({ isActive, changePage }) => {
    const { getWorkspaceData, updatePublishStatus } = useWorkspaceStore();
    const { id, title, sections } = useEditorStore();
    const { addToast, removeAllToasts } = useToasts();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(getWorkspaceData().sidePanelContent.welcomeMessage);
    const [video, setVideo] = useState(getWorkspaceData().sidePanelContent.welcomeVideo);
    const [file, setFile] = useState<File | null>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const { showLoading, hideLoading } = useLoading();

    const update = (): void => {
        setIsLoading(true);
        removeAllToasts();
        const tmpData = getWorkspaceData().sidePanelContent;
        tmpData.welcomeMessage = message;
        communication
            .updateWorkspaceContent(id, { title, sections, sidePanelContent: tmpData })
            .then(() => {
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                useWorkspaceStore.setState({
                    sidePanelContent: tmpData,
                });
                setHasChanges(false);
                setIsLoading(false);
                updatePublishStatus(false);
            })
            .catch(() => {
                addToast('Something went wrong! Please check the entered data.', {
                    appearance: 'error',
                });
                setIsLoading(false);
            });
    };

    const upload = (): void => {
        if (file) {
            showLoading();
            const formData = new FormData();
            formData.append('files', file);
            Communication.uploadWorkspaceWelcomeVideo(id, formData)
                .then((res: any) => {
                    hideLoading();
                    useWorkspaceStore.setState({ sidePanelContent: res.data.content.sidePanelContent });
                    setVideo(res.data.content.sidePanelContent?.welcomeVideo);
                })
                .catch((err: Error) => {
                    hideLoading();
                    console.error(err);
                });
        }
        return;
    };

    const removeWelcomeVideo = (): void => {
        setFile(null);
        showLoading();
        Communication.removeWorkspaceWelcomeVideo(id)
            .then((res: any) => {
                hideLoading();
                useWorkspaceStore.setState({ sidePanelContent: res.data.content.sidePanelContent });
                setVideo('');
            })
            .catch((err: Error) => {
                hideLoading();
                console.error(err);
            });
    };

    const inputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 50 * 1024 * 1024) {
                addToast('File too large! Please upload a file less than 50 MB.', {
                    appearance: 'error',
                });
                return;
            }
            setFile(e.target.files[0]);
        }
    };

    const fileInputClick = (
        e: MouseEvent<HTMLInputElement> & {
            target: HTMLInputElement;
        },
    ): void => {
        e.target.value = '';
        setFile(null);
    };

    useEffect(() => {
        upload();
    }, [file]);

    useEffect(() => {
        if (message !== getWorkspaceData().sidePanelContent.welcomeMessage) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [message]);

    return (
        <MainWrapper>
            {isActive ? (
                <>
                    <StyledHeader onClick={() => changePage(0)}>
                        <img src={arrowLeft} alt="icon"></img>
                        Settings
                    </StyledHeader>
                    <PositionStyle>
                        <StyledTitle>
                            <img src={Chat} alt="icon" />
                            Welcome message
                        </StyledTitle>
                        <TextArea
                            label="Welcome message"
                            placeholder="Type here or leave blank"
                            maxLength={500}
                            value={message}
                            onChange={(value: string) => setMessage(value)}
                        ></TextArea>
                        <StyledTitle>
                            <img src={Chat} alt="icon" />
                            Welcome video
                        </StyledTitle>
                        <StyledVideoWrapper>
                            {video ? (
                                <Video
                                    videoUrl={`${process.env.REACT_APP_API_URL}/workspaces/welcome/${video}`}
                                    smallPlayIcon
                                />
                            ) : (
                                <div className="video-placeholder">
                                    <img alt="placeholder" src={VideoIcon} />
                                </div>
                            )}
                            {video ? (
                                <label onClick={() => removeWelcomeVideo()}>Remove welcome video</label>
                            ) : (
                                <label className="upload-label" htmlFor={`welcome-video-${id}`}>
                                    Select welcome video
                                </label>
                            )}
                            <input
                                id={`welcome-video-${id}`}
                                accept="video/*"
                                type="file"
                                onChange={inputChange}
                                onClick={fileInputClick}
                            />
                        </StyledVideoWrapper>
                    </PositionStyle>
                    {hasChanges && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                isLoading={isLoading}
                                onClick={() => {
                                    update();
                                }}
                            >
                                Save
                            </Button>
                            <Button className="clickable" theme={ButtonTheme.naked} onClick={() => changePage(0)}>
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                </>
            ) : (
                <></>
            )}
        </MainWrapper>
    );
};

const StyledVideoWrapper = styled.div`
    margin-top: 30px;
    height: fit-content;
    position: relative;
    transition: 0.3s all ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;

    &.blur {
        margin-top: 0;
    }

    .video-placeholder {
        width: 100%;
        height: 100px;
        background-color: var(--background-grey);
        border-radius: 5px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    & > video {
        pointer-events: none;
        max-width: 100%;
        object-fit: scale-down;
        border-radius: 5px;
    }

    video {
        display: block;
        border-radius: 5px;
    }

    input {
        display: none;
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        //background-color: rgba(0, 0, 0, 0.3);
        //color: white;
        background-color: rgba(243, 243, 243, 0.77);
        color: black;
        //text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s all ease-in-out;
        z-index: 3;
    }

    &:hover {
        label {
            opacity: 1;
            pointer-events: all;
            font-size: 15pt;
        }
    }
`;

export default WelcomeMessage;
