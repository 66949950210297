import { FunctionComponent, MouseEventHandler } from 'react';
import ArrowLeft from '@Assets/icons/backLink/arrow-left.svg';
import styled from 'styled-components';
interface Props {
    onClick: MouseEventHandler;
    className?: string;
    href?: string;
    text?: string | any;
}

const BackLink: FunctionComponent<Props> = ({ onClick, text, className, href }) => {
    return href ? (
        <a href={href}>
            <StyledBackLink onClick={onClick} className={`${className ? className : ''}`.trim()}>
                <img alt="arrow-left" src={`${ArrowLeft}`} />
                <p>{text}</p>
            </StyledBackLink>
        </a>
    ) : (
        <StyledBackLink onClick={onClick} className={`${className ? className : ''}`.trim()}>
            <img alt="arrow-left" src={`${ArrowLeft}`} />
            <p>{text}</p>
        </StyledBackLink>
    );
};

const StyledBackLink = styled.div<Props>`
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        margin-right: 13px;
    }
    p {
        color: var(--black-200);
    }
`;

export default BackLink;
