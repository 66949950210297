import { FunctionComponent, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import communication from '@Communication';
import { CategoryDto, CategoryType } from '@/global/dtos/categories.dto';
import Button, { ButtonTheme } from '@Components/button';
import Input, { InputComponentType, InputComponentTheme } from '@Components/input';
import Modal from '@Components/modal';
import { StyledNewBlockModal } from '../categoriesTable';

interface Props {
    type: CategoryType;
    createCategoryModalVisibility: boolean;
    setCreateCategoryModalVisibility: Dispatch<SetStateAction<boolean>>;
    setCategories: Dispatch<SetStateAction<CategoryDto[] | null>>;
}

const AddNewCategory: FunctionComponent<Props> = ({
    type,
    createCategoryModalVisibility,
    setCreateCategoryModalVisibility,
    setCategories,
}) => {
    const { addToast, removeAllToasts } = useToasts();
    const [name, setName] = useState('');

    const createNewCategory = async (): Promise<void> => {
        removeAllToasts();
        try {
            const res = await communication.createCategory({
                name,
                type: type,
                protected: true,
                factory: false,
            });
            const newCategory: CategoryDto = res?.data;
            setCategories((prev) => [...(prev as CategoryDto[]), newCategory]);
            setCreateCategoryModalVisibility(false);
            setName('');
            addToast('Category created!', {
                appearance: 'success',
            });
        } catch (e: any) {
            const isExisting = e.response.data?.code === 1027;
            addToast(isExisting ? 'Please create a unique category name!' : 'Something went wrong!', {
                appearance: 'error',
            });
        }
    };
    return (
        <>
            <Modal
                modalVisible={createCategoryModalVisibility}
                closeModal={() => {
                    setCreateCategoryModalVisibility(false);
                    setName('');
                }}
                size={500}
                title={`Add ${type.toLowerCase()} category`}
            >
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Input
                            type={InputComponentType.Text}
                            theme={InputComponentTheme.Light}
                            size="100%"
                            value={name}
                            onChange={(value: string) => setName(value)}
                            placeholder="Category name"
                        />
                        {/* {canCreateProtected() && (
                <ToggleSwitch
                    label="Protected"
                    checked={categoryProtected}
                    onChange={(value: boolean) => setCategoryProtected(!value)}
                ></ToggleSwitch>
            )} */}
                        <TwoButtonsStyle>
                            <Button
                                theme={ButtonTheme.primary}
                                onClick={createNewCategory}
                                disabled={name === ''}
                                size={160}
                            >
                                Save
                            </Button>
                            <Button
                                theme={ButtonTheme.naked}
                                onClick={() => {
                                    setCreateCategoryModalVisibility(false);
                                    setName('');
                                }}
                                size={160}
                            >
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    </div>
                </StyledNewBlockModal>
            </Modal>
        </>
    );
};
export const TwoButtonsStyle = styled.div`
    margin-top: 36px;
    display: flex;
    gap: 12px;
    /* Media Queries */
    @media only screen and (max-width: 600px) {
        .button {
            width: 100%;
            min-width: auto;
        }
    }
`;

export default AddNewCategory;
