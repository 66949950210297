import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    className?: string;
    onChange?: Function;
    checked: boolean;
    isDisabled?: boolean;
    label?: string;
}
const ToggleSwitch: FunctionComponent<Props> = ({ className, onChange, checked, isDisabled, label }) => {
    const change = (): void => {
        onChange ? onChange(checked) : null;
    };
    return (
        <StyledWrapper
            className={`switch-wrapper ${checked ? 'checked' : ''} ${isDisabled ? 'disabled' : ''} ${
                className ?? ''
            }`.trim()}
            onClick={() => onChange}
            checked
        >
            <label className="switch cursor-pointer">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => {
                        change();
                    }}
                    disabled={isDisabled}
                />
                <span className="switch-button"></span>
            </label>
            <label className="side-label">{label}</label>
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div<Props>`
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 7fr;
    grid-gap: 10px;
    user-select: none;

    .switch {
        position: relative;
        width: 40px;
        height: 24px;
        background-color: var(--black-50);
        border-radius: 11px;
        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.24), inset 0 5px 17px 0 rgba(0, 0, 0, 0.19);

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
    .switch-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--white);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transition: transform 300ms ease 0s;
        transform: translate3d(3px, 3px, 0px);
        box-shadow: var(--box-shadow2);
    }
    .switch-label {
        position: absolute;
        top: 0;
        color: var(--white);
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 15px;
        right: 10px;
    }
    &.checked {
        .switch {
            background-color: var(--primary-400);
        }
        .switch-button {
            transform: translate3d(18px, 3px, 0px);
        }
        .switch-label {
            left: 10px;
        }
    }

    .side-label {
        font-size: 12px;
        padding-top: 5px;
        color: var(--black-200);
    }

    &.disabled {
        opacity: 0.3;
        * {
            cursor: not-allowed !important;
        }
    }
`;
export default ToggleSwitch;
