import { create } from 'zustand';
import { ButtonTheme } from '../components/button';

interface DialogueState {
    visible: boolean;
    title: string;
    description?: string;

    buttons?: Array<{ theme: ButtonTheme; text: string; callback: Function }>;
    setDialogue: ({
        title,
        description,
        buttons,
        visible,
    }: {
        title?: string;
        description?: string;
        buttons?: Array<{ theme: ButtonTheme; text: string; callback: Function }>;
        visible?: boolean;
    }) => void;
    closeDialogue: () => void;
}

const useDialogueState = create<DialogueState>((set) => ({
    title: '',
    description: '',
    buttons: [
        {
            theme: ButtonTheme.primary,
            text: '',
            callback: (): void => {
                return;
            },
        },
    ],
    visible: false,
    setDialogue: ({ title, description, buttons }) =>
        set({
            title: title ? title : 'Are you sure?',
            description: description,
            buttons: buttons,
            visible: true,
        }),
    closeDialogue: () =>
        set({
            title: '',
            visible: false,
        }),
}));

export default useDialogueState;
