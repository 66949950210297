import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import colors from '@Global/colors';
import communication from '@Communication';
import { StyledSearch } from '@/pages/workspaces';
import { TemplateDto } from '@Global/dtos/template.dto';
import SelectComponent, { SelectOptionType, SelectComponentTheme } from '@Components/select';
import { InputComponentIconPosition, InputComponentType, InputComponentTheme } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import SearchIcon from '@Assets/icons/search-normal.svg';
import LayersIcon from '@Assets/icons/workspaces/layers.svg';
import NewWorkspaceCard from '../newWorkspaceCard';

interface Props {
    createNewWorkspace: Function;
    setIsTemplatePreviewVisible?: Function;
    setCreateFromTemplateVisible?: Function;
    setPreviewTemplateId?: Function;
}

const FromTemplate: FunctionComponent<Props> = ({
    createNewWorkspace,
    setIsTemplatePreviewVisible,
    setCreateFromTemplateVisible,
    setPreviewTemplateId,
}) => {
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState('');

    const [templates, setTemplates] = useState<TemplateDto[]>([]);
    const [categories, setCategories] = useState<SelectOptionType[]>([]);

    const [filterCategory, setFilterCategory] = useState<SelectOptionType | null>(null);
    const [filteredTemplates, setFilteredTemplates] = useState<TemplateDto[] | null>(null);

    useEffect(() => {
        communication.getAllTemplates().then((res: any) => {
            const list = res.data;
            setFilteredTemplates(list);
            setTemplates(list);
        });
    }, []);

    useEffect(() => {
        if (templates) {
            const categoriesOptions: SelectOptionType[] = [];
            for (const template of templates) {
                if (
                    template.category &&
                    !categoriesOptions.find((category) => category.value === template.category?.id)
                ) {
                    categoriesOptions.push({ value: template.category.id, label: template.category.name });
                }
            }
            setCategories(categoriesOptions);
        }
    }, [templates]);

    useEffect(() => {
        if (templates) {
            if (filterCategory) {
                setFilteredTemplates(
                    templates.filter((template) =>
                        filterCategory ? template.category?.id === filterCategory.value : true,
                    ),
                );
            } else {
                setFilteredTemplates(templates);
            }
        }
    }, [filterCategory]);

    return (
        <>
            <StyledTemplateFilters>
                <StyledSearch
                    placeholder="Search"
                    type={InputComponentType.Text}
                    theme={InputComponentTheme.Light}
                    value={searchValue}
                    icon={SearchIcon}
                    size={366}
                    iconPosition={InputComponentIconPosition.Right}
                    onChange={(value: string) => setSearchValue(value)}
                    className="templates-search"
                ></StyledSearch>
                <StyledSelectComponent
                    theme={SelectComponentTheme.Light}
                    optionList={categories}
                    value={filterCategory}
                    placeholder="Filter by category"
                    isClearable={true}
                    isSearchable={false}
                    onChange={(selectedOption) => {
                        setFilterCategory(selectedOption as SelectOptionType);
                    }}
                ></StyledSelectComponent>
            </StyledTemplateFilters>
            <StyledTemplateHeader>
                <p>Your templates</p>
                <Button
                    className="see-all"
                    theme={ButtonTheme.link}
                    color={colors.primary400}
                    size="auto"
                    onClick={() => navigate('/templates')}
                >
                    See all
                </Button>
            </StyledTemplateHeader>
            <StyledTemplateCards>
                {filteredTemplates
                    ?.filter((t) => t.name.toLowerCase().includes(searchValue.toLowerCase()))
                    .map((templateItem: TemplateDto) => {
                        return (
                            <div key={templateItem.id}>
                                <NewWorkspaceCard
                                    imageSrc={LayersIcon}
                                    title={
                                        templateItem.name.length > 6
                                            ? templateItem.name.substring(0, 16) + ' ...'
                                            : templateItem.name
                                    }
                                    paragraph={templateItem.category ? templateItem.category.name : '-'}
                                    templateId={templateItem.id}
                                    template
                                    setIsTemplatePreviewVisible={setIsTemplatePreviewVisible}
                                    setCreateFromTemplateVisible={setCreateFromTemplateVisible}
                                    setPreviewTemplateId={setPreviewTemplateId}
                                    templateButtonClick={() => {
                                        createNewWorkspace(templateItem.id);
                                    }}
                                />
                            </div>
                        );
                    })}
            </StyledTemplateCards>
        </>
    );
};
const StyledSelectComponent = styled(SelectComponent)`
    margin-left: 12px;
`;
const StyledTemplateFilters = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .templates-search {
        .input-container {
            &::after {
                background: var(--black-20);
            }
        }
    }
`;
const StyledTemplateHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    p {
        color: var(--black-75);
    }
`;
const StyledTemplateCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 11px;
    img {
        width: 36px;
        height: 36px;
    }
`;
export default FromTemplate;
