import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { stripHtml } from 'string-strip-html';
import { TableBlock, TableRow } from '@/types/workspace';
import styled from 'styled-components';
import Direction from '@Global/direction';
import nanogen from '@Helpers/nanogen';
import useGlobalState from '@Store/global';
import useEditorStore from '@Store/editor';
import Clipboard from '@Components/editor/table/clipboard';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import { fixUrl } from '@/functions/url';
import BlockActions, { BlockActionItem } from '@Components/editor/actionWrapper';
import ArrowDown from '@Assets/icons/workspaces/arrow-down-circle.svg';
import ArrowUp from '@Assets/icons/workspaces/arrow-up-circle.svg';
import DeleteIcon from '@Assets/icons/workspaces/delete-circle.svg';
import AddColumnIcon from '@Assets/icons/workspaces/row-horizontal.svg';
import AddRowIcon from '@Assets/icons/workspaces/row-vertical.svg';
import DeleteNakedIcon from '@Assets/icons/workspaces/delete.svg';
import AddLinkIcon from '@Assets/icons/attachment.svg';
import AddIcon from '@Assets/icons/workspaces/add-circle.svg';
import EditIcon from '@Assets/icons/actions/edit.svg';

interface Props {
    tableData: TableBlock;
    className?: string | '';
    activeSection: number;
    onChange?: Function; // Triggers each time content changes
    onUpdate?: Function; // Triggers when store should be updated
    onDelete?: Function; // Triggers when block is destroyed
}

Quill.register('modules/clipboard', Clipboard, true);

const TableBlockElement: FunctionComponent<Props> = ({
    tableData,
    className,
    activeSection,
    onChange = () => {
        return;
    },
    onUpdate = () => {
        return;
    },
    onDelete = () => {
        return;
    },
}) => {
    const { activeActionElement } = useGlobalState();
    const { sections, activeElementId, setActiveElement } = useEditorStore();
    const [content, setContent] = useState(tableData.content || []);
    const [isActive, setIsActive] = useState(false);
    const [isLinkPromptVisible, setIsLinkPromptVisible] = useState(false);
    const [selectedCell, setSelectedCell] = useState<string | null>(null);
    const [linkPromptData, setLinkPromptData] = useState({ title: '', url: '' });
    const tableInput = useRef<HTMLInputElement | null>(null);
    const MAX_LENGTH = 100000;
    const [hoveredCol, setHoveredCol] = useState(-1);

    const [selectedRowColor, setSelectedRowColor] = useState('');
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [selectedColColor, setSelectedColColor] = useState('');
    const [selectedColIndex, setSelectedColIndex] = useState(-1);
    const [selectedPalette, setSelectedPalette] = useState('');

    const colorPalette = [
        { className: 'red', color: 'rgba(204,0,0, 0.5)' },
        { className: 'blue', color: 'rgba(61,133,198, 0.5)' },
        { className: 'green', color: 'rgba(106,168,79, 0.5)' },
        { className: 'yellow', color: 'rgba(241,194,50, 0.5)' },
        { className: 'grey', color: '#f9f9fa' },
        { className: 'white', color: '#ffffff' },
    ];

    useEffect(() => {
        activeElementId === tableData.id ? focus() : loseFocus();
    }, [activeElementId]);

    useEffect(() => {
        onUpdate();
    }, []);

    const updateStore = (contentTmp: { rows: TableRow[] }): void => {
        const sectionsTmp = [...sections];
        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === tableData.id) as
            | TableBlock
            | undefined;
        if (block) {
            block.content = { ...contentTmp };
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onChange();
    };

    /**
     * Update table cell content.
     */
    const updateCell = ({ text, id }: { text?: string; id: string }): void => {
        const contentTmp = { ...content };
        const parsedText = text;

        for (const row of contentTmp.rows) {
            const target = row.cells.find((cell) => cell.id === id);

            if (parsedText && target && parsedText?.length < MAX_LENGTH + 7) {
                target.value = parsedText;
            }
        }

        setContent({ ...contentTmp });
        updateStore(contentTmp);
    };

    const addColumn = (): void => {
        setSelectedRowColor('');

        const contentTmp = { ...content };
        const columnId = nanogen();
        for (const row of contentTmp.rows) {
            row.cells.push({
                id: nanogen(),
                columnId,
                value: '',
                style: '',
            });
        }
        setContent({ ...contentTmp });
        updateStore({ ...contentTmp });
        onUpdate();
    };

    const addRow = (): void => {
        setSelectedColColor('');

        const contentTmp = { ...content };
        const newRow: TableRow = {
            id: nanogen(),
            cells: contentTmp.rows[0].cells.map((header) => ({
                id: nanogen(),
                columnId: header.id,
                value: '',
                style: '',
            })),
        };
        contentTmp.rows.push(newRow);
        setContent({ ...contentTmp });
        updateStore({ ...contentTmp });
        onUpdate();
    };

    const removeRow = (rowIndex: number): void => {
        const contentTmp = { ...content };
        contentTmp.rows.splice(rowIndex, 1);
        updateStore({ ...contentTmp });
        if (contentTmp.rows.length === 0) {
            deleteBlock();
        }
    };

    const removeColumn = (columnIndex: number): void => {
        const contentTmp = { ...content };
        for (const row of contentTmp.rows) {
            row.cells.splice(columnIndex, 1);
            if (row.cells.length === 0) {
                deleteBlock();
            }
        }

        setContent({ ...contentTmp });
        updateStore({ ...contentTmp });
        if (contentTmp.rows.length === 0) {
            deleteBlock();
        }
        onUpdate();
    };

    /**
     * Move selected block in given direction
     * @param direction
     */
    const moveBlock = (direction: Direction): void => {
        const sectionsTmp = [...sections];
        const blocks = sectionsTmp[activeSection].content.items;
        const index = blocks.findIndex((bl) => bl.id === tableData.id);
        if (
            (direction === Direction.down && index !== -1 && index < blocks.length - 1) ||
            (direction === Direction.up && index > 0)
        ) {
            const dString = JSON.stringify(blocks[index]);
            const dString2 = JSON.stringify(blocks[direction === Direction.down ? index + 1 : index - 1]);
            blocks[index] = JSON.parse(dString2);
            blocks[direction === Direction.down ? index + 1 : index - 1] = JSON.parse(dString);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onUpdate();
        onChange();
    };

    /*
     * Delete selected block from the section
     */
    const deleteBlock = (): void => {
        const sectionsTmp = [...sections];
        const index = sectionsTmp[activeSection].content.items.findIndex((bl) => bl.id === tableData.id);
        if (index !== -1) {
            sectionsTmp[activeSection].content.items.splice(index, 1);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onDelete();
    };

    const focus = (): void => {
        setActiveElement(tableData.id);
        setIsActive(true);
    };

    const loseFocus = (): void => {
        setIsActive(false);
        tableInput.current?.blur();
        onUpdate();
        setSelectedCell(null);
        setIsLinkPromptVisible(false);
    };

    const actions: BlockActionItem[] = [
        {
            icon: AddColumnIcon,
            title: 'Add column',
            onClick: () => addColumn(),
        },
        {
            icon: AddRowIcon,
            title: 'Add row',
            onClick: () => addRow(),
        },
        {
            icon: ArrowUp,
            title: 'Move Up',
            onClick: () => moveBlock(Direction.up),
        },
        {
            icon: ArrowDown,
            title: 'Move Down',
            onClick: () => moveBlock(Direction.down),
        },
        {
            icon: DeleteIcon,
            title: 'Delete',
            separated: true,
            onClick: () => deleteBlock(),
        },
    ];

    const changeColorRow = (rowBgColor: string, rowIndex: number): void => {
        setSelectedRowColor(rowBgColor);
        setSelectedRowIndex(rowIndex);
        setSelectedPalette('row');
        const contentTmp = { ...content };

        contentTmp.rows.map((row, index) => {
            if (index === rowIndex) {
                const cells = row.cells.map((cell) => (cell.style = rowBgColor));
                return {
                    ...row,
                    cells: cells,
                };
            }
            return row;
        });

        setContent({ ...contentTmp });
        updateStore(contentTmp);
        onUpdate();
    };

    const changeColorCol = (colBgColor: string, colIndex: number): void => {
        setSelectedColColor(colBgColor);
        setSelectedColIndex(colIndex);
        setSelectedPalette('col');
        const contentTmp = { ...content };

        for (const row of contentTmp.rows) {
            const target = row.cells.find((cell, index) => cell && index === colIndex);

            if (target) {
                target.style = colBgColor;
            }
        }

        setContent({ ...contentTmp });
        updateStore(contentTmp);
        onUpdate();
    };

    return (
        <TableBlockStyled
            className={`table-block-${tableData.id} editor-block ${isActive ? 'active' : ''} ${className ?? ''}`}
            onMouseDown={focus}
        >
            <TableWrapper>
                <StyledTable className={`table ${className || ''}`}>
                    <tbody>
                        {content.rows.map((row, rIndex) => (
                            <tr key={row.id}>
                                {row.cells.map((cell, cIndex) => (
                                    <td
                                        className={`${hoveredCol === cIndex ? 'hovered' : ''}`}
                                        key={cell.id}
                                        onMouseEnter={() => setHoveredCol(cIndex)}
                                        onMouseLeave={() => setHoveredCol(-1)}
                                        style={{
                                            backgroundColor: `${
                                                cell.style
                                                    ? cell.style
                                                    : (selectedPalette === 'row' &&
                                                          selectedRowIndex === rIndex &&
                                                          selectedRowColor) ||
                                                      (selectedPalette === 'col' &&
                                                          selectedColIndex === cIndex &&
                                                          selectedColColor)
                                            }`,
                                        }}
                                    >
                                        {rIndex === 0 ? (
                                            <>
                                                <div className="column-icons">
                                                    <img
                                                        className={'column-icon'}
                                                        src={DeleteNakedIcon}
                                                        onClick={() => removeColumn(cIndex)}
                                                        title="Remove column"
                                                        alt="icon"
                                                    />
                                                    <img
                                                        className={'edit-icon edit-col-icon'}
                                                        src={EditIcon}
                                                        onClick={() =>
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === cell.id ? null : cell.id,
                                                            })
                                                        }
                                                        title="Pick column color"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <ColorPalette
                                                    className={`column-palette ${
                                                        activeActionElement === cell.id ? 'active' : ''
                                                    }`}
                                                >
                                                    {colorPalette.map((palette) => {
                                                        return (
                                                            <p
                                                                className={palette.className}
                                                                key={palette.color}
                                                                onClick={() => {
                                                                    changeColorCol(palette.color, cIndex);
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </ColorPalette>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="cell-body">
                                            <StyledInput
                                                placeholder="Empty"
                                                onBlur={() => {
                                                    onUpdate();
                                                }}
                                                className="table-block-editor"
                                                modules={{ toolbar: false }}
                                                onChange={(value) => {
                                                    updateCell({
                                                        text: value,
                                                        id: cell.id,
                                                    });
                                                }}
                                                value={cell.value}
                                                onFocus={() => {
                                                    setActiveElement('table-cell');
                                                }}
                                            />
                                            <img
                                                src={AddLinkIcon}
                                                alt="icon"
                                                className="link-icon"
                                                onClick={() => {
                                                    setSelectedCell(cell.id);
                                                    setIsLinkPromptVisible(true);
                                                    setLinkPromptData({ title: stripHtml(cell.value).result, url: '' });
                                                }}
                                            />
                                        </div>
                                        {cIndex === 0 ? (
                                            <>
                                                <div className="row-icons">
                                                    <img
                                                        className="row-icon"
                                                        src={DeleteNakedIcon}
                                                        onClick={() => removeRow(rIndex)}
                                                        title="Remove row"
                                                        alt="icon"
                                                    />
                                                    <img
                                                        className={'edit-icon edit-row-icon'}
                                                        src={EditIcon}
                                                        onClick={() =>
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === row.id ? null : row.id,
                                                            })
                                                        }
                                                        title="Pick column color"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <ColorPalette
                                                    className={`row-palette ${
                                                        activeActionElement === row.id ? 'active' : ''
                                                    }`}
                                                >
                                                    {colorPalette.map((palette) => {
                                                        return (
                                                            <p
                                                                className={palette.className}
                                                                key={palette.color}
                                                                onClick={() => {
                                                                    changeColorRow(palette.color, rIndex);
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </ColorPalette>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </StyledTable>
                <StyledLinkPrompt className={isLinkPromptVisible ? 'visible visible-link-block' : ''}>
                    <div
                        className="backdrop"
                        onClick={() => {
                            setSelectedCell(null);
                            setIsLinkPromptVisible(false);
                        }}
                    />
                    <div className="link-prompt-body">
                        <Input
                            type={InputComponentType.Text}
                            placeholder="Link title"
                            size={100}
                            value={linkPromptData.title}
                            onChange={(value: string) => {
                                setLinkPromptData({ title: value, url: linkPromptData.url });
                            }}
                        />
                        <Input
                            type={InputComponentType.Url}
                            placeholder="Link URL"
                            size={200}
                            value={linkPromptData.url}
                            onChange={(value: string) => {
                                setLinkPromptData({ title: linkPromptData.title, url: value });
                            }}
                        />
                        <Button
                            theme={ButtonTheme.primary}
                            leftIcon={AddIcon}
                            onClick={() => {
                                updateCell({
                                    text: `<a href="${fixUrl(linkPromptData.url)}">${linkPromptData.title}</a>`,
                                    id: selectedCell as string,
                                });
                                setIsLinkPromptVisible(false);
                                setSelectedCell(null);
                                onUpdate();
                            }}
                        ></Button>
                    </div>
                </StyledLinkPrompt>
            </TableWrapper>

            <BlockActions elementId={tableData.id} items={actions} />
        </TableBlockStyled>
    );
};

const StyledLinkPrompt = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s all ease-in-out;

    .backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000045;
    }

    .link-prompt-body {
        position: absolute;
        background-color: white;
        padding: 11px;
        border-radius: 5px;
        display: flex;
        gap: 14px;
        justify-content: center;
        align-items: center;

        .button {
            height: 40px;
            padding: 6px 16px;
        }
    }

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
`;

const TableBlockStyled = styled.div`
    position: relative;
    box-sizing: border-box;
    margin-bottom: 30px;
    border-radius: 5px;
    padding: 10px;
    transition: 0.3s all ease-in-out;
    border: 1px solid transparent;

    .block-actions {
        opacity: 0;
        pointer-events: none;
        z-index: 2;
    }

    &.active {
        .block-actions {
            opacity: 1;
            pointer-events: all;
        }
    }

    &:hover {
        cursor: pointer;
        .block-actions {
            opacity: 1;
            pointer-events: all;
        }
    }
`;

export const TableWrapper = styled.div`
    width: 100%;
`;

const StyledInput = styled(ReactQuill)`
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px;
    font-size: 14px;
    line-height: 22px;
    border: none;
    height: 100%;
    min-height: 50px;

    .ql-toolbar {
        display: none;
    }

    .ql-container {
        border: none;
        overflow: auto;

        .ql-editor {
            &.ql-blank:before {
                color: rgb(0 0 0 / 22%);
            }
        }
    }
`;

export const StyledTable = styled.table`
    background-color: var(--black-50);
    border-spacing: 1px;
    position: relative;
    table-layout: fixed;
    border-radius: 8px;
    width: 100%;
    td {
        position: relative;
        .column-icons {
            gap: 5px;
            left: 50%;
            transform: translate(-50%, 0);
            top: -12px;
            position: absolute;
            opacity: 0;
            z-index: 1;
            display: flex;
        }
        .row-icons {
            top: calc(50% - 25px);
            left: -9px;
            display: flex;
            flex-direction: column;
            position: absolute;
            opacity: 0;
            z-index: 1;
            gap: 5px;
        }
    }
    .column-icon,
    .row-icon,
    .edit-icon {
        transition: all 0.2s linear;
        box-sizing: border-box;
        cursor: pointer;
        background-color: white;
        border-radius: 20px;
        padding: 2px;
        box-shadow: 0 1px 6px 0 rgb(82 82 82 / 35%);

        :hover {
            box-shadow: 0 3px 6px 0 rgb(82 82 82 / 85%);
        }
    }

    tr:last-child td:first-child {
        div {
            border-bottom-left-radius: 8px;
        }
        border-bottom-left-radius: 8px;
        .table-block-editor {
            border-bottom-left-radius: 8px;
        }
    }

    tr:last-child td:last-child {
        div {
            border-bottom-right-radius: 8px;
        }
        border-bottom-right-radius: 8px;
        .table-block-editor {
            border-bottom-right-radius: 8px;
        }
    }
    tr:first-child td:first-child {
        div {
            border-top-left-radius: 8px;
        }
        border-top-left-radius: 8px;
        .table-block-editor {
            border-top-left-radius: 8px;
        }
    }

    tr:first-child td:last-child {
        div {
            border-top-right-radius: 8px;
        }
        border-top-right-radius: 8px;
        .table-block-editor {
            border-top-right-radius: 8px;
        }
    }

    td {
        min-width: 100px;
        background-color: white;

        .cell-body {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 18px;
                transition: opacity 0.2s linear;
                opacity: 0;
                cursor: pointer;
                border-radius: 0 0 0 5px;
            }
        }
        &:hover {
            .cell-body {
                img {
                    &.link-icon {
                        opacity: 1;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            position: relative;
            transition: 0.3s box-shadow ease-in-out;

            &:hover {
                filter: brightness(0.97);
            }

            td {
                &:hover {
                    .column-icons {
                        opacity: 1;
                    }
                    &:first-child {
                        .row-icons {
                            opacity: 1;
                        }
                    }
                }

                &.hovered {
                    filter: brightness(0.97);
                }
                .table-block-editor {
                    color: var(--black-60);
                    &::placeholder {
                        color: var(--black-100);
                    }
                    &:focus {
                        z-index: 1;
                    }
                }
            }
        }
    }
`;

const ColorPalette = styled.div`
    display: grid;
    gap: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 7px 9px 0px, rgba(0, 0, 0, 0.15) 0px 17px 50px 0px;
    border-radius: 8px;
    transition: all 0.2s ease-in-out 0s;
    width: 80px;
    position: absolute;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    grid-template-columns: 1fr 1fr 1fr;
    &.column-palette {
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    &.row-palette {
        left: -65px;
        top: 0;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
    > p {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid var(--black-50);
        &.red {
            background: rgba(204, 0, 0, 0.5);
        }
        &.blue {
            background: rgba(61, 133, 198, 0.5);
        }
        &.green {
            background: rgba(106, 168, 79, 0.5);
        }
        &.yellow {
            background: rgba(241, 194, 50, 0.5);
        }
        &.white {
            background: #ffffff;
        }
        &.grey {
            background: #f9f9fa;
        }
    }
`;
export default TableBlockElement;
