import { FunctionComponent, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import BackLink from '@Components/backLink';
import communication from '@Communication';
import Sidebar from '@/pages/library/sidebar';
import styled from 'styled-components';
import { FileRes } from '@/types/file';
import NoItems from '@Components/noItems';
import Button, { ButtonTheme } from '@/components/button';
import colors from '@/global/colors';

const allowedMimetypes = [
    'text/plain',
    'text/plain; charset=UTF-8',
    'application/pdf',
    'video/mpeg',
    'video/mp4',
    'audio/mpeg',
];

const allowedImgtypes = ['image/png', 'image/jpeg', 'image/gif'];

const FileEditor: FunctionComponent = () => {
    const { fileId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [file, setFile] = useState<FileRes | null>(null);
    const [overlayData, setOverlayData] = useState('');
    const [displayPreview, setDisplayPreview] = useState(false);
    const [imgPreview, setImgPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        if (fileId) {
            setIsLoading(true);
            if (location.state.type !== 'link') {
                communication
                    .getFile(fileId)
                    .then(async (res: any) => {
                        if (res?.status === 200) {
                            setFile(res.data);
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) => {
                        if (error) {
                            setIsLoading(false);
                        }
                    });
            } else {
                communication
                    .getLink(fileId)
                    .then(async (res: any) => {
                        if (res.status === 200) {
                            setUrl(res.data?.url);
                            setFile(res.data);
                        }
                    })
                    .catch((error: any) => {
                        if (error) {
                            setIsLoading(false);
                        }
                    });
            }
        }
    }, [fileId]);

    const GoToLink = (): void => {
        window.open(`${url}` as string, '_blank');
    };

    useEffect(() => {
        setIsLoading(true);
        if (file?.type !== 'link' && file?.link) {
            communication
                .downloadFile(file.link)
                .then((response: any) => {
                    const type = response.headers['content-type'];
                    const blob = new Blob([response.data], {
                        type,
                    });
                    const objectURL = URL.createObjectURL(blob);
                    setOverlayData(objectURL);
                    if (allowedImgtypes.includes(type)) {
                        setImgPreview(true);
                    } else {
                        if (allowedMimetypes.includes(type)) {
                            setImgPreview(false);
                            setDisplayPreview(true);
                        } else {
                            setImgPreview(false);
                            setDisplayPreview(false);
                        }
                    }
                    setIsLoading(false);
                })
                .catch((error: any) => {
                    if (error) {
                        setIsLoading(false);
                    }
                });
        } else {
            setImgPreview(false);
            setIsLoading(false);
        }
    }, [file?.link]);

    return (
        file && (
            <>
                <Sidebar
                    data={{
                        id: file.id || '',
                        name: file.name,
                        description: file.description,
                        thumbnail: file.thumbnail,
                        category: file.category,
                        type: file.type ?? location.state.type,
                    }}
                ></Sidebar>
                <StyledBase>
                    <StyledBackLinkContainer>
                        <BackLink
                            className="clickable"
                            text="Back"
                            onClick={() => {
                                navigate('/library');
                            }}
                        />
                    </StyledBackLinkContainer>
                    {isLoading ? (
                        <div className="skeleton-wrapper">
                            <Skeleton style={{ marginBottom: '5px' }} height={300} width="100%" />
                        </div>
                    ) : (
                        <StyledFrameContainer>
                            {imgPreview ? (
                                <img src={overlayData} alt="image-preview"></img>
                            ) : displayPreview ? (
                                <iframe
                                    src={overlayData + '#toolbar=0'}
                                    style={{ flex: 1 }}
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                ></iframe>
                            ) : (
                                <div className="no-preview">
                                    <NoItems
                                        content={
                                            <>
                                                {location.state.type === 'link' ? (
                                                    <>
                                                        <p>No preview available for this file type!</p>
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            color={colors.primary400}
                                                            onClick={GoToLink}
                                                        >
                                                            Open link
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <p>No preview available for this file type!</p>
                                                )}
                                            </>
                                        }
                                    />
                                </div>
                            )}
                        </StyledFrameContainer>
                    )}
                </StyledBase>
            </>
        )
    );
};

const StyledBackLinkContainer = styled.div`
    flex: 1;
    display: flex;
    padding: 20px;
`;
const StyledBase = styled.div`
    display: flex;
    flex-direction: column;
    .skeleton-wrapper {
        padding: 0 30px;
    }
`;
const StyledFrameContainer = styled.div`
    height: calc(100vh - 126px);
    display: flex;
    justify-content: center;
    margin: 0 20px;
    iframe {
        flex: 1;
        border: none;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .no-preview {
        width: 100%;
        height: calc(100% - 58px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 8px;
    }
`;

export default FileEditor;
