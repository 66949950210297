import { FunctionComponent, useEffect, useState } from 'react';
import communication from '@/communication';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import PlayIcon from '@Assets/icons/sharedView/play-circle.svg';

interface Props {
    link?: string;
    type?: string;
}
const PreDownloadBlock: FunctionComponent<Props> = ({ link, type }) => {
    const [objectData, setObjectData] = useState('');

    useEffect(() => {
        if (link) {
            communication.downloadFile(link).then((res: any) => {
                if (res?.status === 200) {
                    const objectType = res.headers['content-type'];
                    const blob = new Blob([res.data], {
                        type: objectType,
                    });
                    const objectURL = URL.createObjectURL(blob);
                    setObjectData(objectURL);
                }
            });
        }
    }, [link]);

    return (
        <>
            {type === 'pdf' ? (
                (objectData === '' && <Skeleton style={{ marginBottom: '5px' }} height={400} width="100%" />) || (
                    <StyledOverlayIframe
                        src={objectData + '#toolbar=0'}
                        style={{ flex: 1 }}
                        allowFullScreen
                        width="100%"
                        height="600px"
                    />
                )
            ) : (
                <div className="video-container">
                    <video src={objectData} crossOrigin="anonymous" />
                    <img src={PlayIcon} alt="play icon" />
                </div>
            )}
        </>
    );
};

const StyledOverlayIframe = styled.iframe`
    border-radius: 8px;
    border: 10px solid rgba(170, 170, 170, 0.2);
`;

export default PreDownloadBlock;
