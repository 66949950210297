import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RouteContent from './routes';
import GlobalStyle from '@Global/index';
import { ToastProvider } from 'react-toast-notifications';
import Dialogue from '@Components/dialogue';
import useGlobalState from '@Store/global';
import Loading from '@Components/loading';
import LogoPng from '@Assets/sync-page-logo.png';
import './App.scss';

const App: React.FunctionComponent = () => {
    const { isNavbarCollapsed, metaTitle, metaSlogan, metaDescription } = useGlobalState();

    return (
        <BrowserRouter>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta charSet="utf-8" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="theme-color" content="#00726d" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta property="title" content={metaTitle} />
                <meta property="quote" content={metaSlogan} />
                <meta property="image" content={LogoPng} />
            </Helmet>
            <GlobalStyle />
            <Dialogue />
            <Loading />
            <ToastProvider placement="bottom-center" autoDismiss={true}>
                <div className={`app ${isNavbarCollapsed ? 'collapsed' : ''}`}>
                    <RouteContent />
                </div>
            </ToastProvider>
        </BrowserRouter>
    );
};

export default App;
