import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import hamburgerIcon from '@Assets/icons/settings/hamburger.svg';
import { AssociatedActionPlanType, ContactsDetailsType, SectionType } from '@/types/workspace';
import Workspace from '../workspace';
import Section from '../section';
import CloseIcon from '@Assets/icons/close.svg';
import './mobileSharedView.scss';
import ActionPlanItem from '../sidebar/actionPlan';
import PointsOfContact from '../sidebar/PointsOfContact';
import Modal from '@Components/modal';
import Input, { InputComponentType } from '@Components/input';
import { isValidEmail } from '@/functions/regex';
import Button, { ButtonTheme } from '@Components/button';
import { dispatchEvent, EventName } from '@Helpers/analytics';
import { useToasts } from 'react-toast-notifications';
import communication from '@Communication';
import CheckIcon from '@Assets/icons/sharedView/check-circle.svg';
import SentEmailIcon from '@Assets/icons/mark-email.svg';
import SectionTitles from '../sidebar/sectionTitles';
import colors from '@Global/colors';
import WelcomeSection from '../welcomeSection';
interface Props {
    organizationLogo?: string | null;
    organizationName?: string | null;
    organizationId?: string | null;
    welcomeMessage?: string | null;
    companyProfilePicture?: string | null;
    companyLogo?: string | null;
    companyId?: string | null;
    companyName?: string | null;
    welcomeVideoUrl?: string | null;
    welcomeVideo: string | null;
    sections?: SectionType[];
    title?: string | null;
    inforoomSections?: SectionType[] | null;
    inforoomTitle?: string | null;
    activeTab: number;
    setActiveTab: Function;
    actionPlanData?: AssociatedActionPlanType | null;
    contacts?: ContactsDetailsType[] | null;
    openOverlay: Function;
    openNewTab: Function;
    setOverlayData: Function;
    isProtected: boolean;
    swToken?: string;
    token?: string;
    selectedTitle?: string;
    workspaceId: string | null;
}

const MobileSharedView: FunctionComponent<Props> = ({
    organizationLogo,
    organizationName,
    organizationId,
    welcomeMessage,
    companyProfilePicture,
    companyLogo,
    companyId,
    companyName,
    welcomeVideoUrl,
    welcomeVideo,
    sections,
    title,
    inforoomSections,
    activeTab,
    setActiveTab,
    actionPlanData,
    contacts,
    openOverlay,
    openNewTab,
    setOverlayData,
    inforoomTitle,
    isProtected,
    swToken,
    token,
    selectedTitle,
    workspaceId,
}) => {
    const [isCollapsedMenu, setCollapsedMenu] = useState(true);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [shareEmail, setShareEmail] = useState<string | null>(null);
    const { addToast, removeAllToasts } = useToasts();
    const [sharedModalVisible, setSharedModalVisible] = useState(false);

    const copyLink = (): void => {
        removeAllToasts();
        navigator.clipboard.writeText(window.location.href).then();
        addToast('Link copied', { appearance: 'success' });
        dispatchEvent(EventName.SharedPageClick, workspaceId, { page: 'sharedView', isProtected }, swToken, token);
        dispatchEvent(EventName.CopiedShareLink, workspaceId, { page: 'sharedView', isProtected }, swToken, token);
    };

    const GoToSite = (): void => {
        window.open('https://syncpage.io/' as string, '_blank');
    };

    const sendShareRequest = (sharedEmail: string): void => {
        dispatchEvent(EventName.SharedPageClick, workspaceId, { page: 'sharedView', isProtected }, swToken, token);
        communication
            .createRequest({
                type: 'share',
                data: { inviteToken: token, emailToShareWith: sharedEmail },
                sharedViewToken: swToken,
            })
            .then((res: any) => {
                if (res?.status === 200) {
                    setIsShareModalVisible(false);
                    setSharedModalVisible(true);
                    dispatchEvent(
                        EventName.SharedPageShare,
                        workspaceId,
                        { page: 'sharedView', isProtected },
                        swToken,
                        token,
                    );
                }
            });
    };

    useEffect(() => {
        setSharedModalVisible(false);
    }, [selectedTitle]);

    return (
        <>
            <Modal
                modalVisible={isShareModalVisible}
                closeModal={() => setIsShareModalVisible(false)}
                size="100%"
                title={`${isProtected ? 'Invite' : 'Share this Workspace'}`}
            >
                <StyledModal>
                    <div className="modal-content">
                        {isProtected ? (
                            <>
                                <Input
                                    type={InputComponentType.Email}
                                    value={shareEmail ? shareEmail : ''}
                                    label="Email of a person you want to share this SyncPage with"
                                    size="100%"
                                    errorMessage={
                                        shareEmail ? (isValidEmail(shareEmail) ? '' : 'Please provide valid email') : ''
                                    }
                                    placeholder="Add email"
                                    onChange={(v: string) => setShareEmail(v)}
                                />
                                {/* <Input
                                    type={InputComponentType.Text}
                                    value={shareName ? shareName : ''}
                                    label="Name"
                                    size="100%"
                                    onChange={(v: string) => setShareName(v === '' ? null : v)}
                                /> */}
                                <Button
                                    theme={ButtonTheme.primary}
                                    onClick={() => {
                                        shareEmail ? sendShareRequest(shareEmail) : true;
                                    }}
                                    disabled={shareEmail ? !isValidEmail(shareEmail) : true}
                                    size={200}
                                >
                                    Invite
                                </Button>
                            </>
                        ) : (
                            <>
                                <Input
                                    type={InputComponentType.Text}
                                    value={window.location.href}
                                    size="100%"
                                    label="Workspace link"
                                    onIconPress={() => copyLink()}
                                />
                                <Button
                                    theme={ButtonTheme.primary}
                                    onClick={() => {
                                        copyLink();
                                        setIsShareModalVisible(false);
                                    }}
                                    size={200}
                                >
                                    Copy link
                                </Button>
                            </>
                        )}
                    </div>
                </StyledModal>
            </Modal>
            <Modal
                modalVisible={sharedModalVisible}
                closeModal={() => null}
                size={500}
                title="Done!"
                closeIcon={CheckIcon}
            >
                <StyledSharedWorkspaceContainer>
                    <img crossOrigin="anonymous" src={SentEmailIcon}></img>
                    <p>A request to share this workspace with {shareEmail} has been sent successfully.</p>
                </StyledSharedWorkspaceContainer>

                <Button
                    theme={ButtonTheme.primary}
                    onClick={() => {
                        setSharedModalVisible(false);
                    }}
                    size={100}
                >
                    Ok
                </Button>
            </Modal>
            <div className="horizontal-navbar shared-view-navbar mobile-navbar">
                <div className="horizontal-navbar-item">
                    <img
                        src={isCollapsedMenu ? hamburgerIcon : CloseIcon}
                        alt="icon"
                        onClick={() => setCollapsedMenu(!isCollapsedMenu)}
                    ></img>
                </div>
                <div className="horizontal-navbar-item">
                    {organizationLogo ? (
                        <StyledOrganizationLogo
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_API_URL}/organizations/logo/${organizationId}`}
                        />
                    ) : (
                        <h3>{organizationName}</h3>
                    )}
                </div>
            </div>
            <div
                className={
                    isCollapsedMenu ? 'shared-view-vertical-navbar collapsed-mobile' : 'shared-view-vertical-navbar'
                }
            >
                <StyledSidebarContainer>
                    <PointsOfContact
                        contacts={contacts}
                        workspaceId={workspaceId}
                        isProtected={isProtected}
                    ></PointsOfContact>
                    <SectionTitles
                        sections={sections}
                        setCollapsedMenu={setCollapsedMenu}
                        workspaceId={workspaceId}
                        isProtected={isProtected}
                    ></SectionTitles>
                    <ActionPlanItem
                        actionPlanData={actionPlanData}
                        workspaceId={workspaceId}
                        isProtected={isProtected}
                    ></ActionPlanItem>
                </StyledSidebarContainer>
            </div>
            <div className="shared-view-content">
                <StyledContentContainer>
                    <div className="action-block">
                        {inforoomSections && (
                            <StyledTabsContainer>
                                <div
                                    className={`${activeTab === 0 ? 'tabs-item selected' : 'tabs-item'}`}
                                    onClick={() => {
                                        // dispatchEvent(EventName.WorkspaceVisit, workspaceId, { page: 'sharedView' });
                                        setActiveTab(0);
                                    }}
                                >
                                    <span>Workspace</span>
                                </div>
                                <div
                                    className={`${activeTab === 1 ? 'tabs-item selected' : 'tabs-item'}`}
                                    onClick={() => {
                                        dispatchEvent(
                                            EventName.SharedPageClick,
                                            workspaceId,
                                            { page: 'sharedView', isProtected },
                                            swToken,
                                            token,
                                        );
                                        setActiveTab(1);
                                    }}
                                >
                                    <span>Info Room</span>
                                </div>
                            </StyledTabsContainer>
                        )}
                        <div
                            className="tabs-item share-btn"
                            onClick={() => {
                                setIsShareModalVisible(true);
                            }}
                        >
                            <span>Share</span>
                        </div>
                    </div>

                    <StyledTabs index={activeTab}>
                        <div className="tab-content">
                            <div>
                                <WelcomeSection
                                    welcomeMessage={welcomeMessage}
                                    companyProfilePicture={companyProfilePicture}
                                    companyLogo={companyLogo}
                                    welcomeVideoUrl={welcomeVideoUrl}
                                    welcomeVideo={welcomeVideo}
                                    companyId={companyId}
                                    companyName={companyName}
                                />
                                {sections && (
                                    <Workspace workspaceName={title ?? ''}>
                                        {sections.map((section) => {
                                            return (
                                                <Section
                                                    id={`section-${section.id}`}
                                                    key={section.id}
                                                    sectionName={section.options.title}
                                                    visible={section.options.visible}
                                                    sectionItems={section.content.items}
                                                    setMiddleSection={() => null}
                                                    openOverlay={openOverlay}
                                                    openNewTab={openNewTab}
                                                    setOverlayData={setOverlayData}
                                                    workspaceId={workspaceId}
                                                    isProtected={isProtected}
                                                ></Section>
                                            );
                                        })}
                                    </Workspace>
                                )}
                            </div>
                        </div>
                        <div className="tab-content">
                            <div>
                                {inforoomSections && (
                                    <Workspace workspaceName={inforoomTitle ?? ''}>
                                        {inforoomSections.map((section) => {
                                            return (
                                                <Section
                                                    id={`section-${section.id}`}
                                                    key={section.id}
                                                    sectionName={section.options.title}
                                                    visible={section.options.visible}
                                                    sectionItems={section.content.items}
                                                    setMiddleSection={() => null}
                                                    openOverlay={openOverlay}
                                                    openNewTab={openNewTab}
                                                    setOverlayData={setOverlayData}
                                                    workspaceId={workspaceId}
                                                    isProtected={isProtected}
                                                ></Section>
                                            );
                                        })}
                                    </Workspace>
                                )}
                            </div>
                        </div>
                    </StyledTabs>
                    <StyledFooter>
                        <Button theme={ButtonTheme.link} color={colors.black100} onClick={GoToSite}>
                            Powered by SyncPage
                        </Button>
                    </StyledFooter>
                </StyledContentContainer>
            </div>
        </>
    );
};

const StyledOrganizationLogo = styled.img`
    margin-right: 10px;
    height: 50px;
    object-fit: contain;
    max-width: 100px;
    max-height: 50px;
`;
const StyledContentContainer = styled.div`
    overflow: scroll;
    padding: 0 20px;
    scroll-margin-top: 70px;
    margin-top: 70px;
`;

const StyledTabsContainer = styled.div`
    display: flex;
    height: 100%;
    align-self: center;
    gap: 20px;
    .tabs-item {
        align-items: center;
        display: flex;
        user-select: none;
        position: relative;
        &:after {
            content: '';
            border-radius: 6px;
            background: transparent;
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: -1px;
        }

        &.selected {
            color: var(--primary-400);
            &:after {
                background: var(--primary-400);
            }
        }
    }
`;

const StyledTabs = styled.div<{ index: number }>`
    width: 100%;

    .tab-content {
        visibility: hidden;
        height: 0;
        overflow: hidden;
        pointer-events: none;
    }

    .tab-content:nth-child(${(props) => props.index + 1}) {
        visibility: visible;
        height: auto;
        overflow: visible;
        pointer-events: all;
        margin-top: 37px;
    }
`;

const StyledModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        .input {
            margin: 10px 0;
        }

        .button {
            // margin: 30px calc(100% - 320px) 0 calc(100% - 320px);
            margin-top: 25px;
        }
    }
`;

const StyledSharedWorkspaceContainer = styled.div`
    display: flex;
    gap: 15px;
    margin: 25px 0;
`;

const StyledSidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 12px 0;
`;

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--grey-10);
    width: 100%;
`;

export default MobileSharedView;
