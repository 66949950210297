import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Communication from '@Communication';
import useDialogue from '@Hooks/useDialogue';
import { FileRes } from '@/types/file';
import LibraryTile from '@Components/library/tile';
import Button, { ButtonTheme } from '@Components/button';
import NoItems from '@Components/noItems';
import LibrarySkeleton from './librarySkeleton';

interface Props {
    className?: string;
    onSelect?: Function;
    searchTerm?: string;
    globalFiles?: FileRes[] | null;
    onDelete?: Function;
    onUpload?: Function;
    preview?: boolean;
    setUploadModal?: Dispatch<SetStateAction<boolean>>;
}

const ContentLibraryArea: FunctionComponent<Props> = ({
    className,
    onSelect,
    onDelete = (): void => {
        return;
    },
    searchTerm,
    globalFiles,
    preview,
    setUploadModal,
}) => {
    const navigate = useNavigate();
    const [files, setFiles] = useState<FileRes[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setDialogue, closeDialogue } = useDialogue();

    const deleteItem = (id: string, index: number, type: string): void => {
        if (!isLoading) {
            setIsLoading(true);
            if (type !== 'link') {
                Communication.deleteFile(id)
                    .then(() => {
                        files?.splice(index, 1);
                        setFiles(files);
                        setIsLoading(false);
                        onDelete();
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            } else {
                Communication.deleteLink(id)
                    .then(() => {
                        files?.splice(index, 1);
                        setFiles(files);
                        setIsLoading(false);
                        onDelete();
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    const goToTheFile = (id: string, type: string): void => {
        navigate(`/library/${id}`, { state: { type } });
    };

    useEffect(() => {
        Communication.getAllFilesAndLinks().then((res: any) => {
            setFiles(res.data);
        });
    }, []);

    useEffect(() => {
        if (globalFiles) {
            setFiles(globalFiles);
        }
    }, [globalFiles]);

    return (
        <StyledContentLibraryArea className={`${className ?? ''}`}>
            {files ? (
                <>
                    {files?.filter((file: FileRes) => file.name.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''))
                        ?.length !== 0 ? (
                        <StyledFilesWrapper>
                            {files
                                ?.filter((file: FileRes) =>
                                    file.name.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''),
                                )
                                .map((file: FileRes, index: number) => (
                                    <LibraryTile
                                        fileItem={file}
                                        key={file.id}
                                        preview={preview}
                                        onClick={() => {
                                            if (onSelect) {
                                                onSelect(file);
                                            } else {
                                                goToTheFile(file.id, file.type);
                                            }
                                        }}
                                        onDelete={() =>
                                            setDialogue({
                                                title: 'Delete the file?',
                                                description: 'Are you sure you want to remove this file from library',
                                                buttons: [
                                                    {
                                                        theme: ButtonTheme.danger,
                                                        text: 'Delete',
                                                        callback: () => {
                                                            deleteItem(file.id, index, file.type);
                                                            closeDialogue();
                                                        },
                                                    },
                                                    {
                                                        theme: ButtonTheme.naked,
                                                        text: 'Cancel',
                                                        callback: () => {
                                                            closeDialogue();
                                                        },
                                                    },
                                                ],
                                            })
                                        }
                                    />
                                ))}
                        </StyledFilesWrapper>
                    ) : (
                        <NoItems
                            content={
                                <>
                                    <p>No files yet.</p>
                                    {setUploadModal && (
                                        <div>
                                            <p>Start with</p>
                                            <Button
                                                theme={ButtonTheme.link}
                                                color="var(--primary-400)"
                                                onClick={() => {
                                                    setUploadModal && setUploadModal(true);
                                                }}
                                            >
                                                uploading a new file.
                                            </Button>
                                        </div>
                                    )}
                                </>
                            }
                        ></NoItems>
                    )}
                </>
            ) : (
                <LibrarySkeleton />
            )}
        </StyledContentLibraryArea>
    );
};
const StyledContentLibraryArea = styled.div`
    width: 100%;
    padding: 60px 20px;
`;

const StyledFilesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    gap: 20px;

    .block-actions {
        opacity: 0;
        pointer-events: none;
    }

    &:hover {
        position: relative;
        z-index: 1;

        > .block-actions {
            opacity: 1;
            pointer-events: all;
        }
    }
`;

export default ContentLibraryArea;
