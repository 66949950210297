import { FocusEvent, FunctionComponent } from 'react';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';

export type SelectOptionType = {
    value: string;
    label: string;
    icon?: string;
} & {
    [key: string]: any;
};
export enum SelectComponentTheme {
    Dark = 'dark',
    Light = 'light',
}

interface Props {
    label?: string;
    className?: string;
    placeholder?: string;
    isMulti?: boolean;
    theme?: SelectComponentTheme;
    optionList: SelectOptionType[];
    value?: SelectOptionType | SelectOptionType[] | null;
    defaultValue?: SelectOptionType | SelectOptionType[] | null;
    isClearable?: boolean;
    isSearchable?: boolean;
    isLoading?: boolean;
    noOptionsText?: string;
    onChange?: (data: SelectOptionType | SelectOptionType[]) => void;
    onSearch?: (value: string) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onCreate?: (value: string) => void;
}

const SelectComponent: FunctionComponent<Props> = ({
    label,
    className,
    placeholder,
    isMulti = false,
    optionList,
    defaultValue,
    value,
    theme,
    isClearable,
    isSearchable,
    isLoading,
    noOptionsText,
    onChange = () => {
        return;
    },
    onSearch = () => {
        return;
    },
    onFocus = () => {
        return;
    },
    onCreate,
}) => {
    const handleSelect = (data: SelectOptionType | SelectOptionType[]): void => {
        onChange(data);
    };

    return (
        <>
            <StyledSelectContainer className={`select ${className ?? ''}`}>
                {label ? <label>{label}</label> : <></>}
                {onCreate ? (
                    <CreatableSelect
                        options={optionList}
                        value={value}
                        defaultValue={defaultValue}
                        getOptionLabel={(props: any) => {
                            return (
                                <div className="option-with-icon">
                                    {props.icon && <img src={props.icon} alt="option icon" />}
                                    <span>{props.label}</span>
                                </div>
                            ) as unknown as string;
                        }}
                        onChange={handleSelect}
                        onInputChange={onSearch}
                        isMulti={isMulti}
                        isLoading={isLoading}
                        allowCreateWhileLoading={false}
                        onFocus={onFocus}
                        styles={theme === SelectComponentTheme.Light ? colorLightStyles : colorDarkStyles}
                        isClearable={isClearable ?? true}
                        isSearchable={isSearchable ?? true}
                        placeholder={placeholder}
                        onCreateOption={onCreate}
                        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                        noOptionsMessage={() => (noOptionsText ? noOptionsText : 'No options')}
                        classNamePrefix="react-select"
                    />
                ) : (
                    <Select
                        options={optionList}
                        value={value}
                        defaultValue={defaultValue}
                        getOptionLabel={(props: any) => {
                            return (
                                <div className="option-with-icon">
                                    {props.icon && <img src={props.icon} alt="option icon" />}
                                    <span>{props.label}</span>
                                </div>
                            ) as unknown as string;
                        }}
                        onChange={handleSelect}
                        onInputChange={onSearch}
                        isMulti={isMulti}
                        onFocus={onFocus}
                        isLoading={isLoading}
                        styles={theme === SelectComponentTheme.Light ? colorLightStyles : colorDarkStyles}
                        isClearable={isClearable ?? true}
                        isSearchable={isSearchable ?? true}
                        placeholder={placeholder}
                        noOptionsMessage={() => (noOptionsText ? noOptionsText : 'No options')}
                        classNamePrefix="react-select"
                    />
                )}
            </StyledSelectContainer>
        </>
    );
};

const colorLightStyles: StylesConfig<any> = {
    valueContainer: (styles) => ({
        ...styles,
        padding: '2px 18px',
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: 'var(--background-grey)',
    }),
    menuList: (styles) => ({
        ...styles,
        padding: '0',
    }),
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: 'var(--white)',
        borderColor: 'var(--white)',
        filter: isFocused ? 'drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1))' : 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        border: 'none',
        boxShadow: '0px',
        minHeight: '50px',
        fontSize: '14px',
        lineHeight: '18px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? 'var(--background-grey)'
                : isFocused
                ? data.color
                : undefined,
            color: isDisabled ? '#ccc' : isSelected ? 'var(--black-60)' : 'var(--black-75)',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            border: 'none',
            borderRadius: '3px',
            fontSize: '14px',
            lineHeight: '18px',
            padding: '10px 18px',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled ? 'var(--background-grey)' : undefined,
            },
        };
    },
    noOptionsMessage: (styles) => ({
        ...styles,
        padding: '10px 18px',
    }),
    input: (styles) => ({ ...styles, borderColor: 'var(--background-grey)', borderWidth: '2px', borderRadius: '8px' }),
    placeholder: (styles) => ({ ...styles, color: 'var(--black-75)' }),
    singleValue: (styles) => ({ ...styles }),
};

const colorDarkStyles: StylesConfig<any> = {
    valueContainer: (styles) => ({
        ...styles,
        padding: '2px 18px',
    }),
    indicatorSeparator: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? 'var(--background-grey)' : 'var(--white)',
    }),
    menuList: (styles) => ({
        ...styles,
        padding: '0',
    }),
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? 'var(--white)' : 'var(--background-grey)',
        borderColor: isFocused ? 'var(--white)' : 'var(--background-grey)',
        filter: isFocused ? 'drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1))' : 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        border: 'none',
        boxShadow: '0px',
        minHeight: '50px',
        fontSize: '14px',
        lineHeight: '18px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? 'var(--background-grey)'
                : isFocused
                ? data.color
                : undefined,
            color: isDisabled ? '#ccc' : isSelected ? 'var(--black-60)' : 'var(--black-75)',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            border: 'none',
            borderRadius: '3px',
            fontSize: '14px',
            lineHeight: '18px',
            padding: '10px 18px',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled ? 'var(--background-grey)' : undefined,
            },
        };
    },
    noOptionsMessage: (styles) => ({
        ...styles,
        padding: '10px 18px',
    }),
    input: (styles) => ({ ...styles, borderColor: 'var(--background-grey)', borderWidth: '2px', borderRadius: '8px' }),
    placeholder: (styles) => ({ ...styles, color: 'var(--black-75)' }),
    singleValue: (styles) => ({ ...styles }),
};

const StyledSelectContainer = styled.div`
    text-align-last: left;
    display: flex;
    outline-color: var(--background-grey);
    font-family: inherit;
    border-radius: 8px;
    border-style: hidden;
    font-size: 14px;
    cursor: pointer;
    flex-direction: column;
    .option-with-icon {
        display: flex;
        align-items: center;
        img {
            margin-right: 8px;
        }
    }
    label {
        color: var(--black-75);
        font-size: 12px;
        padding: 8px 0px;
    }
`;

export default SelectComponent;
