import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { SectionType } from '@/types/workspace';
import ArrowDropdownActive from '@Assets/icons/sharedView/arrow-drop-down-active.svg';
import ArrowDropdownInactive from '@Assets/icons/sharedView/arrow-drop-down-inactive.svg';
import { dispatchEvent, EventName } from '@Helpers/analytics';

interface Props {
    sections?: SectionType[] | null;
    selectedTitle?: string;
    setCollapsedMenu: Function;
    workspaceId: string | null;
    isProtected?: boolean;
}

const SectionTitles: FunctionComponent<Props> = ({
    sections,
    selectedTitle,
    setCollapsedMenu,
    workspaceId,
    isProtected,
}) => {
    const [_clickedTitle, setCLickedTitle] = useState<string | undefined>(selectedTitle);
    const [isCollapsed, setCollapsed] = useState(true);

    const swToken = localStorage.getItem('sharedViewToken') ?? undefined;
    const token = localStorage.getItem('token') ?? undefined;

    useEffect(() => {
        if (selectedTitle) {
            setTimeout(() => {
                const sectionTitle = document.getElementById(`title-${selectedTitle}`);
                sectionTitle?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
            }, 100);
        }
    }, [selectedTitle]);

    const handleClick = (id: string): void => {
        setTimeout(() => {
            const sectionElement = document.getElementById(`section-${id}`);
            sectionElement?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
            const scrolledY = window.scrollY;
            if (scrolledY) {
                window.scroll(0, scrolledY - 34);
            }
        }, 100);
        setCLickedTitle(id);
    };

    return (
        <StyledContainers>
            <StyledTitlesHeaderContainer
                onClick={() => {
                    setCollapsed(!isCollapsed);
                    dispatchEvent(
                        EventName.SharedPageClick,
                        workspaceId,
                        { page: 'sharedView', isProtected },
                        swToken,
                        token,
                    );
                }}
                className={`${isCollapsed ? '' : 'shadow'}`}
            >
                <StyledDropdownContainer>
                    <StyledDropdownIcon src={isCollapsed ? ArrowDropdownInactive : ArrowDropdownActive} />
                </StyledDropdownContainer>
                <span className={`${isCollapsed ? '' : 'active-color'}`}>Content</span>
            </StyledTitlesHeaderContainer>
            {!isCollapsed && (
                <StyledTitlesContainer>
                    {sections &&
                        sections.map((section) => {
                            return (
                                section.options.title && (
                                    <StyledTitlesListItem
                                        id={`title-${section.id}`}
                                        key={section.id}
                                        className={`${selectedTitle === section.id ? 'selected-title' : ''}`}
                                        onClick={() => {
                                            dispatchEvent(
                                                EventName.SharedPageClick,
                                                workspaceId,
                                                {
                                                    page: 'sharedView',
                                                    isProtected,
                                                },
                                                swToken,
                                                token,
                                            );
                                            handleClick(section.id);
                                            setCollapsedMenu(true);
                                        }}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: section.options.title,
                                            }}
                                        ></div>
                                    </StyledTitlesListItem>
                                )
                            );
                        })}
                </StyledTitlesContainer>
            )}
        </StyledContainers>
    );
};

const StyledContainers = styled.div`
    width: 100%;
    background: var(--white);
    border-radius: 8px;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
`;
const StyledTitlesHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0;
    cursor: pointer;

    &.shadow {
        box-shadow: 0px 2px 0px rgba(216.75, 216.75, 216.75, 0.15);
        padding-bottom: 10px;
    }

    span {
        font-size: 18px;
        font-weight: 600;
        word-wrap: break-word;
        &.active-color {
            color: var(--black-60);
        }
        color: var(--black-75);
    }
`;

const StyledDropdownContainer = styled.div`
    margin: 0 24px;
`;

const StyledDropdownIcon = styled.img`
    height: 28px;
    width: 28px;
`;

const StyledTitlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
    margin: 15px 5px 15px 5px;
`;

const StyledTitlesListItem = styled.div`
    div {
        cursor: pointer;
        margin-bottom: 13px;
        color: var(--black-100);
        line-height: 24px;
        font-size: 16px;
        p {
            font-size: 16px;
        }
    }
    &.selected-title {
        div {
            color: var(--black-60);
            p {
                font-weight: 600;
            }
        }
    }
`;

export default SectionTitles;
