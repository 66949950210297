import { FunctionComponent, useState, SetStateAction, Dispatch } from 'react';
import nanogen from '@Helpers/nanogen';
import { BlockType } from '@/types/workspace';
import { isValidUrl } from '@Helpers/regex';
import { fixUrl } from '@Helpers/url';
import Communication from '@Communication';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import { LinkWrapper } from '..';
interface Props {
    addItem: Function;
    setLinkModal: Dispatch<SetStateAction<boolean>>;
    setName: Dispatch<SetStateAction<string>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    name: string;
    onUpdate: Function;
    isLoading: boolean;
}
const AddLinkModal: FunctionComponent<Props> = ({
    addItem,
    setLinkModal,
    setName,
    setIsLoading,
    name,
    onUpdate,
    isLoading,
}) => {
    const [url, setUrl] = useState('');
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const addLink = async (): Promise<void> => {
        setShowValidationMessage(true);

        if (isValidUrl(url) && name !== '') {
            setIsLoading(true);
            const fullUrl = fixUrl(url) as string;
            const id = nanogen();
            let thumbnail;
            try {
                const res = await Communication.createLinkPreviewThumbnail(fullUrl, id, 'false');
                thumbnail = res.data;
            } catch (e) {
                console.error(e);
            }

            addItem({
                id: id,
                type: BlockType.Link,
                content: {
                    name,
                    description: '',
                    url: fullUrl,
                    thumbnail,
                },
                options: {},
            });
            setLinkModal(false);
            setName('');
            setUrl('');
            onUpdate();
            setIsLoading(false);
        }
    };

    return (
        <LinkWrapper>
            <Input
                className="link-input"
                type={InputComponentType.Text}
                placeholder="Title"
                onChange={(value: string) => setName(value)}
                value={name}
                size="100%"
                onEnter={addLink}
            />
            <Input
                className="link-input"
                type={InputComponentType.Url}
                placeholder="URL"
                onChange={(value: string) => setUrl(value)}
                value={url}
                size="100%"
                onEnter={addLink}
                errorMessage={
                    showValidationMessage
                        ? url === '' || isValidUrl(url)
                            ? ''
                            : 'Please provide valid URL address!'
                        : ''
                }
            />
            <Button
                theme={ButtonTheme.primary}
                disabled={url === '' || name === ''}
                isLoading={isLoading}
                onClick={() => addLink()}
                size={160}
            >
                Add
            </Button>
        </LinkWrapper>
    );
};
export default AddLinkModal;
