import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    children?: string | any;
    className?: string;
    tooltipText?: string;
    tooltipPositionBottom?: boolean; // Default position is top - if you want to change, just add this prop
    width?: string;
    fontSize?: string;
    padding?: string;
    borderRadius?: string;
}
const Tooltip: FunctionComponent<Props> = ({
    children,
    className,
    tooltipText,
    tooltipPositionBottom,
    width,
    fontSize,
    padding,
    borderRadius,
}) => {
    return (
        <StyledTooltip
            className={`${className ? className : ''}`.trim()}
            tooltipPositionBottom={tooltipPositionBottom}
            width={width}
            fontSize={fontSize}
            padding={padding}
            borderRadius={borderRadius}
        >
            {children}
            <span className="label">{tooltipText}</span>
        </StyledTooltip>
    );
};
const StyledTooltip = styled.span<Props>`
    position: relative;
    cursor: pointer;
    .label {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: 50%;
        bottom: ${(props) => (props.tooltipPositionBottom ? 'auto' : '100%')};
        top: ${(props) => (props.tooltipPositionBottom ? '100%' : 'auto')};
        transform: ${(props) => (props.tooltipPositionBottom ? 'translate(-50%, 0);' : 'translate(-50%, -50%)')};
        padding: ${(props) => (props.padding ? props.padding : '2px 6px')};
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '2px')};
        line-height: 16px;
        font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
        background: var(--black-60);
        color: var(--white);
        text-align: ${(props) => (props.width ? 'left' : 'center')};
        white-space: ${(props) => (props.width ? 'unset' : 'nowrap')};
        width: ${(props) => (props.width ? props.width : 'auto')};
        margin-top: ${(props) => (props.tooltipPositionBottom ? '10px' : 'auto')};
        transition: all 0.3s ease-in-out;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            bottom: ${(props) => (props.tooltipPositionBottom ? '100%' : 'auto')};
            top: ${(props) => (props.tooltipPositionBottom ? 'auto' : '100%')};
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: ${(props) =>
                props.tooltipPositionBottom
                    ? 'transparent transparent var(--black-60) transparent'
                    : 'var(--black-60) transparent transparent transparent'};
        }
    }

    &:hover {
        .label {
            visibility: visible;
            opacity: 1;
        }
    }
`;
export default Tooltip;
