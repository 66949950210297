import React from 'react';
import styled from 'styled-components';
import RoleCodes from '@Global/roles';
import useUserStore from '@Store/user';
import Button, { ButtonTheme } from '@Components/button';
import NoItems from '@Components/noItems';

const DeleteAccount: React.FunctionComponent = () => {
    const { activeUserRoles } = useUserStore();

    const getIsRoleAdmin = (): boolean => {
        if (
            activeUserRoles.includes(RoleCodes.SUPER_ADMIN) ||
            activeUserRoles.includes(RoleCodes.DIRECTOR) ||
            activeUserRoles.includes(RoleCodes.ADMIN)
        ) {
            return true;
        } else return false;
    };

    return (
        <StyledDeleteAccount className="page delete-account">
            <NoItems
                className="content"
                content={
                    <>
                        {getIsRoleAdmin() ? (
                            <>
                                <p>Close your account </p>
                                <p>To close your SyncPage account, please contact support.</p>
                                <div>
                                    <p>You can get in touch by emailing us at</p>{' '}
                                    <Button
                                        target="_blank"
                                        theme={ButtonTheme.link}
                                        color="var(--primary-400)"
                                        href="https://syncpage.io/contact"
                                    >
                                        support@syncpage.io
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <p>Your account is managed</p>
                                <p>You can&apos;t deactivate or delete your SyncPage account</p>
                                <p>because it&apos;s owned and managed by an organization.</p>
                                <p>Contact your organization&apos;s admin for assistance.</p>
                            </>
                        )}
                    </>
                }
            />
        </StyledDeleteAccount>
    );
};
const StyledDeleteAccount = styled.div`
    box-sizing: border-box;
    margin: auto;
    padding: 38px 24px 33px;
    .content {
        background-color: var(--white);
        border-radius: 4px;
        position: inherit;
        max-width: 1600px;
        margin: auto;
        padding: 50px 15px;
    }
`;
export default DeleteAccount;
