import { FunctionComponent, useState } from 'react';
import DeleteIcon from '@Assets/icons/workspaces/delete.svg';
import ArrowDown from '@Assets/icons/workspaces/arrow-down.svg';
import ArrowUp from '@Assets/icons/workspaces/arrow-up.svg';
import VisibleIcon from '@Assets/icons/eye/eye-show.svg';
import HiddenIcon from '@Assets/icons/eye/eye-hide.svg';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import CopyIcon from '@Assets/icons/workspaces/paper-add.svg';
import { BlockType, SectionType, ShelfBlock } from '@/types/workspace';
import { ButtonTheme } from '@Components/button';
import styled from 'styled-components';
import useEditorStore from '@Store/editor';
import Direction from '@Global/direction';
import nanogen from '@Helpers/nanogen';
import useDialogue from '@Hooks/useDialogue';
import { EditorType } from '@/types/editor';
import Communication from '@Communication';
import useLoading from '@Hooks/useLoading';
import Tooltip from '@Components/tooltip';
export interface ActionProps {
    section: SectionType;
    onChange?: Function;
}

const SectionActionWrapper: FunctionComponent<ActionProps> = ({ section, onChange }) => {
    const { id, sections, updateSections, type } = useEditorStore();
    const { setDialogue, closeDialogue } = useDialogue();
    const { showLoading, hideLoading } = useLoading();
    const [activeSection, setActiveSection] = useState('');

    /**
     * Switch section visibility on or off by section ID
     */
    const toggleSectionVisibility = (): void => {
        const sectionsTmp = [...sections];
        const target = sectionsTmp.find((s) => s.id === section.id);
        if (target) {
            target.options.visible = !target.options.visible;
            updateSections(sectionsTmp);
        }
        onChange ? onChange() : null;
    };

    /**
     * Remove section from the page
     */
    const deleteSection = async (): Promise<void> => {
        const sectionsTmp = [...sections];
        const index = sectionsTmp.findIndex((s) => s.id === section.id);
        if (index !== -1) {
            showLoading();
            switch (type) {
                case EditorType.WORKSPACE:
                    await Communication.deleteWorkspaceFiles({
                        workspaceId: id,
                        sectionId: section.id,
                    });
                    break;
                case EditorType.TEMPLATE:
                    await Communication.deleteTemplateFiles({
                        templateId: id,
                        sectionId: section.id,
                    });
                    break;
                case EditorType.INFO_ROOM:
                    await Communication.deleteInfoRoomFiles({
                        infoRoomId: id,
                        sectionId: section.id,
                    });
                    break;
            }
            hideLoading();
            sectionsTmp.splice(index, 1);
            updateSections(sectionsTmp);
        }
        onChange ? onChange() : null;
    };

    /**
     * Create new section with the content of selected section
     */
    const duplicateSection = (): void => {
        const sectionsTmp = [...sections];
        const index = sectionsTmp.findIndex((s) => s.id === section.id);
        if (index !== -1) {
            const dString = JSON.stringify(sectionsTmp[index]);
            const duplicate: SectionType = JSON.parse(dString);
            duplicate.id = nanogen();
            duplicate.content.items = duplicate.content.items.map((b) => {
                const { id: _id, ...rest } = b;
                // Do not duplicate shelf items
                if (b.type === BlockType.Shelf) {
                    const block = b as ShelfBlock;
                    block.id = nanogen();
                    block.content.items = [];
                    return block;
                }
                return {
                    id: nanogen(),
                    ...rest,
                };
            });
            sectionsTmp.splice(index + 1, 0, duplicate);
            updateSections(sectionsTmp);
            setTimeout(() => {
                const sectionElement = document.getElementById(`section-${duplicate.id}`);
                sectionElement?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
        onChange ? onChange() : null;
    };

    /**
     * Move selected section in given direction
     * @param direction
     */
    const moveSection = (direction: Direction): void => {
        const sectionsTmp = [...sections];
        const index = sectionsTmp.findIndex((s) => s.id === section.id);
        if (
            (direction === Direction.down && index !== -1 && index < sections.length - 1) ||
            (direction === Direction.up && index > 0)
        ) {
            const dString = JSON.stringify(sectionsTmp[index]);
            const dString2 = JSON.stringify(sectionsTmp[direction === Direction.down ? index + 1 : index - 1]);
            sectionsTmp[index] = JSON.parse(dString2);
            sectionsTmp[direction === Direction.down ? index + 1 : index - 1] = JSON.parse(dString);
            updateSections(sectionsTmp);
        }
        onChange ? onChange() : null;
    };

    return (
        <StyledSectionIcons
            className={`section-icons ${activeSection === section.id ? 'expanded' : ''}`}
            id={`section-icons-${section.id}`}
            onMouseEnter={() => {
                setActiveSection(section.id);
            }}
            onMouseLeave={() => {
                setActiveSection('');
            }}
        >
            <Tooltip tooltipText={section.options.visible ? 'Hide Section' : 'Show Section'} tooltipPositionBottom>
                <img
                    className="section-icon clickable"
                    onClick={() => toggleSectionVisibility()}
                    src={section.options.visible ? VisibleIcon : HiddenIcon}
                    alt="icon"
                />
            </Tooltip>
            <img className="section-icon section-square-icon" src={MoreDotsIcon} alt="icon" />
            <div className="section-hidden-icons">
                <Tooltip tooltipText="Duplicate" tooltipPositionBottom>
                    <img
                        className="section-icon clickable"
                        onClick={() => duplicateSection()}
                        src={CopyIcon}
                        alt="icon"
                    />
                </Tooltip>
                <Tooltip tooltipText="Move Down" tooltipPositionBottom>
                    <img
                        className="section-icon clickable"
                        onClick={() => moveSection(Direction.down)}
                        src={ArrowDown}
                        alt="icon"
                    />
                </Tooltip>
                <Tooltip tooltipText="Move Up" tooltipPositionBottom>
                    <img
                        className="section-icon clickable"
                        onClick={() => moveSection(Direction.up)}
                        src={ArrowUp}
                        alt="icon"
                    />
                </Tooltip>
                <Tooltip tooltipText="Delete" tooltipPositionBottom>
                    <img
                        className="section-icon clickable"
                        onClick={() =>
                            setDialogue({
                                title: 'Are you sure you want to delete the entire section?',
                                buttons: [
                                    {
                                        theme: ButtonTheme.danger,
                                        text: 'Delete',
                                        callback: () => {
                                            deleteSection().then();
                                            closeDialogue();
                                        },
                                    },
                                    {
                                        theme: ButtonTheme.naked,
                                        text: 'Cancel',
                                        callback: () => {
                                            closeDialogue();
                                        },
                                    },
                                ],
                            })
                        }
                        src={DeleteIcon}
                        alt="icon"
                    />
                </Tooltip>
            </div>
        </StyledSectionIcons>
    );
};

const StyledSectionIcons = styled.div`
    cursor: pointer;
    transition: all 0.3s ease;

    img {
        margin-right: 10px;
        transition: all ease 0.4s;
        user-select: none;

        &:last-child {
            margin-right: 0;
        }
    }

    .section-hidden-icons {
        display: inline-flex;
        overflow: hidden;
        transition: all ease 0.5s;
        width: 0;
        opacity: 0;
        .label {
            display: none;
        }
    }

    &:hover {
        opacity: 1 !important;
    }

    &.expanded {
        .section-square-icon {
            margin-right: -18px;
            transform: translate3d(18px, 0, 0);
            pointer-events: none;
            opacity: 0;
        }

        .section-hidden-icons {
            width: 102px;
            opacity: 1;
            overflow: unset;
            .label {
                display: block;
            }
        }
    }
`;

export default SectionActionWrapper;
