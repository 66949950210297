import fetch from './fetch';
import routes from './routes';
import { OrganizationRegisterDto, OrganizationUpdateDto } from '@Global/dtos/organization.dto';

export default class Organization {
    public getOrganization(): any {
        return fetch({
            method: 'get',
            url: routes.organization.me(),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public updateOrganization(data: OrganizationUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.organization.me(),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public registerOrganization(body: OrganizationRegisterDto, token: string): any {
        return fetch(
            {
                method: 'post',
                url: routes.organization.register(token),
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                data: body,
            },
            false,
        );
    }

    public uploadLogoPicture(id: string, formData: FormData): any {
        return fetch({
            method: 'patch',
            url: routes.organization.picture(id),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }

    public removeLogoPicture(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.organization.picture(id),
        });
    }

    public getLogoPicture(id: string): any {
        return fetch({
            method: 'get',
            url: routes.organization.picture(id),
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }
}
