/* eslint-disable @typescript-eslint/no-shadow */
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import '@Global/index';
import Logo from '@Assets/icons/logo.svg';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import AuthStyle from '@Components/style';
import Communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { returnToLogin } from '@Helpers/auth';
import { isValidPassword } from '@Helpers/regex';
import Check from '@Assets/icons/tick-circle.svg';
import LockIcon from '@Assets/icons/lock-reset.svg';
import { CommonStyledPopup } from '@Components/popup/styledPopup';

const ResetPassword: FunctionComponent = () => {
    const { addToast, removeAllToasts } = useToasts();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const token = urlParams.get('token') ?? '';
        if (!token) returnToLogin('resetPassword');
    }, []);

    const passwordChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.toString());
    };
    const confirmPasswordChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        setConfirmPassword(event.toString());
    };

    const getData: any = async () => {
        if (isValidPassword(password)) {
            removeAllToasts();
            if (password !== confirmPassword) {
                addToast('Passwords mismatch', {
                    appearance: 'error',
                });
                return;
            }
            const token = urlParams.get('token') ?? '';
            setLoading(true);
            await Communication.resetPassword(token, password)
                .then(() => {
                    setLoading(false);
                    addToast('Password is updated you can login', {
                        appearance: 'success',
                    });
                    setPopupVisible(true);
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (error.request.status === 401) {
                        addToast('User not found', {
                            appearance: 'error',
                        });
                    }
                });
        }
    };

    return (
        <>
            <CommonStyledPopup
                isVisible={popupVisible}
                closePopup={() => {
                    returnToLogin();
                }}
                timer={5}
            >
                <div className="title">
                    <h3>Password reset successful!</h3>
                    <img src={Check} alt="Check Icon" />
                </div>
                <div className="description">
                    <img src={LockIcon} alt="Lock Icon" />
                    <p>You will be returned to login shortly.</p>
                </div>
            </CommonStyledPopup>

            <AuthStyle>
                <>
                    <img src={Logo} alt={'logo-image'}></img>
                    <h3>Reset password</h3>
                    <p>Enter new password.</p>
                    <Input
                        type={InputComponentType.Password}
                        placeholder="Password"
                        value={password}
                        onEnter={getData}
                        errorMessage={
                            isValidPassword(password) || password === ''
                                ? ''
                                : 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                        }
                        onChange={passwordChangeHandler}
                        size="100%"
                    ></Input>
                    <StyledInput
                        type={InputComponentType.Password}
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={confirmPasswordChangeHandler}
                        errorMessage={
                            isValidPassword(password) || confirmPassword === ''
                                ? password === confirmPassword
                                    ? ''
                                    : 'Passwords does not match'
                                : 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                        }
                        size="100%"
                        onEnter={getData}
                    ></StyledInput>
                    <p>
                        Return to <a href="/login">Login </a>
                        or <a href="/register">Create New Account</a>
                    </p>
                    <Button
                        theme={ButtonTheme.primary}
                        size={200}
                        onClick={getData}
                        isLoading={loading}
                        disabled={!isValidPassword(password) || password !== confirmPassword || password === ''}
                    >
                        Reset password
                    </Button>
                </>
            </AuthStyle>
        </>
    );
};

const StyledInput = styled(Input)`
    margin: 10px 0;
`;

export default ResetPassword;
