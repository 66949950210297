import { FunctionComponent, Dispatch, SetStateAction, useState } from 'react';
import { CompanyDto } from '@Global/dtos/company.dto';
import useGlobalState from '@Store/global';
import Button, { ButtonTheme } from '@Components/button';
import {
    SkeletonWrapper,
    TableBody,
    TableHeader,
    TableHeaderItem,
    TableSkeleton,
    TableSkeletonWrapper,
} from '@Components/table';
import CircleLetter from '@Components/circleLetter';
import NoItems from '@Components/noItems';
import CompanyIcon from '@Assets/icons/accounts/company.svg';
import LinkedInIcon from '@Assets/icons/social/linkedin-big.svg';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import EditIconInactive from '@Assets/icons/settings/inactive/edit.svg';
import EditIconActive from '@Assets/icons/settings/active/edit.svg';
import { StyledTableItem } from '..';
import { StyledAction, WrapperStyled } from '../../workspaces/table';

interface Props {
    allCompanies: CompanyDto[] | null;
    getAddMoreOrEmpty: Function;
    setCompanyModal?: Dispatch<SetStateAction<boolean>>;
    selectedRow?: string | number;
    openCompany: Function;
    searchValue: string;
    deleteCompany: Function;
}

const AllCompanies: FunctionComponent<Props> = ({
    allCompanies,
    setCompanyModal,
    getAddMoreOrEmpty,
    selectedRow,
    openCompany,
    searchValue,
    deleteCompany,
}) => {
    const { activeActionElement } = useGlobalState();

    const [hoveredBtn, setHoveredBtn] = useState('');

    return (
        <>
            {allCompanies ? (
                allCompanies?.filter(
                    (company: CompanyDto) =>
                        company.name?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        company.email?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        company.website?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        company.linkedin?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        company.hq?.toLowerCase().includes(searchValue.toLowerCase() ?? ''),
                )?.length !== 0 ? (
                    <>
                        <TableHeader columns={6}>
                            <TableHeaderItem>Company</TableHeaderItem>
                            <TableHeaderItem>Website</TableHeaderItem>
                            <TableHeaderItem>LinkedIn</TableHeaderItem>
                            <TableHeaderItem>Contacts</TableHeaderItem>
                            <TableHeaderItem>Workspaces</TableHeaderItem>
                            <TableHeaderItem>Actions</TableHeaderItem>
                        </TableHeader>
                        <TableBody>
                            {allCompanies
                                ?.filter(
                                    (company: CompanyDto) =>
                                        company.name?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        company.email?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        company.website?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        company.linkedin?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        company.hq?.toLowerCase().includes(searchValue.toLowerCase() ?? ''),
                                )
                                .map((company: CompanyDto, id: number) => {
                                    return (
                                        <StyledTableItem
                                            columns={6}
                                            key={id}
                                            onClick={() => openCompany(company.id, id)}
                                            className={`companies table-item ${
                                                selectedRow === id ? 'selected-item' : ''
                                            }`.trim()}
                                        >
                                            <p>
                                                {company.name ? (
                                                    company.logoUrl || company.profilePicture ? (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={
                                                                company.profilePicture
                                                                    ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${company.id}`
                                                                    : company.logoUrl
                                                            }
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={CompanyIcon}
                                                            alt="company icon"
                                                        />
                                                    )
                                                ) : null}
                                                {company.name}
                                            </p>
                                            <p>{company.website ? company.website : '-'}</p>
                                            <p>
                                                {company.linkedin ? (
                                                    <a
                                                        href={company.linkedin}
                                                        style={{ display: 'flex' }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            style={{ maxWidth: '66px' }}
                                                            src={LinkedInIcon}
                                                            alt="link"
                                                        />
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </p>
                                            <p className="associated-account">
                                                {company?.CompanyContacts[0]?.contact ? (
                                                    company?.CompanyContacts[0]?.contact.profilePicture ? (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={`${process.env.REACT_APP_API_URL}/accounts/contact/picture/${company?.CompanyContacts[0]?.contact.id}`}
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <CircleLetter
                                                            bgColor="var(--primary-500)"
                                                            letter={`${
                                                                company?.CompanyContacts[0]?.contact?.firstName &&
                                                                company?.CompanyContacts[0]?.contact?.firstName[0]?.toUpperCase()
                                                            }${
                                                                company?.CompanyContacts[0]?.contact.lastName &&
                                                                company?.CompanyContacts[0]?.contact.lastName !== null
                                                                    ? company?.CompanyContacts[0]?.contact?.lastName[0]?.toUpperCase()
                                                                    : ''
                                                            }`.trim()}
                                                            withTooltip
                                                            tooltipText={
                                                                company?.CompanyContacts[0]?.contact.firstName +
                                                                ' ' +
                                                                (company?.CompanyContacts[0]?.contact.lastName &&
                                                                company?.CompanyContacts[0]?.contact.lastName !== null
                                                                    ? company?.CompanyContacts[0]?.contact?.lastName[0]
                                                                    : '')
                                                            }
                                                        />
                                                    )
                                                ) : (
                                                    '-'
                                                )}
                                                <span>
                                                    {getAddMoreOrEmpty(
                                                        company?.CompanyContacts,
                                                        company?.CompanyContacts[0]?.contact?.firstName +
                                                            ' ' +
                                                            (company?.CompanyContacts[0]?.contact.lastName &&
                                                            company?.CompanyContacts[0]?.contact.lastName !== null
                                                                ? company?.CompanyContacts[0]?.contact?.lastName[0]
                                                                : ''),
                                                    )}
                                                </span>
                                            </p>
                                            <p className="associated-workspaces">
                                                {company?.Workspaces[0]?.name
                                                    ? getAddMoreOrEmpty(
                                                          company?.Workspaces,
                                                          company?.Workspaces[0]?.name,
                                                      )
                                                    : '-'}
                                            </p>
                                            <p className="actions">
                                                <StyledAction
                                                    className={`block-actions ${
                                                        activeActionElement === company.id ? 'active' : ''
                                                    }`}
                                                >
                                                    <img
                                                        id={`actions-${company.id}`}
                                                        className={`block-actions-expander ${
                                                            activeActionElement !== company.id ? 'hidden' : ''
                                                        }`}
                                                        src={MoreDotsIcon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === company.id
                                                                        ? null
                                                                        : company.id,
                                                            });
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <WrapperStyled
                                                        className={`block-actions-body ${
                                                            activeActionElement !== company.id ? 'hidden' : ''
                                                        }`}
                                                    >
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'delete'
                                                                    ? DeleteIconActive
                                                                    : DeleteIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('delete')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteCompany(company.id);
                                                            }}
                                                        >
                                                            Delete Company
                                                        </Button>
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'edit'
                                                                    ? EditIconActive
                                                                    : EditIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('edit')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openCompany(company.id, id);
                                                            }}
                                                        >
                                                            Edit Company
                                                        </Button>
                                                    </WrapperStyled>
                                                </StyledAction>
                                            </p>
                                        </StyledTableItem>
                                    );
                                })}
                        </TableBody>
                    </>
                ) : (
                    <NoItems
                        content={
                            <>
                                <p>No companies yet.</p>
                                <div>
                                    <p>Start with</p>
                                    <Button
                                        theme={ButtonTheme.link}
                                        color="var(--primary-400)"
                                        onClick={() => {
                                            setCompanyModal && setCompanyModal(true);
                                        }}
                                    >
                                        creating a new one.
                                    </Button>
                                </div>
                            </>
                        }
                    />
                )
            ) : (
                <SkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                </SkeletonWrapper>
            )}
        </>
    );
};
export default AllCompanies;
