import React from 'react';

const ActionPlans: React.FunctionComponent = () => {
    return (
        <div className="page action-plans">
            <h3>Action Plans</h3>
        </div>
    );
};
export default ActionPlans;
