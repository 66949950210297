import { FunctionComponent, useState, useEffect } from 'react';
import './navbar.scss';
import useGlobalState from '@Store/global';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RequestDto } from '@Global/dtos/request.dto';
import Communication from '@Communication';
import useWindowSize from '@Hooks/useResize';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import HelpInactive from '@Assets/icons/navbar/help.svg';
import SupportIcon from '@Assets/icons/navbar/support-agent.svg';
import { NavbarData } from '@/types/navbar';
import useUserStore from '@Store/user';
import Button, { ButtonTheme } from '@/components/button';

interface Props {
    hasBackButton?: boolean;
    backCallback?: Function;
    navbarData: NavbarData;
    className?: string;
}

const VerticalNavbar: FunctionComponent<Props> = ({ hasBackButton = false, backCallback, navbarData, className }) => {
    const { isNavbarCollapsed } = useGlobalState();
    const { activeUserRoles } = useUserStore();
    const location = useLocation();
    const size = useWindowSize();
    const navigate = useNavigate();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [pendingRequests, setPendingRequests] = useState<RequestDto[] | null>(null);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        if (isMobileWidth) useGlobalState.setState({ isNavbarCollapsed: true });
    }, [isMobileWidth]);

    useEffect(() => {
        Communication.getAllRequestsByStatus('pending').then((res: any) => {
            setPendingRequests(res?.data);
        });
    }, [location.pathname]);

    return (
        <div className={`vertical-navbar ${isNavbarCollapsed ? 'collapsed' : ''} ${className ?? ''}`}>
            <div className="navigation">
                {(hasBackButton || backCallback) && (
                    <div className="back-nav">
                        <StyledLink
                            to="/"
                            onClick={() => {
                                if (backCallback) backCallback();
                                if (isMobileWidth) useGlobalState.setState({ isNavbarCollapsed: true });
                            }}
                        >
                            <img src={arrowLeft} className="front" alt="icon"></img>
                            <p>Back</p>
                        </StyledLink>
                    </div>
                )}
                {navbarData.map((group) => {
                    return group.excludeForRoles?.includes(activeUserRoles[0]) ? null : (
                        <div className="nav-group" key={group.groupName}>
                            <span className="group-title">{group.groupName}</span>
                            {group.links.map((item) => {
                                return item.excludeForRoles?.includes(activeUserRoles[0]) ? null : (
                                    <div className="nav-item" key={item.title}>
                                        <StyledLink
                                            to={item.url}
                                            active={(
                                                location.pathname.includes(item.url) ||
                                                (!!item.default && location.pathname === '/')
                                            ).toString()}
                                            className={`clickable nav-link ${
                                                item.url === '/requests' ? 'has-pending-req' : ''
                                            }`.trim()}
                                            onClick={() => {
                                                useGlobalState.setState({ activeNavbarLink: item.url });
                                                if (isMobileWidth) useGlobalState.setState({ isNavbarCollapsed: true });
                                            }}
                                        >
                                            <div>
                                                <img
                                                    draggable="false"
                                                    src={
                                                        location.pathname.includes(item.url) ||
                                                        (!!item.default && location.pathname === '/')
                                                            ? item.activeIcon
                                                            : item.inactiveIcon
                                                    }
                                                    alt="icon"
                                                />
                                                <p>{item.title}</p>
                                            </div>
                                            {item.pendingRequests &&
                                            location.pathname !== '/requests' &&
                                            pendingRequests?.length &&
                                            pendingRequests?.length > 0 ? (
                                                <p className="pending-request">{pendingRequests?.length} Pending</p>
                                            ) : (
                                                <></>
                                            )}
                                        </StyledLink>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div className="bottom-links">
                {isNavbarCollapsed ? (
                    <StyledLink
                        to={'/subscription'}
                        className="clickable nav-link"
                        onClick={() => {
                            if (isMobileWidth) useGlobalState.setState({ isNavbarCollapsed: true });
                        }}
                    >
                        <p className="visible upgrade-icon">🔥</p>
                    </StyledLink>
                ) : (
                    <StyledUpgradeContainer>
                        <p className="upgrade-container-title">Upgrade to Pro 🔥</p>
                        <p>Get unlimited workspaces</p>
                        <p>& advanced features.</p>

                        <Button
                            theme={ButtonTheme.black}
                            size={142}
                            onClick={() => {
                                navigate('/subscription');
                            }}
                        >
                            Upgrade
                        </Button>
                    </StyledUpgradeContainer>
                )}

                <StyledLink
                    target="_blank"
                    to={'https://help.syncpage.io/'}
                    className="clickable nav-link"
                    onClick={() => {
                        if (isMobileWidth) useGlobalState.setState({ isNavbarCollapsed: true });
                    }}
                >
                    <img draggable="false" src={HelpInactive} alt="icon" />
                    <p>Help center</p>
                </StyledLink>
                <StyledLink
                    target="_blank"
                    to={'https://syncpage.io/contact'}
                    className="clickable nav-link"
                    onClick={() => {
                        if (isMobileWidth) useGlobalState.setState({ isNavbarCollapsed: true });
                    }}
                >
                    <img draggable="false" src={SupportIcon} alt="icon" />
                    <p>Contact support</p>
                </StyledLink>
            </div>
        </div>
    );
};

interface LinkProps {
    active?: string;
}

const StyledLink = styled(Link)<LinkProps>`
    background-color: ${(props) => (props.active === 'true' ? 'var(--black-20)' : 'transparent')};
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 14px;
    border-radius: 10px;
    box-sizing: initial;
    width: 154px;

    // Media Queries
    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    &:hover {
        //background-color: ${(props) => (props.active !== 'true' ? 'var(--primary-50)' : 'var(--primary-400)')};;
        box-shadow: var(--box-shadow2);
    }
    &.nav-link {
        p {
            margin-left: 20px;
        }
    }
    &.has-pending-req {
        justify-content: space-between;
    }

    > div {
        display: flex;
        align-items: center;
    }
    &.visible {
        opacity: 1;
    }
    p {
        color: ${(props) => (props.active === 'true' ? 'var(--black-60)' : '#AFAFB7')};
        font-size: 14px;
        &.pending-request {
            font-size: 8px;
            font-weight: 600;
            letter-spacing: -0.08px;
            margin-left: 5px;
        }
    }
`;

const StyledUpgradeContainer = styled.div`
    margin: 24px 6px;
    border-radius: 9px;
    background-color: var(--black-20);
    width: 166px;
    height: 146px;
    padding: 12px;

    p {
        color: var(--black-60);
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .upgrade-container-title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.16px;
        padding-bottom: 8px;
    }

    Button {
        margin-top: 20px;
    }
`;

export default VerticalNavbar;
