import { FunctionComponent, MouseEventHandler } from 'react';
import styled from 'styled-components';

interface CardProps {
    className?: string;
    imageSrc?: string | any;
    title?: string;
    paragraph?: string;
    onClick?: MouseEventHandler<HTMLDivElement> & Function;
    disabled?: boolean;
}

const BlockOption: FunctionComponent<CardProps> = ({ className, imageSrc, paragraph, title, onClick, disabled }) => {
    return (
        <StyledBlock
            className={`card ${className ? className : ''} ${disabled ? 'disabled' : ''} cursor-pointer`.trim()}
            onClick={onClick}
        >
            <img src={imageSrc} alt="block-img" />
            <h6>{title}</h6>
            {paragraph ? <span>{paragraph}</span> : <></>}
        </StyledBlock>
    );
};

const StyledBlock = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    transition: all 0.1s ease-in-out;
    width: 122px;
    height: 122px;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;

    h6 {
        font-size: 14px;
        margin-bottom: 4px;
        color: var(--black-75);
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(82, 82, 82, 0.16);
    }

    &.disabled {
        cursor: not-allowed;
        box-shadow: none !important;

        img,
        h6 {
            opacity: 0.3;
        }
    }
`;
export default BlockOption;
