import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '@Global/colors';
import DeleteIcon from '@Assets/icons/workspaces/delete-circle.svg';
import 'react-quill/dist/quill.snow.css';
import { EmbedShelfItem, MediaBlock } from '@/types/workspace';
import Direction from '@Global/direction';
import ArrowDown from '@Assets/icons/workspaces/arrow-down-circle.svg';
import ArrowUp from '@Assets/icons/workspaces/arrow-up-circle.svg';
import useEditorStore from '@Store/editor';
import Communication from '@Communication';
import { EditorType } from '@/types/editor';
import { useToasts } from 'react-toast-notifications';
import BlockActions, { BlockActionItem } from '@Components/editor/actionWrapper';
import Modal from '@Components/modal';
import Tooltip from '@Components/tooltip';
import UploadFile from '@Components/uploadFile';
import ReplaceIcon from '@Assets/icons/workspaces/change-circle.svg';
import Button, { ButtonTheme } from '@Components/button';
import useGlobalState from '@Store/global';
import { MediaCommand } from '@Global/dtos/workspace.dto';
import { EmbedTypes } from '@Global/embedTypes';
import { StyledNewBlockModal } from '../base/styled';
import BlockOption from '../card';
import LibraryIcon from '@Assets/icons/workspaces/library.svg';
import UploadIcon from '@Assets/icons/workspaces/upload.svg';
import EmbedIcon from '@Assets/icons/workspaces/embed.svg';
import ContentLibrary from '@/pages/library/content';
import { FileRes } from '@/types/file';
import PreDownloadBlock from './PreDownloadBlock';
import EmbedFormModal from '../shelf/embedFormModal';
import EmbedContent from '../../../sharedView/embedContent';

interface Props {
    className?: string | '';
    onChange?: Function; // Triggers each time content changes
    onUpdate?: Function; // Triggers when store should be updated
    onDelete?: Function; // Triggers when block is destroyed
    mediaBlock: MediaBlock;
    activeSection: number;
}

const MediaBlockElement: FunctionComponent<Props> = ({
    className,
    mediaBlock,
    activeSection,
    onChange = () => {
        return;
    },
    onUpdate = () => {
        return;
    },
    onDelete = () => {
        return;
    },
}) => {
    const { id, type: editorType, sections, activeElementId, setActiveElement } = useEditorStore();

    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [embedModal, setEmbedModal] = useState(false);

    const { addToast } = useToasts();
    const { mediaCommand } = useGlobalState();
    const [file, setFile] = useState<File | null>(null);
    const [folder, setFolder] = useState<string>('workspaces');
    const [mediaModal, setMediaModal] = useState(false);
    const [libraryModal, setLibraryModal] = useState(false);
    const [files, setFiles] = useState<FileRes[]>([]);
    const [mediaType, setMediaType] = useState<string>('');
    const supportedTypes = ['pdf', 'mov', 'mp4', 'avi', 'jpeg', 'jpg', 'png'];

    useEffect(() => {
        activeElementId === mediaBlock.id ? focus() : loseFocus();
    }, [activeElementId]);

    useEffect(() => {
        console.log(file);
    }, [file]);

    useEffect(() => {
        Communication.getAllFilesAndLinks().then((res: any) => {
            setFiles(res.data.filter((libraryFile: FileRes) => supportedTypes.includes(libraryFile.type)));
        });
        switch (editorType) {
            case EditorType.WORKSPACE:
                setFolder('workspaces');
                break;
            case EditorType.INFO_ROOM:
                setFolder('inforooms');
                break;
            case EditorType.TEMPLATE:
                setFolder('templates');
                break;
        }
    }, []);

    useEffect(() => {
        if (activeElementId === mediaBlock.id && mediaCommand === MediaCommand.OPEN_MEDIA_MODAL) {
            setMediaModal(true);
            useGlobalState.setState({ shelfCommand: null });
        }
    }, [mediaCommand]);

    const isPdf = (): boolean | undefined => file?.type.includes('pdf');
    const isVideo = (): boolean | undefined => file?.type.includes('video');

    /**
     * Update  block content.
     */
    const update = async (): Promise<void> => {
        const sectionsTmp = [...sections];
        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === mediaBlock.id) as
            | MediaBlock
            | undefined;
        if (block && file) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('files', file);
            formData.append('name', `${isPdf() ? 'pdf' : isVideo() ? 'video' : 'image'}-${mediaBlock.id}`);
            switch (editorType) {
                case EditorType.WORKSPACE:
                    formData.append('workspaceId', id);
                    break;
                case EditorType.INFO_ROOM:
                    formData.append('infoRoomId', id);
                    break;
                case EditorType.TEMPLATE:
                    formData.append('templateId', id);
                    break;
            }
            try {
                if (mediaBlock.content.id) {
                    await Communication.deleteFile(mediaBlock.content.id);
                    block.content = {
                        type: isPdf() ? 'pdf' : isVideo() ? 'video' : 'image',
                    };
                    useEditorStore.setState({ sections: sectionsTmp });
                }
                const fileRes = await Communication.uploadFile(formData);
                block.content = {
                    id: fileRes.data.id,
                    link: fileRes.data.link,
                    type: isPdf() ? 'pdf' : isVideo() ? 'video' : 'image',
                };
                useEditorStore.setState({ sections: sectionsTmp });
                setUploadModal(false);
                setIsLoading(false);
                setFile(null);
                loseFocus();
                onChange({ id: fileRes.data.id, link: fileRes.data.link });
            } catch (e) {
                setUploadModal(false);
                setIsLoading(false);
                setFile(null);
                addToast('Error during file upload', {
                    appearance: 'error',
                });
            }
        }
    };

    const setFromLibrary = async (fileFromLibrary: FileRes): Promise<void> => {
        const sectionsTmp = [...sections];
        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === mediaBlock.id) as
            | MediaBlock
            | undefined;
        if (block) {
            setIsLoading(true);
            try {
                //const fileRes = await Communication.uploadFile(formData);
                block.content = {
                    id: fileFromLibrary.id,
                    link: fileFromLibrary.link,
                    type: fileFromLibrary?.type.includes('pdf')
                        ? 'pdf'
                        : ['MP4', 'MOV', 'AVI'].includes(fileFromLibrary?.type.toUpperCase())
                        ? 'video'
                        : 'image',
                    global: true,
                };
                useEditorStore.setState({ sections: sectionsTmp });
                setUploadModal(false);
                setIsLoading(false);
                setFile(null);
                loseFocus();
                onChange({ id: fileFromLibrary.id, link: fileFromLibrary.link });
            } catch (e) {
                setUploadModal(false);
                setIsLoading(false);
                setFile(null);
                addToast('Error during file upload', {
                    appearance: 'error',
                });
            }
        }
    };
    const addEmbedMediaItem = (item: any): void => {
        const sectionsTmp = [...sections];

        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === mediaBlock.id) as
            | EmbedShelfItem
            | undefined;

        if (block) {
            setIsLoading(true);
            try {
                block.content = {
                    name: item.content.name,
                    url: item.content.url,
                    type: item.content.type,
                };
            } catch (e) {
                setUploadModal(false);
                setIsLoading(false);
                addToast('Error during file upload', {
                    appearance: 'error',
                });
            }
        }
    };

    /**
     * Delete selected block from the section
     */
    const deleteBlock = async (): Promise<void> => {
        setIsLoading(true);
        const sectionsTmp = [...sections];
        const index = sectionsTmp[activeSection].content.items.findIndex((bl) => bl.id === mediaBlock.id);
        if (index !== -1) {
            if (mediaBlock.content.id && activeElementId !== mediaBlock.id) {
                await Communication.deleteFile(mediaBlock.content.id);
            }
            sectionsTmp[activeSection].content.items.splice(index, 1);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        setIsLoading(false);
        onDelete();
    };

    /**
     * Move selected block in given direction
     * @param direction
     */
    const moveBlock = (direction: Direction): void => {
        const sectionsTmp = [...sections];
        const blocks = sectionsTmp[activeSection].content.items;
        const index = blocks.findIndex((bl) => bl.id === mediaBlock.id);
        if (
            (direction === Direction.down && index !== -1 && index < blocks.length - 1) ||
            (direction === Direction.up && index > 0)
        ) {
            const dString = JSON.stringify(blocks[index]);
            const dString2 = JSON.stringify(blocks[direction === Direction.down ? index + 1 : index - 1]);
            blocks[index] = JSON.parse(dString2);
            blocks[direction === Direction.down ? index + 1 : index - 1] = JSON.parse(dString);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onUpdate();
        onChange();
    };

    const loseFocus = (): void => {
        setIsActive(false);
        setFile(null);
        onUpdate();
    };

    const focus = (): void => {
        setActiveElement(mediaBlock.id);
        setIsActive(true);
    };

    const actions: BlockActionItem[] = [
        {
            icon: ArrowUp,
            title: 'Move Up',
            onClick: () => moveBlock(Direction.up),
        },
        {
            icon: ArrowDown,
            title: 'Move Down',
            onClick: () => moveBlock(Direction.down),
        },
        {
            icon: ReplaceIcon,
            title: 'Replace media',
            onClick: () => {
                setMediaModal(true);
            },
        },
        {
            icon: DeleteIcon,
            title: 'Remove',
            separated: true,
            onClick: () => deleteBlock(),
        },
    ];

    useEffect(() => {
        const blockType = mediaBlock.content.type;
        const embedTypes = EmbedTypes;
        const isEmbedType = embedTypes?.filter((eType: string) => eType === blockType);

        if (blockType === 'video' || blockType === 'pdf') {
            setMediaType('video&pdf');
        } else if (blockType === 'image') {
            setMediaType('image');
        } else if (blockType === isEmbedType[0]) {
            setMediaType('embed');
        } else {
            setMediaType('');
        }
    }, [mediaBlock.content.type]);

    return (
        <>
            {/*MODAL FOR MEDIA BLOCK*/}
            <Modal
                modalVisible={mediaModal}
                closeModal={() => {
                    setMediaModal(false);
                    if (!mediaBlock.content.link) void deleteBlock();
                }}
                title="Add media item"
            >
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Tooltip tooltipText="Add content from your Library." width="157px">
                            <BlockOption
                                imageSrc={LibraryIcon}
                                onClick={() => {
                                    setMediaModal(false);
                                    setLibraryModal(true);
                                }}
                                title="From Library"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Upload a file from your computer." width="157px">
                            <BlockOption
                                imageSrc={UploadIcon}
                                onClick={() => {
                                    setFile(null);
                                    setMediaModal(false);
                                    setUploadModal(true);
                                }}
                                title="Upload a file"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Embed content from other sites." width="157px">
                            <BlockOption
                                imageSrc={EmbedIcon}
                                onClick={() => {
                                    setMediaModal(false);
                                    setEmbedModal(true);
                                }}
                                title="Embed content"
                            />
                        </Tooltip>
                        {/* <BlockOption
                            imageSrc={LinkIcon}
                            onClick={() => {
                                setShelfModal(false);
                                setLinkModal(true);
                            }}
                            title="Add link"
                        />
                        <BlockOption
                            imageSrc={CloudIcon}
                            onClick={() => {
                                setShelfModal(false);
                                handleOpenPicker();
                            }}
                            title="Google Drive"
                        /> */}
                    </div>
                </StyledNewBlockModal>
            </Modal>
            <Modal
                className="add-from-library-modal"
                modalVisible={libraryModal}
                size="50%"
                closeModal={() => {
                    if (!mediaBlock.content.link) void deleteBlock();
                    setLibraryModal(false);
                }}
                title="Add from library"
            >
                <ContentLibrary
                    globalFiles={files}
                    onSelect={(item: FileRes) => {
                        setFromLibrary(item);
                        setLibraryModal(false);
                    }}
                />
            </Modal>
            <Modal
                modalVisible={uploadModal}
                size={500}
                closeModal={() => {
                    if (!isLoading) {
                        setUploadModal(false);
                        setFile(null);
                        if (!mediaBlock.content.link) void deleteBlock();
                    }
                }}
                title="Upload a file"
            >
                <UploadWrapper>
                    <UploadFile file={file} isLoading={isLoading} setFile={setFile} />
                    <Button
                        theme={ButtonTheme.primary}
                        onClick={() => update()}
                        disabled={!file}
                        isLoading={isLoading}
                        size={160}
                    >
                        Upload
                    </Button>
                </UploadWrapper>
            </Modal>
            <Modal
                className="embed-form-modal"
                modalVisible={embedModal}
                size={500}
                closeModal={() => {
                    setEmbedModal(false);
                    if (!mediaBlock.content.embed) void deleteBlock();
                }}
                title="Embed content"
            >
                <EmbedFormModal
                    blockType="media"
                    addItem={addEmbedMediaItem}
                    onUpdate={onUpdate}
                    setEmbedModal={setEmbedModal}
                    deleteBlock={deleteBlock}
                    mediaBlock={mediaBlock}
                />
            </Modal>
            <MediaBlockStyled
                className={`media-block media-block-${mediaBlock.id} editor-block ${isActive ? 'active' : ''} ${
                    className ?? ''
                }`}
                onMouseEnter={focus}
                onMouseLeave={loseFocus}
            >
                <StyledMediaWrapper>
                    {mediaType === 'embed' ? (
                        <EmbedContent selectedItemData={mediaBlock} blockType="media" />
                    ) : mediaType === 'video&pdf' ? (
                        <PreDownloadBlock link={mediaBlock.content.link} type={mediaBlock.content.type} />
                    ) : (
                        <img
                            crossOrigin="anonymous"
                            src={
                                mediaBlock.content.global
                                    ? `${process.env.REACT_APP_API_URL}/files/download/${mediaBlock.content.link}`
                                    : `${process.env.REACT_APP_API_URL}/${folder}/files/${mediaBlock.content.link}`
                            }
                            alt="image"
                        />
                    )}
                </StyledMediaWrapper>
                <BlockActions elementId={mediaBlock.id} items={actions} />
            </MediaBlockStyled>
        </>
    );
};

const MediaBlockStyled = styled.div`
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid transparent;
    box-sizing: border-box;
    padding: 10px;
    transition: all ease 0.3s;
    min-height: 50px;
    word-wrap: break-word;
    border-radius: 5px;

    &:hover {
        box-shadow: ${colors.boxShadow};
        cursor: pointer;
    }
`;

const StyledMediaWrapper = styled.div`
    margin-top: 30px;
    height: fit-content;
    position: relative;
    transition: 0.3s all ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;

    &.blur {
        margin-top: 0;
    }

    > img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    .video-container {
        position: relative;
        width: 100%;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.18);
            z-index: 1;
        }
        img {
            z-index: 2;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 50%);
            bottom: 50%;
        }
    }
`;

const UploadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin-right: auto;
    }
`;

export default MediaBlockElement;
