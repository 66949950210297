import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '@Components/navbar';
import useUserStore from '@Store/user';
import initFetch from '@Helpers/init';
import useGlobalState from '@Store/global';
import useEditorStore from '@Store/editor';

const Private: React.FunctionComponent = () => {
    const { setActiveSectionId } = useEditorStore();
    const { id, email } = useUserStore();

    useEffect(() => {
        if (!id || !email) {
            initFetch().then();
        }
    }, [id, email]);

    const findId = (elementsArray: HTMLElement[], elementId: string): boolean => {
        return !!elementsArray.find((element) => element.id === elementId);
    };

    const findClass = (elementsArray: HTMLElement[], elementClass: string): boolean => {
        return !!elementsArray.find((element) => element.classList.contains(elementClass));
    };

    return id || email ? (
        <>
            <Navbar />
            <div
                className="body private"
                onMouseMove={(e) => {
                    if (
                        e.target instanceof HTMLElement &&
                        !e.target.classList.contains('section-icons') &&
                        !e.target.classList.contains('section-icon') &&
                        !e.target.classList.contains('section-hidden-icons')
                    ) {
                        setActiveSectionId(null);
                    }
                }}
                onMouseUp={(e) => {
                    if (e.target instanceof HTMLElement) {
                        const targetedElements: HTMLElement[] = [];
                        let currentElement: HTMLElement | null = e.target;

                        while (currentElement !== null) {
                            targetedElements.push(currentElement);
                            currentElement = currentElement.parentElement;
                        }

                        if (!findId(targetedElements, 'navbar-profile-image')) {
                            useGlobalState.setState({ userContextMenu: false });
                        }
                        if (!findClass(targetedElements, 'workspace-context-settings')) {
                            useGlobalState.setState({ workspaceContextMenu: false });
                        }
                        if (!findClass(targetedElements, 'account-context-settings')) {
                            useGlobalState.setState({ accountContextMenu: false });
                        }
                        if (!findClass(targetedElements, 'actions-')) {
                            useGlobalState.setState({ activeActionElement: null });
                        }
                        if (!findClass(targetedElements, 'editor-block')) {
                            useEditorStore.setState({ activeElementId: null });
                        }
                    }
                }}
            >
                <Outlet />
            </div>
        </>
    ) : (
        <></>
    );
};

export default Private;
