import { FunctionComponent, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonTheme } from '@Components/button';
import colors from '@/global/colors';
import PreviewIcon from '@Assets/icons/eye/eye-white.svg';

interface CardProps {
    className?: string;
    imageSrc?: string | any;
    templateId?: string;
    title?: string;
    paragraph?: string;
    onClick?: MouseEventHandler<HTMLDivElement> & Function;
    disabled?: boolean;
    template?: boolean;
    templateButtonClick?: MouseEventHandler<HTMLButtonElement> & Function;
    setIsTemplatePreviewVisible?: Function;
    setCreateFromTemplateVisible?: Function;
    setPreviewTemplateId?: Function;
}

const NewWorkspaceCard: FunctionComponent<CardProps> = ({
    className,
    imageSrc,
    templateId,
    paragraph,
    title,
    onClick,
    disabled,
    template,
    templateButtonClick,
    setIsTemplatePreviewVisible,
    setCreateFromTemplateVisible,
    setPreviewTemplateId,
}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <StyledBlock
            className={`new-workspace-card ${className ? className : ''} ${
                disabled ? 'disabled' : ''
            } cursor-pointer`.trim()}
            onClick={onClick}
        >
            {template ? (
                <TemplateCard
                    onMouseEnter={() => {
                        setHovered(true);
                    }}
                    onMouseLeave={() => {
                        setHovered(false);
                    }}
                >
                    <img src={imageSrc} alt="card-photo" />
                    <div>
                        <h6>{title}</h6>
                        <span>{paragraph}</span>
                    </div>
                    <HoveredBlock className={hovered ? 'hovered' : ''}>
                        <Button
                            leftIcon={PreviewIcon}
                            className="link"
                            theme={ButtonTheme.link}
                            color={colors.white}
                            size={80}
                            onClick={() => {
                                if (setPreviewTemplateId) setPreviewTemplateId(templateId);
                                if (setCreateFromTemplateVisible) setCreateFromTemplateVisible(false);
                                if (setIsTemplatePreviewVisible) setIsTemplatePreviewVisible(true);
                            }}
                        >
                            Preview
                        </Button>
                        <Button onClick={templateButtonClick} size={126}>
                            Use this template
                        </Button>
                    </HoveredBlock>
                </TemplateCard>
            ) : (
                <DefaultCard>
                    <div>
                        <img src={imageSrc} alt="card-photo" />
                        <h6>{title}</h6>
                    </div>
                    <span>{paragraph}</span>
                </DefaultCard>
            )}
        </StyledBlock>
    );
};

const StyledBlock = styled.div`
    background-color: white;
    border-radius: 8px;
    transition: all 0.1s ease-in-out;

    h6 {
        font-size: 14px;
        color: var(--black-60);
    }
    span {
        color: var(--black-75);
        line-height: 18px;
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(82, 82, 82, 0.16);
    }
    img {
        margin-right: 12px;
    }
    &.disabled {
        cursor: not-allowed;
        box-shadow: none !important;

        img,
        h6 {
            opacity: 0.3;
        }
    }
`;
const TemplateCard = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 12px;
    width: 222px;
    height: 72px;
    position: relative;
    span {
        font-size: 12px;
    }
`;
const HoveredBlock = styled.div`
    background-image: linear-gradient(to right, rgba(0, 114, 109, 0.85), rgba(169, 207, 206, 0.85));
    backdrop-filter: blur(2px);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
    opacity: 0;
    visibility: hidden;
    gap: 5px;
    &.hovered {
        opacity: 1;
        visibility: visible;
    }
    button {
        margin: 0;
        font-size: 10px;
        color: var(--primary-400);
        background: var(--white);
        &:hover {
            background: var(--white);
        }
    }
    button {
        &.link {
            margin: 0;
        font-size: 10px;
        color: var(--white);
        background: transparent;
    }
`;
const DefaultCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 256px;
    height: 114px;
    > div {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }
    span {
        font-size: 14px;
    }
`;
export default NewWorkspaceCard;
