import { UserDto } from '@Global/dtos/user.dto';

export type RequestDto = {
    id: string;
    type: RequestType;
    user?: UserDto;
    userEmail?: string | null;
    organizationId: string;
    status?: RequestStatus;
    data?: ShareTypeData | object;
    createdAt: string;
    updatedAt: string;
};

export type ShareTypeData = {
    inviteToken: string;
    emailToShareWith: string;
    name?: string;
    workspaceOwner?: Partial<UserDto>;
    workspaceId: string;
};

export enum RequestType {
    share = 'SHARE',
}

export enum RequestStatus {
    pending = 'PENDING',
    rejected = 'REJECTED',
    approved = 'APPROVED',
}
