import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import TextArea from '@Components/input/textArea';
import Sidebar from '@Components/sidebar';
import communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import PictureUpload from '@/components/pictureUpload';
import Communication from '@Communication';
import SelectComponent, { SelectOptionType } from '@/components/select';
import { CategoryDto, CategoryType } from '@/global/dtos/categories.dto';
import ManageSearch from '@Assets/icons/settings/manage-search.svg';
import ThumbnailPlaceholder from '@Assets/icons/files/thumbnail-placeholder.svg';
interface FileSidebarProps {
    data: {
        id: string;
        name?: string;
        description?: string | null;
        thumbnail?: string;
        category: { id: string; name: string } | null;
        type: string;
    };
}

const FileSidebar: FunctionComponent<FileSidebarProps> = ({ data }) => {
    const { addToast, removeAllToasts } = useToasts();

    const [formData, setFormData] = useState({
        name: data.name,
        description: data.description,
        category: data.category
            ? {
                  value: data.category.id,
                  label: data.category.name,
              }
            : null,
        thumbnail: data.thumbnail,
    });

    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [categories, setCategories] = useState<CategoryDto[]>([]);

    const update = (): void => {
        setLoading(true);
        removeAllToasts();
        if (data.type !== 'link') {
            communication
                .updateFile(data.id, {
                    name: formData.name,
                    description: formData.description,
                    categoryId: formData.category ? formData.category.value : null,
                })
                .then(() => {
                    setLoading(false);
                    addToast('Your changes have been successfully saved!', {
                        appearance: 'success',
                    });
                    setHasChanges(false);
                })
                .catch(() => {
                    setLoading(false);
                    addToast('Something went wrong! Please check entered data.', {
                        appearance: 'error',
                    });
                });
        } else {
            communication
                .updateLink(data.id, {
                    name: formData.name,
                    description: formData.description,
                    categoryId: formData.category ? formData.category.value : null,
                })
                .then(() => {
                    setLoading(false);
                    addToast('Your changes have been successfully saved!', {
                        appearance: 'success',
                    });
                    setHasChanges(false);
                })
                .catch(() => {
                    setLoading(false);
                    addToast('Something went wrong! Please check entered data.', {
                        appearance: 'error',
                    });
                });
        }
    };

    const uploadThumbnail = (fileObject: File): void => {
        if (data.type !== 'link') {
            if (fileObject) {
                setLoading(true);
                const form = new FormData();
                form.append('files', fileObject);
                form.append('itemId', data.id);
                form.append('type', 'FILE');
                form.append('global', 'true');
                Communication.uploadThumbnail(form)
                    .then((res: any) => {
                        setLoading(false);
                        setFormData({ ...formData, thumbnail: res?.data });
                    })
                    .catch((error: Error) => {
                        if (error) {
                            setLoading(false);
                        }
                    });
            }
        } else {
            if (fileObject) {
                setLoading(true);
                const form = new FormData();
                form.append('files', fileObject);
                form.append('itemId', data.id);
                form.append('type', 'LINK');
                form.append('global', 'true');
                if (formData.thumbnail) {
                    Communication.updateThumbnail(formData.thumbnail, form)
                        .then((res: any) => {
                            setLoading(false);
                            setFormData({ ...formData, thumbnail: res?.data });
                        })
                        .catch((error: Error) => {
                            if (error) {
                                setLoading(false);
                            }
                        });
                } else {
                    Communication.uploadThumbnail(form)
                        .then((res: any) => {
                            setLoading(false);
                            setFormData({ ...formData, thumbnail: res?.data });
                        })
                        .catch((error: Error) => {
                            if (error) {
                                setLoading(false);
                            }
                        });
                }
            }
        }
        return;
    };

    const removeThumbnail = (): void => {
        if (formData.thumbnail) {
            setLoading(true);
            Communication.removeThumbnail(formData.thumbnail)
                .then(() => {
                    setLoading(false);
                    setFormData({ ...formData, thumbnail: undefined });
                })
                .catch((err: Error) => {
                    setLoading(false);
                    console.error(err);
                });
        }
    };

    useEffect(() => {
        communication.getAllCategories(CategoryType.FILE).then((res: any) => {
            setCategories(res?.data);
        });
    }, []);

    useEffect(() => {
        setFormData({
            name: data.name,
            description: data.description,
            thumbnail: data.thumbnail,
            category: data.category ? { value: data.category?.id, label: data.category?.name } : null,
        });
    }, [data.name, data.description, data.thumbnail, data.category?.id, data.category?.name]);

    useEffect(() => {
        if (
            formData.name !== data.name ||
            formData.description !== data.description ||
            formData.category?.value !== data.category?.id
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [formData.name, formData.description, formData.category?.value]);

    const categoryList = categories.map((c) => ({
        value: c.id,
        label: c.name,
    }));

    return (
        <Sidebar>
            <StyledTitle>
                <img src={ManageSearch} alt="icon" />
                File details
            </StyledTitle>

            <div className="form-wrapper">
                <Input
                    label="File name"
                    placeholder="name"
                    type={InputComponentType.Text}
                    onChange={(value: string) => setFormData({ ...formData, name: value })}
                    value={formData.name}
                    size="100%"
                />
                <TextArea
                    label="Description"
                    value={formData.description || ''}
                    onChange={(value: string) => setFormData({ ...formData, description: value === '' ? null : value })}
                    placeholder="File description"
                ></TextArea>
                <SelectComponent
                    label="Category"
                    placeholder="Select category"
                    onChange={(value) => {
                        setFormData({ ...formData, category: value as SelectOptionType });
                    }}
                    value={formData.category}
                    optionList={categoryList}
                    isClearable={true}
                />
                <UploadLabel>Thumbnail image:</UploadLabel>
                <PictureUpload
                    defaultImage={ThumbnailPlaceholder}
                    src={
                        formData.thumbnail
                            ? `${process.env.REACT_APP_API_URL}/files/thumbnail/${formData.thumbnail}`
                            : null
                    }
                    isClearable={!!formData.thumbnail}
                    onChange={uploadThumbnail}
                    size={200}
                    onRemove={() => removeThumbnail()}
                    tooltipText={
                        'The optimal file size for thumbnail images is 200 KB, and max-width of 800 pixels. Keeping images between these perimeters will ensure they load properly on SyncPage'
                    }
                />
            </div>
            {hasChanges && (
                <TwoButtonsStyle>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.primary}
                        onClick={() => update()}
                        isLoading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.naked}
                        onClick={() =>
                            setFormData({
                                name: data.name,
                                description: data.description,
                                category: data.category
                                    ? {
                                          value: data.category.id,
                                          label: data.category.name,
                                      }
                                    : null,
                                thumbnail: data.thumbnail,
                            })
                        }
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            )}
        </Sidebar>
    );
};

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 18px;
    img {
        margin-right: 12px;
    }
`;
const UploadLabel = styled.p`
    color: var(--black-75);
    font-size: 12px;
    padding: 8px 0px;
`;
const TwoButtonsStyle = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 20px;
    .button {
        width: 100%;
    }
`;
export default FileSidebar;
