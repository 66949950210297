import { AxiosRequestConfig } from 'axios';
import fetch from './fetch';
import Auth from './auth';
import User from './user';
import Workspace from './workspace';
import Files from './files';
import Organization from './organization';
import Account from './account';
import InfoRoom from './inforoom';
import Template from './template';
import Clearbit from '@/communication/clearbit';
import Category from './category';
import Request from './request';
import ActionPlan from './actionPlan';
import Analytics from './analytics';
import Subscription from './subscription';
import Link from './link';

const apiUrl = process.env.REACT_APP_API_URL;

const applyMixins = (derivedCtor: any, constructors: any[]): void => {
    constructors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            Object.defineProperty(
                derivedCtor.prototype,
                name,
                Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null),
            );
        });
    });
};
class Communication {
    public send(options: AxiosRequestConfig): any {
        options.url = `${apiUrl}${options.url}`;
        return fetch(options);
    }
}

/**
 * The new interface is defined with the exact same name as the class Communication.
 * This is crucial because this interface is extending all communication classes.
 * This means the interfaces will merge their method definition into a single construct (the interface),
 * while at the same time merging into the class definition with the same name.
 *
 * Due to declaration merging, the Communication class will be merged with the Communication interface.
 */
interface Communication
    extends Auth,
        User,
        Workspace,
        Files,
        Organization,
        Account,
        InfoRoom,
        Template,
        Clearbit,
        Request,
        Category,
        ActionPlan,
        Analytics,
        Subscription,
        Link {}

applyMixins(Communication, [
    Auth,
    User,
    Workspace,
    Files,
    Organization,
    Account,
    InfoRoom,
    Template,
    Clearbit,
    Request,
    Category,
    ActionPlan,
    Analytics,
    Subscription,
    Link,
]);

export default new Communication();
