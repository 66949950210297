import { create } from 'zustand';

interface OrganizationState {
    id: string;
    name: string;
    email: string | null;
    website: string | null;
    domain: string;
    address: string | null;
    city: string | null;
    country: string | null;
    logo: string | null;
}

const useOrganizationStore = create<OrganizationState>(() => ({
    id: '',
    name: '',
    domain: '',
    website: null,
    email: null,
    address: null,
    city: null,
    country: null,
    logo: null,
}));

export default useOrganizationStore;
