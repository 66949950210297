import { FunctionComponent, Dispatch, SetStateAction, useState } from 'react';
import { ContactDto } from '@Global/dtos/contact.dto';
import useGlobalState from '@Store/global';
import Button, { ButtonTheme } from '@Components/button';
import { TableBody, TableHeader, TableHeaderItem, TableSkeleton, TableSkeletonWrapper } from '@Components/table';
import CircleLetter from '@Components/circleLetter';
import NoItems from '@Components/noItems';
import CompanyIcon from '@Assets/icons/accounts/company.svg';
import LinkedInIcon from '@Assets/icons/social/linkedin-big.svg';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import EditIconInactive from '@Assets/icons/settings/inactive/edit.svg';
import EditIconActive from '@Assets/icons/settings/active/edit.svg';
import { StyledTableItem } from '..';
import { StyledAction, WrapperStyled } from '../../workspaces/table';
interface Props {
    allContacts: ContactDto[] | null;
    getAddMoreOrEmpty: Function;
    setContactModal?: Dispatch<SetStateAction<boolean>>;
    selectedRow?: string | number;
    openContact: Function;
    searchValue: string;
    deleteContact: Function;
}
const AllContacts: FunctionComponent<Props> = ({
    allContacts,
    setContactModal,
    getAddMoreOrEmpty,
    selectedRow,
    openContact,
    searchValue,
    deleteContact,
}) => {
    const { activeActionElement } = useGlobalState();

    const [hoveredBtn, setHoveredBtn] = useState('');

    return (
        <>
            {allContacts ? (
                allContacts?.filter(
                    (contact) =>
                        contact.firstName?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        contact.lastName?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        contact.email?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                        contact.linkedin?.toLowerCase().includes(searchValue.toLowerCase() ?? ''),
                )?.length !== 0 ? (
                    <>
                        <TableHeader columns={6}>
                            <TableHeaderItem>Contact</TableHeaderItem>
                            <TableHeaderItem>Email</TableHeaderItem>
                            <TableHeaderItem>Linkedin</TableHeaderItem>
                            <TableHeaderItem>Companies</TableHeaderItem>
                            <TableHeaderItem>Workspaces</TableHeaderItem>
                            <TableHeaderItem>Actions</TableHeaderItem>
                        </TableHeader>
                        <TableBody>
                            {allContacts
                                ?.filter(
                                    (contact) =>
                                        contact.firstName?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        contact.lastName?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        contact.email?.toLowerCase().includes(searchValue.toLowerCase() ?? '') ||
                                        contact.linkedin?.toLowerCase().includes(searchValue.toLowerCase() ?? ''),
                                )
                                .map((contact, id: number) => {
                                    return (
                                        <StyledTableItem
                                            columns={6}
                                            key={id}
                                            className={`accounts table-item ${
                                                selectedRow === id ? 'selected-item' : ''
                                            }`.trim()}
                                            onClick={() => openContact(contact.id, id)}
                                        >
                                            <p>
                                                {contact.profilePicture ? (
                                                    <img
                                                        key={contact.profilePicture}
                                                        className="profile-img"
                                                        crossOrigin="anonymous"
                                                        src={`${process.env.REACT_APP_API_URL}/accounts/contact/picture/${contact.id}`}
                                                        alt="profile"
                                                    />
                                                ) : contact?.firstName && contact?.lastName ? (
                                                    <CircleLetter
                                                        bgColor="var(--primary-500)"
                                                        letter={`${contact.firstName[0].toUpperCase()}${contact.lastName[0].toUpperCase()}`}
                                                        withTooltip
                                                        tooltipText={contact.firstName + ' ' + contact.lastName}
                                                    />
                                                ) : contact?.firstName ? (
                                                    <CircleLetter
                                                        bgColor="var(--primary-500)"
                                                        letter={contact?.firstName[0].toUpperCase()}
                                                        withTooltip
                                                        tooltipText={contact.firstName}
                                                    />
                                                ) : null}
                                                {contact?.firstName}

                                                {contact.lastName ? (
                                                    <>
                                                        <br />
                                                        {contact.lastName}
                                                    </>
                                                ) : null}
                                            </p>
                                            <p>{contact.email}</p>
                                            <p>
                                                {contact.linkedin ? (
                                                    <a
                                                        href={contact.linkedin}
                                                        style={{ display: 'flex' }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            style={{ maxWidth: '66px' }}
                                                            src={LinkedInIcon}
                                                            alt="link"
                                                        />
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </p>
                                            <p className="associated-account">
                                                {contact?.CompanyContacts[0]?.company?.name ? (
                                                    contact?.CompanyContacts[0]?.company?.profilePicture ||
                                                    contact?.CompanyContacts[0]?.company.logoUrl ? (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={
                                                                contact?.CompanyContacts[0]?.company?.profilePicture
                                                                    ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${contact?.CompanyContacts[0]?.company?.id}`
                                                                    : contact?.CompanyContacts[0]?.company.logoUrl
                                                            }
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={CompanyIcon}
                                                            alt="company icon"
                                                        />
                                                    )
                                                ) : (
                                                    '-'
                                                )}
                                                <span>
                                                    {getAddMoreOrEmpty(
                                                        contact?.CompanyContacts,
                                                        contact?.CompanyContacts[0]?.company?.name,
                                                    )}
                                                </span>
                                            </p>
                                            <p className="associated-workspaces">
                                                {contact?.Workspaces[0]?.name
                                                    ? getAddMoreOrEmpty(
                                                          contact?.Workspaces,
                                                          contact?.Workspaces[0]?.name,
                                                      )
                                                    : '-'}
                                            </p>
                                            <p className="actions">
                                                <StyledAction
                                                    className={`block-actions ${
                                                        activeActionElement === contact.id ? 'active' : ''
                                                    }`}
                                                >
                                                    <img
                                                        id={`actions-${contact.id}`}
                                                        className={`block-actions-expander ${
                                                            activeActionElement !== contact.id ? 'hidden' : ''
                                                        }`}
                                                        src={MoreDotsIcon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === contact.id
                                                                        ? null
                                                                        : contact.id,
                                                            });
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <WrapperStyled
                                                        className={`block-actions-body ${
                                                            activeActionElement !== contact.id ? 'hidden' : ''
                                                        }`}
                                                    >
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'delete'
                                                                    ? DeleteIconActive
                                                                    : DeleteIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('delete')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteContact(contact.id);
                                                            }}
                                                        >
                                                            Delete Contact
                                                        </Button>
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'edit'
                                                                    ? EditIconActive
                                                                    : EditIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('edit')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openContact(contact.id, id);
                                                            }}
                                                        >
                                                            Edit Contact
                                                        </Button>
                                                    </WrapperStyled>
                                                </StyledAction>
                                            </p>
                                        </StyledTableItem>
                                    );
                                })}
                        </TableBody>
                    </>
                ) : (
                    <NoItems
                        content={
                            <>
                                <p>No contacts yet.</p>
                                <div>
                                    <p>Start with</p>
                                    <Button
                                        theme={ButtonTheme.link}
                                        color="var(--primary-400)"
                                        onClick={() => {
                                            setContactModal && setContactModal(true);
                                        }}
                                    >
                                        creating a new one.
                                    </Button>
                                </div>
                            </>
                        }
                    />
                )
            ) : (
                <>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                </>
            )}
        </>
    );
};
export default AllContacts;
