import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Communication from '@Communication';
import Section from '@/sharedView/section';
import { SectionType } from '@/types/workspace';
import Workspace from '@/sharedView/workspace';
import styled from 'styled-components';

const TemplatePreview: FunctionComponent = () => {
    const { templateId } = useParams();
    const [sectionItems, setSectionItems] = useState<SectionType[] | null>(null);
    const [templateTitle, setTemplateTitle] = useState('');

    useEffect(() => {
        if (templateId && templateId !== 'null') {
            Communication.getTemplate(templateId).then((res: any) => {
                setSectionItems(res.data.content.sections);
                setTemplateTitle(res.data.content.title);
            });
        }
    }, [templateId]);

    return (
        <>
            <div className="body public">
                <div className="shared-view-container">
                    <StyledContent>
                        <StyledBanner>
                            <h3>This is a template preview</h3>
                        </StyledBanner>
                        <Workspace workspaceName={templateTitle}>
                            {sectionItems?.map((section) => {
                                return (
                                    <Section
                                        id={`section-${section.id}`}
                                        key={section.id}
                                        sectionName={section.options.title}
                                        visible={section.options.visible}
                                        sectionItems={section.content.items}
                                        setMiddleSection={() => void 0}
                                        openOverlay={() => void 0}
                                        openNewTab={() => void 0}
                                        setOverlayData={() => void 0}
                                        workspaceId={null}
                                    />
                                );
                            })}
                        </Workspace>
                    </StyledContent>
                </div>
            </div>
        </>
    );
};

const StyledContent = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 913px;
    width: 100%;
    margin-top: 80px;
    flex-direction: column;
`;

const StyledBanner = styled.div`
    padding: 15px 0;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    display: flex;
    background: white;
    align-items: center;
    justify-content: center;
    background-color: rgba(white, white, white, 0.4);
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 4px);
    z-index: 1;
    h3 {
        font-size: 18px;
    }
`;
export default TemplatePreview;
