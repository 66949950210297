import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import communication from '@Communication';
import useWindowSize from '@Hooks/useResize';
import { CreateWorkspaceDto } from '@Global/dtos/workspace.dto';
import useUserStore from '@Store/user';
import { SectionType } from '@/types/workspace';
import 'react-loading-skeleton/dist/skeleton.css';
import EditorBase from '@Components/editor/base';
import Button, { ButtonTheme } from '@Components/button';
import useEditorStore from '@Store/editor';
import { EditorType } from '@/types/editor';
import TemplateSidebar from '@/pages/templates/sidebar';
import { TemplateDto } from '@Global/dtos/template.dto';
import { EditorMobile } from '../../workspaces/editor';

const TemplateEditor: FunctionComponent = () => {
    const { templateId } = useParams();
    const { setActiveElement, setEditorData } = useEditorStore();
    const size = useWindowSize();
    const navigate = useNavigate();
    const { id: ownerId } = useUserStore();
    const { addToast } = useToasts();

    const [updatedAt, setUpdatedAt] = useState(null);
    const [templateData, setTemplateData] = useState<TemplateDto | null>(null);
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 900);

    useEffect(() => {
        setIsTabletWidth(!!size.width && size.width < 900);
    }, [size.width]);

    const updateTemplate = async (data: { title: string; sections: SectionType[] }): Promise<void> => {
        const res = await communication.updateTemplateData(templateId as string, data);
        setUpdatedAt(res.data.updatedAt);
    };

    // FIRST EFFECT WHEN PAGE LOADS
    useEffect(() => {
        if (templateId) {
            communication.getTemplate(templateId).then((res: any) => {
                setActiveElement(null);
                setEditorData(templateId, res.data.content?.title, res.data.content?.sections, EditorType.TEMPLATE);
                setUpdatedAt(res.data.updatedAt);
                setTemplateData(res.data as TemplateDto);
            });
        }
    }, []);

    const createWorkspaceFromTemplate = async (tId = ''): Promise<void> => {
        try {
            const dataToSend: CreateWorkspaceDto = {
                name: 'Untitled workspace',
                owner: ownerId as string,
            };
            if (templateId) {
                dataToSend.templateId = tId;
            }
            const response = await communication.createWorkspace(dataToSend);
            navigate(`/workspaces/${response.data.id}`);
        } catch (e) {
            addToast('Unable to create workspace from template. Please try again.', {
                appearance: 'error',
            });
        }
    };

    return (
        <>
            {templateData && <TemplateSidebar data={templateData} className="editor-sidebar" />}
            {/* BASE */}
            {!isTabletWidth ? (
                <StyledEditorBase
                    className="template-editor"
                    updatedAt={updatedAt}
                    update={updateTemplate}
                    backNavigation="/templates"
                />
            ) : (
                <EditorMobile className="editor-mobile">
                    <p>Your browser is too small. Resize it to be at least 900px wide to edit content.</p>
                    <Button
                        theme={ButtonTheme.primary}
                        onClick={() => {
                            createWorkspaceFromTemplate(templateId);
                        }}
                    >
                        Use this template
                    </Button>
                </EditorMobile>
            )}
        </>
    );
};

const StyledEditorBase = styled(EditorBase)`
    height: calc(100vh - 68px);
`;

export default TemplateEditor;
