import { ChangeRoleDto, DirectorRegisterDto, InviteUserDto, UserRegisterDto } from '@Global/dtos/user.dto';
import fetch from './fetch';
import routes from './routes';

export default class User {
    public getLoggedUser(): any {
        return fetch({
            method: 'get',
            url: routes.user.me,
        });
    }

    public updateSelf(options: { firstName?: string; lastName?: string; properties?: object }): any {
        return fetch({
            method: 'patch',
            url: routes.user.me,
            data: options,
        });
    }

    public uploadProfilePicture(formData: FormData): any {
        return fetch({
            method: 'patch',
            url: routes.user.picture,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }

    public removeProfilePicture(): any {
        return fetch({
            method: 'delete',
            url: routes.user.picture,
        });
    }

    public registerDirector(body: DirectorRegisterDto): any {
        return fetch(
            {
                method: 'post',
                url: routes.user.root,
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                data: body,
            },
            false,
        );
    }

    public registerUser(body: UserRegisterDto, token: string): any {
        return fetch(
            {
                method: 'post',
                url: routes.user.register(token),
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                data: body,
            },
            false,
        );
    }

    public confirmUser(token: string): any {
        return fetch(
            {
                method: 'get',
                url: routes.user.confirm(token),
            },
            false,
        );
    }

    public getAllUsers(invitations = false): any {
        return fetch({
            method: 'get',
            url: routes.user.company,
            params: {
                invitations,
            },
        });
    }

    public inviteUser(body: InviteUserDto): any {
        return fetch({
            method: 'post',
            url: routes.user.company,
            data: body,
        });
    }

    public changeUserActivity(id: string, data: { status: boolean }): any {
        return fetch({
            method: 'patch',
            url: routes.user.activity(id),
            data: data,
        });
    }

    public changeUserRole(body: ChangeRoleDto, id: string): any {
        return fetch({
            method: 'post',
            url: routes.user.changeRole(id),
            data: body,
        });
    }

    public deleteUser(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.user.delete(id),
        });
    }
    public deleteInvitation(email: string): any {
        return fetch({
            method: 'delete',
            url: routes.user.invitation(email),
        });
    }
}
