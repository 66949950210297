import fetch from './fetch';
import routes from './routes';

export default class Request {
    public getAllRequests(): any {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            url: routes.requests.all,
        });
    }
    public getAllRequestsByStatus(status: string): any {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            url: routes.requests.allByStatus(status),
        });
    }

    public approveRequest(requestId: string): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url: routes.requests.approve(requestId),
        });
    }

    public rejectRequest(requestId: string): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url: routes.requests.reject(requestId),
        });
    }

    public createRequest(data: { type: string; data: any; sharedViewToken?: string; authToken?: string }): any {
        return fetch(
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                url: routes.requests.root,
                data,
            },
            false,
        );
    }
}
