import styled from 'styled-components';
import { FunctionComponent, useState, useEffect } from 'react';
import useWindowSize from '@Hooks/useResize';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';
import { UserDto } from '@Global/dtos/user.dto';
import Button, { ButtonTheme } from '@Components/button';
import UsersTable from './table';
import InviteUserForm from './inviteUserForm';

interface Props {
    className?: string;
}

const UsersSettings: FunctionComponent<Props> = ({ className }) => {
    const size = useWindowSize();

    const [showInviteUserForm, setInviteUserForm] = useState(false);
    const [usersList, setUsersList] = useState<UserDto[] | null>(null);
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    const showInviteUserFunction = (): void => {
        setInviteUserForm(!showInviteUserForm);
    };

    return (
        <StyledPage className={`page library-content ${className ?? ''}`}>
            <div className="top-bar">
                <h3>Users</h3>
                <Button
                    theme={ButtonTheme.primary}
                    size={isMobileWidth ? 80 : 200}
                    rightIcon={AddIcon}
                    onClick={() => showInviteUserFunction()}
                >
                    {isMobileWidth ? 'Invite' : 'Invite user'}
                </Button>

                {showInviteUserForm && (
                    <InviteUserForm
                        visibility={showInviteUserForm}
                        formManipulation={showInviteUserFunction}
                        setUsersList={setUsersList}
                    />
                )}
            </div>
            <UsersTable setInviteUserForm={setInviteUserForm} usersList={usersList} setUsersList={setUsersList} />
        </StyledPage>
    );
};

const StyledPage = styled.div`
    .user-role-modal {
        .modal-wrapper {
            overflow: unset;
        }
    }
`;

export default UsersSettings;
