import React, { useEffect, useState } from 'react';
import communication from '@Communication';
import { ConnectedWorkspace } from '@/types/workspace';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import Button, { ButtonTheme } from '@Components/button';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';
import { AssociatedWorkspaceDto } from '@Global/dtos/workspace.dto';
import { AccountSidebarTabs, CompanySidebarSections, ContactSidebarSections } from '../sidebar';
import workspacePicture from '@Assets/icons/accountsSidebar/workspace.svg';
import ListCard from '../../../../components/listCard';
import SelectComponent from '../../../../components/select';
import AddIcon from '@Assets/icons/workspaces/add-circle.svg';
import WorkspaceIcon from '@Assets/icons/workspaces/no-workspace.svg';
import { StyledTitle, StyledHeader, TwoButtonsStyle } from '../../../workspaces/editor/sidebar/styledComponents';
import AccountsImageName from '../accountsImageName';
interface Props {
    activeTab: number;
    changeSection: Function;
    updateAssociatedContactWorkspaces: Function;
    updateAssociatedCompanyWorkspaces: Function;
    loading: boolean;
}

const AssociatedWorkspaces: React.FunctionComponent<Props> = ({
    activeTab,
    changeSection,
    updateAssociatedContactWorkspaces,
    updateAssociatedCompanyWorkspaces,
    loading,
}) => {
    const [allWorkspaces, setWorkspaces] = useState<Array<AssociatedWorkspaceDto>>([]);
    const { id: companyId, Workspaces: companyWs } = useCompanyStore();
    const { id: contactId, Workspaces: contactWs } = useContactStore();

    const [wsIdsAdd, setIdsAdd] = useState<Array<string>>([]);
    const [wsIdsRemove, setIdsRemove] = useState<Array<string>>([]);

    const [associatedWorkspaces, setAssociatedWorkspaces] = useState<AssociatedWorkspaceDto[]>([]);

    const [hasChangesAccount, setHasChangesAccount] = useState(false);

    const [selectedWorkspace, setSelectedWorkspace] = useState<AssociatedWorkspaceDto | null>(null);

    useEffect(() => {
        if (wsIdsAdd.length > 0 || wsIdsRemove.length > 0) {
            setHasChangesAccount(true);
        } else {
            setHasChangesAccount(false);
        }
    }, [wsIdsAdd, wsIdsRemove]);

    useEffect(() => {
        setIdsAdd([]);
        setIdsRemove([]);
    }, [activeTab]);

    const addAssociatedWorkspaces = (workspace: AssociatedWorkspaceDto): void => {
        setAssociatedWorkspaces((current) => [...current, workspace as AssociatedWorkspaceDto]);
        const filteredWorkspacesOptionList = allWorkspaces.filter((item) => item.id !== workspace.id);
        const filteredWorkspacesOptionListMap = filteredWorkspacesOptionList.map((item: AssociatedWorkspaceDto) => {
            return {
                ...item,
                value: `${item.name}`,
                label: `${item.name}`,
            };
        });
        setWorkspaces(filteredWorkspacesOptionListMap);

        if (activeTab === (AccountSidebarTabs.COMPANY_SIDEBAR as unknown)) {
            const companyWorkspaceIds = companyWs?.map((ws) => ws.id);
            if (!companyWorkspaceIds?.includes(workspace.id)) {
                setIdsAdd((current) => [...current, workspace.id]);
            }
            if (companyWorkspaceIds?.includes(workspace.id)) {
                const filteredIdsRemove = wsIdsRemove.filter((item) => item !== workspace.id);
                setIdsRemove(filteredIdsRemove);
            }
        } else {
            const contactWorkspaceIds = contactWs?.map((ws) => ws.id);
            if (!contactWorkspaceIds?.includes(workspace.id)) {
                setIdsAdd((current) => [...current, workspace.id]);
            }
            if (contactWorkspaceIds?.includes(workspace.id)) {
                const filteredIdsRemove = wsIdsRemove.filter((item) => item !== workspace.id);
                setIdsRemove(filteredIdsRemove);
            }
        }
        setSelectedWorkspace(null);
    };

    const removeAssociatedWorkspaces = (workspace: AssociatedWorkspaceDto): void => {
        workspace.value = workspace.id;
        workspace.label = workspace.name as string;
        const filteredWorkspacesOptionList = associatedWorkspaces.filter((item) => item.id !== workspace.id);
        setAssociatedWorkspaces(filteredWorkspacesOptionList);
        setWorkspaces((current) => [...current, workspace as AssociatedWorkspaceDto]);

        if (activeTab === AccountSidebarTabs.COMPANY_SIDEBAR) {
            const companyWorkspaceIds = companyWs?.map((ws) => ws.id);
            if (companyWorkspaceIds?.includes(workspace.id)) {
                setIdsRemove((current) => [...current, workspace.id]);
            }
            if (!companyWorkspaceIds?.includes(workspace.id)) {
                const filteredIdsAdd = wsIdsAdd.filter((item) => item !== workspace.id);
                setIdsAdd(filteredIdsAdd);
            }
        } else {
            const contactWorkspaceIds = contactWs?.map((ws) => ws.id);
            if (contactWorkspaceIds?.includes(workspace.id)) {
                setIdsRemove((current) => [...current, workspace.id]);
            }
            if (!contactWorkspaceIds?.includes(workspace.id)) {
                const filteredIdsAdd = wsIdsAdd.filter((item) => item !== workspace.id);
                setIdsAdd(filteredIdsAdd);
            }
        }
    };

    const updateWorkspaces = async (): Promise<void> => {
        if (activeTab === AccountSidebarTabs.CONTACT_SIDEBAR) {
            updateAssociatedContactWorkspaces(contactId, {
                contact: true,
                addIds: wsIdsAdd,
                removeIds: wsIdsRemove,
            });
        } else if (activeTab === AccountSidebarTabs.COMPANY_SIDEBAR) {
            updateAssociatedCompanyWorkspaces(companyId, {
                company: true,
                addIds: wsIdsAdd,
                removeIds: wsIdsRemove,
            });
        }
        setIdsRemove([]);
        setIdsAdd([]);
        return;
    };

    useEffect(() => {
        communication.getAllWorkspaces().then((res: any) => {
            setWorkspaces(res.data);
            if (activeTab === AccountSidebarTabs.CONTACT_SIDEBAR) {
                setAssociatedWorkspaces([...(contactWs ?? [])]);
                const wsIds = contactWs?.map((ws: { id: string }) => ws.id);
                const filteredWorkspacesOptionList = res.data?.filter(
                    (item: ConnectedWorkspace) => !wsIds?.includes(item.id),
                );
                const filteredWorkspacesOptionListMap = filteredWorkspacesOptionList.map(
                    (item: AssociatedWorkspaceDto) => {
                        return {
                            id: item.id,
                            name: item.name,
                            value: item.id,
                            label: item.name,
                        };
                    },
                );
                setWorkspaces(filteredWorkspacesOptionListMap);
            } else if (activeTab === AccountSidebarTabs.COMPANY_SIDEBAR) {
                setAssociatedWorkspaces([...(companyWs ?? [])]);
                const wsIds = companyWs?.map((ws: { id: string }) => ws.id);
                const filteredWorkspacesOptionList = res.data?.filter(
                    (item: ConnectedWorkspace) => !wsIds?.includes(item.id),
                );
                const filteredWorkspacesOptionListMap = filteredWorkspacesOptionList.map(
                    (item: AssociatedWorkspaceDto) => {
                        return {
                            id: item.id,
                            name: item.name,
                            value: item.id,
                            label: item.name,
                        };
                    },
                );
                setWorkspaces(filteredWorkspacesOptionListMap);
            }
        });
    }, [contactWs, companyWs, contactId, companyId]);

    return (
        <>
            <StyledHeader onClick={() => changeSection(0)}>
                <img src={arrowLeft} alt="icon"></img>
                {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Contact' : 'Company'} settings
            </StyledHeader>
            <AccountsImageName activeTab={activeTab} />
            <StyledTitle>
                <img src={workspacePicture} className="front" alt="icon"></img>
                Associated workspaces
            </StyledTitle>
            <div className="frame-wrapper">
                <div className={`account-select-wrapper ${selectedWorkspace ? 'selected' : ''}`}>
                    <SelectComponent
                        placeholder="Choose new workspace"
                        optionList={allWorkspaces}
                        value={selectedWorkspace}
                        onChange={(data) => {
                            setSelectedWorkspace(data as unknown as AssociatedWorkspaceDto);
                            addAssociatedWorkspaces(data as unknown as AssociatedWorkspaceDto);
                        }}
                    />
                    <Button
                        theme={ButtonTheme.primary}
                        disabled={!selectedWorkspace}
                        rightIcon={AddIcon}
                        onClick={() => {
                            if (selectedWorkspace) {
                                addAssociatedWorkspaces(selectedWorkspace);
                            }
                        }}
                    ></Button>
                </div>
                <div className="items-list">
                    <p>Associated workspaces:</p>
                    {associatedWorkspaces && associatedWorkspaces.length > 0 ? (
                        associatedWorkspaces
                            ?.filter((workspace) => workspace.name)
                            ?.map((workspace) => {
                                return (
                                    <ListCard
                                        key={workspace.id}
                                        title={workspace.name ?? ''}
                                        subtitle={''}
                                        removeCallback={() => removeAssociatedWorkspaces(workspace)}
                                        placeholderLogo={WorkspaceIcon}
                                    />
                                );
                            })
                    ) : (
                        <div className="share-empty-list">No one at the moment!</div>
                    )}
                </div>
            </div>
            {hasChangesAccount && (
                <TwoButtonsStyle>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.primary}
                        onClick={() => updateWorkspaces()}
                        isLoading={loading}
                        disabled={!hasChangesAccount}
                    >
                        Save
                    </Button>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                                ? changeSection(ContactSidebarSections.CONTACT_MAIN)
                                : changeSection(CompanySidebarSections.COMPANY_MAIN);
                        }}
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            )}
        </>
    );
};
export default AssociatedWorkspaces;
