import { FunctionComponent, useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';
import DeleteIcon from '@Assets/icons/workspaces/delete-circle.svg';
import { TextBlock } from '@/types/workspace';
import Direction from '@Global/direction';
import ArrowDown from '@Assets/icons/workspaces/arrow-down-circle.svg';
import ArrowUp from '@Assets/icons/workspaces/arrow-up-circle.svg';
import useEditorStore from '@Store/editor';
import BlockActions, { BlockActionItem } from '@Components/editor/actionWrapper';
import isHtmlEmpty from '@Components/editor/text/checkEmpty';

interface Props {
    className?: string | '';
    onChange?: Function; // Triggers each time content changes
    onUpdate?: Function; // Triggers when store should be updated
    onDelete?: Function; // Triggers when block is destroyed
    textBlock: TextBlock;
    activeSection: number;
}

const TextBlockElement: FunctionComponent<Props> = ({
    className,
    textBlock,
    activeSection,
    onChange = () => {
        return;
    },
    onUpdate = () => {
        return;
    },
    onDelete = () => {
        return;
    },
}) => {
    const { sections, activeElementId, setActiveElement } = useEditorStore();
    const [value, setValue] = useState(textBlock.content.value || '');
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        activeElementId === textBlock.id ? focus() : loseFocus();
    }, [activeElementId]);

    /**
     * Update  block content.
     * @param text
     */
    const update = (text: string): void => {
        setValue(text);
        const sectionsTmp = [...sections];
        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === textBlock.id) as
            | TextBlock
            | undefined;
        if (block) {
            block.content.value = text;
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onChange(text);
    };

    /**
     * Delete selected block from the section
     */
    const deleteBlock = (): void => {
        const sectionsTmp = [...sections];
        const index = sectionsTmp[activeSection].content.items.findIndex((bl) => bl.id === textBlock.id);
        if (index !== -1) {
            sectionsTmp[activeSection].content.items.splice(index, 1);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onDelete();
    };

    /**
     * Move selected block in given direction
     * @param direction
     */
    const moveBlock = (direction: Direction): void => {
        const sectionsTmp = [...sections];
        const blocks = sectionsTmp[activeSection].content.items;
        const index = blocks.findIndex((bl) => bl.id === textBlock.id);
        if (
            (direction === Direction.down && index !== -1 && index < blocks.length - 1) ||
            (direction === Direction.up && index > 0)
        ) {
            const dString = JSON.stringify(blocks[index]);
            const dString2 = JSON.stringify(blocks[direction === Direction.down ? index + 1 : index - 1]);
            blocks[index] = JSON.parse(dString2);
            blocks[direction === Direction.down ? index + 1 : index - 1] = JSON.parse(dString);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onUpdate();
        onChange();
    };

    const loseFocus = (): void => {
        setIsActive(false);
        onUpdate();
    };

    const focus = (): void => {
        setActiveElement(textBlock.id);
        setIsActive(true);
    };

    const actions: BlockActionItem[] = [
        {
            icon: ArrowUp,
            title: 'Move Up',
            onClick: () => moveBlock(Direction.up),
        },
        {
            icon: ArrowDown,
            title: 'Move Down',
            onClick: () => moveBlock(Direction.down),
        },
        {
            icon: DeleteIcon,
            title: 'Delete',
            separated: true,
            onClick: () => deleteBlock(),
        },
    ];

    return (
        <TextBlockStyled
            id={`text-block-${textBlock.id}`}
            className={`editor-block ${isActive ? 'active' : ''} ${className ?? ''}`}
            onMouseDown={focus}
        >
            {isActive ? (
                <>
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'blockQuote',
                                'link',
                                'indent',
                                'outdent',
                                'numberedList',
                                'bulletedList',
                                '|',
                                'undo',
                                'redo',
                            ],
                            placeholder: 'Your text goes here',
                            link: {
                                defaultProtocol: 'https://',
                            },
                        }}
                        data={textBlock.content.value}
                        onChange={(event, editor) => {
                            if (event) {
                                const data = editor.getData();
                                update(data);
                            }
                        }}
                    />
                </>
            ) : (
                <>
                    <div
                        className="text-content"
                        dangerouslySetInnerHTML={{
                            __html: isHtmlEmpty(value) ? '<p class="dummy-text">Empty text box</p>' : value,
                        }}
                    />
                </>
            )}
            <BlockActions elementId={textBlock.id} items={actions} />
        </TextBlockStyled>
    );
};

const TextBlockStyled = styled.div`
    position: relative;

    .text-content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        border: 1px solid transparent;
        box-sizing: border-box;
        padding: 10px;
        transition: all ease 0.3s;
        min-height: 50px;
        word-wrap: break-word;
        border-radius: 5px;
        cursor: pointer;

        ul,
        ol {
            padding-inline-start: 40px;
        }

        .dummy-text {
            color: var(--black-75);
            height: 30px;
            display: flex;
            align-items: center;
        }

        h1 {
            font-size: 18px;
            font-weight: 600;
            color: var(--black-300);
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            color: var(--black-300);
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--black-300);
        }

        h4 {
            font-size: 16px;
            font-weight: 600;
            color: var(--black-300);
        }
    }
    .ck {
        &.ck-editor {
            .ck-sticky-panel {
                .ck-sticky-panel__content_sticky {
                    margin-top: 103px;
                    border-radius: 5px;
                    box-shadow: none;
                    z-index: 2;
                }
            }
            .ck-toolbar {
                &.ck-toolbar_grouping {
                    border-radius: 5px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border: 1px solid var(--black-50);
                }
                .ck-toolbar__separator {
                    background: var(--black-20);
                }
                .ck-button {
                    &:active,
                    &:focus {
                        border: 1px solid var(--black-20);
                        box-shadow: none;
                    }
                    &.ck-on {
                        background: var(--black-20);
                        box-shadow: none;
                        svg {
                            path {
                                fill: var(--primary-400);
                            }
                        }
                        &:focus {
                            box-shadow: none;
                            border: 1px solid var(--black-20);
                        }
                    }
                    .ck-button__label {
                        color: var(--black-60);
                    }
                }
            }
            .ck-content {
                &.ck-editor__editable {
                    border-radius: 5px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border: 1px solid var(--black-50);
                    border-top: none;
                    &.ck-focused {
                        box-shadow: none;
                    }
                }

                ul,
                ol {
                    padding-inline-start: 40px;
                }
            }
        }
    }

    .block-actions {
        opacity: 0;
        pointer-events: none;
        z-index: 2;
    }

    &.active {
        .block-actions {
            opacity: 1;
            pointer-events: all;
        }
    }

    &:hover {
        .block-actions {
            opacity: 1;
            pointer-events: all;
        }
    }
`;

export default TextBlockElement;
