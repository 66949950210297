import fetch from './fetch';
import routes from './routes';

export default class Analytics {
    public getWorkspacesStatistic(): any {
        return fetch({
            method: 'get',
            url: routes.analytics.workspacesStatistic,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getActivity(): any {
        return fetch({
            method: 'get',
            url: routes.analytics.activity,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getActivityByWSId(id: string): any {
        return fetch({
            method: 'get',
            url: routes.analytics.activityByWSId(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getViewsByWSId(id: string): any {
        return fetch({
            method: 'get',
            url: routes.analytics.viewsByWSId(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getStatisticByWSId(id: string): any {
        return fetch({
            method: 'get',
            url: routes.analytics.statisticByWSId(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
}
