import { SectionType, SidePanelContentType } from '@/types/workspace';
import { ContactDto } from './contact.dto';
import { CompanyDto } from './company.dto';
import { UserDto } from './user.dto';
import { CategoryDto } from './categories.dto';

export type WorkspaceContentDto = {
    title: string;
    sections: SectionType[];
    sidePanelContent?: SidePanelContentType;
};

export type WorkspaceUpdateDto = {
    name?: string;
    contactId?: string | null;
    companyId?: string | null;
    categoryId?: string | null;
    owner?: string;
    infoRoom?: string | null;
};

export type WorkspaceDto = {
    id: string;
    name: string;
    contactId?: string;
    companyId?: string;
    categoryId?: string;
    owner: string;
    infoRoom: string;
    content: object;
    contact?: ContactDto;
    company?: CompanyDto;
    category?: CategoryDto;
    user: UserDto;
    publishedContent: object;
    createdAt: Date;
    updatedAt: Date;
    views: number;
    clicks: number;
    status: PayloadWorkspaceDto;
    archived?: boolean;
};

export type DeleteWorkspaceFilesDto = {
    workspaceId: string;
    sectionId: string;
    shelfId?: string;
};

export type CreateWorkspaceDto = {
    name: string;
    contactId?: string;
    companyId?: string;
    categoryId?: string;
    owner: string;
    templateId?: string;
};

export type AssociatedWorkspaceDto = {
    id: string;
    name?: string;
    label: string;
    value: string;
};

export type AssociatedWorkspacesPayloadDto = {
    contact?: boolean;
    company?: boolean;
    addIds: string[];
    removeIds: string[];
};

export type SharedWorkspaceResponseDto = {
    id: string;
    payload: PayloadWorkspaceDto;
    link: string;
};

export type UpdateSharedWorkspaceInfo = {
    users?: PayloadUserDto[];
    restricted: boolean;
    notify: boolean;
};

export type PayloadWorkspaceDto = {
    workspaceId: string;
    users?: PayloadUserDto[];
    restricted?: boolean;
    notify?: boolean;
};

export type PayloadUserDto = {
    name?: string;
    email: string;
    autoSend: boolean;
};

export type ShareUser = {
    label: string;
    value: string;
    email: string;
    name?: string;
    autoSend?: boolean;
};

export enum ShelfCommand {
    OPEN_LIBRARY = 'open.library',
    OPEN_LINK = 'open.link',
    OPEN_UPLOAD = 'open.upload',
    OPEN_DRIVE = 'open.drive',
    OPEN_EMBED = 'open.embed',
}

export enum MediaCommand {
    OPEN_MEDIA_MODAL = 'open.media_modal',
    OPEN_UPLOAD = 'open.upload',
}
