import { FunctionComponent, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import '@Global/index';
import Logo from '@Assets/icons/logo.svg';
import Check from '@Assets/icons/tick-circle.svg';
import PlatformIcon from '@Assets/icons/platform.svg';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '../../components/button';
import AuthStyle from '@Components/style';
import Communication from '@Communication';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { slugify } from '@Helpers/slugify';
import { returnToLogin } from '@Helpers/auth';
import { UserStatus } from '@Global/dtos/user.dto';
import { isValidUrl } from '@Helpers/regex';
import { CommonStyledPopup } from '@Components/popup/styledPopup';
import { fixUrl } from '@Helpers/url';

const RegisterOrganization: FunctionComponent = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [name, setName] = useState('');
    const [domain, setDomain] = useState('');
    const [website, setWebsite] = useState('');
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [loginToken, setLoginToken] = useState('');
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);
    const [popupVisible, setPopupVisible] = useState(false);
    const [websiteError, setWebsiteError] = useState<boolean>(false);

    useEffect(() => {
        setDomain(slugify(name));
    }, [name]);

    useEffect(() => {
        const t = urlParams.get('token') ?? '';
        if (!t) {
            returnToLogin();
        }

        const lt = urlParams.get('login') ?? false;
        if (lt) {
            localStorage.setItem('loginToken', t);
            navigate('/');
        }

        setToken(t);
    }, []);

    const registerOrganization: any = async () => {
        setWebsiteError(true);
        if (website === '' || isValidUrl(website)) {
            setLoading(true);
            await Communication.registerOrganization(
                {
                    name,
                    domain,
                    website: fixUrl(website) as string,
                },
                token,
            )
                .then((res: any) => {
                    setLoading(false);
                    setPopupVisible(true);
                    setLoginToken(res.data.token);
                })
                .catch((error: any) => {
                    if (error) {
                        setLoading(false);
                        addToast('Something went wrong! Please check the entered data.', {
                            appearance: 'error',
                        });
                    }
                });
        }
    };

    return (
        <>
            <CommonStyledPopup
                isVisible={popupVisible}
                closePopup={() => {
                    setPopupVisible(false);
                    navigate(`/?token=${loginToken}&status=${UserStatus.READY}`);
                }}
                timer={4}
            >
                <div className="title">
                    <h3>Your registration is complete! </h3>
                    <img src={Check} alt="Check Icon" />
                </div>
                <div className="description">
                    <img src={PlatformIcon} alt="Platform Icon" />
                    <p>You will be redirected to the platform.</p>
                </div>
            </CommonStyledPopup>

            <AuthStyle>
                <img src={Logo} alt="logo"></img>
                <h3>
                    Complete registration for
                    <span style={{ color: 'var(--primary-400)', fontSize: '22px' }}>SyncPage</span>
                </h3>
                <p>Create your organization</p>

                <StyledNameWrapper>
                    <StyledInput
                        type={InputComponentType.Text}
                        placeholder="Company name"
                        value={name}
                        onChange={(value: string) => setName(value)}
                        size="100%"
                        onEnter={registerOrganization}
                    />
                    {/* <StyledInput
                        type={InputComponentType.Text}
                        placeholder="Domain"
                        value={domain}
                        errorMessage={
                            isValidSlug(domain) || domain === ''
                                ? ''
                                : 'Please provide valid domain (only lowercase letters without whitespace)!'
                        }
                        onChange={(value: string) => {
                            setCustomDomain(!!value);
                            setDomain(value);
                        }}
                        size="100%"
                    /> */}
                </StyledNameWrapper>
                <StyledInput
                    type={InputComponentType.Url}
                    placeholder="Website"
                    value={website}
                    errorMessage={
                        websiteError
                            ? isValidUrl(website) || website === ''
                                ? ''
                                : 'Please provide valid website!'
                            : ''
                    }
                    onChange={(value: string) => setWebsite(value)}
                    size="100%"
                    onEnter={registerOrganization}
                />

                <StyledButton
                    theme={ButtonTheme.primary}
                    size={200}
                    disabled={name === '' || domain === ''}
                    onClick={registerOrganization}
                    isLoading={loading}
                >
                    Register
                </StyledButton>
            </AuthStyle>
        </>
    );
};

const StyledButton = styled(Button)`
    margin-top: 20px;
`;

const StyledNameWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const StyledInput = styled(Input)`
    &:nth-child(n + 3) {
        margin: 10px 0;
    }
`;

export default RegisterOrganization;
