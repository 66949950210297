import { create } from 'zustand';
import { ContactDto } from '@Global/dtos/contact.dto';
import { AssociatedCompanyCompanyDto } from '@Global/dtos/company.dto';
import { AssociatedWorkspaceDto } from '@Global/dtos/workspace.dto';

interface Contact {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    linkedin?: string;
    profilePicture?: string;
    Workspaces?: AssociatedWorkspaceDto[];
    CompanyContacts?: AssociatedCompanyCompanyDto[];

    getContactData: () => {
        id: string;
        email: string;
        firstName: string | undefined;
        lastName: string | undefined;
        linkedin: string | undefined;
        profilePicture?: string;
        Workspaces?: AssociatedWorkspaceDto[];
        CompanyContacts?: AssociatedCompanyCompanyDto[];
    };
    setContactData: (contact: {
        id?: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        linkedin?: string;
        profilePicture?: string;
        Workspaces?: AssociatedWorkspaceDto[];
        CompanyContacts?: AssociatedCompanyCompanyDto[];
    }) => void;
    reset: () => void;
    allContacts: ContactDto[];
}

const initialState = {
    id: '',
    email: '',
    firstName: undefined,
    lastName: undefined,
    linkedin: undefined,
    profilePicture: '',
    Workspaces: [],
    CompanyContacts: [],
};

const useContactStore = create<Contact>((set, get) => ({
    ...initialState,
    allContacts: [],
    getContactData: () => ({
        id: get().id,
        email: get().email,
        firstName: get().firstName,
        lastName: get().lastName,
        linkedin: get().linkedin,
        profilePicture: get().profilePicture,
        Workspaces: get().Workspaces,
        CompanyContacts: get().CompanyContacts,
    }),
    setContactData: (contact: {
        id?: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        linkedin?: string;
        profilePicture?: string;
        Workspaces?: AssociatedWorkspaceDto[];
        CompanyContacts?: AssociatedCompanyCompanyDto[];
    }) => set(contact),
    reset: () => {
        set(initialState);
    },
}));

export default useContactStore;
