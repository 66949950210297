import { NavbarData } from '@/types/navbar';
import RoleCodes from '@Global/roles';
import WorkspacesActive from '@Assets/icons/navbar/active/workspace.svg';
import WorkspacesInactive from '@Assets/icons/navbar/inactive/workspace.svg';
import AccountsActive from '@Assets/icons/navbar/active/user.svg';
import AccountsInactive from '@Assets/icons/navbar/inactive/user.svg';
import LibraryActive from '@Assets/icons/navbar/active/folder.svg';
import LibraryInactive from '@Assets/icons/navbar/inactive/folder.svg';
import InfoRoomsActive from '@Assets/icons/navbar/active/info.svg';
import InfoRoomsInactive from '@Assets/icons/navbar/inactive/info.svg';
import TemplatesActive from '@Assets/icons/navbar/active/template.svg';
import TemplatesInactive from '@Assets/icons/navbar/inactive/template.svg';
import DeleteAccountActive from '@Assets/icons/navbar/active/remove.svg';
import DeleteAccountInactive from '@Assets/icons/navbar/inactive/remove.svg';
import InsightsActive from '@Assets/icons/navbar/active/analytics.svg';
import InsightsInactive from '@Assets/icons/navbar/inactive/analytics.svg';
import SettingsActive from '@Assets/icons/navbar/active/settings.svg';
import SettingsInactive from '@Assets/icons/navbar/inactive/settings.svg';
import RequestsActive from '@Assets/icons/navbar/active/request.svg';
import RequestsInactive from '@Assets/icons/navbar/inactive/request.svg';
import ProfileActive from '@Assets/icons/navbar/active/user.svg';
import ProfileInactive from '@Assets/icons/navbar/inactive/user.svg';
import OrganizationActive from '@Assets/icons/settingsNavbar/active/organization.svg';
import OrganizationInactive from '@Assets/icons/settingsNavbar/inactive/organization.svg';
import UsersActive from '@Assets/icons/settingsNavbar/active/users.svg';
import UsersInactive from '@Assets/icons/settingsNavbar/inactive/users.svg';
import FilesActive from '@Assets/icons/settingsNavbar/active/files.svg';
import FilesInactive from '@Assets/icons/settingsNavbar/inactive/files.svg';
import SubscriptionPlansActive from '@Assets/icons/settingsNavbar/active/subscription-plans.svg';
import SubscriptionPlansInactive from '@Assets/icons/settingsNavbar/inactive/subscription-plans.svg';

export const mainNavbarData: NavbarData = [
    {
        groupName: 'Work',
        links: [
            {
                url: '/workspaces',
                activeIcon: WorkspacesActive,
                inactiveIcon: WorkspacesInactive,
                default: true,
                title: 'Workspaces',
            },
            {
                url: '/accounts',
                activeIcon: AccountsActive,
                inactiveIcon: AccountsInactive,
                title: 'Accounts',
            },
            {
                url: '/requests',
                activeIcon: RequestsActive,
                inactiveIcon: RequestsInactive,
                title: 'Requests',
                pendingRequests: true,
            },
        ],
    },
    {
        groupName: 'Content',
        links: [
            {
                url: '/library',
                activeIcon: LibraryActive,
                inactiveIcon: LibraryInactive,
                title: 'Library',
            },
            {
                url: '/inforooms',
                activeIcon: InfoRoomsActive,
                inactiveIcon: InfoRoomsInactive,
                title: 'Info rooms',
            },
            {
                url: '/templates',
                activeIcon: TemplatesActive,
                inactiveIcon: TemplatesInactive,
                title: 'Templates',
            },
        ],
    },
    {
        groupName: 'Analytics',
        links: [
            {
                url: '/analytics',
                activeIcon: InsightsActive,
                inactiveIcon: InsightsInactive,
                title: 'Insights',
            },
        ],
    },
    {
        groupName: '',
        links: [
            {
                url: '/profile',
                activeIcon: SettingsActive,
                inactiveIcon: SettingsInactive,
                title: 'Settings',
            },
        ],
    },
];

export const settingsNavbarData: NavbarData = [
    {
        groupName: 'Profile settings',
        links: [
            {
                url: '/profile',
                activeIcon: ProfileActive,
                inactiveIcon: ProfileInactive,
                title: 'Profile',
            },
        ],
    },
    {
        groupName: 'Organization settings',
        excludeForRoles: [RoleCodes.USER, RoleCodes.MANAGER],
        links: [
            {
                url: '/organization',
                activeIcon: OrganizationActive,
                inactiveIcon: OrganizationInactive,
                title: 'Company',
            },
            {
                url: '/user-management',
                activeIcon: UsersActive,
                inactiveIcon: UsersInactive,
                title: 'Users',
            },
            {
                url: '/subscription',
                activeIcon: SubscriptionPlansActive,
                inactiveIcon: SubscriptionPlansInactive,
                title: 'Plans',
            },
        ],
    },
    {
        groupName: 'Content Settings',
        excludeForRoles: [RoleCodes.USER],
        links: [
            {
                url: '/templates/settings',
                activeIcon: TemplatesActive,
                inactiveIcon: TemplatesInactive,
                title: 'Templates',
            },
            {
                url: '/workspaces/settings',
                activeIcon: WorkspacesActive,
                inactiveIcon: WorkspacesInactive,
                title: 'Workspaces',
            },
            {
                url: '/files/settings',
                activeIcon: FilesActive,
                inactiveIcon: FilesInactive,
                title: 'Files',
            },
        ],
    },
    {
        groupName: 'Advanced Settings',
        links: [
            {
                url: '/delete-account',
                activeIcon: DeleteAccountActive,
                inactiveIcon: DeleteAccountInactive,
                title: 'Delete account',
            },
        ],
    },
];
