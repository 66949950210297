import { FunctionComponent, useState, ChangeEvent, SetStateAction, Dispatch } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import nanogen from '@Helpers/nanogen';
import ReplaceIcon from '@Assets/icons/workspaces/change-circle.svg';
import UploadWhiteIcon from '@Assets/icons/workspaces/upload-white.svg';
interface Props {
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
    isLoading: boolean;
    label?: string | any;
}

const UploadFile: FunctionComponent<Props> = ({ isLoading, file, setFile, label }) => {
    const { addToast } = useToasts();
    const [fileUploadID] = useState(nanogen());

    const [chooseFileLoading, setChooseFileLoading] = useState(false);

    const getShorterFileName = (): string => {
        if (file) {
            const nameArray = file.name.split('.');
            const ext = `.${nameArray[nameArray.length - 1]}`;
            const fileName = file.name.split(ext)[0];
            if (fileName.length > 24) {
                return `${fileName.substring(0, 24)}...${ext}`;
            }
            return `${fileName}${ext}`;
        }
        return '';
    };

    const inputChange = (e: ChangeEvent<HTMLInputElement> | any): void => {
        setChooseFileLoading(true);
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 50 * 1024 * 1024) {
                addToast('File too large! Please upload a file less than 50 MB.', {
                    appearance: 'error',
                });
                setChooseFileLoading(false);
                return;
            }
            setFile(e.target.files[0]);
            e.target.value = '';
        }
        setChooseFileLoading(false);
    };

    return (
        <StyledUploadFile>
            {label ? <p className="upload-input-label">{label}</p> : <></>}
            <div className="upload-input-wrapper">
                <div className={`selected-file-label ${file ? 'full' : ''}`} title={file?.name}>
                    {file ? `${getShorterFileName()}` : 'No file selected...'}
                </div>
                <label
                    htmlFor={`fu-${fileUploadID}`}
                    className={`${file ? 'selected' : ''} ${isLoading ? 'disabled' : ''} ${
                        chooseFileLoading ? 'loading' : ''
                    }`.trim()}
                >
                    {(chooseFileLoading && (
                        <div className="spinner-wrapper">
                            <div className="spinner"></div>
                        </div>
                    )) ||
                        (file ? (
                            <img src={ReplaceIcon} alt="icon" />
                        ) : (
                            <>
                                Choose a file
                                <img src={UploadWhiteIcon} alt="icon" />
                            </>
                        ))}
                </label>
                <input
                    id={`fu-${fileUploadID}`}
                    accept="video/*,image/*, .pdf"
                    type="file"
                    onChange={inputChange}
                    disabled={isLoading}
                />
            </div>
        </StyledUploadFile>
    );
};
const StyledUploadFile = styled.div`
    width: 100%;
    input[type='file'] {
        opacity: 0;
    }
    .upload-input-label {
        color: var(--black-75);
        font-size: 12px;
        padding: 8px 0px;
    }

    .upload-input-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        margin-bottom: 18px;
        min-height: 50px;
        cursor: pointer;

        label {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            background: linear-gradient(
                246deg,
                var(--primary-300) 0.59%,
                var(--primary-400) 50.69%,
                var(--primary-400) 61.38%,
                var(--primary-300) 96.41%
            );
            background-size: 200% 100%;
            background-position: right;
            transition: background-size 0.5s ease-in-out, transform 0.3s, background-position 0.5s ease-in-out,
                border 0.3s ease-in-out;
            color: white;
            font-size: 14px;
            line-height: 24px;
            padding: 0 16px;
            border-radius: 5px;
            width: 158px;
            height: 36px;
            cursor: pointer;
            transition: ease all 0.3s;
            position: absolute;
            right: 11px;
            top: 7px;
            &:hover {
                background-position: left;
            }
            .spinner-wrapper {
                display: flex;
                justify-content: center;
                pointer-events: none;
                opacity: 0;
                height: 0;
                transition: ease all 0.3s;
                .spinner {
                    border: 2px solid white;
                    border-top: 3px solid rgba(255, 255, 255, 0.2);
                    border-radius: 50%;
                    width: 14px;
                    height: 14px;
                    animation: spin 1s linear infinite;
                }
            }

            &.loading {
                .spinner-wrapper {
                    opacity: 1;
                    height: auto;
                }
            }

            &.disabled {
                opacity: 0.6;
                filter: grayscale(1);
                box-shadow: none !important;
                cursor: not-allowed;
            }

            &.selected {
                background: transparent;
                width: 20px;
            }
        }

        .selected-file-label {
            color: var(--black-75);
            border-radius: 8px;
            text-align: center;
            overflow-wrap: break-word;
            word-break: break-all;
            width: 100%;
            min-height: 50px;
            background-color: white;
            display: flex;
            align-items: center;
            padding: 0 180px 0 10px;
            position: absolute;

            &.full {
                color: var(--primary-400);
            }
        }
    }
`;
export default UploadFile;
