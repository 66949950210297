import { SubscriptionCreateDto } from '@/global/dtos/subscription.dto';
import fetch from './fetch';
import routes from './routes';

export default class Subscription {
    public getSubscription(): any {
        return fetch({
            method: 'get',
            url: routes.subscription.me,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public upgradeSubscription(data: SubscriptionCreateDto): any {
        return fetch({
            method: 'post',
            url: routes.subscription.all,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
}
