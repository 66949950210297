import { FunctionComponent, useEffect, useState } from 'react';
import deepEqual from 'deep-equal';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import colors from '@Global/colors';
import { PayloadUserDto, PayloadWorkspaceDto, SharedWorkspaceResponseDto, ShareUser } from '@Global/dtos/workspace.dto';
import useWorkspaceStore from '@Store/workspace';
import useEditorStore from '@Store/editor';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';
import { EditorType } from '@/types/editor';
import { SectionType } from '@/types/workspace';
import communication from '@Communication';
import GlobalHotkeys from '@Global/keypress';
import useWindowSize from '@Hooks/useResize';
import '@Components/editor/base/main.scss';
import EditorBase from '@Components/editor/base';
import Button, { ButtonTheme } from '@Components/button';
import Modal from '@Components/modal';
import Input, { InputComponentIconPosition, InputComponentType, InputComponentTheme } from '@Components/input';
import SelectComponent, { SelectComponentTheme } from '@Components/select';
import { StyledSharedModal } from '@Components/editor/base/styled';
import ToggleSwitch from '@Components/toggleSwitch';
import ListCard from '@Components/listCard';
import EmailSent from '@Assets/icons/email-sent.svg';
import AddIcon from '@Assets/icons/workspaces/add-circle.svg';
import CopyIcon from '@Assets/icons/workspaces/shareModal/copy.svg';
import OpenInNewIcon from '@Assets/icons/workspaces/shareModal/open-in-new.svg';
import LockInactive from '@Assets/icons/workspaces/shareModal/inactive/lock.svg';
import LockActive from '@Assets/icons/workspaces/shareModal/active/lock.svg';
import GlobeInactive from '@Assets/icons/workspaces/shareModal/inactive/language.svg';
import GlobeActive from '@Assets/icons/workspaces/shareModal/active/language.svg';
import DoneIcon from '@Assets/icons/workspaces/shareModal/done.svg';
import SidebarModalContent from '@/pages/workspaces/editor/sidebar/sidebarModalContent';

const WorkspaceEditor: FunctionComponent = () => {
    const { workspaceId } = useParams();
    const { allContacts } = useContactStore();
    const { allCompanies } = useCompanyStore();
    const { addToast, removeAllToasts } = useToasts();
    const { sidePanelContent, updatePublishStatus, setWorkspaceData } = useWorkspaceStore();
    const { setActiveElement, setEditorData } = useEditorStore();
    const size = useWindowSize();

    const [loading, setLoading] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [shareUrl, setShareUrl] = useState<string | null>(null);
    const [updatedAt, setUpdatedAt] = useState(null);
    const [isRestricted, setIsRestricted] = useState<boolean>(false);
    const [sharedAccounts, setSharedAccounts] = useState<ShareUser[]>([]);
    const [sendEmail, setSendEmail] = useState(false);
    const [notify, setNotify] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState<ShareUser | null>(null);
    const [accountsList, setAccountsList] = useState<ShareUser[]>([]);
    const [rawShareData, setRawDataData] = useState<PayloadWorkspaceDto | null>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 900);

    useEffect(() => {
        setIsTabletWidth(!!size.width && size.width < 900);
    }, [size.width]);

    const updateWorkspace = async (data: { title: string; sections: SectionType[] }): Promise<void> => {
        const res = await communication.updateWorkspaceContent(workspaceId as string, { sidePanelContent, ...data });
        const isPublished = JSON.stringify(res.data.content) === JSON.stringify(res.data.publishedContent);
        updatePublishStatus(isPublished);
        setUpdatedAt(res.data.updatedAt);
    };

    const GoToSharedView = (): void => {
        window.open(`${shareUrl}` as string, '_blank');
    };
    useEffect(() => {
        if (rawShareData) {
            const payloadDataUsers: PayloadUserDto[] = sharedAccounts.map((user) => ({
                email: user?.email,
                autoSend: sendEmail ?? user?.autoSend,
            }));
            const newData: PayloadWorkspaceDto = {
                workspaceId: workspaceId as string,
                users: payloadDataUsers,
                restricted: isRestricted,
                notify: notify,
            };
            if (rawShareData?.users) {
                const tmpUsers: PayloadUserDto[] = rawShareData?.users.map((user) => ({
                    email: user?.email,
                    autoSend: user?.autoSend,
                }));
                const tmpRawShareData = {
                    workspaceId: rawShareData.workspaceId,
                    users: tmpUsers,
                    restricted: rawShareData.restricted,
                    notify: rawShareData.notify,
                };
                setHasChanges(!deepEqual(newData, tmpRawShareData));
            } else {
                setHasChanges(!deepEqual(newData, rawShareData));
            }
        }
    }, [isRestricted, sharedAccounts, notify, sendEmail, rawShareData]);

    // FIRST EFFECT WHEN PAGE LOADS
    useEffect(() => {
        if (workspaceId) {
            communication.getWorkspace(workspaceId).then((res: any) => {
                GlobalHotkeys.add(`workspaces/${workspaceId}/escape`, 'Escape', () => {
                    setShareModal(false);
                });
                setActiveElement(null);
                const isPublished = JSON.stringify(res.data.content) === JSON.stringify(res.data.publishedContent);
                setWorkspaceData(
                    res.data.id,
                    res.data.name,
                    !!res.data.publishedContent,
                    res.data.archived,
                    res.data.company && res.data.company.id
                        ? {
                              id: res.data.company.id,
                              name: res.data.company.name,
                              profilePicture: res.data.company.profilePicture,
                              logoUrl: res.data.company.logoUrl,
                          }
                        : null,

                    res.data.contact && res.data.contact.id
                        ? {
                              id: res.data.contact.id,
                              firstName: res.data.contact.firstName,
                              lastName: res.data.contact.lastName,
                              email: res.data.contact.email,
                              profilePicture: res.data.contact.profilePicture,
                          }
                        : null,
                    res.data.category,
                    {
                        id: res.data.user.id,
                        firstName: res.data.user.firstName,
                        lastName: res.data.user.lastName,
                        email: res.data.user.email,
                        profilePicture: res.data.user.properties.profilePicture,
                    },

                    res.data.InfoRoom
                        ? {
                              name: res.data.InfoRoom.name,
                              id: res.data.InfoRoom.id,
                          }
                        : null,
                    res.data.actionPlan
                        ? {
                              id: res.data.actionPlan.id,
                              name: res.data.actionPlan.name,
                              content: res.data.actionPlan.content,
                          }
                        : null,
                    {
                        pointsOfContact: {
                            contacts: res.data.content.sidePanelContent.pointsOfContact.contacts ?? [],
                        },
                        actionPlan: res.data.content.sidePanelContent?.actionPlan ?? '',
                        welcomeMessage: res.data.content.sidePanelContent?.welcomeMessage ?? '',
                        welcomeVideo: res.data.content.sidePanelContent?.welcomeVideo ?? '',
                    },
                    res.data.createdAt,
                    res.data.updatedAt,
                );
                setEditorData(workspaceId, res.data.content?.title, res.data.content?.sections, EditorType.WORKSPACE);

                updatePublishStatus(isPublished);
                setUpdatedAt(res.data.updatedAt);
            });

            communication.getSharedWorkspaceInfo(workspaceId).then(async (res: any) => {
                const response = res.data as SharedWorkspaceResponseDto;
                setShareUrl(response.link);
                setIsRestricted(!!response.payload.restricted);
                const sharedUsers = response.payload.users || [];
                const userEmails = sharedUsers.map((u: { email: string }) => u.email);
                const filteredContactsArray = allContacts.filter(
                    (item) => item.email && !userEmails?.includes(item.email),
                );
                const filteredCompaniesArray = allCompanies.filter(
                    (item) => item.email && !userEmails?.includes(item.email),
                );

                setSharedAccounts(
                    sharedUsers.map((u) => ({
                        label: `${u.name ?? ''}`,
                        value: `${u.name ?? ''} [${u.email}]`,
                        email: u.email,
                        autoSend: u.autoSend,
                        name: u.name,
                    })),
                );

                setAccountsList(
                    filteredContactsArray
                        .map((c) => ({
                            label: `${c.firstName ?? ''} ${c.lastName ?? ''}`,
                            value: `${c.firstName ?? ''} ${c.lastName ?? ''} [${c.email}]`,
                            name: `${c.firstName ?? ''} ${c.lastName ?? ''}`,
                            email: c.email,
                            autoSend: sendEmail,
                        }))
                        .concat(
                            filteredCompaniesArray.map((c) => ({
                                label: `${c.name ?? ''}`,
                                value: `${c.name ?? ''} ${c.email}`,
                                name: `${c.name ?? ''}`,
                                email: c.email,
                                autoSend: sendEmail,
                            })),
                        ),
                );
                setRawDataData(response.payload);
            });
        }

        // useGlobalState.setState({ isNavbarCollapsed: true });
    }, []);

    const removeSharedPeople = (user: ShareUser): void => {
        const filteredList = sharedAccounts.filter((item) => item?.email !== user?.email);
        setSharedAccounts(filteredList);
        if (user.name) {
            setAccountsList((current) => [...current, user]);
        }
    };

    const addSharedPeople = (user: ShareUser): void => {
        setSharedAccounts((current) => [...current, user as ShareUser]);
        const filteredList = accountsList.filter((item) => item?.email !== user?.email);
        setAccountsList(filteredList);
        setSelectedAccount(null);
    };

    const saveShareOptions = async (): Promise<void> => {
        removeAllToasts();
        setLoading(true);
        const payloadDataUsers: PayloadUserDto[] = sharedAccounts.map((user) => ({
            email: user.email,
            name: user.name ?? undefined,
            autoSend: user.autoSend ? user.autoSend : sendEmail,
        }));
        if (workspaceId) {
            communication
                .updateSharedWorkspaceInfo(workspaceId, {
                    users: isRestricted ? [...payloadDataUsers] : [],
                    restricted: isRestricted,
                    notify,
                })
                .then((res: any) => {
                    setLoading(false);
                    setShareModal(false);
                    addToast('Your changes have been successfully saved!', {
                        appearance: 'success',
                    });
                    setRawDataData(res.data.payload);
                })
                .catch(() => {
                    setLoading(false);
                    addToast('Something went wrong! Please check entered data.', {
                        appearance: 'error',
                    });
                });
        }
    };

    return (
        <>
            <SidebarModalContent className="editor-sidebar" />

            {/* MODAL FOR SHARING THE WORKSPACE */}
            <Modal
                modalVisible={shareModal}
                closeModal={() => setShareModal(!shareModal)}
                size={572}
                title="Share this workspace"
            >
                <CustomStyledSharedModal>
                    <p className="share-description">
                        {!isRestricted
                            ? 'Use sharing options to create a public link or share with specific people.'
                            : 'Manage who can access this workspace.'}
                    </p>
                    <SharingOptions>
                        <p>Sharing options</p>
                        <OptionsWrapper>
                            <div
                                className={`cursor-pointer option ${isRestricted ? '' : 'active'}`.trim()}
                                onClick={() => {
                                    setIsRestricted(false);
                                }}
                            >
                                <div>
                                    <img src={isRestricted ? GlobeInactive : GlobeActive} alt="icon" />
                                    Public access
                                </div>
                                {!isRestricted ? <img src={DoneIcon} alt="icon" /> : <></>}
                            </div>
                            <div
                                className={`cursor-pointer option ${isRestricted ? 'active' : ''}`.trim()}
                                onClick={() => {
                                    setIsRestricted(true);
                                }}
                            >
                                <div>
                                    <img src={!isRestricted ? LockInactive : LockActive} alt="icon" />
                                    Restricted access
                                </div>
                                {isRestricted ? <img src={DoneIcon} alt="icon" /> : <></>}
                            </div>
                        </OptionsWrapper>
                    </SharingOptions>
                    {isRestricted ? (
                        <>
                            <div className={`account-select-wrapper ${selectedAccount ? 'selected' : ''}`}>
                                <SelectComponent
                                    theme={SelectComponentTheme.Light}
                                    placeholder="Email address or name"
                                    label="Type email address or name"
                                    optionList={accountsList}
                                    value={selectedAccount}
                                    onCreate={(value: string) => {
                                        setSelectedAccount({ value, label: value, email: value });
                                        addSharedPeople({ value, label: value, email: value });
                                    }}
                                    onChange={(data) => {
                                        setSelectedAccount(data as ShareUser);
                                        addSharedPeople(data as ShareUser);
                                    }}
                                />
                                <Button
                                    theme={ButtonTheme.primary}
                                    disabled={!selectedAccount}
                                    rightIcon={AddIcon}
                                    onClick={() => {
                                        if (selectedAccount) {
                                            addSharedPeople(selectedAccount);
                                        }
                                    }}
                                ></Button>
                            </div>
                            <div className="users-list">
                                <p>People with access</p>
                                {sharedAccounts.length > 0 ? (
                                    <div className="all-users">
                                        {sharedAccounts
                                            ?.filter((user) => user?.email)
                                            ?.map((user) => {
                                                return (
                                                    <ListCard
                                                        key={user.email}
                                                        title={user.email}
                                                        subtitle={user.name}
                                                        statusImage={user.autoSend ? EmailSent : null}
                                                        removeCallback={() => removeSharedPeople(user)}
                                                    />
                                                );
                                            })}
                                    </div>
                                ) : (
                                    <div className="share-empty-list">No one at the moment!</div>
                                )}
                            </div>
                            <ToggleSwitchBlock>
                                <ToggleSwitch
                                    label="Automatically notify viewers"
                                    checked={sharedAccounts.length === 0 ? false : sendEmail}
                                    onChange={(value: boolean) => setSendEmail(!value)}
                                    isDisabled={sharedAccounts.length === 0}
                                ></ToggleSwitch>
                                <ToggleSwitch
                                    label="Notify me of workspace activity"
                                    checked={sharedAccounts.length === 0 ? false : notify}
                                    onChange={(value: boolean) => setNotify(!value)}
                                    isDisabled={sharedAccounts.length === 0}
                                ></ToggleSwitch>
                            </ToggleSwitchBlock>
                        </>
                    ) : null}
                    <div className="modal-content">
                        {shareUrl && (
                            <>
                                <div className="link-wrapper">
                                    <Input
                                        type={InputComponentType.Text}
                                        theme={InputComponentTheme.Light}
                                        value={shareUrl}
                                        size="100%"
                                        label="Link for manual share:"
                                        icon={CopyIcon}
                                        iconPosition={InputComponentIconPosition.Right}
                                        onIconPress={() => {
                                            removeAllToasts();
                                            navigator.clipboard.writeText(shareUrl).then();
                                            addToast('Link copied', { appearance: 'success' });
                                        }}
                                    />
                                </div>
                                <div className="button-grid">
                                    <Button
                                        theme={ButtonTheme.primary}
                                        onClick={() => saveShareOptions()}
                                        isLoading={loading}
                                        disabled={!hasChanges}
                                    >
                                        Save settings
                                    </Button>
                                    <Button
                                        theme={ButtonTheme.naked}
                                        rightIcon={OpenInNewIcon}
                                        size={212}
                                        onClick={() => {
                                            window.open(`${shareUrl}`, '_blank');
                                        }}
                                    >
                                        Open Workspace
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </CustomStyledSharedModal>
            </Modal>

            {/* BASE */}
            {!isTabletWidth ? (
                <EditorBaseStyled
                    updatedAt={updatedAt}
                    update={updateWorkspace}
                    setShareModal={setShareModal}
                    backNavigation="/workspaces"
                    shareUrl={shareUrl}
                />
            ) : (
                <EditorMobile className="editor-mobile">
                    <p>
                        Your browser is too small. Resize it to be at least 900px wide to edit content. Check{' '}
                        <Button theme={ButtonTheme.link} color={colors.primary400} onClick={GoToSharedView}>
                            Shared view.
                        </Button>
                    </p>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.primary}
                        onClick={() => {
                            setShareModal(true);
                        }}
                    >
                        Share
                    </Button>
                </EditorMobile>
            )}
        </>
    );
};

const EditorBaseStyled = styled(EditorBase)`
    height: calc(100vh - 68px) !important;
`;

export const EditorMobile = styled.div`
    padding: 24px;
    display: flex;
    align-items: start;
    p {
        margin-right: 27px;
        button {
            padding: 5px 0px;
        }
    }
`;

const CustomStyledSharedModal = styled(StyledSharedModal)`
    .account-select-wrapper {
        display: flex;

        .button {
            height: 40px;
            width: 0;
            opacity: 0;
            padding: 0;
            border: 0;
            transition: all 0.3s ease-in-out;
        }

        .select {
            transition: 0.3s all ease-in-out;
            flex: 1;
        }

        &.selected {
            .button {
                width: 50px;
                opacity: 1;
                border: 2px solid #00726d;
                margin-left: 10px;
            }
        }
    }

    .share-description {
        margin-bottom: 30px;
    }

    .switch-wrapper {
        &:nth-child(5) {
            margin-bottom: 15px;
        }
    }
`;
const SharingOptions = styled.div`
    p {
        color: var(--black-75);
        font-size: 12px;
        padding: 8px 0;
    }
`;
const OptionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    .option {
        background-color: var(--white);
        padding: 15px 18px;
        color: var(--black-75);
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:focus,
        &:hover {
            filter: drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1));
        }
        &.active {
            color: var(--black-60);
        }
        div {
            display: flex;
            align-items: center;
            img {
                margin-right: 12px;
            }
        }
    }
`;
const ToggleSwitchBlock = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    gap: 12px;
    label {
        &.side-label {
            font-size: 14px;
        }
    }
`;
export default WorkspaceEditor;
