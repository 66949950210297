import { FunctionComponent, useEffect, useState } from 'react';
import '@Global/index';
import communication from '@Communication';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import PictureUpload from '@/components/pictureUpload';
import useUserStore from '@Store/user';
import styled from 'styled-components';
import useDialogue from '@Hooks/useDialogue';
import Communication from '@Communication';
import UserIcon from '@Assets/icons/settings/profile/user.svg';
import PersonIcon from '@Assets/icons/settings/profile/person.svg';
import WorkIcon from '@Assets/icons/settings/profile/work.svg';
import InfoIcon from '@Assets/icons/settings/profile/info.svg';
import Tooltip from '@Components/tooltip';
import { isValidUrl } from '@Helpers/regex';
import { useToasts } from 'react-toast-notifications';

const UserProfile: FunctionComponent = () => {
    const { addToast, removeAllToasts } = useToasts();
    const { firstName, lastName, email, properties } = useUserStore();

    const [hasChanges, setHasChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setDialogue, closeDialogue } = useDialogue();

    const [formData, setFormData] = useState({
        tmpFirstName: firstName,
        tmpLastName: lastName,
        tmpEmail: email,
        title: properties?.title,
        phoneContact: properties?.contact?.phone,
        linkedinContact: properties?.contact?.linkedIn,
        chatLinkContact: properties?.contact?.chatLink,
        calendarLinkContact: properties?.contact?.calendarLink,
    });

    useEffect(() => {
        if (
            firstName !== formData.tmpFirstName ||
            lastName !== formData.tmpLastName ||
            email !== formData.tmpEmail ||
            properties?.title !== formData.title ||
            properties?.contact?.phone !== formData.phoneContact ||
            properties?.contact?.linkedIn !== formData.linkedinContact ||
            properties?.contact?.chatLink !== formData.chatLinkContact ||
            properties?.contact?.calendarLink !== formData.calendarLinkContact
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [
        formData.tmpFirstName,
        formData.tmpLastName,
        formData.tmpEmail,
        formData.title,
        formData.phoneContact,
        formData.linkedinContact,
        formData.chatLinkContact,
        formData.calendarLinkContact,
    ]);

    //Update user info
    const updateUser = (): void => {
        removeAllToasts();
        setLoading(true);
        const tmpProperties = properties ?? {};
        tmpProperties.title = formData.title;
        tmpProperties.contact = {
            phone: formData.phoneContact,
            linkedIn: formData.linkedinContact,
            chatLink: formData.chatLinkContact,
            calendarLink: formData.calendarLinkContact,
        };

        communication
            .updateSelf({
                firstName: formData.tmpFirstName,
                lastName: formData.tmpLastName,
                properties: tmpProperties,
            })
            .then((res: any) => {
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                useUserStore.setState(res.data);
                setLoading(false);
                setHasChanges(false);
            })
            .catch((error: any) => {
                if (error) {
                    setLoading(false);
                }
            });
    };

    const uploadPicture = (fileObject: File): void => {
        if (fileObject) {
            setLoading(true);
            const form = new FormData();
            form.append('files', fileObject);
            Communication.uploadProfilePicture(form)
                .then((res: any) => {
                    setLoading(false);
                    useUserStore.setState(res.data);
                })
                .catch((error: Error) => {
                    if (error) {
                        setLoading(false);
                    }
                });
        }
        return;
    };

    const removePicture = (): void => {
        setLoading(true);
        Communication.removeProfilePicture()
            .then((res: any) => {
                setLoading(false);
                useUserStore.setState(res.data);
            })
            .catch((error: Error) => {
                if (error) {
                    setLoading(false);
                }
            });
    };

    const handlePropertiesChange = (
        property: 'title' | 'email' | 'phone' | 'linkedin' | 'whatsApp' | 'chatLink' | 'calendarLink',
        value: string,
    ): void => {
        if (property === 'title') setFormData({ ...formData, title: value });
        // if (property === 'email') setFormData({ ...formData, emailContact: value });;
        if (property === 'phone') setFormData({ ...formData, phoneContact: value });
        if (property === 'linkedin') setFormData({ ...formData, linkedinContact: value });
        if (property === 'chatLink') setFormData({ ...formData, chatLinkContact: value });
        if (property === 'calendarLink') setFormData({ ...formData, calendarLinkContact: value });
    };

    return (
        <Page className="page profile-page">
            <LeftBlock>
                <PictureUpload
                    src={
                        properties?.profilePicture
                            ? `${process.env.REACT_APP_API_URL}/users/me/picture/${properties.profilePicture}`
                            : null
                    }
                    size={100}
                    defaultImage={UserIcon}
                    isClearable={!!properties?.profilePicture}
                    onChange={uploadPicture}
                    onRemove={() => removePicture()}
                    tooltipText={
                        'The optimal file size for profile images is 200 KB, and max-width of 800 pixels. Keeping images between these perimeters will ensure they load properly on SyncPage'
                    }
                />
            </LeftBlock>
            <RightBlock>
                <StyledUserForm>
                    <Title>
                        <p>Profile information</p>
                        <img alt="profile" src={PersonIcon} />
                    </Title>
                    <BlockTwoInputs>
                        <Input
                            type={InputComponentType.Text}
                            value={formData.tmpFirstName}
                            label="First name"
                            onChange={(v: string) => setFormData({ ...formData, tmpFirstName: v })}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.tmpLastName}
                            label="Last name"
                            onChange={(v: string) => setFormData({ ...formData, tmpLastName: v })}
                        />
                    </BlockTwoInputs>
                    <Input
                        type={InputComponentType.Text}
                        value={formData.tmpEmail}
                        label="Email"
                        size={300}
                        isDisabled={true}
                    />
                    <Title>
                        <div className="with-tooltip">
                            <p>Working information</p>
                            <Tooltip
                                tooltipText="Working information (your title and contact details) will be
                             displayed to customers when you are added as a point of contact."
                                width="380px"
                                tooltipPositionBottom
                                fontSize="12px"
                                padding="12px"
                                borderRadius="8px"
                            >
                                <img alt="info" src={InfoIcon} />
                            </Tooltip>
                        </div>
                        <img alt="work" src={WorkIcon} />
                    </Title>
                    <BlockTwoInputs>
                        <Input
                            type={InputComponentType.Text}
                            value={formData.title}
                            label="User working title"
                            onChange={(v: string) => handlePropertiesChange('title', v)}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.phoneContact}
                            label="Phone number"
                            onChange={(v: string) => handlePropertiesChange('phone', v)}
                        />
                    </BlockTwoInputs>
                    <BlockTwoInputs>
                        <Input
                            type={InputComponentType.Text}
                            value={formData.linkedinContact}
                            label="LinkedIn"
                            errorMessage={
                                formData.linkedinContact
                                    ? isValidUrl(formData.linkedinContact)
                                        ? ''
                                        : 'Please provide valid link'
                                    : ''
                            }
                            onChange={(v: string) => handlePropertiesChange('linkedin', v)}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.chatLinkContact}
                            label="Chat link"
                            onChange={(v: string) => handlePropertiesChange('chatLink', v)}
                        />
                    </BlockTwoInputs>

                    <Input
                        type={InputComponentType.Text}
                        value={formData.calendarLinkContact}
                        label="Calendar link"
                        onChange={(v: string) => handlePropertiesChange('calendarLink', v)}
                    />
                </StyledUserForm>
                {hasChanges && (
                    <ActionBlock>
                        <Button
                            theme={ButtonTheme.naked}
                            size={127}
                            onClick={() =>
                                setFormData({
                                    tmpFirstName: firstName,
                                    tmpLastName: lastName,
                                    tmpEmail: email,
                                    title: properties?.title,
                                    phoneContact: properties?.contact?.phone,
                                    linkedinContact: properties?.contact?.linkedIn,
                                    chatLinkContact: properties?.contact?.chatLink,
                                    calendarLinkContact: properties?.contact?.calendarLink,
                                })
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            className="profile-update-button"
                            theme={ButtonTheme.primary}
                            isLoading={loading}
                            size={127}
                            onClick={() => {
                                setDialogue({
                                    title: 'Confirm update',
                                    description: 'Are you sure you want to update user information?',
                                    buttons: [
                                        {
                                            theme: ButtonTheme.primary,
                                            text: 'Update',
                                            callback: () => {
                                                updateUser();
                                                closeDialogue();
                                            },
                                        },
                                        {
                                            theme: ButtonTheme.naked,
                                            text: 'Cancel',
                                            callback: () => {
                                                closeDialogue();
                                            },
                                        },
                                    ],
                                });
                            }}
                        >
                            Save
                        </Button>
                    </ActionBlock>
                )}
            </RightBlock>
        </Page>
    );
};

const Page = styled.div`
    display: flex;
    gap: 20px;
    max-width: 900px;
    /* Media Queries */
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

export const LeftBlock = styled.div`
    width: 133px;
    .profile-image-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const RightBlock = styled.div`
    width: 100%;
    margin-bottom: 60px;
    height: fit-content;
`;

const StyledUserForm = styled.div`
    background-color: white;
    padding: 26px 24px;
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    margin-bottom: 24px;

    .input-container {
        width: 100%;
    }
    .input {
        margin-bottom: 10px;
        width: 100%;
    }

    input[type='file'] {
        display: none;
    }

    .upload-label {
        border: 2px solid #00726d;
        background-color: #00726d;
        color: white;
        font-size: 16px;
        line-height: 24px;
        padding: 9px 16px;
        border-radius: 5px;
        min-width: auto;
        cursor: pointer;
        transition: ease all 0.3s;
        text-align: center;
        margin-top: 30px;
        width: 100%;

        &:hover {
            box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .selected-file {
        margin: 10px 0;
        color: var(--black-75);
        min-height: 20px;
        padding: 6px;
        border-radius: 5px;
        text-align: center;
        overflow-wrap: break-word;
        word-break: break-all;

        &.full {
            color: var(--primary-400);
        }
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--black-20);
    padding-bottom: 26px;
    margin-bottom: 36px;

    &:not(:first-child) {
        margin-top: 68px;
    }
    .with-tooltip {
        display: flex;
        align-items: center;
        .label {
            @media only screen and (max-width: 500px) {
                width: 200px;
            }
            @media only screen and (max-width: 400px) {
                width: 150px;
            }
        }
        span {
            display: flex;
        }
    }
    p {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-right: 11px;
        color: var(--black-60);
    }
`;

export const BlockTwoInputs = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ActionBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;

export default UserProfile;
