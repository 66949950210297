import fetch from '@/communication/fetch';
import routes from '@/communication/routes';
import {
    CreateTemplateDto,
    DeleteTemplateFilesDto,
    TemplateContentDto,
    TemplateUpdateDto,
} from '@Global/dtos/template.dto';

export default class Template {
    public getAllTemplates(): any {
        return fetch({
            method: 'get',
            url: routes.template.all,
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public getTemplate(id: string): any {
        return fetch({
            method: 'get',
            url: routes.template.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public createTemplate(data: CreateTemplateDto): any {
        return fetch({
            method: 'post',
            url: routes.template.all,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public updateTemplate(id: string, data: TemplateUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.template.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public updateTemplateData(id: string, content: TemplateContentDto): any {
        return fetch({
            method: 'patch',
            url: routes.template.single(id),
            headers: { 'Content-Type': 'application/json' },
            data: { content },
        });
    }

    public deleteTemplate(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.template.single(id),
        });
    }

    public deleteTemplateFiles(data: DeleteTemplateFilesDto): any {
        return fetch({
            method: 'delete',
            url: routes.template.files,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
}
