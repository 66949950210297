import styled from 'styled-components';

const AuthStyle = styled.div`
    margin-top: calc((100vh - 650px) / 2);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 12px;
    text-align-last: center;
    padding: 50px;
    max-width: 450px;
    background-color: white;

    & img {
        padding-bottom: 30px;
    }

    & h3 {
        color: black;
        text-align: center;

        span {
            margin-left: 0.5rem;
        }
    }

    & p {
        color: var(--black-75);
        margin: 40px;
    }

    & a {
        color: var(--primary-400);
        text-decoration: none;
    }
`;

export default AuthStyle;
