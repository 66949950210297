import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    children: React.ReactNode;
    workspaceName: string;
}

const Workspace: FunctionComponent<Props> = ({ className, children, workspaceName }) => {
    return (
        <StyledWorkspace className={className} workspaceName={workspaceName}>
            <div className="workspace-container">
                <StyledWorkspaceName
                    dangerouslySetInnerHTML={{
                        __html: workspaceName,
                    }}
                />
                {children}
            </div>
        </StyledWorkspace>
    );
};

const StyledWorkspace = styled.div<Props>`
    border-radius: 8px;
    background: var(--white);
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
    flex: 1;

    .workspace-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;

        > h1 {
            @media only screen and (max-width: 768px) {
                margin-top: 5px;
            }
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            color: var(--black-300);

            > p {
                font-size: 28px;
                line-height: 42px;
            }
        }
        p {
            font-size: 16px;
            line-height: 22.4px;
        }

        @media only screen and (max-width: 768px) {
            padding: 20px;
        }
    }
`;

const StyledWorkspaceName = styled.h1`
    margin-bottom: 10px;
`;

export default Workspace;
