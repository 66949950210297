import { FunctionComponent, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { BlockType, FileShelfItem, GDriveShelfItem, ShelfItem } from '@/types/workspace';
import { GoogleDriveTypes } from '@/types/file';
import DefaultIcon from '@Assets/icons/files/other.svg';
import VideoIcon from '@Assets/icons/files/video.svg';
import LinkIcon from '@Assets/icons/files/link.svg';
import PlayIconSmall from '@Assets/icons/play-circle-small.svg';
import EmbedIcon from '@Assets/icons/workspaces/embed.svg';

interface Props {
    onClick?: MouseEventHandler & Function;
    shelfItem: ShelfItem;
}

const getFileType = (shelfItem: ShelfItem): string => {
    switch (shelfItem.type) {
        case BlockType.File:
            return (shelfItem as FileShelfItem).content.type;
        case BlockType.GoogleDrive:
            // eslint-disable-next-line no-case-declarations
            const content = (shelfItem as GDriveShelfItem).content;
            // eslint-disable-next-line no-case-declarations
            const isApplication = content.mimeType.includes('application');
            // eslint-disable-next-line no-case-declarations
            const isVideo = content.mimeType.includes('video');
            // eslint-disable-next-line no-case-declarations
            const type = isApplication
                ? GoogleDriveTypes[content.mimeType.toString()]
                : isVideo
                ? GoogleDriveTypes[content.mimeType.toString()]
                : content.mimeType.split('/')[1];
            return type || '?';
        default:
            return shelfItem.type;
    }
};

const ShelfBlockItem: FunctionComponent<Props> = ({ onClick, shelfItem }) => {
    const isVideo = (type: string): boolean => {
        return ['MP4', 'MOV', 'AVI'].includes(type.toUpperCase());
    };

    return (
        <StyledItem onClick={onClick} className="shelf-item">
            <div className="shelf-tile">
                <div className="tile-thumbnail">
                    {(shelfItem as FileShelfItem).content.thumbnail ? (
                        <div className={`${isVideo(getFileType(shelfItem)) ? 'video' : ''}`.trim()}>
                            <img
                                className="tile-image"
                                src={`${process.env.REACT_APP_API_URL}/files/thumbnail/${
                                    (shelfItem as FileShelfItem).content.thumbnail
                                }`}
                                crossOrigin="anonymous"
                                alt="tile"
                            />
                            {isVideo(getFileType(shelfItem)) ? (
                                <img src={PlayIconSmall} alt="play icon" className="play-icon cursor-pointer" />
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <img
                            alt="tile"
                            className="placeholder-image"
                            src={
                                isVideo(getFileType(shelfItem))
                                    ? VideoIcon
                                    : getFileType(shelfItem) === 'link'
                                    ? LinkIcon
                                    : getFileType(shelfItem) === 'embed'
                                    ? EmbedIcon
                                    : DefaultIcon
                            }
                        />
                    )}
                </div>
                <div className="file-name">{shelfItem.content.name}</div>
                {shelfItem.type === BlockType.GoogleDrive && (
                    <img
                        className="google-icon"
                        src={(shelfItem as GDriveShelfItem).content.icon}
                        alt="Google Drive icon"
                    />
                )}
            </div>
        </StyledItem>
    );
};

const StyledItem = styled.div`
    .shelf-tile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin: auto;
        width: 197px;
        border-radius: 7px;
        transition: box-shadow 0.3s ease-in-out;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
            width: 133px;
        }

        .tile-thumbnail {
            width: 197px;
            height: 197px;
            background-color: var(--background-grey);
            border-radius: 8px;

            &:hover {
                filter: drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1));
            }

            @media only screen and (max-width: 768px) {
                width: 133px;
                height: 133px;
            }

            .tile-image {
                width: 197px;
                height: 197px;
                border-radius: 8px;
                object-fit: cover;

                @media only screen and (max-width: 768px) {
                    width: 133px;
                    height: 133px;
                }
            }

            .placeholder-image {
                width: 197px;
                height: 197px;
                object-fit: scale-down;

                @media only screen and (max-width: 768px) {
                    width: 133px;
                    height: 133px;
                }
            }
        }
        .video {
            position: relative;
            height: 197px;
            @media only screen and (max-width: 768px) {
                height: 133px;
            }
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 8px;
                background: rgba(0, 0, 0, 0.18);
            }
            img {
                &.play-icon {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 50%);
                    bottom: 50%;
                    z-index: 1;
                }
            }
        }

        &.active {
            .block-actions {
                opacity: 1;
                pointer-events: all;
            }
        }

        &:hover {
            .block-actions {
                opacity: 1;
                pointer-events: all;
            }
        }

        .file-name {
            color: var(--black-250);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 8px;
        }

        .google-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            box-sizing: border-box;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24), 0 5px 17px 0 rgba(0, 0, 0, 0.19);
        }

        &:active {
            user-select: none;
        }
    }
`;

export default ShelfBlockItem;
