import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Block, MediaBlock } from '@/types/workspace';
import styled from 'styled-components';
import { BlockType, ShelfBlock, TableBlock, TextBlock } from '@/types/workspace';
import Tooltip from '@Components/tooltip';
import ShelfBlockItem from '../shelfBlockItem';
import BannerBlockItem from '../bannerBlockItem';
import TableBlockItem from '../tableBlockItem';
import LinkIcon from '@Assets/icons/workspaces/link.svg';
import { useToasts } from 'react-toast-notifications';
import { dispatchEvent, EventName } from '@Helpers/analytics';

interface Props {
    id?: string;
    className?: string;
    sectionName: string;
    visible: boolean;
    sectionItems: Block[];
    setMiddleSection: Function;
    openOverlay: Function;
    openNewTab: Function;
    setOverlayData: Function;
    workspaceId: string | null;
    isProtected?: boolean;
}

const Section: FunctionComponent<Props> = ({
    id,
    className,
    sectionName,
    sectionItems,
    setMiddleSection,
    openOverlay,
    setOverlayData,
    openNewTab,
    workspaceId,
    isProtected,
}) => {
    const [swToken, setSwToken] = useState<string | undefined>();
    const [token, setToken] = useState<string | undefined>();
    const [isActive, setIsActive] = useState(false);
    const { addToast, removeAllToasts } = useToasts();
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);
    const sectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const sharedViewToken = localStorage.getItem('sharedViewToken');
        if (sharedViewToken) setSwToken(sharedViewToken);
    }, [swToken]);

    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken) setToken(userToken);
    }, [swToken]);

    const loseFocus = (): void => {
        setIsActive(false);
    };

    const focus = (): void => {
        setIsActive(true);
    };

    const copyLink = (activeSectionId: string | null): void => {
        const scroll = urlParams.get('scroll');
        let url = window.location.href;
        if (scroll) {
            url = url.substring(0, url.indexOf('scroll') - 1);
        }
        removeAllToasts();
        if (activeSectionId) {
            navigator.clipboard
                .writeText(url + `?scroll=${activeSectionId.substring(activeSectionId.indexOf('-') + 1)}`)
                .then();
            addToast('Link copied', { appearance: 'success' });
        }
        dispatchEvent(EventName.SharedPageClick, workspaceId, { page: 'sharedView', isProtected }, swToken, token);
    };

    useEffect(() => {
        const options: IntersectionObserverInit = {
            rootMargin: '-50% 0% -50% 0%',
            threshold: 0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.getAttribute('id')?.split('-')[1];
                    setMiddleSection(sectionId);
                }
            });
        }, options);

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <>
            <StyledSection id={`${id ?? ''}`} className={className} ref={sectionRef}>
                <div className="section-container">
                    <div className="section-header" onMouseEnter={() => focus()} onMouseLeave={() => loseFocus()}>
                        <h1
                            className="section-title"
                            dangerouslySetInnerHTML={{
                                __html: sectionName,
                            }}
                        />
                        {isActive && (
                            <Tooltip tooltipText="Copy link to this section" tooltipPositionBottom>
                                <img
                                    className="section-share-icon clickable"
                                    onClick={() => copyLink(id ? id : null)}
                                    src={LinkIcon}
                                    alt="icon"
                                />
                            </Tooltip>
                        )}
                    </div>
                    {sectionItems.map((block) => {
                        switch (block.type) {
                            case BlockType.Text: {
                                const textBlock = block as TextBlock;
                                return (
                                    <div
                                        key={block.id}
                                        className="text-container"
                                        dangerouslySetInnerHTML={{
                                            __html: textBlock.content.value.replace(
                                                /href/g,
                                                'rel="noreferrer" target=_blank href',
                                            ),
                                        }}
                                    />
                                );
                            }
                            case BlockType.Shelf: {
                                const shelfBlock = block as ShelfBlock;
                                return (
                                    <div key={block.id} className="shelf-container">
                                        {shelfBlock.content.items.map((shelfItem) => {
                                            return (
                                                <ShelfBlockItem
                                                    key={shelfItem.id}
                                                    shelfItem={shelfItem}
                                                    onClick={() => {
                                                        if (shelfItem.type === 'link' && 'url' in shelfItem.content) {
                                                            setOverlayData(shelfItem.content.url);
                                                            openNewTab(shelfItem.content.url);
                                                            dispatchEvent(
                                                                EventName.LinkOpen,
                                                                workspaceId,
                                                                {
                                                                    page: 'sharedView',
                                                                    isProtected,
                                                                    link: shelfItem.content.url,
                                                                },
                                                                swToken,
                                                                token,
                                                            );
                                                        } else {
                                                            openOverlay(shelfItem);
                                                            if ('link' in shelfItem.content) {
                                                                dispatchEvent(
                                                                    EventName.FileOpen,
                                                                    workspaceId,
                                                                    {
                                                                        page: 'sharedView',
                                                                        isProtected,
                                                                        fileId: shelfItem.id,
                                                                    },
                                                                    swToken,
                                                                    token,
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            }
                            case BlockType.Table: {
                                const tableBlock = block as TableBlock;
                                return <TableBlockItem key={tableBlock.id} tableData={tableBlock}></TableBlockItem>;
                            }
                            case BlockType.Media: {
                                const mediaBlock = block as MediaBlock;
                                return (
                                    <BannerBlockItem
                                        key={mediaBlock.id}
                                        type={mediaBlock?.content?.type}
                                        link={mediaBlock?.content?.link ?? ''}
                                        mediaBlock={mediaBlock}
                                        workspaceId={workspaceId}
                                        isProtected={isProtected}
                                    />
                                );
                            }
                            default:
                                return null;
                        }
                    })}
                </div>
            </StyledSection>
        </>
    );
};

const StyledSection = styled.div`
    max-width: 850px;
    width: 100%;
    box-shadow: rgba(217, 217, 217, 0.15) 0px 2px 0px;
    margin-bottom: 40px;

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: var(--black-300);

        &.section-title {
            padding: 0;

            > p {
                font-size: 22px;
                line-height: 32px;
            }
        }
    }

    .section-container {
        margin-bottom: 25px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .section-header {
        margin-bottom: 10px;
        display: flex;

        .section-share-icon {
            margin: 0 10px;
        }
    }
`;

export default Section;
