import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { dispatchEvent, EventName } from '@Helpers/analytics';
import NewTabIcon from '@Assets/icons/sharedView/new-tab.svg';
import DownloadIcon from '@Assets/icons/sharedView/download.svg';
import NoPreviewIcon from '@Assets/icons/sharedView/preview-off.svg';
import { StyledActions } from '../embedContent';
interface Props {
    overlayData: string;
    imgPreview: boolean;
    displayPreview: boolean;
    showDownload: boolean;
    openNewTab: Function;
    fileId: string;
    workspaceId?: string | null;
    swToken?: string;
    token?: string;
}
const OverlayModal: FunctionComponent<Props> = ({
    overlayData,
    imgPreview,
    displayPreview,
    showDownload,
    openNewTab,
    fileId,
    workspaceId,
    swToken,
    token,
}) => {
    return (
        <>
            {(overlayData === '' && <Skeleton style={{ marginBottom: '5px' }} height={400} width="100%" />) || (
                <>
                    {imgPreview ? (
                        <StyledOverlayImage src={overlayData} alt="image" />
                    ) : displayPreview ? (
                        <StyledOverlayIframe
                            src={overlayData + '#toolbar=0'}
                            style={{ flex: 1 }}
                            allowFullScreen
                            width="100%"
                        />
                    ) : (
                        <StyledWarning>
                            <img alt="no preview" className="cursor-pointer" src={NoPreviewIcon} />
                            <p>This type of file can not be previewed.</p>
                            <p>Try to download it.</p>
                        </StyledWarning>
                    )}
                    <StyledActions>
                        {showDownload && (
                            <a
                                href={overlayData}
                                download
                                onClick={() => {
                                    dispatchEvent(
                                        EventName.FileDownload,
                                        workspaceId,
                                        { page: 'sharedView', fileId: fileId },
                                        swToken,
                                        token,
                                    );
                                }}
                            >
                                <img alt="download button" className="cursor-pointer" src={DownloadIcon} />
                                Download
                            </a>
                        )}
                        <div className="cursor-pointer" onClick={() => openNewTab(overlayData)}>
                            <img alt="new tab icon" src={NewTabIcon} />
                            Open
                        </div>
                    </StyledActions>
                </>
            )}
        </>
    );
};
const StyledOverlayImage = styled.img`
    width: 100%;
    height: 95%;
    object-fit: contain;
`;

const StyledOverlayIframe = styled.iframe`
    border-radius: 8px;
    border: 10px solid rgba(170, 170, 170, 0.2);
`;

const StyledWarning = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    border-radius: 8px;
`;
export default OverlayModal;
