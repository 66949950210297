import fetch from './fetch';
import routes from './routes';

export default class Clearbit {
    public getCompanyNameSuggestions(query: string): any {
        return fetch(
            {
                method: 'get',
                url: routes.clearbit.autocomplete(query),
                headers: { 'Content-Type': 'application/json' },
            },
            false,
        );
    }

    public getCompanyLogo(companyName: string): any {
        return fetch(
            {
                method: 'get',
                url: routes.clearbit.logo(companyName),
                headers: { 'Content-Type': 'application/json' },
            },
            false,
        );
    }
}
