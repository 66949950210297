import useDialogueState from '@Store/dialogue';
import { ButtonTheme } from '../components/button';

type SetDialogueType = {
    title?: string;
    description?: string;
    buttons?: Array<{ theme: ButtonTheme; text: string; callback: Function }>;
    visible?: boolean;
};

const useDialogue = (): { setDialogue: (data: SetDialogueType) => void; closeDialogue: () => void } => {
    const { setDialogue } = useDialogueState();
    return {
        setDialogue: (data) => {
            setDialogue(data);
        },
        closeDialogue: () => useDialogueState.setState({ visible: false }),
    };
};

export default useDialogue;
