import { FunctionComponent, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isValidEmail, isValidPassword } from '@Helpers/regex';
import { UserStatus } from '@Global/dtos/user.dto';
import { getGoogleUrl } from '@Helpers/getGoogleUrl';
import '@Global/index';
import Communication from '@Communication';
import { CommonStyledPopup } from '@Components/popup/styledPopup';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import AuthStyle from '@Components/style';
import GoogleIcon from '@Assets/icons/google.svg';
import Logo from '@Assets/icons/logo.svg';
import Check from '@Assets/icons/tick-circle.svg';
import EmailIcon from '@Assets/icons/mark-email.svg';
import PlatformIcon from '@Assets/icons/platform.svg';

const Register: FunctionComponent = () => {
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [loginToken, setLoginToken] = useState('');

    const [loading, setLoading] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    useEffect(() => {
        const t = urlParams.get('token') ?? '';
        setToken(t);
    }, []);

    const getData: any = async () => {
        setShowValidationMessage(true);
        if (token) {
            if (isValidPassword(password) && firstName !== '' && lastName !== '') {
                setLoading(true);
                Communication.registerUser(
                    {
                        firstName,
                        lastName,
                        password,
                    },
                    token,
                )
                    .then((res: any) => {
                        setLoginToken(res.data.token);
                        setLoading(false);
                        setPopupVisible(true);
                    })
                    .catch((error: any) => {
                        if (error) {
                            setLoading(false);
                            addToast('Something went wrong! Please check the entered data.', {
                                appearance: 'error',
                            });
                        }
                    });
            }
        } else {
            if (
                isValidPassword(password) &&
                isValidEmail(email) &&
                firstName !== '' &&
                lastName !== '' &&
                password !== ''
            ) {
                setLoading(true);

                Communication.registerDirector({
                    firstName,
                    lastName,
                    email,
                    password,
                })
                    .then(() => {
                        setLoading(false);
                        setPopupVisible(true);
                    })
                    .catch((error: any) => {
                        if (error) {
                            setLoading(false);
                            addToast('Something went wrong! Please check the entered data.', {
                                appearance: 'error',
                            });
                        }
                    });
            }
        }
    };

    const resendEmail: any = async () => {
        setLoading(true);
        Communication.resendToken(email)
            .then(() => {
                setLoading(false);
                setPopupVisible(true);
            })
            .catch((error: any) => {
                if (error) {
                    setLoading(false);
                    addToast('Something went wrong! Please check the entered data.', {
                        appearance: 'error',
                    });
                }
            });
    };

    const locationData = useLocation();
    const from = ((locationData.state as any)?.from?.pathname as string) || '/';

    const redirectGoogleAuth = (): void => {
        setLoadingGoogle(true);
        location.href = getGoogleUrl(from);
    };

    return (
        <>
            <CommonStyledPopup
                isVisible={popupVisible}
                closePopup={() => {
                    setPopupVisible(false);
                    navigate(`/?token=${loginToken}&status=${UserStatus.READY}`);
                }}
                timer={token ? 4 : undefined}
            >
                <div className="title">
                    <h3>{token ? 'Your registration is complete!' : 'Nice, first step is completed!'}</h3>
                    <img src={Check} alt="CheckIcon" />
                </div>
                <div className="description">
                    {token ? (
                        <>
                            <img src={PlatformIcon} alt="Platform Icon" />
                            <p>You will be redirected to the platform.</p>
                        </>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <img src={EmailIcon} alt="Email Icon" />
                            <div style={{ paddingTop: '8px' }}>
                                <p>An email has been sent. Complete your signup through the </p>
                                <p>link we sent to your email address.</p>

                                <p style={{ marginTop: '30px' }}>
                                    Didn&apos;t recieve an email?{' '}
                                    <span style={{ cursor: 'pointer', color: '#00726d' }} onClick={resendEmail}>
                                        Resend email
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </CommonStyledPopup>

            <AuthStyle>
                <img src={Logo} alt="logo"></img>
                <h3 style={{ margin: '0 0 30px 0' }}>
                    {token ? 'You have been invited to ' : 'Create your free account'}
                    {token && <span style={{ color: 'var(--primary-400)', fontSize: '22px' }}>SyncPage</span>}
                </h3>
                <form>
                    <StyledNameWrapper>
                        <StyledInput
                            type={InputComponentType.Text}
                            placeholder="First Name"
                            value={firstName}
                            onChange={(value: string) => setFirstName(value)}
                            size="100%"
                            onEnter={getData}
                        />
                        <StyledInput
                            type={InputComponentType.Text}
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(value: string) => setLastName(value)}
                            size="100%"
                            onEnter={getData}
                        />
                    </StyledNameWrapper>
                    {!token && (
                        <StyledInput
                            type={InputComponentType.Email}
                            placeholder="Email Address"
                            value={email}
                            onChange={(value: string) => setEmail(value)}
                            errorMessage={
                                showValidationMessage
                                    ? isValidEmail(email) || email === ''
                                        ? ''
                                        : 'Please provide valid email!'
                                    : ''
                            }
                            size="100%"
                            onEnter={getData}
                        />
                    )}
                    <StyledInput
                        type={InputComponentType.Password}
                        placeholder="Create password"
                        value={password}
                        onChange={(value: string) => setPassword(value)}
                        errorMessage={
                            showValidationMessage
                                ? isValidPassword(password) || password === ''
                                    ? ''
                                    : 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                                : ''
                        }
                        size="100%"
                        onEnter={getData}
                    />
                </form>
                <TermsBlock>
                    <p>
                        By clicking the button below, I agree to SyncPage’s{' '}
                        <Link to="https://syncpage.io/term-of-services" target="_blank">
                            Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link to="https://syncpage.io/privacy" target="_blank">
                            Privacy Policy
                        </Link>
                    </p>
                </TermsBlock>
                {!token ? (
                    <p>
                        Already have an account? <a href="/login">Login</a>
                    </p>
                ) : (
                    <></>
                )}
                <ButtonsBlock>
                    <Button
                        theme={ButtonTheme.primary}
                        size={token ? 300 : 200}
                        disabled={firstName === '' || lastName === '' || password === '' || (!token && email === '')}
                        onClick={getData}
                        isLoading={loading}
                    >
                        {!token ? 'Sign up with email' : 'Join organization'}
                    </Button>
                    <Button
                        className="google-btn"
                        theme={ButtonTheme.naked}
                        leftIcon={GoogleIcon}
                        size={token ? 300 : 200}
                        onClick={redirectGoogleAuth}
                        isLoading={loadingGoogle}
                    >
                        {!token ? 'Sign up with Google' : 'Join with Google'}
                    </Button>
                </ButtonsBlock>
            </AuthStyle>
        </>
    );
};

const StyledNameWrapper = styled.div`
    display: flex;
    gap: 10px;
    .input {
        margin: 0;
    }
`;

const StyledInput = styled(Input)`
    margin: 10px 0;
`;
const TermsBlock = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    p {
        margin: 0;
        line-height: 20px;
    }
`;

const ButtonsBlock = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    button {
        &.google-btn.with-icon.left-icon {
            .children {
                img {
                    margin-right: 5px;
                    width: 27px;
                    max-height: none;
                    max-width: none;
                    padding-bottom: 0;
                }
            }
        }
    }
`;

export default Register;
