import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Communication from '@Communication';
import { RequestDto, RequestStatus } from '@Global/dtos/request.dto';
import RequestsCard from '@/pages/requests/requestsCard';
import NoItems from '@Components/noItems';
import { StyledTabsHeader, StyledTabs } from '../accounts';
import RequestsSkeleton from './requestsSkeleton';

const Requests: React.FunctionComponent = () => {
    const [requests, setRequests] = useState<RequestDto[] | null>(null);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        fetchAllRequests();
    }, []);

    const fetchAllRequests = (): void => {
        Communication.getAllRequests().then((res: any) => {
            setRequests(res.data);
        });
    };

    const tabClick = (change: number): void => {
        setActiveTab(change);
    };

    return (
        <StyledPage className="page requests">
            <div className="top-bar">
                <h3>Requests</h3>
            </div>
            <div className="tabs">
                <StyledTabsHeader
                    className={`tab ${activeTab === 0 ? 'active' : ''}`}
                    onClick={() => {
                        tabClick(0);
                    }}
                >
                    <p>Pending</p>
                </StyledTabsHeader>
                <StyledTabsHeader
                    className={`tab ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => {
                        tabClick(1);
                    }}
                >
                    <p>Archived</p>
                </StyledTabsHeader>
            </div>
            <StyledTabs>
                <div className={`content ${activeTab === 0 ? 'activeTab' : ''}`}>
                    <div className="inner-content">
                        {requests ? (
                            requests.filter((r) => r.status === RequestStatus.pending).length > 0 ? (
                                requests
                                    .filter((r) => r.status === RequestStatus.pending)
                                    .map((request) => {
                                        return (
                                            <RequestsCard
                                                key={request.id}
                                                requestData={request}
                                                onResolved={fetchAllRequests}
                                            />
                                        );
                                    })
                            ) : (
                                <StyledNoRequest>
                                    <NoItems
                                        content={
                                            <>
                                                <p>No new requests. </p>
                                                <p>Customer requests will show up here.</p>
                                            </>
                                        }
                                    />
                                </StyledNoRequest>
                            )
                        ) : (
                            <RequestsSkeleton />
                        )}
                    </div>
                </div>
            </StyledTabs>
            <StyledTabs>
                <div className={`content ${activeTab === 1 ? 'activeTab' : ''}`}>
                    <div className="inner-content">
                        {requests ? (
                            requests.filter((r) => r.status !== RequestStatus.pending).length > 0 ? (
                                requests
                                    .filter((r) => r.status !== RequestStatus.pending)
                                    .map((request) => {
                                        return (
                                            <RequestsCard
                                                key={request.id}
                                                requestData={request}
                                                onResolved={fetchAllRequests}
                                            />
                                        );
                                    })
                            ) : (
                                <StyledNoRequest>
                                    <NoItems
                                        content={
                                            <>
                                                <p>No archived requests.</p>
                                                <p>Archived requests will show up here.</p>
                                            </>
                                        }
                                    />
                                </StyledNoRequest>
                            )
                        ) : (
                            <RequestsSkeleton />
                        )}
                    </div>
                </div>
            </StyledTabs>
        </StyledPage>
    );
};

const StyledPage = styled.div`
    .filter-bar {
        justify-content: flex-start !important;
    }
    .content {
        max-width: 1600px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        pointer-events: none;

        &.activeTab {
            visibility: visible;
            height: auto;
            overflow: visible;
            pointer-events: all;
        }
        .inner-content {
            background-color: white;
            border-radius: 0 8px 8px 8px;
            padding: 24px;
            box-sizing: border-box;
            p {
                color: var(--black-60);
            }
        }
    }
`;

const StyledNoRequest = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Requests;
