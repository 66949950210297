import { FunctionComponent, useEffect, useState } from 'react';
import useGlobalState from '@Store/global';
import GlobalHotkeys from '@Global/keypress';
import Sidebar from '@Components/sidebar';
import InfoRoom from '../infoRoom';
import WorkspaceDetails from '../workspaceDetails';
import Settings from '../settings';
import PointsOfContact from '../pointsOfContact';
import WelcomeMessage from '../welcomeMessage';
import ActionPlan from '../actionPlan';

interface Props {
    className?: string;
}

const SidebarModalContent: FunctionComponent<Props> = ({ className }) => {
    const { workspaceContextMenu } = useGlobalState();

    const [activePage, setActivePage] = useState(0);

    const changePage = (num: number): void => {
        setActivePage(num);
    };

    useEffect(() => {
        GlobalHotkeys.add('workspaces/sidebar/escape', 'Escape', () => changePage(0));
    }, []);

    return (
        <>
            <Sidebar className={`${workspaceContextMenu ? 'overlay-visible' : ''} ${className ?? className}`.trim()}>
                {activePage === 0 ? (
                    <Settings isActive={true} changePage={changePage} />
                ) : activePage === 1 ? (
                    <WorkspaceDetails isActive={true} changePage={changePage} />
                ) : activePage === 2 ? (
                    <InfoRoom isActive={true} changePage={changePage} />
                ) : activePage === 3 ? (
                    <PointsOfContact isActive={true} changePage={changePage} />
                ) : activePage === 4 ? (
                    <ActionPlan isActive={true} changePage={changePage} />
                ) : activePage === 5 ? (
                    <WelcomeMessage isActive={true} changePage={changePage} />
                ) : (
                    <></>
                )}
            </Sidebar>
        </>
    );
};

export default SidebarModalContent;
