import { RequestDto, RequestStatus } from '@Global/dtos/request.dto';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonTheme } from '@Components/button';
import ShareRequestBody from '@/pages/requests/requestsCard/body';
import Communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import useDialogue from '@Hooks/useDialogue';
import RequestActionButton from '@/pages/requests/requestsCard/actionButton';
import CheckIcon from '@Assets/icons/requests/check-circle.svg';
import CancelIcon from '@Assets/icons/requests/cancel.svg';
interface Props {
    requestData: RequestDto;
    onResolved: Function;
}

const RequestsCard: React.FunctionComponent<Props> = ({ requestData, onResolved }) => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const { setDialogue, closeDialogue } = useDialogue();

    const approve = (): void => {
        setLoading(true);
        Communication.approveRequest(requestData.id)
            .then(() => {
                setLoading(false);
                addToast('Request approved successfully', { appearance: 'success' });
                onResolved();
            })
            .catch((e: any) => {
                setLoading(false);
                addToast('Something went wrong! Please try again.', { appearance: 'error' });
                console.error(e);
            });
    };

    const reject = (): void => {
        setLoading(true);
        Communication.rejectRequest(requestData.id)
            .then(() => {
                setLoading(false);
                addToast('Request rejected', { appearance: 'warning' });
                onResolved();
            })
            .catch((e: any) => {
                setLoading(false);
                addToast('Something went wrong! Please try again.', { appearance: 'error' });
                console.error(e);
            });
    };

    return (
        <StyledRequestCard>
            <ShareRequestBody requestData={requestData} />
            <StyledAction className={`${requestData.status === RequestStatus.pending ? 'pending' : ''}`.trim()}>
                <RequestActionButton requestData={requestData} />
                <StyledButtonWrapper>
                    {requestData.status === RequestStatus.pending ? (
                        <>
                            <Button
                                theme={ButtonTheme.naked}
                                isLoading={loading}
                                size={150}
                                // leftIcon={CancelIcon}
                                onClick={() => {
                                    setDialogue({
                                        title: 'Confirm reject',
                                        description: 'Are you sure you want to reject this request?',
                                        buttons: [
                                            {
                                                theme: ButtonTheme.danger,
                                                text: 'Reject',
                                                callback: () => {
                                                    reject();
                                                    closeDialogue();
                                                },
                                            },
                                            {
                                                theme: ButtonTheme.naked,
                                                text: 'Cancel',
                                                callback: () => {
                                                    closeDialogue();
                                                },
                                            },
                                        ],
                                    });
                                }}
                            >
                                Reject
                            </Button>
                            <Button
                                theme={ButtonTheme.primary}
                                isLoading={loading}
                                size={150}
                                // leftIcon={CheckIcon}
                                onClick={() => {
                                    setDialogue({
                                        title: 'Confirm approve',
                                        description: 'Are you sure you want to approve this request?',
                                        buttons: [
                                            {
                                                theme: ButtonTheme.primary,
                                                text: 'Approve',
                                                callback: () => {
                                                    approve();
                                                    closeDialogue();
                                                },
                                            },
                                            {
                                                theme: ButtonTheme.naked,
                                                text: 'Cancel',
                                                callback: () => {
                                                    closeDialogue();
                                                },
                                            },
                                        ],
                                    });
                                }}
                            >
                                Approve
                            </Button>
                        </>
                    ) : requestData.status === RequestStatus.rejected ? (
                        <Button theme={ButtonTheme.link} className="not-clickable" leftIcon={CancelIcon}>
                            Rejected
                        </Button>
                    ) : (
                        <Button theme={ButtonTheme.link} className="not-clickable" leftIcon={CheckIcon}>
                            Approved
                        </Button>
                    )}
                </StyledButtonWrapper>
            </StyledAction>
        </StyledRequestCard>
    );
};

const StyledAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    .button {
        font-weight: 600;

        &.go-to-btn {
            font-size: 12px;
        }
        &.not-clickable {
            pointer-events: none;
        }

        &.go-to-btn,
        &.not-clickable {
            width: auto;
            height: auto;
            padding: 0;
        }
    }
    &.pending {
        // Media Queries
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            .go-to-btn {
                margin-bottom: 10px;
            }
        }
        @media only screen and (max-width: 470px) {
            .button {
                min-width: auto;
                width: auto;
            }
        }
    }
`;

const StyledButtonWrapper = styled.div`
    display: flex;
    gap: 18px;
`;

const StyledRequestCard = styled.div`
    width: 100%;
    &:not(:last-child) {
        border-bottom: 1px solid var(--black-20);
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
`;
export default RequestsCard;
