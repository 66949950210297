import { LinkCreateDto } from '@/global/dtos/link.dto';
import fetch from './fetch';
import routes from './routes';

export default class Links {
    public getAllLinks(): any {
        return fetch({
            method: 'get',
            url: routes.link.all,
        });
    }
    public getLink(id: string): any {
        return fetch({
            method: 'get',
            url: routes.link.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public updateLink(id: string, data: any): any {
        return fetch({
            method: 'patch',
            url: routes.link.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public deleteLink(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.link.single(id),
        });
    }
    public createLink(data: LinkCreateDto): any {
        return fetch({
            method: 'post',
            url: routes.link.all,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public uploadLinkThumbnail(formData: FormData): any {
        return fetch({
            method: 'post',
            url: routes.link.thumbnail,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }
    public removeLinkThumbnail(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.link.thumbnailRemove(id),
        });
    }
    public copyLinkFromLibrary(
        linkId: string,
        data: { workspaceId?: string; templateId?: string; infoRoomId?: string },
    ): any {
        return fetch({
            method: 'patch',
            url: routes.link.library(linkId),
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
        });
    }
}
