import React, { useEffect, useState } from 'react';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import Tooltip from '@Components/tooltip';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';
import ManageSearch from '@Assets/icons/accountsSidebar/details.svg';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import InfoIcon from '@Assets/icons/settings/profile/info.svg';
import { AccountSidebarTabs, CompanySidebarSections, ContactSidebarSections } from '../../sidebar/sidebar';
import { isValidEmail, isValidUrl } from '@Helpers/regex';
import SelectComponent, { SelectOptionType } from '@Components/select';
import Communication from '@/communication';
import { fixUrl } from '@Helpers/url';
import { StyledTitle, StyledHeader, TwoButtonsStyle } from '../../../workspaces/editor/sidebar/styledComponents';
import { StyledWebsiteLabel } from '../..';
import AccountsImageName from '../accountsImageName';
interface Props {
    activeTab: number;
    changeSection: Function;
    loading: boolean;
    sendContactData: Function;
    sendCompanyData: Function;
}

const AccountDetails: React.FunctionComponent<Props> = ({
    activeTab,
    changeSection,
    loading,
    sendContactData,
    sendCompanyData,
}) => {
    const {
        id: companyId,
        name,
        email,
        website,
        employeeNumber,
        hq,
        linkedin,
        allCompanies,
        setCompanyData,
    } = useCompanyStore();
    const {
        id: contactId,
        email: contactEmail,
        firstName,
        lastName,
        linkedin: contactLinkedin,
        allContacts,
        setContactData,
    } = useContactStore();

    const [hasChangesContact, setHasChangesContact] = useState(false);
    const [hasChangesCompany, setHasChangesCompany] = useState(false);
    const [companySuggestions, setCompanySuggestions] = useState<SelectOptionType[]>([]);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [contactEmailError, setContactEmailError] = useState<boolean>(false);

    useEffect(() => {
        const companyData = allCompanies.find((company) => company.id === companyId);
        if (
            name !== companyData?.name ||
            email !== companyData?.email ||
            website !== companyData.website ||
            employeeNumber !== companyData.employeeNumber ||
            hq !== companyData.hq ||
            linkedin !== companyData.linkedin
        ) {
            setHasChangesCompany(true);
        } else {
            setHasChangesCompany(false);
        }
    }, [name, email, website, employeeNumber, hq, linkedin]);

    useEffect(() => {
        const contactData = allContacts.find((contact) => contact.id === contactId);
        if (
            contactEmail !== contactData?.email ||
            firstName !== contactData?.firstName ||
            lastName !== contactData.lastName ||
            contactLinkedin !== contactData.linkedin
        ) {
            setHasChangesContact(true);
        } else {
            setHasChangesContact(false);
        }
    }, [contactEmail, firstName, lastName, contactLinkedin]);

    useEffect(() => {
        setContactEmailError(false);
    }, [contactEmail]);

    const updateData = (): void => {
        if (activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)) {
            if (!isValidEmail(contactEmail) || contactEmail === '') {
                setContactEmailError(true);
            } else {
                sendContactData();
                setHasChangesContact(false);
            }
        } else {
            if (email !== '') {
                if (email && isValidEmail(email)) {
                    sendCompanyData();
                    setHasChangesCompany(false);
                } else {
                    setEmailError(true);
                }
            } else {
                sendCompanyData();
                setHasChangesCompany(false);
            }
        }
    };

    return (
        <>
            <StyledHeader
                onClick={() => {
                    activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                        ? changeSection(ContactSidebarSections.CONTACT_MAIN)
                        : changeSection(CompanySidebarSections.COMPANY_MAIN);
                }}
            >
                <img src={arrowLeft} alt="icon"></img>
                {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Contact' : 'Company'} settings
            </StyledHeader>
            <AccountsImageName activeTab={activeTab} />
            <StyledTitle>
                <img src={ManageSearch} alt="icon" />
                {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Contact' : 'Company'} details
            </StyledTitle>
            {activeTab === AccountSidebarTabs.CONTACT_SIDEBAR ? (
                <>
                    <div className="frame-wrapper">
                        <Input
                            label="Email"
                            placeholder="email"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setContactData({ email: value })}
                            value={contactEmail}
                            size="100%"
                            errorMessage={contactEmailError ? 'Please provide valid email!' : ''}
                        />
                        <Input
                            label="First name"
                            placeholder="first name"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setContactData({ firstName: value })}
                            value={firstName}
                            size="100%"
                        />
                        <Input
                            label="Last name"
                            placeholder="last name"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setContactData({ lastName: value })}
                            value={lastName}
                            size="100%"
                        />
                        <Input
                            label="Linkedin"
                            placeholder="linkedin"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setContactData({ linkedin: value })}
                            value={contactLinkedin}
                            size="100%"
                            errorMessage={
                                contactLinkedin
                                    ? contactLinkedin !== '' && contactLinkedin !== null && isValidUrl(contactLinkedin)
                                        ? ''
                                        : 'Please provide valid URL address!'
                                    : ''
                            }
                        />
                    </div>
                    {hasChangesContact && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                onClick={() => updateData()}
                                isLoading={loading}
                                disabled={
                                    !hasChangesContact ||
                                    (contactLinkedin
                                        ? contactLinkedin !== '' &&
                                          contactLinkedin !== null &&
                                          !isValidUrl(contactLinkedin)
                                        : '') ||
                                    firstName === ''
                                }
                            >
                                Save
                            </Button>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.naked}
                                onClick={() => {
                                    activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                                        ? changeSection(ContactSidebarSections.CONTACT_MAIN)
                                        : changeSection(CompanySidebarSections.COMPANY_MAIN);
                                }}
                            >
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                </>
            ) : (
                <>
                    <div className="frame-wrapper">
                        <Input
                            label="Company name"
                            placeholder="name"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setCompanyData({ name: value })}
                            value={name}
                            size="100%"
                        />
                        <Input
                            label="Company email"
                            placeholder="email"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setCompanyData({ email: value })}
                            value={email}
                            size="100%"
                            errorMessage={
                                emailError ? (email && isValidEmail(email) ? '' : 'Please provide valid email!') : ''
                            }
                        />
                        <StyledWebsiteLabel>
                            <div className="with-tooltip">
                                <div className="label-wrapper">Company website</div>
                                <Tooltip
                                    tooltipText={
                                        'Add a website manually or start typing a company name to automatically find the website and logo (for example, type "apple" instead of "https://www.apple.com/").'
                                    }
                                    width="223px"
                                    tooltipPositionBottom
                                >
                                    <img alt="info" src={InfoIcon} />
                                </Tooltip>
                            </div>
                        </StyledWebsiteLabel>
                        <SelectComponent
                            placeholder="website"
                            isClearable={false}
                            isMulti={false}
                            value={website ? { label: website, value: website } : null}
                            onSearch={(value: string) => {
                                if (value !== '') {
                                    Communication.getCompanyNameSuggestions(value).then((res: any) => {
                                        setCompanySuggestions(
                                            res.data.map((c: any) => ({ value: c.domain, label: c.domain })),
                                        );
                                    });
                                }
                            }}
                            onChange={(selectedOption) => {
                                setCompanyData({
                                    website: fixUrl((selectedOption as SelectOptionType).value) as string,
                                });
                            }}
                            optionList={companySuggestions}
                        />
                        {/* <Input
                                label="Number of employees"
                                placeholder="employeeNumber"
                                type={InputComponentType.Text}
                                onChange={(value: string) =>
                                    isValidNumber(value)
                                        ? setCompanyData({ employeeNumber: parseInt(value) })
                                        : setCompanyData({ employeeNumber: null })
                                }
                                value={employeeNumber?.toString()}
                                errorMessage={
                                    employeeNumber
                                        ? isValidNumber(employeeNumber.toString()) || employeeNumber?.toString() === ''
                                            ? ''
                                            : 'Please provide valid number!'
                                        : ''
                                }
                                size="100%"
                            /> */}
                        {/* <Input
                                label="HQ"
                                placeholder="hq"
                                type={InputComponentType.Text}
                                onChange={(value: string) => setCompanyData({ hq: value })}
                                value={hq}
                                size="100%"
                            /> */}
                        <Input
                            label="Linkedin"
                            placeholder="linkedin"
                            type={InputComponentType.Text}
                            onChange={(value: string) => setCompanyData({ linkedin: value })}
                            value={linkedin}
                            size="100%"
                            errorMessage={
                                linkedin
                                    ? linkedin !== '' && linkedin !== null && isValidUrl(linkedin)
                                        ? ''
                                        : 'Please provide valid URL address!'
                                    : ''
                            }
                        />
                    </div>
                    {hasChangesCompany && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                onClick={() => updateData()}
                                isLoading={loading}
                                disabled={
                                    !hasChangesCompany ||
                                    (!isValidUrl(website) && website !== '') ||
                                    (linkedin ? linkedin !== '' && linkedin !== null && !isValidUrl(linkedin) : '') ||
                                    website === '' ||
                                    name === ''
                                }
                            >
                                Save
                            </Button>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.naked}
                                onClick={() => {
                                    activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                                        ? changeSection(ContactSidebarSections.CONTACT_MAIN)
                                        : changeSection(CompanySidebarSections.COMPANY_MAIN);
                                }}
                            >
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                </>
            )}
        </>
    );
};

export default AccountDetails;
