import styled from 'styled-components';
import { FunctionComponent, useState } from 'react';
import Modal from '../../../components/modal';
import Button, { ButtonTheme } from '../../../components/button';
import RoleCodes from '../../../global/roles';
import SelectComponent, { SelectComponentTheme, SelectOptionType } from '../../../components/select';
import useUserStore from '../../../store/user';

interface Props {
    className?: string;
    onSelect?: Function;
    searchTerm?: string;
    visibility: boolean;
    formManipulation: Function;
    roleCode: number | undefined;
    onChange: Function;
    saveRoles: Function;
}

const ChangeUserRoleForm: FunctionComponent<Props> = ({
    visibility,
    formManipulation,
    roleCode,
    onChange,
    saveRoles,
}) => {
    const [formModal, setFormModal] = useState<boolean>(visibility);
    const { activeUserRoles } = useUserStore();
    const closeForm = (): void => {
        setFormModal(!formModal);
        formManipulation();
    };

    const getRoleList = (): SelectOptionType[] => {
        if (activeUserRoles.includes(RoleCodes.SUPER_ADMIN)) {
            return [
                { value: RoleCodes.DIRECTOR.toString(), label: 'Director' },
                { value: RoleCodes.ADMIN.toString(), label: 'Admin' },
                { value: RoleCodes.MANAGER.toString(), label: 'Manager' },
                { value: RoleCodes.USER.toString(), label: 'User' },
            ];
        } else if (activeUserRoles.includes(RoleCodes.DIRECTOR)) {
            return [
                { value: RoleCodes.ADMIN.toString(), label: 'Admin' },
                { value: RoleCodes.MANAGER.toString(), label: 'Manager' },
                { value: RoleCodes.USER.toString(), label: 'User' },
            ];
        } else if (activeUserRoles.includes(RoleCodes.ADMIN)) {
            return [
                { value: RoleCodes.MANAGER.toString(), label: 'Manager' },
                { value: RoleCodes.USER.toString(), label: 'User' },
            ];
        } else return [];
    };

    const getSelectValue = (initialRoleCode: number | undefined): SelectOptionType => {
        switch (initialRoleCode) {
            case 1: {
                return { label: 'Super Admin', value: '1' };
            }
            case 2: {
                return { label: 'Director', value: '2' };
            }
            case 3: {
                return { label: 'Admin', value: '3' };
            }
            case 4: {
                return { label: 'Manager', value: '4' };
            }
            case 5: {
                return { label: 'User', value: '5' };
            }
            default:
                return { label: 'No roles', value: '-1' };
        }
    };

    return (
        <Modal
            modalVisible={formModal}
            closeModal={() => closeForm()}
            size={350}
            title="Change user role"
            className="user-role-modal"
        >
            <StyledSharedModal>
                <div className="modal-content">
                    <SelectComponent
                        label="User role"
                        placeholder="Select user role"
                        value={getSelectValue(roleCode)}
                        onChange={(value) => onChange(value)}
                        optionList={getRoleList()}
                        isClearable={false}
                        theme={SelectComponentTheme.Light}
                    />
                    <TwoButtonsStyle>
                        <Button className="copy-button" theme={ButtonTheme.primary} onClick={() => saveRoles()}>
                            Save
                        </Button>
                        <Button className="clickable" theme={ButtonTheme.naked} onClick={() => setFormModal(false)}>
                            Cancel
                        </Button>
                    </TwoButtonsStyle>
                </div>
            </StyledSharedModal>
        </Modal>
    );
};

const StyledSharedModal = styled.div`
    .modal-header {
        margin-bottom: 20px;
    }

    .modal-content {
        display: flex;
        flex-direction: column;

        .select {
            width: 100%;
        }
    }
`;
const TwoButtonsStyle = styled.div`
    margin-top: 36px;
    display: flex;
    gap: 12px;
    .button {
        width: 100%;
    }
`;
export default ChangeUserRoleForm;
