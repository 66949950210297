import { create } from 'zustand';
import { CompanyDto } from '@Global/dtos/company.dto';
import { AssociatedWorkspaceDto } from '@Global/dtos/workspace.dto';
import { AssociatedContactContactDto } from '@Global/dtos/contact.dto';

interface Company {
    id: string;
    name: string;
    email?: string;
    website: string;
    employeeNumber?: number | null;
    hq?: string;
    linkedin?: string;
    profilePicture?: string;
    logoUrl?: string;
    Workspaces?: AssociatedWorkspaceDto[];
    CompanyContacts?: AssociatedContactContactDto[];

    getCompanyData: () => {
        id?: string;
        name: string;
        email: string | undefined;
        website: string;
        employeeNumber: number | undefined | null;
        hq: string | undefined;
        linkedin: string | undefined;
        profilePicture: string | undefined;
        logoUrl: string | undefined;
        Workspaces?: AssociatedWorkspaceDto[];
        CompanyContacts?: AssociatedContactContactDto[];
    };
    setCompanyData: (company: {
        id?: string;
        name?: string;
        email?: string;
        website?: string;
        employeeNumber?: number | null;
        hq?: string;
        linkedin?: string;
        profilePicture?: string;
        logoUrl?: string;
        Workspaces?: AssociatedWorkspaceDto[];
        CompanyContacts?: AssociatedContactContactDto[];
    }) => void;
    reset: () => void;
    allCompanies: CompanyDto[];
}

const initialState = {
    id: '',
    name: '',
    email: undefined,
    website: '',
    employeeNumber: undefined,
    hq: undefined,
    linkedin: undefined,
    profilePicture: undefined,
    logoUrl: undefined,
    Workspaces: [],
    CompanyContacts: [],
};

const useCompanyStore = create<Company>((set, get) => ({
    ...initialState,
    allCompanies: [],
    getCompanyData: () => ({
        id: get().linkedin,
        name: get().name,
        email: get().email,
        website: get().website,
        employeeNumber: get().employeeNumber,
        hq: get().hq,
        linkedin: get().linkedin,
        profilePicture: get().profilePicture,
        logoUrl: get().logoUrl,
        Workspaces: get().Workspaces,
        CompanyContacts: get().CompanyContacts,
    }),
    setCompanyData: (company: {
        id?: string;
        name?: string;
        email?: string;
        website?: string;
        employeeNumber?: number | null;
        hq?: string;
        linkedin?: string;
        profilePicture?: string;
        logoUrl?: string;
        Workspaces?: AssociatedWorkspaceDto[];
        CompanyContacts?: AssociatedContactContactDto[];
    }) => set(company),
    reset: () => {
        set(initialState);
    },
}));

export default useCompanyStore;
