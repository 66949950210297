import Quill from 'quill';
import useEditorStore from '@Store/editor';
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
    onPaste(e: any): void {
        const { activeElementId } = useEditorStore.getState();

        if (activeElementId === 'table-cell' || activeElementId === 'title') {
            e.preventDefault();
            const range = this.quill.getSelection();
            const text = e.clipboardData.getData('text/plain').replace(/(\r\n|\n|\r)/gm, '');
            const delta = new Delta().retain(range.index).delete(range.length).insert(text);
            const index = text.length + range.index;
            const length = 0;
            this.quill.updateContents(delta, 'silent');
            this.quill.setSelection(index, length, 'silent');
            this.quill.scrollIntoView();
        }
    }
}

export default PlainClipboard;
