import { FunctionComponent, MouseEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import attachSize from '@Helpers/size';

export enum ButtonTheme {
    primary = 'primary',
    black = 'black',
    danger = 'danger',
    naked = 'naked',
    link = 'link',
}

interface Props {
    leftIcon?: any;
    rightIcon?: any;
    hoverIcon?: any;
    color?: string;
    size?: number | string;
    onClick?: MouseEventHandler<HTMLButtonElement> & Function;
    disabled?: boolean;
    isLoading?: boolean;
    className?: string;
    href?: string;
    target?: string;
    children?: string | any;
    feedback?: string;
    onMouseEnter?: (MouseEventHandler<HTMLButtonElement> & Function) | undefined;
    onMouseLeave?: (MouseEventHandler<HTMLButtonElement> & Function) | undefined;
    theme?: ButtonTheme;
}

const Button: FunctionComponent<Props> = ({
    leftIcon,
    rightIcon,
    hoverIcon,
    color,
    size,
    children,
    onClick,
    disabled,
    isLoading,
    className,
    href,
    feedback,
    onMouseEnter,
    onMouseLeave,
    theme,
    target,
}) => {
    const [loadingTriggered, setLoadingTriggered] = useState(false);
    const [noChildren] = useState(!children);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        if (feedback) {
            if (isLoading) {
                setLoadingTriggered(true);
            } else if (loadingTriggered) {
                setTimeout(() => setLoadingTriggered(false), 1200);
            }
        }
    }, [isLoading]);

    return href ? (
        <a href={href} target={target}>
            <StyledButton
                className={`button i ${className ? className : ''} ${isLoading ? 'loading' : ''} ${
                    leftIcon ? 'with-left-icon' : rightIcon ? 'with-right-icon' : ''
                } ${!isLoading && loadingTriggered ? 'feedback' : ''}`.trim()}
                color={color}
                theme={theme}
                size={size}
                onClick={(e) => {
                    if (feedback) {
                        setLoadingTriggered(true);
                        setTimeout(() => setLoadingTriggered(false), 1200);
                    }
                    onClick ? onClick(e) : null;
                }}
                disabled={disabled}
                href={href}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {isLoading !== undefined && (
                    <div className="spinner-wrapper">
                        <div className="spinner"></div>
                    </div>
                )}

                <div className={`children ${noChildren ? 'no-children' : ''}`}>
                    {leftIcon && <img src={leftIcon} alt="icon" />}
                    {children}
                    {rightIcon && <img src={rightIcon} alt="icon" />}
                </div>
                <div className="f-text">{feedback}</div>
            </StyledButton>
        </a>
    ) : (
        <StyledButton
            className={`button i ${className ? className : ''} ${isLoading ? 'loading' : ''} ${
                leftIcon ? 'with-icon left-icon' : rightIcon ? 'with-icon right-icon' : ''
            } ${!isLoading && loadingTriggered ? 'feedback' : ''}`.trim()}
            color={color}
            theme={theme}
            size={size}
            onClick={(e) => {
                if (feedback) {
                    setLoadingTriggered(true);
                    setTimeout(() => setLoadingTriggered(false), 1200);
                }
                onClick ? onClick(e) : null;
            }}
            disabled={disabled}
            href={href}
            onMouseEnter={(e) => {
                setHovered(true);
                onMouseEnter ? onMouseEnter(e) : null;
            }}
            onMouseLeave={(e) => {
                setHovered(false);
                onMouseLeave ? onMouseLeave(e) : null;
            }}
        >
            {isLoading !== undefined && (
                <div className="spinner-wrapper">
                    <div className="spinner"></div>
                </div>
            )}

            <div className={`children ${noChildren ? 'no-children' : ''}`}>
                {leftIcon && <img src={hovered && hoverIcon ? hoverIcon : leftIcon} alt="icon" />}
                {children}
                {rightIcon && <img src={hovered && hoverIcon ? hoverIcon : rightIcon} alt="icon" />}
            </div>
            <div className="f-text">{feedback}</div>
        </StyledButton>
    );
};

const getButtonBgColor = (themeColor: string, active?: 'active' | ''): string => {
    switch (themeColor) {
        case 'primary':
            return active
                ? 'var(--primary-400)'
                : 'linear-gradient(246deg, var(--primary-300) 0.59%, var(--primary-400) 50.69%,  var(--primary-400) 61.38%, var(--primary-300) 96.41%)';
        case 'black':
            return active
                ? '#16171B'
                : 'linear-gradient(246deg,  var(--black-60) 0.59%, #16171B 50.69%,  #16171B 61.38%,  var(--black-60) 96.41%)';
        case 'danger':
            return active
                ? '#E22A4C'
                : 'linear-gradient(246deg, #FF284F 0.59%, #E22A4C 50.69%,  #E22A4C 61.38%, #FF284F 96.41%)';
        case 'naked':
            return active ? 'var(--black-75)' : 'transparent';
        case 'link':
            return 'transparent';
        default:
            return active
                ? 'var(--primary-400)'
                : 'linear-gradient(246deg, var(--primary-300) 0.59%, var(--primary-400) 50.69%,  var(--primary-400) 61.38%, var(--primary-300) 96.41%)';
    }
};

const getButtonColor = (themeColor: string, color?: string, active?: 'active' | ''): string => {
    switch (themeColor) {
        case 'primary':
            return active ? 'var(--primary-300)' : 'white';
        case 'black':
            return active ? 'rgba(170, 170, 170, 0.67)' : 'white';
        case 'danger':
            return active ? '#FF284F' : 'white';
        case 'naked':
            return 'var(--black-60)';
        case 'link':
            return color ? color : 'var(--black-60)';
        default:
            return 'white';
    }
};

const StyledButton = styled.button<Props>`
    border: ${(props) => (props.theme === ButtonTheme.naked ? '1px solid var(--black-60)' : 'none')};
    background: ${(props) => getButtonBgColor(props.theme)};
    color: ${(props) => getButtonColor(props.theme, props.color)};
    font-size: 14px;
    line-height: 24px;
    padding: 5px 20px;
    border-radius: 5px;
    min-width: ${(props) => attachSize(props.size)};
    width: ${(props) => attachSize(props.size)};
    height: 36px;
    background-size: 200% 100%;
    background-position: right;
    transition: background-size 0.5s ease-in-out, transform 0.3s, background-position 0.5s ease-in-out,
        border 0.3s ease-in-out;

    &:active {
        border: ${(props) => getButtonColor(props.theme, props.color, 'active')};
        background: ${(props) => getButtonBgColor(props.theme, 'active')};
        color: ${(props) => getButtonColor(props.theme, props.color, 'active')};
        transform: scale(0.95);
    }

    &:disabled {
        border: none;
        background: rgba(170, 170, 170, 0.5);
        color: var(--black-75);

        img {
            opacity: 0.2;
            -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
        }
    }

    &:hover {
        border: ${(props) => (props.theme === ButtonTheme.naked ? '1px solid var(--black-75)' : 'none')};
        cursor: pointer;
        background-position: left;
        &:disabled {
            cursor: not-allowed;
            border: none;
            background: rgba(170, 170, 170, 0.5);
            color: var(--black-75);
            transform: none;
            img {
                opacity: 0.2;
                -webkit-filter: grayscale(1) invert(1);
                filter: grayscale(1) invert(1);
            }
            &:active {
                background: rgba(170, 170, 170, 0.5);
            }
        }
        &:active {
            background: ${(props) => getButtonBgColor(props.theme, 'active')};
        }
    }

    &.with-icon {
        .children {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 20px;
                max-height: 20px;
            }
        }

        &.left-icon {
            .children {
                img {
                    margin-right: 10px;
                }

                &.no-children {
                    img {
                        margin-right: 0;
                    }
                }
            }
        }
        &.right-icon {
            .children {
                img {
                    margin-left: 10px;
                }

                &.no-children {
                    img {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .children {
        transition: ease all 0.3s;
        opacity: 1;
    }

    .f-text {
        opacity: 0;
        height: 0;
        width: 0;
        //animation: pulse 1s ease-in-out infinite;
    }

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        height: 0;
        transition: ease all 0.3s;
        .spinner {
            border: 2px solid ${(props) => (props.theme === ButtonTheme.naked ? 'var(--black-60)' : 'white')};
            border-top: 3px solid rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            width: 14px;
            height: 14px;
            animation: spin 1s linear infinite;
        }
    }
    &.loading {
        pointer-events: none;

        .spinner-wrapper {
            opacity: 1;
            height: auto;
        }

        .children {
            opacity: 0;
            height: 0;
            overflow: hidden;
        }

        .f-text {
            opacity: 0;
            height: 0;
            width: 0;
        }
    }
    &.feedback {
        pointer-events: none;

        .spinner-wrapper {
            opacity: 0;
            height: 0;
        }

        .children {
            opacity: 0;
            height: 0;
            overflow: hidden;
        }

        .f-text {
            opacity: 1;
            height: auto;
            width: auto;
            animation: fading 1.2s linear 1;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes fading {
        0% {
            opacity: 1;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1.3);
        }
    }
`;

export default Button;
