import { ChangeEvent, FunctionComponent, useState } from 'react';
import '@Global/index';
import Logo from '@Assets/icons/logo.svg';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import AuthStyle from '@Components/style';
import Communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { isValidEmail } from '@Helpers/regex';
import Check from '@Assets/icons/tick-circle.svg';
import EmailIcon from '@Assets/icons/mark-email.svg';
import { CommonStyledPopup } from '@Components/popup/styledPopup';

const ForgotPassword: FunctionComponent = () => {
    const { addToast, removeAllToasts } = useToasts();
    const [popupVisible, setPopupVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.toString());
    };

    const getData: any = async () => {
        setShowValidationMessage(true);

        if (isValidEmail(email)) {
            removeAllToasts();
            setLoading(true);
            try {
                await Communication.generateToken(email);
                setLoading(false);
                setPopupVisible(true);
                addToast('Check your email to get token', {
                    appearance: 'success',
                });
            } catch (error: any) {
                setLoading(false);
                addToast(
                    error.request.status === 404 ? 'User is not found' : 'Something went wrong. Please try again.',
                    {
                        appearance: 'error',
                    },
                );
            }
        }
    };

    const resendEmail: any = async () => {
        setLoading(true);
        try {
            await Communication.generateToken(email);
            setLoading(false);
            addToast('Check your email to get token', {
                appearance: 'success',
            });
        } catch (error: any) {
            setLoading(false);
            addToast(error.request.status === 404 ? 'User is not found' : 'Something went wrong. Please try again.', {
                appearance: 'error',
            });
        }
    };

    return (
        <>
            <CommonStyledPopup isVisible={popupVisible}>
                <div className="title">
                    <h3>Link generated! </h3>
                    <img src={Check} alt="CheckIcon" />
                </div>
                <div className="description" style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <img src={EmailIcon} alt="EmailIcon" />
                    <div style={{ paddingTop: '8px' }}>
                        <p>Please check your email for reset link.</p>

                        <p style={{ marginTop: '10px' }}>
                            Didn&apos;t recieve an email?{' '}
                            <span style={{ cursor: 'pointer', color: '#00726d' }} onClick={resendEmail}>
                                Resend email
                            </span>
                        </p>
                    </div>
                </div>
            </CommonStyledPopup>

            <AuthStyle>
                <>
                    <img src={Logo} alt="logo"></img>
                    <h3>Forgot password</h3>
                    <p>Enter your email and we will send you a link to get back your account.</p>

                    <StyledInput
                        type={InputComponentType.Email}
                        placeholder="Email Address"
                        onChange={emailChangeHandler}
                        value={email}
                        errorMessage={
                            showValidationMessage
                                ? isValidEmail(email) || email === ''
                                    ? ''
                                    : 'Please provide valid email!'
                                : ''
                        }
                        size="100%"
                        onEnter={getData}
                    ></StyledInput>

                    <p>
                        Return to <a href="/login">Login </a>
                        or <a href="/sign">Create New Account</a>
                    </p>

                    <Button
                        disabled={email === ''}
                        theme={ButtonTheme.primary}
                        size={200}
                        isLoading={loading}
                        onClick={getData}
                    >
                        Reset password
                    </Button>
                </>
            </AuthStyle>
        </>
    );
};

const StyledInput = styled(Input)`
    margin: 10px 0;
`;

export default ForgotPassword;
