import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';
import CircleLetter from '@Components/circleLetter';
import CompanyIcon from '@Assets/icons/accounts/company.svg';
import { AccountSidebarTabs } from '../sidebar';
interface Props {
    activeTab: number;
}
const AccountsImageName: FunctionComponent<Props> = ({ activeTab }) => {
    const { id: companyId, name, logoUrl, profilePicture } = useCompanyStore();
    const { id: contactId, firstName, lastName, profilePicture: contactPicture } = useContactStore();

    return (
        <StyledImageName>
            {(activeTab === AccountSidebarTabs.CONTACT_SIDEBAR &&
                (contactPicture ? (
                    <img
                        key={contactPicture}
                        className="profile-img"
                        crossOrigin="anonymous"
                        src={`${process.env.REACT_APP_API_URL}/accounts/contact/picture/${contactId}`}
                        alt="profile"
                    />
                ) : firstName && lastName ? (
                    <CircleLetter
                        bgColor="var(--primary-500)"
                        letter={`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}
                    />
                ) : firstName ? (
                    <CircleLetter bgColor="var(--primary-500)" letter={firstName[0].toUpperCase()} />
                ) : null)) ||
                (logoUrl || profilePicture ? (
                    <img
                        className="profile-img"
                        crossOrigin="anonymous"
                        src={
                            profilePicture
                                ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${companyId}`
                                : logoUrl
                        }
                        alt="profile"
                    />
                ) : (
                    <img className="profile-img" crossOrigin="anonymous" src={CompanyIcon} alt="company icon" />
                ))}
            {activeTab === AccountSidebarTabs.CONTACT_SIDEBAR ? firstName + (lastName ? ' ' + lastName : '') : name}
        </StyledImageName>
    );
};

const StyledImageName = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .profile-img {
        border-radius: 50px;
        height: 33px;
        margin-right: 10px;
        width: 33px;
        object-fit: cover;
    }
`;

export default AccountsImageName;
