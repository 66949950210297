import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { AssociatedActionPlanType } from '@/types/workspace';
import CheckedIcon from '@Assets/icons/sharedView/checked.svg';
import UncheckedIcon from '@Assets/icons/sharedView/unchecked.svg';
import ArrowDropdownActive from '@Assets/icons/sharedView/arrow-drop-down-active.svg';
import ArrowDropdownInactive from '@Assets/icons/sharedView/arrow-drop-down-inactive.svg';
import { dispatchEvent, EventName } from '@Helpers/analytics';

interface Props {
    actionPlanData?: AssociatedActionPlanType | null;
    workspaceId: string | null;
    isProtected?: boolean;
}

const ActionPlanItem: FunctionComponent<Props> = ({ actionPlanData, workspaceId, isProtected }) => {
    const [isCollapsed, setCollapsed] = useState(true);

    const swToken = localStorage.getItem('sharedViewToken') ?? undefined;
    const token = localStorage.getItem('token') ?? undefined;

    return (
        <StyledContainers>
            {actionPlanData && (
                <>
                    <StyledActionPlanHeaderContainer
                        onClick={() => {
                            dispatchEvent(
                                EventName.SharedPageClick,
                                workspaceId,
                                { page: 'sharedView', isProtected },
                                swToken,
                                token,
                            );
                            setCollapsed(!isCollapsed);
                        }}
                        className={`${isCollapsed ? '' : 'shadow'}`}
                    >
                        <StyledDropdownContainer>
                            <StyledDropdownIcon src={isCollapsed ? ArrowDropdownInactive : ArrowDropdownActive} />
                        </StyledDropdownContainer>
                        <span className={`${isCollapsed ? '' : 'active-color'}`}>{actionPlanData.content.title}</span>
                    </StyledActionPlanHeaderContainer>
                    {!isCollapsed && (
                        <StyledItemsContainer>
                            {actionPlanData.content.items.map((item, index) => {
                                return (
                                    <StyledListItem key={index} className={`${item.checked ? '' : 'unchecked'}`}>
                                        {item.checked ? (
                                            <StyledCheckedIcon src={CheckedIcon} />
                                        ) : (
                                            <StyledCheckedIcon src={UncheckedIcon} />
                                        )}
                                        {item.label}
                                    </StyledListItem>
                                );
                            })}
                        </StyledItemsContainer>
                    )}
                </>
            )}
        </StyledContainers>
    );
};

const StyledContainers = styled.div`
    width: 100%;
    background: var(--white);
    border-radius: 8px;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
`;

const StyledActionPlanHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 12px 0;

    &.shadow {
        box-shadow: 0px 2px 0px rgba(216.75, 216.75, 216.75, 0.15);
        padding-bottom: 10px;
    }

    span {
        font-size: 18px;
        font-weight: 600;
        word-wrap: break-word;
        &.active-color {
            color: var(--black-60);
        }
        color: var(--black-75);
    }
`;

const StyledCheckedIcon = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 20px;
`;

const StyledItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    direction: rtl;
    align-items: center;
    padding-left: 24px;
    margin: 15px 5px 15px 5px;
}
`;

const StyledListItem = styled.span`
    margin: 8px 0;
    direction: ltr;
    align-self: end;
    display: flex;
    align-items: center;

    &.unchecked {
        color: var(--black-100);
    }
`;

const StyledDropdownContainer = styled.div`
    margin: 0 24px;
`;

const StyledDropdownIcon = styled.img`
    height: 28px;
    width: 28px;
`;

export default ActionPlanItem;
