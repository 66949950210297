import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Workspace from './workspace';
import Section from './section';
import './sharedView.scss';
import communication from '@Communication';
import {
    AssociatedActionPlanType,
    EmbedShelfItem,
    SectionType,
    ShelfItem,
    SidePanelContentType,
} from '@/types/workspace';
import useEditorStore from '@Store/editor';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import useLoading from '@Hooks/useLoading';
import { dispatchEvent, EventName } from '@Helpers/analytics';
import ActionPlanItem from './sidebar/actionPlan';
import SectionTitles from './sidebar/sectionTitles';
import PointsOfContact from './sidebar/PointsOfContact';
import HorizontalNavbar from './horizontalNavbar';
import useWindowSize from '../hooks/useResize';
import MobileSharedView from './mobileSharedView';
import Modal from '@Components/modal';
import lockIcon from '@Assets/icons/sharedView/lock.svg';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import WelcomeSection from './welcomeSection';
import SectionsSkeleton from './sectionsSkeleton';
import OverlayModal from './overlayModal';
import EmbedContent from './embedContent';
import { ObjectKeys } from '@/types/objectKeys';

const allowedMimetypes = [
    'text/plain',
    'text/plain; charset=UTF-8',
    'application/pdf',
    'video/mpeg',
    'video/mp4',
    'audio/mpeg',
];

const allowedImgtypes = ['image/png', 'image/jpeg', 'image/gif'];

const SharedView: FunctionComponent = () => {
    const { title, sections } = useEditorStore();
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);
    const { showLoading, hideLoading } = useLoading();
    const size = useWindowSize();

    const [activeTab, setActiveTab] = useState(0);
    const [infoRoomTitle, setInfoRoomTitle] = useState('');
    const [infoRoomSections, setInfoRoomSections] = useState<SectionType[] | null>(null);
    const [workspaceSections, setWorkspaceSections] = useState<SectionType[] | null>(null);
    const [isRestricted, setIsRestricted] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [wrongEmail, setWrongEmail] = useState(false);
    const [workspaceId, setWorkspaceId] = useState<string | null>();
    const [organizationName, setOrganizationName] = useState(null);
    const [organizationId, setOrganizationId] = useState<string | null>(null);
    const [organizationLogo, setOrganizationLogo] = useState<string | null>(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyLogo, setCompanyLogo] = useState<string | null>(null);
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [companyProfilePicture, setCompanyProfilePicture] = useState<string | null>(null);
    const [sidePanelContent, setSidePanelContent] = useState<SidePanelContentType | any>();
    const [actionPlanData, setActionPlanData] = useState<AssociatedActionPlanType | null>();
    const [missingContent, setMissingContent] = useState(false);

    const [token, setToken] = useState<string | undefined>(localStorage.getItem('token') ?? undefined);
    const [swToken, setSwToken] = useState<string | undefined>(localStorage.getItem('sharedViewToken') ?? undefined);
    const [isProtected, setIsProtected] = useState(false);
    const [scrollSectionId, setScrollSectionId] = useState<string | null>(null);
    const [middleSection, setMiddleSection] = useState('');
    const [welcomeVideo, setWelcomeVideo] = useState<string | null>(null);
    const [welcomeVideoUrl, setWelcomeVideoUrl] = useState<string | null>(null);

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const { addToast, removeAllToasts } = useToasts();
    const [showDownload, setShowDownload] = useState(false);
    const [overlayData, setOverlayData] = useState('');
    const [overlayTitle, setOverlayTitle] = useState('');
    const [imgPreview, setImgPreview] = useState(false);
    const [displayPreview, setDisplayPreview] = useState(false);
    const [fileId, setFileId] = useState('');
    const [overlayModal, setOverlayModal] = useState(false);
    const [embedContentModal, setEmbedContentModal] = useState(false);
    const [selectedItemData, setSelectedItemData] = useState<EmbedShelfItem | any>({});

    const { invite } = useParams();

    useEffect(() => {
        showLoading();
        const pin = urlParams.get('pin');
        setSwToken(localStorage.getItem('sharedViewToken') ?? undefined);
        setToken(localStorage.getItem('token') ?? undefined);
        setScrollSectionId(urlParams.get('scroll'));

        if (!invite) {
            setMissingContent(true);
            hideLoading();
        } else {
            communication
                .getSharedView(invite)
                .then((res: ObjectKeys) => {
                    if (res?.status === 200) {
                        setWorkspaceData(res);
                        hideLoading();
                    }
                })
                .catch(() => {
                    communication
                        .getProtectedSharedView(invite, token ?? undefined, swToken, pin)
                        .then((resp: ObjectKeys) => {
                            if (resp?.status === 200) {
                                setWorkspaceData(resp);
                                if (resp.data?.sharedViewToken) {
                                    localStorage.setItem('sharedViewToken', resp.data?.sharedViewToken);
                                }
                            } else if (resp.status === 500) {
                                setMissingContent(true);
                                hideLoading();
                            } else {
                                setIsRestricted(true);
                                hideLoading();
                            }
                        })
                        .catch((error: any) => {
                            if (error) {
                                setIsRestricted(true);
                                hideLoading();
                            }
                        });
                });
        }
    }, []);

    useEffect(() => {
        setWorkspaceSections(sections);
    }, [sections]);

    useEffect(() => {
        setScrollSectionId(urlParams.get('scroll'));
        setTimeout(() => {
            const sectionElement = document.getElementById(`section-${scrollSectionId}`);
            sectionElement?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }, 500);
    }, [scrollSectionId]);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        const sharedViewToken = localStorage.getItem('sharedViewToken');
        if (sharedViewToken) setSwToken(sharedViewToken);
    }, [swToken]);

    const sendEmail = (): void => {
        removeAllToasts();
        setIsLoading(true);
        const scroll = urlParams.get('scroll') ?? undefined;
        if (invite) {
            communication
                .sendSharedViewInvite(invite, email, scroll)
                .then((res: ObjectKeys) => {
                    if (res?.status === 200) {
                        setWrongEmail(false);
                        setIsLoading(false);
                        addToast('Email sent!. Link to this shared view has been sent to your email.', {
                            appearance: 'success',
                            autoDismiss: false,
                        });
                    }
                })
                .catch(() => {
                    setWrongEmail(true);
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (welcomeVideo) {
            communication.getWorkspaceWelcomeVideo(welcomeVideo).then((res: ObjectKeys) => {
                if (res?.status === 200) {
                    const type = res.headers['content-type'];
                    const blob = new Blob([res.data], {
                        type,
                    });
                    setWelcomeVideoUrl(URL.createObjectURL(blob));
                }
            });
        }
    }, [welcomeVideo]);

    const setWorkspaceData = (res: ObjectKeys): void => {
        setIsRestricted(false);
        useEditorStore.setState({
            sections: res.data?.workspace?.publishedContent?.sections,
            title: res.data?.workspace?.publishedContent?.title,
        });
        setWorkspaceId(res.data?.workspace?.id);
        setOrganizationName(res.data?.workspace?.Organizations?.name);
        if (res.data?.workspace?.Organizations?.logo) {
            setOrganizationLogo(res.data?.workspace?.Organizations?.logo);
        }
        if (res.data?.workspace?.Organizations?.id) {
            setOrganizationId(res.data?.workspace?.Organizations.id);
        }
        setCompanyName(res.data?.workspace?.company?.name);
        if (res.data?.workspace?.company?.id) {
            setCompanyId(res.data?.workspace?.company.id);
        }
        if (res.data?.workspace?.company?.profilePicture) {
            setCompanyProfilePicture(res.data?.workspace?.company?.profilePicture);
        }
        if (res.data?.workspace?.company?.logoUrl) {
            setCompanyLogo(res.data?.workspace?.company?.logoUrl);
        }
        setInfoRoomTitle(res.data?.workspace?.InfoRoom?.content?.title);
        setInfoRoomSections(res.data?.workspace?.InfoRoom?.content?.sections);
        setSidePanelContent(res.data?.workspace?.publishedContent?.sidePanelContent);
        setWelcomeVideo(res.data?.workspace?.publishedContent?.sidePanelContent?.welcomeVideo);
        setActionPlanData(res.data?.workspace?.actionPlan);
        setIsProtected(res.data?.restricted);
        hideLoading();
        dispatchEvent(
            EventName.SharedPageVisit,
            res.data?.workspace?.id,
            { page: 'sharedView', isProtected: res.data?.restricted },
            res.data?.sharedViewToken,
            token,
        );
    };

    const openOverlay = (shelfItem: ShelfItem): void => {
        showLoading();

        if (shelfItem.type === 'embed') {
            setEmbedContentModal(true);
            setSelectedItemData(shelfItem);
            hideLoading();
        } else if ('embedUrl' in shelfItem.content) {
            setOverlayModal(true);
            setShowDownload(true);
            setOverlayData(shelfItem.content.url);
            setFileId(shelfItem.id);
            if (allowedImgtypes.includes(shelfItem.content.mimeType)) {
                setImgPreview(true);
                hideLoading();
            } else {
                if (allowedMimetypes.includes(shelfItem.content.mimeType)) {
                    setImgPreview(false);
                    setDisplayPreview(true);
                    hideLoading();
                } else {
                    setImgPreview(false);
                    setDisplayPreview(false);
                    hideLoading();
                }
            }
        } else if ('link' in shelfItem.content) {
            setOverlayModal(true);
            communication.downloadFile(shelfItem.content.link).then((res: ObjectKeys) => {
                if (res?.status === 200) {
                    const type = res.headers['content-type'];
                    const blob = new Blob([res.data], {
                        type,
                    });
                    const objectURL = URL.createObjectURL(blob);
                    setOverlayData(objectURL);
                    setFileId(shelfItem.id);
                    setOverlayTitle(shelfItem.content.name);
                    if (allowedImgtypes.includes(type)) {
                        setImgPreview(true);
                    } else {
                        if (allowedMimetypes.includes(type)) {
                            setImgPreview(false);
                            setDisplayPreview(true);
                        } else {
                            setImgPreview(false);
                            setDisplayPreview(false);
                        }
                    }
                    setShowDownload(true);
                    hideLoading();
                }
                hideLoading();
            });
            hideLoading();
        } else {
            setOverlayData(shelfItem.content.url);
            setShowDownload(false);
            hideLoading();
        }
    };

    const openNewTab = (link: string): void => {
        window.open(link, '_blank', 'noreferrer');
        setOverlayModal(false);
    };

    const closeOverlay = (): void => {
        setOverlayModal(!overlayModal);
        setOverlayData('');
    };

    const getEmbedModalWidthByFType = (fileType: string): string => {
        if (
            fileType?.includes('google') ||
            fileType?.includes('typeform') ||
            fileType?.includes('airtable') ||
            fileType?.includes('figma')
        ) {
            return '50vw';
        } else {
            return '730px';
        }
    };

    return (
        <>
            <Modal
                className="custom-modal"
                modalVisible={overlayModal}
                closeModal={() => closeOverlay()}
                title={overlayTitle}
            >
                <OverlayModal
                    overlayData={overlayData}
                    imgPreview={imgPreview}
                    displayPreview={displayPreview}
                    showDownload={showDownload}
                    openNewTab={openNewTab}
                    fileId={fileId}
                    workspaceId={workspaceId}
                    swToken={swToken}
                    token={token}
                />
            </Modal>
            <Modal
                className="embed-content-modal"
                modalVisible={embedContentModal}
                size={getEmbedModalWidthByFType(selectedItemData?.content?.type)}
                closeModal={() => {
                    setEmbedContentModal(false);
                    setSelectedItemData({});
                }}
                title={selectedItemData ? selectedItemData?.content?.name : 'Embed content'}
            >
                <EmbedContent selectedItemData={selectedItemData} />
            </Modal>
            <div className="body public">
                {missingContent ? (
                    <StyledErrorContainer>
                        <h1>404 not found</h1>
                        <p>Something went wrong..</p>
                    </StyledErrorContainer>
                ) : (
                    <>
                        {isRestricted ? (
                            <Modal
                                size={500}
                                modalVisible={true}
                                closeModal={() => null}
                                title="Private Workspace"
                                closeIcon={lockIcon}
                            >
                                <StyledForm>
                                    <p>Please provide your email to confirm identity:</p>
                                    <StyledInput
                                        type={InputComponentType.Text}
                                        value={email}
                                        placeholder="Your email"
                                        size="100%"
                                        onChange={(v: string) => {
                                            setEmail(v);
                                            setWrongEmail(false);
                                        }}
                                        onEnter={() => {
                                            sendEmail();
                                        }}
                                        theme="light"
                                        errorMessage={
                                            wrongEmail ? 'This view is not accessible to the provided email' : ''
                                        }
                                    />
                                    <Button
                                        className="submit-button"
                                        theme={ButtonTheme.primary}
                                        onClick={() => {
                                            sendEmail();
                                        }}
                                        isLoading={isLoading}
                                    >
                                        Submit
                                    </Button>
                                </StyledForm>
                            </Modal>
                        ) : (
                            <>
                                {!isMobileWidth ? (
                                    <div className="shared-view-container">
                                        <HorizontalNavbar
                                            isProtected={isProtected}
                                            organizationLogo={organizationLogo}
                                            organizationId={organizationId}
                                            organizationName={organizationName}
                                            infoRoomSections={infoRoomSections}
                                            setActiveTab={setActiveTab}
                                            swToken={swToken}
                                            token={token}
                                            workspaceId={workspaceId ?? null}
                                            activeTab={activeTab}
                                        />

                                        <div className="shared-view-content">
                                            <StyledSidebarContainer>
                                                <div className="shared-view-sidebar">
                                                    {sections && (
                                                        <SectionTitles
                                                            sections={sections}
                                                            selectedTitle={middleSection}
                                                            setCollapsedMenu={() => null}
                                                            workspaceId={workspaceId ?? null}
                                                        />
                                                    )}
                                                    {actionPlanData && (
                                                        <ActionPlanItem
                                                            actionPlanData={actionPlanData}
                                                            workspaceId={workspaceId ?? null}
                                                        />
                                                    )}
                                                    {sidePanelContent?.pointsOfContact?.contacts && (
                                                        <PointsOfContact
                                                            contacts={sidePanelContent?.pointsOfContact?.contacts}
                                                            workspaceId={workspaceId ?? null}
                                                        />
                                                    )}
                                                </div>
                                            </StyledSidebarContainer>
                                            <StyledTabs index={activeTab}>
                                                <div className="tab-content">
                                                    <div>
                                                        <WelcomeSection
                                                            welcomeMessage={sidePanelContent?.welcomeMessage}
                                                            companyProfilePicture={companyProfilePicture}
                                                            companyLogo={companyLogo}
                                                            welcomeVideoUrl={welcomeVideoUrl}
                                                            welcomeVideo={welcomeVideo}
                                                            companyId={companyId}
                                                            companyName={companyName}
                                                        />
                                                        {workspaceSections ? (
                                                            workspaceSections?.length !== 0 ? (
                                                                <Workspace workspaceName={title}>
                                                                    {workspaceSections?.map((section) => {
                                                                        return (
                                                                            <Section
                                                                                id={`section-${section.id}`}
                                                                                key={section.id}
                                                                                sectionName={section.options.title}
                                                                                visible={section.options.visible}
                                                                                sectionItems={section.content.items}
                                                                                setMiddleSection={setMiddleSection}
                                                                                openOverlay={openOverlay}
                                                                                openNewTab={openNewTab}
                                                                                setOverlayData={setOverlayData}
                                                                                workspaceId={workspaceId ?? null}
                                                                            />
                                                                        );
                                                                    })}
                                                                </Workspace>
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : (
                                                            <SectionsSkeleton />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="tab-content">
                                                    <div>
                                                        {infoRoomSections ? (
                                                            <Workspace workspaceName={infoRoomTitle}>
                                                                {infoRoomSections.map((section) => {
                                                                    return (
                                                                        <Section
                                                                            id={`section-${section.id}`}
                                                                            key={section.id}
                                                                            sectionName={section.options.title}
                                                                            visible={section.options.visible}
                                                                            sectionItems={section.content.items}
                                                                            setMiddleSection={setMiddleSection}
                                                                            openOverlay={openOverlay}
                                                                            openNewTab={openNewTab}
                                                                            setOverlayData={setOverlayData}
                                                                            workspaceId={workspaceId ?? null}
                                                                        />
                                                                    );
                                                                })}
                                                            </Workspace>
                                                        ) : (
                                                            <SectionsSkeleton />
                                                        )}
                                                    </div>
                                                </div>
                                            </StyledTabs>
                                        </div>
                                    </div>
                                ) : (
                                    <MobileSharedView
                                        organizationId={organizationId}
                                        organizationLogo={organizationLogo}
                                        organizationName={organizationName}
                                        companyId={companyId}
                                        companyLogo={companyLogo}
                                        companyName={companyName}
                                        title={title}
                                        sections={sections}
                                        welcomeMessage={sidePanelContent?.welcomeMessage}
                                        welcomeVideoUrl={welcomeVideoUrl}
                                        welcomeVideo={welcomeVideo}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        inforoomSections={infoRoomSections}
                                        actionPlanData={actionPlanData}
                                        contacts={sidePanelContent?.pointsOfContact?.contacts}
                                        openOverlay={openOverlay}
                                        openNewTab={openNewTab}
                                        setOverlayData={setOverlayData}
                                        isProtected={isProtected}
                                        companyProfilePicture={companyProfilePicture}
                                        inforoomTitle={infoRoomTitle}
                                        swToken={swToken}
                                        token={token}
                                        workspaceId={workspaceId ?? null}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const StyledSidebarContainer = styled.div`
    //    position: relative;
`;
const StyledTabs = styled.div<{ index: number }>`
    width: 100%;
    margin-left: 330px;
    margin-top: 105px;

    .tab-content {
        visibility: hidden;
        height: 0;
        overflow: hidden;
        pointer-events: none;
    }

    .tab-content:nth-child(${(props) => props.index + 1}) {
        visibility: visible;
        height: auto;
        overflow: visible;
        pointer-events: all;
    }
`;

const StyledForm = styled.div`
    margin-top: 30px;
    p {
        color: var(--black-75);
    }

    .submit-button {
        margin-top: 20px;
    }
`;

const StyledInput = styled(Input)`
    margin: 10px 0;
`;

const StyledErrorContainer = styled.div`
    position: absolute;
    top: 45%;
    left: 45%;
`;

export default SharedView;
