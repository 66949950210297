import { FunctionComponent, ReactNode } from 'react';

interface Props {
    className?: string;
    id?: string;
    onClick?: Function;
    children: ReactNode;
}

const Section: FunctionComponent<Props> = ({
    id,
    className,
    onClick = () => {
        return;
    },
    children,
}) => {
    return (
        <div id={`${id ? id : ''}`} className={`${className ? className : ''}`} onClick={() => onClick()}>
            {children}
        </div>
    );
};

export default Section;
