export enum AccountSidebarTabs {
    CONTACT_SIDEBAR = 0,
    COMPANY_SIDEBAR = 1,
}

export enum ContactSidebarSections {
    CONTACT_MAIN = 0,
    CONTACT_DETAILS = 1,
    CONTACT_PROFILE_PICTURE = 2,
    CONTACT_ASSOCIATED_WORKSPACES = 3,
    CONTACT_ASSOCIATED_COMPANIES = 4,
}

export enum CompanySidebarSections {
    COMPANY_MAIN = 0,
    COMPANY_DETAILS = 1,
    COMPANY_LOGO = 2,
    COMPANY_ASSOCIATED_WORKSPACES = 3,
    COMPANY_ASSOCIATED_CONTACTS = 4,
}
