import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import CircleLetter from '@Components/circleLetter';
import Tooltip from '@Components/tooltip';
import CloseActive from '@Assets/icons/workspaces/shareModal/active/close.svg';
import CloseInactive from '@Assets/icons/workspaces/shareModal/inactive/close.svg';

interface Props {
    title: string;
    subtitle?: string;
    profileImage?: any;
    statusImage?: any;
    removeCallback?: Function;
    placeholderLogo?: any;
}

const Index: FunctionComponent<Props> = ({
    title,
    subtitle,
    profileImage,
    statusImage,
    removeCallback,
    placeholderLogo,
}) => {
    const [active, setActive] = useState(false);

    return (
        <StyledUserListCard
            className="user-list-card"
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <div className="user-info">
                {profileImage ? (
                    <div className="profile-circle">
                        <img src={profileImage} alt="profile" />{' '}
                    </div>
                ) : placeholderLogo ? (
                    <img className="profile-img" crossOrigin="anonymous" src={placeholderLogo} alt="icon" />
                ) : (
                    <CircleLetter bgColor="var(--primary-500)" letter={title?.charAt(0).toUpperCase()} />
                )}

                <div className="user-list-card-body">
                    <div className="email-on-list-card">{title}</div>
                    <div className="name-on-list-card">{subtitle ? `(${subtitle})` : ''}</div>
                </div>
            </div>
            <div className="img-set">
                {statusImage ? (
                    <Tooltip
                        tooltipText="An automatic notification email has been sent. "
                        width="115px"
                        tooltipPositionBottom
                        fontSize="12px"
                        className="sent-icon-tooltip"
                    >
                        <img className="sent" alt="sent" src={`${statusImage}`} />
                    </Tooltip>
                ) : null}
                {removeCallback ? (
                    <img
                        className="remove-icon clickable"
                        alt="close"
                        src={active ? CloseActive : CloseInactive}
                        onClick={() => removeCallback()}
                    />
                ) : null}
            </div>
        </StyledUserListCard>
    );
};

const StyledUserListCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 15px 18px;
    border-radius: 8px;
    margin: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1));
    }
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    .user-info {
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
    }
    .sent-icon-tooltip {
        position: static;
        .label {
            left: auto;
            right: 0;
            margin-top: 0;
            transform: none;
        }
    }
    .img-set {
        display: flex;

        img {
            width: 24px;
            height: 24px;

            &.sent {
                margin-right: 5px;
            }
        }
    }

    .user-list-card-body {
        display: flex;
        flex-direction: column;
        .email-on-list-card {
            font-size: 14px;
            color: var(--black-60);
            margin-bottom: 3px;
        }

        .name-on-list-card {
            font-size: 12px;
            color: var(--black-75);
        }
    }

    .profile-circle {
        border-radius: 50px;
        height: 28px;
        left: 624px;
        margin-right: 10px;
        padding-top: 8.5px;
        text-align: center;
        top: 445px;
        width: 28px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export default Index;
