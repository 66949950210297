/* eslint-disable import/extensions */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Public from './public';
import Private from './private';
import ForgotPassword from '@/pages/forgotPassword';
import Workspaces from '@/pages/workspaces';
import WorkspaceEditor from '@/pages/workspaces/editor';
import Register from '@/pages/register';
import UserProfile from '@/pages/userProfile';
import Accounts from '@/pages/accounts';
import Library from '@/pages/library';
import FileEditor from '@/pages/library/editor';
import InfoRooms from '@/pages/infoRooms';
import ActionPlans from '@/pages/actionPlans';
import NotFoundRoute from '@/pages/error';
import SharedView from '@/sharedView';
import ResetPassword from '@/pages/resetPassword';
import RegisterOrganization from '@/pages/registerOrganization';
import Confirm from '@/pages/confirm';
import InfoRoomEditor from '@/pages/infoRooms/editor';
import Users from '@/pages/users';
import OrganizationInfo from '@/pages/companyInfo';
import SubscriptionPlans from '@/pages/subscriptionPlans';
import WorkspaceSettings from '@/pages/workspaceSettings';
import Analytics from '@/pages/analytics';
import Templates from '@/pages/templates';
import TemplateEditor from '@/pages/templates/editor';
import TemplatesSettings from '@/pages/templateSettings';
import FilesSettings from '@/pages/filesSettings';
import Requests from '@/pages/requests';
import WorkspaceAnalytics from '../pages/analytics/workspaceAnalytics';
import Login from '../pages/login';
import DeleteAccount from '../pages/deleteAccount';
import TemplatePreview from '@/pages/templates/preview';

const content: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route element={<Public />}>
                <Route path="/:companyName/:invite" element={<SharedView />} />
                <Route path="/templates/preview/:templateId" element={<TemplatePreview />} />
                <Route path="/sign" element={<Register />} />
                <Route path="/register" element={<RegisterOrganization />} />
                <Route path="/confirm" element={<Confirm />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset" element={<ResetPassword />} />
                <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<Private />}>
                <Route path="/" element={<Workspaces />} />
                <Route path="/workspaces" element={<Workspaces />} />
                <Route path="/workspaces/:workspaceId" element={<WorkspaceEditor />} />
                <Route path="/workspaces/settings" element={<WorkspaceSettings />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/library" element={<Library />} />
                <Route path="/requests" element={<Requests />} />
                <Route path="/library/:fileId" element={<FileEditor />} />
                <Route path="/files/settings" element={<FilesSettings />} />
                <Route path="/inforooms" element={<InfoRooms />} />
                <Route path="/inforooms/:roomId" element={<InfoRoomEditor />} />
                <Route path="/templates" element={<Templates />} />
                <Route path="/templates/:templateId" element={<TemplateEditor />} />
                <Route path="/templates/settings" element={<TemplatesSettings />} />
                <Route path="/action-plans" element={<ActionPlans />} />
                <Route path="*" element={<NotFoundRoute />} />
            </Route>
            <Route element={<Private />}>
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/organization" element={<OrganizationInfo />} />
                <Route path="/user-management" element={<Users />} />
                <Route path="/subscription" element={<SubscriptionPlans />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/analytics/workspace/:workspaceId" element={<WorkspaceAnalytics />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
            </Route>
        </Routes>
    );
};

export default content;
