import { isValidUrl } from '@Helpers/regex';

export const fixUrl = (value: string): string | null => {
    if (isValidUrl(value)) {
        try {
            const fullUrl = new URL(value);
            if (fullUrl) return value;
        } catch (e) {
            return `https://${value}`;
        }
    }
    return null;
};
