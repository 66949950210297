const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/);
const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/\\_+=<>]).{8,}$/);
const urlRegex = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
);
const slugRegex = new RegExp(/^[a-z0-9]+$/);
const numberRegex = new RegExp(/^[0-9]+$/);

export const isValidEmail = (email: string): boolean => emailRegex.test(email);
export const isValidPassword = (pass: string): boolean => passwordRegex.test(pass);
export const isValidUrl = (url: string): boolean => urlRegex.test(url);
export const isValidSlug = (slug: string): boolean => slugRegex.test(slug);
export const isValidNumber = (num: string): boolean => numberRegex.test(num);
