import { useState, useEffect } from 'react';
import ContentLibrary from '@/pages/library/content';
import Button, { ButtonTheme } from '@Components/button';
import useWindowSize from '@Hooks/useResize';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';
import styled from 'styled-components';
import Modal from '@Components/modal';
import Input, { InputComponentType } from '@Components/input';
import UploadFile from '@Components/uploadFile';
import Communication from '@Communication';
import { FileRes } from '@/types/file';
import { useToasts } from 'react-toast-notifications';
import SubscriptionModal from '../subscriptionPlans/subscriptionModal';
import BlockOption from '@/components/editor/card';
import UploadIcon from '@Assets/icons/workspaces/upload.svg';
import LinkIcon from '@Assets/icons/workspaces/link.svg';
import { isValidUrl } from '@Helpers/regex';
import { fixUrl } from '@Helpers/url';
import Tooltip from '@/components/tooltip';

const Library: React.FunctionComponent = () => {
    const { addToast, removeAllToasts } = useToasts();
    const size = useWindowSize();

    const [isLoading, setIsLoading] = useState(false);

    const [file, setFile] = useState<File | null>(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [uploadModal, setUploadModal] = useState(false);
    const [globalFiles, setGlobalFiles] = useState<FileRes[] | null>(null);
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);
    const [subscriptionModalVisibility, setSubscriptionModalVisibility] = useState(false);
    const [shelfModal, setShelfModal] = useState(false);
    const [linkModal, setLinkModal] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    const uploadFile = (): void => {
        removeAllToasts();
        const fileFromStore = file;
        if (fileFromStore && name) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('files', fileFromStore);
            formData.append('name', name);
            if (description) {
                formData.append('description', description);
            }
            Communication.uploadFile(formData)
                .then((res: any) => {
                    const filesTmp = globalFiles && [...globalFiles];
                    setIsLoading(false);
                    setUploadModal(false);
                    setName('');
                    setDescription('');
                    filesTmp?.push(res.data);
                    setGlobalFiles(filesTmp);
                    setFile(null);
                })
                .catch((err: any) => {
                    setUploadModal(false);
                    setIsLoading(false);
                    setName('');
                    setDescription('');
                    setFile(null);
                    let errorText = 'Something went wrong';
                    switch (err.response.data?.code) {
                        case 3013:
                            errorText = 'File too large!';
                            break;
                        case 1014:
                            errorText = 'File with that name already exists!';
                            break;
                        case 1035:
                            setSubscriptionModalVisibility(true);
                            break;
                    }
                    err.response.data?.code === 1035
                        ? null
                        : addToast(errorText, {
                              appearance: 'error',
                          });
                });
        }
        return;
    };

    useEffect(() => {
        Communication.getAllFilesAndLinks().then((res: any) => {
            setGlobalFiles(res.data);
        });
    }, []);

    const addLink = async (): Promise<void> => {
        setShowValidationMessage(true);

        if (isValidUrl(url) && name !== '') {
            setIsLoading(true);
            const fullUrl = fixUrl(url) as string;
            const link = {
                name,
                url: fullUrl,
            };
            Communication.createLink(link)
                .then((res: any) => {
                    const filesTmp = globalFiles && [...globalFiles];
                    filesTmp?.push({ type: 'link', link: res.data.url, ...res.data });
                    setGlobalFiles(filesTmp);
                    setLinkModal(false);
                    setName('');
                    setUrl('');
                    setIsLoading(false);
                })
                .catch(() => {
                    setLinkModal(false);
                    setName('');
                    setUrl('');
                    setIsLoading(false);
                    addToast('Something went wrong', {
                        appearance: 'error',
                    });
                });
        }
    };

    return (
        <>
            <SubscriptionModal
                modalVisible={subscriptionModalVisibility}
                setModalVisible={setSubscriptionModalVisibility}
                closeModal={() => {
                    setSubscriptionModalVisibility(false);
                }}
            ></SubscriptionModal>
            <Modal modalVisible={shelfModal} closeModal={() => setShelfModal(false)} title="Add files">
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Tooltip tooltipText="Upload a file from your computer." width="157px">
                            <BlockOption
                                imageSrc={UploadIcon}
                                onClick={() => {
                                    setShelfModal(false);
                                    setUploadModal(true);
                                }}
                                title="Upload a file"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Save important links." width="157px">
                            <BlockOption
                                imageSrc={LinkIcon}
                                onClick={() => {
                                    setShelfModal(false);
                                    setLinkModal(true);
                                }}
                                title="External link"
                            />
                        </Tooltip>
                    </div>
                </StyledNewBlockModal>
            </Modal>
            <Modal
                modalVisible={uploadModal}
                size={500}
                closeModal={() => {
                    setUploadModal(false);
                    setFile(null);
                    setName('');
                    setDescription('');
                }}
                title="Upload a file"
            >
                <UploadWrapper>
                    <UploadFile file={file} isLoading={isLoading} setFile={setFile} />
                    <Input
                        type={InputComponentType.Text}
                        placeholder="Title"
                        onChange={(value: string) => setName(value)}
                        value={name}
                        size="100%"
                        onEnter={
                            name !== ''
                                ? uploadFile
                                : () => {
                                      return;
                                  }
                        }
                    ></Input>
                    <Button
                        theme={ButtonTheme.primary}
                        onClick={() => uploadFile()}
                        disabled={!file || !name}
                        isLoading={isLoading}
                        size={160}
                    >
                        Upload
                    </Button>
                </UploadWrapper>
            </Modal>
            <Modal
                modalVisible={linkModal}
                size={500}
                closeModal={() => {
                    setLinkModal(false);
                }}
                title="Add link"
            >
                <LinkWrapper>
                    <Input
                        className="link-input"
                        type={InputComponentType.Text}
                        placeholder="Title"
                        onChange={(value: string) => setName(value)}
                        value={name}
                        size="100%"
                        onEnter={addLink}
                    />
                    <Input
                        className="link-input"
                        type={InputComponentType.Url}
                        placeholder="URL"
                        onChange={(value: string) => setUrl(value)}
                        value={url}
                        size="100%"
                        onEnter={addLink}
                        errorMessage={
                            showValidationMessage
                                ? url === '' || isValidUrl(url)
                                    ? ''
                                    : 'Please provide valid URL address!'
                                : ''
                        }
                    />
                    <Button
                        theme={ButtonTheme.primary}
                        disabled={url === '' || name === ''}
                        isLoading={isLoading}
                        onClick={() => addLink()}
                        size={160}
                    >
                        Add
                    </Button>
                </LinkWrapper>
            </Modal>
            <StyledPage className="page library">
                <StyledAction className="top-bar">
                    <h3>Content Library</h3>
                    <Button
                        theme={ButtonTheme.primary}
                        size={isMobileWidth ? 120 : 200}
                        rightIcon={AddIcon}
                        onClick={() => {
                            setFile(null);
                            setShelfModal(true);
                        }}
                    >
                        {isMobileWidth ? 'New' : 'Add new'}
                    </Button>
                </StyledAction>
                <ContentLibrary
                    globalFiles={globalFiles}
                    preview={true}
                    setUploadModal={setUploadModal}
                    onDelete={() => {
                        Communication.getAllFiles().then((res: any) => {
                            setGlobalFiles(res.data);
                        });
                    }}
                ></ContentLibrary>
            </StyledPage>
        </>
    );
};

const StyledPage = styled.div`
    max-width: 1600px;
`;

const StyledAction = styled.div`
    display: flex;
    justify-content: space-between;
`;

const UploadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        margin-bottom: 36px;
        width: 100%;
        background-color: white;
    }

    button {
        margin-right: auto;
    }
`;

export const StyledNewBlockModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &.new-block {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .link-input {
        margin-bottom: 18px;

        input {
            background-color: white;
        }
    }

    .link-input:nth-child(2) {
        margin-bottom: 36px;
    }

    .button {
        margin-right: auto;
    }
`;

export default Library;
