import React from 'react';
import { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { AssociatedWorkspacesPayloadDto } from '@Global/dtos/workspace.dto';
import { AssociatedContactPayloadDto, ContactDto } from '@Global/dtos/contact.dto';
import { AssociatedCompanyPayloadDto, CompanyDto } from '@Global/dtos/company.dto';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';
import communication from '@/communication';
import Communication from '@/communication';
import { fixUrl } from '@Helpers/url';
import { isValidEmail, isValidUrl } from '@Helpers/regex';
import useDialogue from '@Hooks/useDialogue';
import useWindowSize from '@Hooks/useResize';
import { StyledSearch } from '@/pages/workspaces';
import Button, { ButtonTheme } from '@Components/button';
import Modal from '@Components/modal';
import Input, { InputComponentIconPosition, InputComponentType, InputComponentTheme } from '@Components/input';
import { Table, TableRow } from '@Components/table';
import Tooltip from '@Components/tooltip';
import SelectComponent, { SelectOptionType, SelectComponentTheme } from '@Components/select';
import SearchIcon from '@Assets/icons/search-normal.svg';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';
import CloseIcon from '@Assets/icons/close.svg';
import AccountsSidebarFrame from './sidebar/frame';
import InfoIcon from '@Assets/icons/settings/profile/info.svg';
import './account.scss';
import AllCompanies from './allCompanies';
import AllContacts from './allContacts';

const Accounts: React.FunctionComponent = () => {
    const { allCompanies } = useCompanyStore();
    const { allContacts } = useContactStore();
    const size = useWindowSize();
    const {
        name,
        email,
        website,
        employeeNumber,
        hq,
        linkedin,
        profilePicture,
        logoUrl,
        Workspaces: companyWorkspaces,
        CompanyContacts: associatedContacts,
        setCompanyData,
        reset: resetCompany,
    } = useCompanyStore();
    const {
        email: contactEmail,
        firstName,
        lastName,
        linkedin: contactLinkedin,
        profilePicture: contactProfilePicture,
        Workspaces: contactWorkspaces,
        CompanyContacts: associatedCompanies,
        setContactData,
        reset: resetContact,
    } = useContactStore();
    const { setDialogue, closeDialogue } = useDialogue();
    const { addToast } = useToasts();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);
    const [activeTab, setActiveTab] = useState(1);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [activeSection, setActiveSection] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [contactId, setContactId] = useState('');

    const [file, setFile] = useState<File | null>(null);
    const [contactModal, setContactModal] = useState(false);
    const [companyModal, setCompanyModal] = useState(false);
    const [newCompany, setNewCompany] = useState(true);
    const [newContact, setNewContact] = useState(true);
    const [isExpandedMenu, setIsExpandedMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [websiteLoading, setWebsiteLoading] = useState(false);
    const [updateAfterChanging, setUpdateAfterChanging] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const [companySuggestions, setCompanySuggestions] = useState<SelectOptionType[]>([]);
    const [companies, setCompanies] = useState<CompanyDto[] | null>(null);
    const [contacts, setContacts] = useState<ContactDto[] | null>(null);

    useEffect(() => {
        setCompanies(allCompanies);
        setContacts(allContacts);
    }, []);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    useEffect(() => {
        communication.getAllContacts().then((res: any) => {
            useContactStore.setState({ allContacts: res?.data });
            setContacts(res?.data);
        });
        communication.getAllCompanies().then((res: any) => {
            useCompanyStore.setState({ allCompanies: res?.data });
            setCompanies(res?.data);
        });
    }, [
        file,
        profilePicture,
        contactProfilePicture,
        companyWorkspaces,
        contactWorkspaces,
        associatedContacts,
        associatedCompanies,
        updateAfterChanging,
    ]);

    useEffect(() => {
        if (name === '') {
            setIsDisabled(true);
        } else if (website !== '') {
            setIsDisabled(!isValidUrl(website));
        } else {
            setIsDisabled(false);
        }
    }, [website, name]);

    const closeSidebar = (): void => {
        setIsExpandedMenu(false);
        setActiveSection(0);
        setSelectedRow(-1);
    };

    const openCompany = (compId: string, id: number): void => {
        if (id !== undefined) {
            setSelectedRow(id);
        }
        setActiveTab(1);
        setCompanyId(compId);
        setNewCompany(false);
        setIsExpandedMenu(true);
        const tempCompany = allCompanies?.find((company) => company.id === compId);
        if (tempCompany) {
            setCompanyData(tempCompany);
        }
    };

    const openCreateCompany = (): void => {
        setNewCompany(true);
        resetCompany();
        setCompanySuggestions([]);
        setCompanyModal(true);
        setIsExpandedMenu(false);
        setSelectedRow(-1);
    };

    const openContact = (contId: string, id: number): void => {
        if (id !== undefined) {
            setSelectedRow(id);
        }
        setActiveTab(0);
        setContactId(contId);
        setIsExpandedMenu(true);
        setNewContact(false);

        const tempContact = allContacts?.find((contact) => contact.id === contId);
        if (tempContact) {
            setContactData(tempContact);
        }
    };

    const openCreateContact = (): void => {
        setNewContact(true);
        resetContact();
        setContactModal(true);
        setIsExpandedMenu(false);
        setSelectedRow(-1);
    };

    const tabClick = (change: number): void => {
        if (activeTab !== change) {
            setIsExpandedMenu(false);
            setSelectedRow(-1);
            setActiveSection(0);
        }
        setActiveTab(change);
    };

    const sendCompanyData = async (): Promise<any> => {
        setLoading(true);
        if (newCompany) {
            await communication
                .createCompany({
                    name,
                    website: fixUrl(website) as string,
                    logoUrl,
                })
                .then(() => {
                    setLoading(false);
                    setCompanyModal(false);
                    addToast('Company successfully created', {
                        appearance: 'success',
                    });
                    setUpdateAfterChanging(!updateAfterChanging);
                    resetCompany();
                    setCompanySuggestions([]);
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (error) {
                        if (JSON.parse(error.request.response).code === 1024) {
                            addToast('Company with same name already exists', {
                                appearance: 'error',
                            });
                            setCompanyData({ name: '' });
                        } else if (JSON.parse(error.request.response).code === 1025) {
                            addToast('Company with same website already exists', {
                                appearance: 'error',
                            });
                            setCompanyData({ website: '' });
                        } else {
                            addToast('Something went wrong! Please try again.', {
                                appearance: 'error',
                            });
                            setCompanyModal(false);
                            resetCompany();
                            setCompanySuggestions([]);
                        }
                    }
                });
        } else {
            const companyData = allCompanies.find((company) => company.id === companyId);
            const companyObj = {
                ...(name !== companyData?.name && { name }),
                email,
                ...(website !== companyData?.website && { website }),
                employeeNumber,
                hq,
                linkedin,
            };
            await communication
                .updateCompany(companyId, companyObj)
                .then(() => {
                    setLoading(false);
                    setCompanyModal(false);
                    addToast('Company successfully updated', {
                        appearance: 'success',
                    });
                    setUpdateAfterChanging(!updateAfterChanging);
                    setCompanySuggestions([]);
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (JSON.parse(error.request.response).code === 1024) {
                        addToast('Company with same name already exists', {
                            appearance: 'error',
                        });
                        setCompanyData({ name: '' });
                        setCompanySuggestions([]);
                    } else if (JSON.parse(error.request.response).code === 1025) {
                        addToast('Company with same website already exists', {
                            appearance: 'error',
                        });
                        setCompanyData({ website: '' });
                        setCompanySuggestions([]);
                    } else {
                        addToast('Something went wrong! Please try again.', {
                            appearance: 'error',
                        });
                        setCompanyModal(false);
                        resetCompany();
                        setCompanySuggestions([]);
                    }
                });
        }
    };

    const sendContactData = async (): Promise<any> => {
        setLoading(true);
        if (newContact) {
            setShowValidationMessage(true);
            if (isValidEmail(contactEmail) && contactEmail !== '' && firstName !== '') {
                await communication
                    .createContact({
                        email: contactEmail,
                        firstName,
                        lastName,
                        linkedin: contactLinkedin,
                    })
                    .then(() => {
                        setLoading(false);
                        setContactModal(false);
                        addToast('Contact successfully created', {
                            appearance: 'success',
                        });
                        setUpdateAfterChanging(!updateAfterChanging);
                        resetContact();
                        setShowValidationMessage(false);
                    })
                    .catch((error: any) => {
                        setLoading(false);
                        if (error.request.status === 409) {
                            setContactData({ email: '' });
                            addToast('Contact with same email address already exist', {
                                appearance: 'error',
                            });
                            setShowValidationMessage(false);
                        } else if (error.request.status === 400) {
                            setContactModal(false);
                            resetContact();
                            addToast('Something went wrong! Please try again.', {
                                appearance: 'error',
                            });
                            setShowValidationMessage(false);
                        }
                    });
            }
            setLoading(false);
        } else {
            const contactData = allContacts.find((contact) => contact.id === contactId);
            const contactObj = {
                ...(contactEmail !== contactData?.email && { email: contactEmail }),
                firstName,
                lastName,
                linkedin: contactLinkedin,
            };
            await communication
                .updateContact(contactId, contactObj)
                .then(() => {
                    setLoading(false);
                    addToast('Contact successfully updated', {
                        appearance: 'success',
                    });
                    setContactModal(false);
                    setUpdateAfterChanging(!updateAfterChanging);
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (error.request.status === 409) {
                        setContactData({ email: '' });
                        addToast('Contact with same email address already exist', {
                            appearance: 'error',
                        });
                    } else if (error.request.status === 400) {
                        setContactModal(false);
                        resetContact();
                        addToast('Something went wrong! Please try again.', {
                            appearance: 'error',
                        });
                    }
                });
        }
    };

    const updateCompanyPicture = (image: File): void => {
        setLoading(true);
        const formData = new FormData();
        formData.append('files', image);
        communication
            .uploadCompanyProfilePicture(companyId, formData)
            .then((res: any) => {
                setLoading(false);
                useCompanyStore.setState({ profilePicture: res.data?.profilePicture });
                setFile(null);
            })
            .catch((err: Error) => {
                setLoading(false);
                setFile(null);
                console.error(err);
            });

        return;
    };

    const removeCompanyPicture = (): void => {
        setLoading(true);
        communication
            .removeCompanyProfilePicture(companyId)
            .then(() => {
                setLoading(false);
                useCompanyStore.setState({ profilePicture: '' });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    const updateContactPicture = (image: File): void => {
        setLoading(true);
        const formData = new FormData();
        formData.append('files', image);
        communication
            .uploadContactProfilePicture(contactId, formData)
            .then((res: any) => {
                setLoading(false);
                useContactStore.setState({ profilePicture: res.data?.profilePicture });
                setFile(null);
            })
            .catch((err: Error) => {
                setLoading(false);
                setFile(null);
                console.error(err);
            });
    };

    const removeContactPicture = (): void => {
        setLoading(true);
        communication
            .removeContactProfilePicture(contactId)
            .then(() => {
                setLoading(false);
                useContactStore.setState({ profilePicture: '' });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    const deleteContact = async (id?: string): Promise<any> => {
        setLoading(true);
        setDialogue({
            description: 'Contact will be deleted. This is irreversible operation.',
            buttons: [
                {
                    theme: ButtonTheme.danger,
                    text: 'Delete',
                    callback: () => {
                        communication
                            .deleteContact(id ? id : contactId)
                            .then(() => {
                                setLoading(false);
                                setIsExpandedMenu(false);
                                addToast('Contact deleted!', { appearance: 'success' });
                                setUpdateAfterChanging(!updateAfterChanging);
                            })
                            .catch(() => {
                                setLoading(false);
                                addToast('Contact can not be deleted at this point!', {
                                    appearance: 'error',
                                });
                            });
                        closeDialogue();
                    },
                },
                {
                    theme: ButtonTheme.naked,
                    text: 'Cancel',
                    callback: () => {
                        closeDialogue();
                    },
                },
            ],
        });
    };

    const deleteCompany = async (id?: string): Promise<any> => {
        setLoading(true);
        setDialogue({
            description: 'Company will be deleted. This is irreversible operation.',
            buttons: [
                {
                    theme: ButtonTheme.danger,
                    text: 'Delete',
                    callback: () => {
                        communication
                            .deleteCompany(id ? id : companyId)
                            .then(() => {
                                setLoading(false);
                                setIsExpandedMenu(false);
                                addToast('Company deleted!', { appearance: 'success' });
                                setUpdateAfterChanging(!updateAfterChanging);
                            })
                            .catch(() => {
                                setLoading(false);
                                addToast('Company can not be deleted at this point!', {
                                    appearance: 'error',
                                });
                            });
                        closeDialogue();
                    },
                },
                {
                    theme: ButtonTheme.naked,
                    text: 'Cancel',
                    callback: () => {
                        closeDialogue();
                    },
                },
            ],
        });
    };

    const updateAssociatedContactWorkspacesMain = (
        currentContact: string,
        data: AssociatedWorkspacesPayloadDto,
    ): void => {
        setLoading(true);
        communication
            .updateAssociatedContactWorkspaces(currentContact, data)
            .then((res: any) => {
                setLoading(false);
                useContactStore.setState({ Workspaces: res?.data });
                addToast('Contact successfully updated', {
                    appearance: 'success',
                });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    const updateAssociatedContacts = (currentCompany: string, data: AssociatedContactPayloadDto): void => {
        setLoading(true);
        communication
            .updateAssociatedContact(currentCompany, data)
            .then((res: any) => {
                setLoading(false);
                useContactStore.setState({ CompanyContacts: res?.data.CompanyContacts });
                addToast('Contact successfully updated', {
                    appearance: 'success',
                });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    const updateAssociatedCompanies = (currentContact: string, data: AssociatedCompanyPayloadDto): void => {
        setLoading(true);
        communication
            .updateAssociatedCompany(currentContact, data)
            .then((res: any) => {
                setLoading(false);
                useCompanyStore.setState({ CompanyContacts: res?.data.CompanyContacts });
                addToast('Company successfully updated', {
                    appearance: 'success',
                });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    const updateAssociatedCompanyWorkspacesMain = (
        currentCompany: string,
        data: AssociatedWorkspacesPayloadDto,
    ): void => {
        setLoading(true);
        communication
            .updateAssociatedCompanyWorkspaces(currentCompany, data)
            .then((res: any) => {
                setLoading(false);
                useCompanyStore.setState({ Workspaces: res?.data });
                addToast('Company successfully updated', {
                    appearance: 'success',
                });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    const getAddMoreOrEmpty = (arrayInfo: Array<Object>, arrayName: string | undefined): string => {
        const textToReturn =
            arrayInfo.length > 1
                ? `${arrayName} + ${arrayInfo.length - 1} more`
                : arrayInfo.length === 0
                ? ''
                : arrayInfo.length === 1
                ? `${arrayName}`
                : '';
        return textToReturn;
    };

    return (
        <>
            <Modal
                modalVisible={companyModal}
                closeModal={() => {
                    setCompanyModal(!companyModal);
                    resetCompany();
                    setCompanySuggestions([]);
                }}
                className="accounts-modal"
                size={572}
                title={newCompany ? 'Create new company' : 'Update company details'}
            >
                <Input
                    type={InputComponentType.Text}
                    theme={InputComponentTheme.Light}
                    placeholder="Name"
                    value={name}
                    label="Company name"
                    size="100%"
                    onChange={(value: string) => setCompanyData({ name: value })}
                />
                <StyledWebsiteLabel>
                    <div className="with-tooltip">
                        <div className="label-wrapper">Company website</div>
                        <Tooltip
                            tooltipText={
                                'Add a website manually or start typing a company name to automatically find the website and logo (for example, type "apple" instead of "https://www.apple.com/").'
                            }
                            width="260px"
                            tooltipPositionBottom
                        >
                            <img alt="info" src={InfoIcon} />
                        </Tooltip>
                    </div>
                </StyledWebsiteLabel>
                <SelectComponent
                    theme={SelectComponentTheme.Light}
                    // type={InputComponentType.Text}
                    placeholder="website"
                    // value={website}
                    // errorMessage={isValidUrl(website) || website === '' ? '' : 'Please provide valid website!'}
                    // label="Company website"
                    isClearable={false}
                    isMulti={false}
                    value={website ? { label: website, value: website } : null}
                    isLoading={websiteLoading}
                    onSearch={(value: string) => {
                        if (value !== '') {
                            setWebsiteLoading(true);
                            setCompanyData({ logoUrl: undefined });
                            Communication.getCompanyNameSuggestions(value)
                                .then((res: any) => {
                                    setCompanySuggestions(
                                        res.data.map((c: any) => ({ value: c.domain, label: c.domain, logo: c.logo })),
                                    );
                                    setWebsiteLoading(false);
                                })
                                .catch(() => {
                                    setWebsiteLoading(false);
                                });
                        }
                    }}
                    onChange={(selectedOption) => {
                        setCompanyData({
                            website: (selectedOption as SelectOptionType).value,
                            logoUrl: (selectedOption as SelectOptionType).logo,
                        });
                    }}
                    onCreate={(value) => {
                        setCompanyData({ website: value });
                    }}
                    noOptionsText="No auto suggestions"
                    optionList={companySuggestions}
                />
                {logoUrl ? (
                    <StyledModelLogoContainer>
                        <div className="company-logo-image-wrapper">
                            <img className="logo-image" src={logoUrl} alt="logo" />
                            <img
                                className="close-icon"
                                src={CloseIcon}
                                alt="icon"
                                onClick={() => setCompanyData({ logoUrl: undefined })}
                            />
                        </div>
                        <StyledLogoHelpMessage>
                            <StyledLogoHelpMessageMain>
                                We managed to find this logo. Feel free to remove it now or change it in company
                                settings anytime.
                            </StyledLogoHelpMessageMain>
                            <StyledLogoHelpMessageClearbit>Logo powered by Clearbit.</StyledLogoHelpMessageClearbit>
                        </StyledLogoHelpMessage>
                    </StyledModelLogoContainer>
                ) : null}

                <TwoButtonsStyle>
                    <Button
                        className="accounts-modal-button"
                        theme={ButtonTheme.primary}
                        disabled={isDisabled}
                        onClick={sendCompanyData}
                        isLoading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            setCompanyModal(!companyModal);
                            resetCompany();
                            setCompanySuggestions([]);
                        }}
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            </Modal>

            <Modal
                modalVisible={contactModal}
                closeModal={() => {
                    setContactModal(!contactModal);
                    resetContact();
                }}
                size={400}
                className="accounts-modal"
                title={newContact ? 'Create new contact' : 'Update contact details'}
            >
                <Input
                    type={InputComponentType.Email}
                    theme={InputComponentTheme.Light}
                    placeholder="Email"
                    value={contactEmail}
                    label="Email"
                    size="100%"
                    errorMessage={
                        showValidationMessage
                            ? isValidEmail(contactEmail) || contactEmail === ''
                                ? ''
                                : 'Please provide valid email!'
                            : ''
                    }
                    onChange={(value: string) => setContactData({ email: value })}
                />
                <Input
                    type={InputComponentType.Text}
                    theme={InputComponentTheme.Light}
                    placeholder="First name"
                    value={firstName}
                    label="First name"
                    size="100%"
                    onChange={(value: string) => setContactData({ firstName: value })}
                />
                <Input
                    type={InputComponentType.Text}
                    theme={InputComponentTheme.Light}
                    placeholder="Last name"
                    value={lastName}
                    label="Last name"
                    size="100%"
                    onChange={(value: string) => setContactData({ lastName: value })}
                />
                <TwoButtonsStyle>
                    <Button
                        className="accounts-modal-button"
                        theme={ButtonTheme.primary}
                        disabled={contactEmail === '' || firstName === undefined || firstName === ''}
                        onClick={sendContactData}
                        isLoading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            setContactModal(!contactModal);
                            resetContact();
                        }}
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            </Modal>

            <AccountsSidebarFrame
                activeTab={activeTab}
                sidebarVisible={isExpandedMenu}
                closeSidebar={closeSidebar}
                sendCompanyData={sendCompanyData}
                sendContactData={sendContactData}
                deleteContact={deleteContact}
                deleteCompany={deleteCompany}
                updateCompanyPicture={updateCompanyPicture}
                removeCompanyPicture={removeCompanyPicture}
                updateContactPicture={updateContactPicture}
                removeContactPicture={removeContactPicture}
                loading={loading}
                setLoading={setLoading}
                file={file}
                setFile={setFile}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                updateAssociatedContactWorkspaces={updateAssociatedContactWorkspacesMain}
                updateAssociatedCompanyWorkspaces={updateAssociatedCompanyWorkspacesMain}
                updateConnectedContacts={updateAssociatedContacts}
                updateConnectedCompanies={updateAssociatedCompanies}
            ></AccountsSidebarFrame>

            <div className={`page accounts ${isExpandedMenu ? 'expanded' : ''}`.trim()}>
                <div className="top-bar">
                    <h3>Accounts</h3>
                    <div className="accounts-action">
                        <StyledSearch
                            placeholder="Search accounts"
                            type={InputComponentType.Text}
                            theme={InputComponentTheme.Light}
                            value={searchValue}
                            icon={SearchIcon}
                            size={366}
                            iconPosition={InputComponentIconPosition.Right}
                            onChange={(value: string) => setSearchValue(value)}
                        ></StyledSearch>
                        <Button
                            className="clickable"
                            theme={ButtonTheme.primary}
                            rightIcon={AddIcon}
                            onClick={() => {
                                activeTab === 0 ? openCreateContact() : openCreateCompany();
                            }}
                        >
                            {isMobileWidth ? 'New' : `New ${activeTab === 0 ? 'contact' : 'company'}`}
                        </Button>
                    </div>
                </div>

                <div className="tabs">
                    <StyledTabsHeader
                        className={`tab ${activeTab === 1 ? 'active' : ''}`}
                        onClick={() => {
                            tabClick(1);
                        }}
                    >
                        <p>Companies</p>
                    </StyledTabsHeader>
                    <StyledTabsHeader
                        className={`tab ${activeTab === 0 ? 'active' : ''}`}
                        onClick={() => {
                            tabClick(0);
                        }}
                    >
                        <p>Contacts</p>
                    </StyledTabsHeader>
                </div>
                <StyledTabs className="tab-content-wrapper">
                    <div className={`tab-content ${activeTab === 0 ? 'activeTab' : ''}`}>
                        <div className="all-accounts-page">
                            <Table style={{ borderRadius: '0 4px 4px 4px' }}>
                                <AllContacts
                                    allContacts={contacts}
                                    openContact={openContact}
                                    setContactModal={setContactModal}
                                    getAddMoreOrEmpty={getAddMoreOrEmpty}
                                    searchValue={searchValue}
                                    selectedRow={selectedRow}
                                    deleteContact={deleteContact}
                                />
                            </Table>
                        </div>
                    </div>
                </StyledTabs>
                <StyledTabs className="tab-content-wrapper">
                    <div className={`tab-content ${activeTab === 1 ? 'activeTab' : ''}`}>
                        <div className="all-accounts-page">
                            <Table style={{ borderRadius: '0 4px 4px 4px' }}>
                                <AllCompanies
                                    allCompanies={companies}
                                    openCompany={openCompany}
                                    setCompanyModal={setCompanyModal}
                                    getAddMoreOrEmpty={getAddMoreOrEmpty}
                                    searchValue={searchValue}
                                    selectedRow={selectedRow}
                                    deleteCompany={deleteCompany}
                                />
                            </Table>
                        </div>
                    </div>
                </StyledTabs>
            </div>
        </>
    );
};

export const StyledTabsHeader = styled.div`
    padding: 9px 20px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    border-bottom: none;
    user-select: none;
    font-weight: 600;
    background-color: #cfcfd7;
    color: var(--white);
    font-size: 16px;

    &.active {
        color: var(--black-75);
        background-color: white;
    }

    &:first-of-type {
        border-radius: 8px 8px 0px 0px;
    }
    &:last-of-type {
        border-radius: 0px 8px 0px 0px;
    }
`;

export const StyledTabs = styled.div`
    &.tab-content-wrapper {
        margin: auto;
        max-width: 1600px;
        display: flex;
    }
    .tab-content {
        visibility: hidden;
        height: 0;
        overflow: hidden;
        pointer-events: none;
        width: 100%;

        &.activeTab {
            visibility: visible;
            height: auto;
            overflow: visible;
            pointer-events: all;
        }
    }
`;

export const StyledTableItem = styled(TableRow)`
    box-sizing: border-box;
    transition: all ease 0.5s;

    &:hover {
        box-shadow: 0px 0px 6px rgba(0, 114, 109, 0.1);
        cursor: pointer;
    }

    .profile-img {
        border-radius: 50px;
        height: 33px;
        margin-right: 10px;
        width: 33px;
        object-fit: cover;
    }

    .associated-workspaces {
        display: inline-block;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        align-items: center;
        display: flex;
        font-size: 12px;
        color: var(--black-60);
        img {
            max-width: 33px;
            max-height: 33px;
            object-fit: cover;

            &.arrow-rotate-true {
                transform: rotate(180deg);
            }
        }
    }
`;

export const TwoButtonsStyle = styled.div`
    margin: 20px 0;
    display: flex;
    gap: 12px;
    .button {
        min-width: 160px;
    }
    /* Media Queries */
    @media only screen and (max-width: 600px) {
        .button {
            width: 100%;
            min-width: auto;
        }
    }
`;

const StyledModelLogoContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

const StyledLogoHelpMessage = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledLogoHelpMessageMain = styled.span`
    color: var(--black-60);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    word-wrap: break-word;
    margin-bottom: 6px;
`;

const StyledLogoHelpMessageClearbit = styled.span`
    color: var(--black-75);
    font-size: 10px;
    font-weight: 400;
    word-wrap: break-word;
`;

export const StyledWebsiteLabel = styled.div`
    .with-tooltip {
        display: flex;
        gap: 3px;
        align-items: center;
        .label-wrapper {
            color: var(--black-75);
            font-size: 12px;
            padding: 8px 0;
        }
        img {
            padding-top: 3px;
        }
    }
`;

export default Accounts;
