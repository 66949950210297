import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    title?: string;
    number?: number;
    icon?: any;
}
const NumberCard: FunctionComponent<Props> = ({ title, number, icon }) => {
    return (
        <StyledNumberCard>
            <h4>{title}</h4>
            <div>
                <h3>{number ? number : 0}</h3>
                <img src={icon} alt="icon" />
            </div>
        </StyledNumberCard>
    );
};
const StyledNumberCard = styled.div`
    border-radius: 8px;
    background: var(--white);
    box-shadow: 0px 1px 6px 0px rgba(217, 217, 217, 0.15);
    h4 {
        padding: 17px 24px;
        border-bottom: 1px solid var(--black-20);
        font-size: 18px;
        /* Media Queries */
        @media only screen and (max-width: 600px) {
            padding: 15px;
        }
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 24px;
        gap: 5px;
        h3 {
            font-size: 53px;
            line-height: 60px;
            letter-spacing: -1.06px;
            word-break: break-all;
            /* Media Queries */
            @media only screen and (max-width: 600px) {
                font-size: 30px;
                line-height: 30px;
            }
        }
        /* Media Queries */
        @media only screen and (max-width: 600px) {
            padding: 15px;
            img {
                width: 50px;
            }
        }
    }
`;
export default NumberCard;
