import { FunctionComponent, useEffect } from 'react';
import HorizontalBar from '@Components/navbar/horizontal';
import VerticalNavbar from '@Components/navbar/vertical';
import { mainNavbarData, settingsNavbarData } from '@Components/navbar/data';
import useGlobalState from '@Store/global';
import { useLocation } from 'react-router-dom';

const Navbar: FunctionComponent = () => {
    const { getIsSettingsNavbar } = useGlobalState();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/')[1];
        useGlobalState.setState({ activeNavbarLink: `/${path}` });
    }, []);

    return (
        <div>
            <HorizontalBar />
            {getIsSettingsNavbar() ? (
                <VerticalNavbar
                    navbarData={settingsNavbarData}
                    backCallback={() => {
                        useGlobalState.setState({ activeNavbarLink: '/' });
                    }}
                />
            ) : (
                <VerticalNavbar navbarData={mainNavbarData} />
            )}
        </div>
    );
};

export default Navbar;
