import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useGlobalState from '@Store/global';
import useWorkspaceStore from '@Store/workspace';
import CloseIcon from '@Assets/icons/close.svg';
import ArrowRight from '@Assets/icons/settings/arrow-right.svg';
import ManageSearch from '@Assets/icons/settings/manage-search.svg';
import Info from '@Assets/icons/settings/info.svg';
import Phone from '@Assets/icons/settings/phone.svg';
import FactCheck from '@Assets/icons/settings/fact-check.svg';
import Chat from '@Assets/icons/settings/chat.svg';
import { MainWrapper } from '../styledComponents';
import AnalyticsInfo from '../analyticsInfo';
import WorkspaceInfo from '../workspaceInfo';
import WorkspaceActions from './workspaceActions';
interface Props {
    isActive: boolean;
    changePage: Function;
}

const Settings: FunctionComponent<Props> = ({ isActive, changePage }) => {
    const { workspaceId } = useParams();
    const { name } = useWorkspaceStore();
    const { workspaceContextMenu } = useGlobalState();

    const settingsItems = [
        {
            title: 'Details',
            icon: ManageSearch,
        },
        {
            title: 'Info room',
            icon: Info,
        },
        {
            title: 'Points of contact',
            icon: Phone,
        },
        {
            title: 'Action plan',
            icon: FactCheck,
        },
        {
            title: 'Welcome section',
            icon: Chat,
        },
    ];

    return (
        <MainWrapper>
            {isActive ? (
                <>
                    <ContextMenu className={`workspace-context-settings ${workspaceContextMenu ? '' : 'hidden'}`}>
                        <StyledHeader onClick={() => useGlobalState.setState({ workspaceContextMenu: false })}>
                            <div>Settings</div>
                            <img src={CloseIcon} alt="icon" className="cursor-pointer" />
                        </StyledHeader>
                        <WorkspaceActions />
                    </ContextMenu>

                    <span className={workspaceContextMenu ? 'blurred' : ''}>
                        <AnalyticsInfo workspaceId={workspaceId} workspaceName={name} />
                        <WorkspaceInfo />
                        <StyledParagraph>Settings</StyledParagraph>
                        <OptionsWrapper>
                            {settingsItems.map((setting, index) => {
                                return (
                                    <StyledOptions key={setting.title} onClick={() => changePage(index + 1)}>
                                        <div>
                                            <img src={setting.icon} className="front" alt="icon" />
                                            {setting.title}
                                        </div>
                                        <img src={ArrowRight} className="back" alt="icon" />
                                    </StyledOptions>
                                );
                            })}
                        </OptionsWrapper>
                    </span>
                </>
            ) : (
                <></>
            )}
        </MainWrapper>
    );
};

export const ContextMenu = styled.div`
    position: absolute;
    background-color: var(--white);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--black-200);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--black-50);
    top: 20px;
    right: 20px;
    padding: 18px 24px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    width: 260px;

    .button {
        width: 100%;

        &.with-icon {
            padding: 8px 0;
            filter: none;
            letter-spacing: -0.16px;
            .children {
                justify-content: left;
            }
            &:hover {
                text-shadow: 0.3px 0.3px 0 var(--black-300);
                -webkit-transition: all 0.1s;
                -moz-transition: all 0.1s;
                -o-transition: all 0.1s;
                transition: all 0.1s;
            }
        }

        &:hover {
            &:active {
                background: transparent;
            }
        }

        &:disabled {
            cursor: not-allowed;
            background: transparent;
            color: var(--black-75);
            transform: none;
            &:hover {
                text-shadow: none;
            }
        }
    }
    &.hidden {
        pointer-events: none;
        opacity: 0;
    }
`;

export const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--black-50);
`;

const StyledParagraph = styled.p`
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;
`;

const OptionsWrapper = styled.div`
    flex: 1;
`;

export const StyledOptions = styled.div`
    border-bottom: 1px solid var(--black-20);
    padding-bottom: 18px;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: var(--black-60);
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
        display: flex;
        align-items: center;
    }
    img {
        &.front {
            margin-right: 12px;
        }
    }
`;

export default Settings;
