const apiUrl = process.env.REACT_APP_API_URL;

const routes = {
    auth: {
        token: `${apiUrl}/testing/token`,
        logout: `${apiUrl}/auth/logout`,
        login: `${apiUrl}/auth/sign`,
        generateToken: `${apiUrl}/auth/reset/generate`,
        resetPassword: `${apiUrl}/auth/reset/update`,
        resendToken: `${apiUrl}/auth/resend`,
    },
    analytics: {
        event: `${apiUrl}/analytics/event`,
        events: `${apiUrl}/analytics/events`,
        workspaceEventsStatistic: (id?: string) => `${apiUrl}/analytics/events/workspace/${id}/statistic`,
        workspacesStatistic: `${apiUrl}/analytics/events/workspaces/statistic`,
        activity: `${apiUrl}/analytics/events/activity/`,
        activityByWSId: (id: string) => `${apiUrl}/analytics/events/activity/${id}`,
        viewsByWSId: (id: string) => `${apiUrl}/analytics/events/workspace/${id}/views`,
        statisticByWSId: (id: string) => `${apiUrl}/analytics/events/workspace/${id}/statistic`,
    },
    user: {
        root: `${apiUrl}/users`,
        me: `${apiUrl}/users/me`,
        picture: `${apiUrl}/users/me/picture`,
        company: `${apiUrl}/users/company`,
        register: (token: string) => `${apiUrl}/users/company/${token}`,
        confirm: (token: string) => `${apiUrl}/users/confirm/${token}`,
        changeRole: (id: string) => `${apiUrl}/users/company/role/${id}`,
        deactivate: (id: string) => `${apiUrl}/users/company/deactivate/${id}`,
        activity: (id: string) => `${apiUrl}/users/company/activity/${id}`,
        delete: (id: string) => `${apiUrl}/users/company/${id}`,
        invitation: (email: string) => `${apiUrl}/users/company/invitation/${email}`,
    },
    workspace: {
        all: `${apiUrl}/workspaces/`,
        archived: `${apiUrl}/workspaces/archived`,
        files: `${apiUrl}/workspaces/files`,
        single: (id: string) => `${apiUrl}/workspaces/${id}`,
        getShareWorkspaceInfo: (id: string) => `${apiUrl}/workspaces/share/${id}`,
        updateShareWorkspaceInfo: (id: string) => `${apiUrl}/workspaces/share/${id}`,
        publish: (id: string) => `${apiUrl}/workspaces/${id}/publish`,
        conceal: (id: string) => `${apiUrl}/workspaces/${id}/conceal`,
        archive: (id: string) => `${apiUrl}/workspaces/${id}/archive`,
        welcomeVideo: (id: string) => `${apiUrl}/workspaces/welcome/${id}`,
        duplicate: (id: string) => `${apiUrl}/workspaces/duplicate/${id}`,
        sharedView: `${apiUrl}/workspaces/shared`,
        protectedSharedView: `${apiUrl}/workspaces/shared/protected`,
    },
    file: {
        all: `${apiUrl}/files/`,
        single: (id: string) => `${apiUrl}/files/${id}`,
        fileDownload: (link: string) => `${apiUrl}/files/download/${link}`,
        thumbnail: `${apiUrl}/files/thumbnail`,
        updateThumbnail: (link: string) => `${apiUrl}/files/thumbnail/${link}`,
        thumbnailRemove: (link: string) => `${apiUrl}/files/thumbnail/${link}`,
        linkThumbnail: `${apiUrl}/files/link/thumbnail`,
        mediaFile: (link: string) => `${apiUrl}/files/media/${link}`,
        withLinks: `${apiUrl}/files/links`,
        library: (id: string) => `${apiUrl}/files/library/${id}`,
    },
    account: {
        singleCompany: (id: string) => `${apiUrl}/accounts/company/${id}`,
        singleContact: (id: string) => `${apiUrl}/accounts/contact/${id}`,
        allContacts: `${apiUrl}/accounts/contact`,
        allCompanies: `${apiUrl}/accounts/company`,
        contactPicture: (id: string) => `${apiUrl}/accounts/contact/picture/${id}`,
        companyPicture: (id: string) => `${apiUrl}/accounts/company/picture/${id}`,
        companyProfilePicture: (id: string) => `${apiUrl}/accounts/company/picture/${id}`,
        accountWorkspaces: (id: string) => `${apiUrl}/workspaces/account/${id}`,
        associatedContactsCompanies: (id: string) => `${apiUrl}/accounts/contactcompany/${id}`,
    },
    organization: {
        me: () => `${apiUrl}/organizations/me`,
        register: (token: string) => `${apiUrl}/organizations/register/${token}`,
        picture: (id: string) => `${apiUrl}/organizations/logo/${id}`,
    },
    infoRoom: {
        all: `${apiUrl}/inforooms/`,
        files: `${apiUrl}/inforooms/files`,
        single: (id: string) => `${apiUrl}/inforooms/${id}`,
    },
    template: {
        all: `${apiUrl}/templates/`,
        files: `${apiUrl}/templates/files`,
        single: (id: string) => `${apiUrl}/templates/${id}`,
    },
    clearbit: {
        autocomplete: (query: string) => `https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`,
        logo: (company: string) => `https://logo.clearbit.com/${company}`,
    },
    categories: {
        all: (type: string) => `${apiUrl}/categories?type=${type}`,
        single: (id: string) => `${apiUrl}/categories/${id}`,
    },
    requests: {
        root: `${apiUrl}/requests`,
        all: `${apiUrl}/requests/all`,
        allByStatus: (status: string) => `${apiUrl}/requests/all/${status}`,
        single: (id: string) => `${apiUrl}/requests/${id}`,
        approve: (id: string) => `${apiUrl}/requests/approve/${id}`,
        reject: (id: string) => `${apiUrl}/requests/reject/${id}`,
    },
    actionPlans: {
        all: `${apiUrl}/actionplans`,
        single: (id: string) => `${apiUrl}/actionplans/${id}`,
    },
    subscription: {
        me: `${apiUrl}/subscriptions`,
        all: `${apiUrl}/subscriptions`,
    },
    link: {
        all: `${apiUrl}/links`,
        single: (id: string) => `${apiUrl}/links/${id}`,
        thumbnail: `${apiUrl}/links/thumbnail`,
        updateThumbnail: (id: string) => `${apiUrl}/links/thumbnail/${id}`,
        thumbnailRemove: (id: string) => `${apiUrl}/links/thumbnail/${id}`,
        library: (id: string) => `${apiUrl}/links/library/${id}`,
    },
};

export default routes;
