import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    letter?: string | null;
    className?: string;
    bgColor?: string;
    withTooltip?: boolean;
    tooltipText?: string;
    width?: string;
    height?: string;
}
const CircleLetter: FunctionComponent<Props> = ({
    letter,
    className,
    bgColor,
    withTooltip,
    tooltipText,
    width,
    height,
}) => {
    return (
        <StyledCircleLetter
            className={`${className ? className : ''} ${withTooltip ? 'with-tooltip' : ''}`.trim()}
            bgColor={bgColor}
            width={width}
            height={height}
        >
            <span className="letter">
                <span>{letter}</span>
            </span>
            {withTooltip && tooltipText !== '' ? <span className="label">{tooltipText}</span> : <></>}
        </StyledCircleLetter>
    );
};
const StyledCircleLetter = styled.span<Props>`
    .letter {
        height: ${(props) => (props.height ? props.height : '32px')};
        width: ${(props) => (props.width ? props.width : '32px')};
        background: ${(props) => (props.bgColor ? props.bgColor : '#f2d786')};
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            color: var(--white);
            font-size: 12px;
            font-weight: 600;
            line-height: 22px;
        }
    }

    &.with-tooltip {
        position: relative;
        cursor: pointer;
        .label {
            visibility: hidden;
            position: absolute;
            left: 50%;
            bottom: 100%;
            transform: translate(-50%, -50%);
            padding: 2px 6px;
            border-radius: 2px;
            font-size: 10px;
            background: var(--black-60);
            color: var(--white);
            text-align: center;
            white-space: nowrap;
            &:after {
                content: '';
                position: absolute;
                top: 95%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: var(--black-60) transparent transparent transparent;
            }
        }

        &:hover {
            .label {
                visibility: visible;
            }
        }
    }
`;
export default CircleLetter;
