import { SetStateAction, Dispatch } from 'react';
import './table.scss';
import styled from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import useGlobalState from '@Store/global';
import published from '@Assets/icons/status/published.svg';
import unpublished from '@Assets/icons/status/unpublished.svg';
import publishedWarning from '@Assets/icons/status/published-warning.svg';
import noWorkspaceIcon from '@Assets/icons/workspaces/no-workspace.svg';
import EyeGrey from '@Assets/icons/eye/eye-grey.svg';
import { WorkspaceDto } from '@Global/dtos/workspace.dto';
import Tooltip from '@Components/tooltip';
import CircleLetter from '@Components/circleLetter';
import NoItems from '@Components/noItems';
import Button, { ButtonTheme } from '@Components/button';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableSkeleton,
    TableSkeletonWrapper,
    SkeletonWrapper,
} from '@Components/table';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import WorkspaceActions from '../editor/sidebar/settings/workspaceActions';

interface Props {
    workspaces?: WorkspaceDto[] | null;
    setIsChoiceModalVisible?: Dispatch<SetStateAction<boolean>>;
    isArchived?: boolean;
    setAllWorkspaces?: Dispatch<SetStateAction<WorkspaceDto[] | null>>;
    setFilteredWorkspaces?: Dispatch<SetStateAction<WorkspaceDto[] | null>>;
    activeTab?: number;
}

const WorkspaceTable: React.FunctionComponent<Props> = ({
    workspaces,
    setIsChoiceModalVisible,
    isArchived,
    setAllWorkspaces,
    setFilteredWorkspaces,
    activeTab,
}) => {
    const navigate = useNavigate();
    const { activeActionElement } = useGlobalState();

    const goToTheWorkspace = (id: string): void => {
        navigate(`/workspaces/${id}`);
    };

    const getNamesCircle = (workspace: WorkspaceDto, property: 'contact' | 'user'): string | null => {
        const nameInitial = workspace[property]?.firstName?.[0]?.toUpperCase() ?? '';
        const lastNameInitial = workspace[property]?.lastName?.[0]
            ? workspace[property]?.lastName?.[0].toUpperCase()
            : '';
        return nameInitial.trim() || lastNameInitial?.trim() ? `${nameInitial}${lastNameInitial}` : null;
    };

    const getNames = (workspace: WorkspaceDto, property: 'contact' | 'user'): string => {
        return workspace[property]?.firstName && workspace[property]?.lastName
            ? workspace[property]?.firstName + ' ' + workspace[property]?.lastName
            : property === 'user'
            ? workspace[property]?.email
            : workspace[property]?.firstName ?? '';
    };

    return (
        <div className="all-workspace-page">
            {workspaces ? (
                <Table className={`${workspaces?.length === 0 ? 'no-items' : ''}`.trim()}>
                    {workspaces?.length !== 0 ? (
                        <>
                            <TableHeader columns={7}>
                                <TableHeaderItem>Workspace</TableHeaderItem>
                                <TableHeaderItem>Category</TableHeaderItem>
                                <TableHeaderItem>Owner</TableHeaderItem>
                                <TableHeaderItem>Shared with</TableHeaderItem>
                                <TableHeaderItem>Status</TableHeaderItem>
                                <TableHeaderItem>Views</TableHeaderItem>
                                <TableHeaderItem>Actions</TableHeaderItem>
                            </TableHeader>
                            <TableBody>
                                {workspaces?.map((workspace) => {
                                    return (
                                        <StyledTableItem
                                            columns={7}
                                            key={workspace?.id}
                                            className="workspace table-item"
                                            onClick={() => goToTheWorkspace(workspace.id)}
                                        >
                                            <div className="workspace-company">
                                                {workspace?.company?.name ? (
                                                    workspace.company.logoUrl || workspace.company.profilePicture ? (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={
                                                                workspace.company.profilePicture
                                                                    ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${workspace.company.id}`
                                                                    : workspace.company.logoUrl
                                                            }
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <CircleLetter
                                                            bgColor="var(--primary-500)"
                                                            letter={workspace?.company?.name[0].toUpperCase()}
                                                            withTooltip
                                                            tooltipText={workspace?.company?.name}
                                                        />
                                                    )
                                                ) : (
                                                    <>
                                                        {workspace?.contact ? (
                                                            workspace.contact.profilePicture ? (
                                                                <img
                                                                    className="profile-img"
                                                                    crossOrigin="anonymous"
                                                                    src={`${process.env.REACT_APP_API_URL}/accounts/contact/picture/${workspace.contact.id}`}
                                                                    alt="profile"
                                                                />
                                                            ) : (
                                                                <CircleLetter
                                                                    bgColor="var(--primary-500)"
                                                                    letter={getNamesCircle(workspace, 'contact')}
                                                                    withTooltip
                                                                    tooltipText={getNames(workspace, 'contact')}
                                                                />
                                                            )
                                                        ) : (
                                                            <img
                                                                className="profile-img"
                                                                crossOrigin="anonymous"
                                                                src={noWorkspaceIcon}
                                                                alt="no workspace"
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                <div>
                                                    <p className="name">{workspace?.name}</p>
                                                    <p className="company">
                                                        {workspace?.company?.name
                                                            ? workspace?.company?.name
                                                            : getNames(workspace, 'contact')
                                                            ? getNames(workspace, 'contact')
                                                            : '-'}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p>{workspace.category?.name ? workspace.category?.name : '-'}</p>
                                            </div>
                                            <div>
                                                {workspace?.user?.properties?.profilePicture ? (
                                                    <img
                                                        className="profile-img"
                                                        crossOrigin="anonymous"
                                                        src={`${process.env.REACT_APP_API_URL}/users/me/picture/${workspace?.user?.properties?.profilePicture}`}
                                                        alt="profile"
                                                    />
                                                ) : getNamesCircle(workspace, 'user') ? (
                                                    <CircleLetter
                                                        bgColor="var(--primary-500)"
                                                        letter={getNamesCircle(workspace, 'user')}
                                                        withTooltip
                                                        tooltipText={getNames(workspace, 'user')}
                                                    />
                                                ) : null}
                                                <p>{getNames(workspace, 'user')}</p>
                                            </div>
                                            <div className="workspace-status">
                                                {workspace?.status?.restricted ? (
                                                    <>
                                                        <p>Restriced</p>
                                                        <span>
                                                            Shared with {workspace.status.users?.length} contacts
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p>Public access</p>
                                                        <span>Anyone with a link</span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="workspace-published">
                                                {workspace?.publishedContent ? (
                                                    JSON.stringify(workspace.content) ===
                                                    JSON.stringify(workspace.publishedContent) ? (
                                                        <Tooltip tooltipText="All changes were published">
                                                            <img src={published} alt="published" />
                                                            <p>Published</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip tooltipText="Recent changes were not published">
                                                            <img src={publishedWarning} alt="published" />
                                                            <p>Published</p>
                                                        </Tooltip>
                                                    )
                                                ) : (
                                                    <Tooltip tooltipText="Not published">
                                                        <img src={unpublished} alt="unpublished" />
                                                        <p>Unpublished</p>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div className="workspace-views">
                                                <img alt="eye icon" src={EyeGrey} />
                                                <p>{workspace?.views ? workspace?.views : 0}</p>
                                            </div>
                                            <div className="workspace-actions">
                                                <StyledAction
                                                    className={`block-actions ${
                                                        activeActionElement === workspace.id ? 'active' : ''
                                                    }`}
                                                >
                                                    <img
                                                        id={`actions-${workspace.id}`}
                                                        className={`block-actions-expander ${
                                                            activeActionElement !== workspace.id ? 'hidden' : ''
                                                        }`}
                                                        src={MoreDotsIcon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === workspace.id
                                                                        ? null
                                                                        : workspace.id,
                                                            });
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <WrapperStyled
                                                        className={`block-actions-body ${
                                                            activeActionElement !== workspace.id ? 'hidden' : ''
                                                        }`}
                                                    >
                                                        <WorkspaceActions
                                                            selectedWorkspaceId={workspace.id}
                                                            isSelectedPublished={!!workspace.publishedContent}
                                                            isSelectedArchived={workspace?.archived}
                                                            setAllWorkspaces={setAllWorkspaces}
                                                            setFilteredWorkspaces={setFilteredWorkspaces}
                                                            activeTab={activeTab}
                                                        />
                                                    </WrapperStyled>
                                                </StyledAction>
                                            </div>
                                        </StyledTableItem>
                                    );
                                })}
                            </TableBody>
                        </>
                    ) : (
                        <NoItems
                            content={
                                <>
                                    <p>You don’t have any {isArchived ? 'archived' : ''} Workspaces yet.</p>
                                    {!isArchived ? (
                                        <div>
                                            <p>Start with</p>
                                            <Button
                                                theme={ButtonTheme.link}
                                                color="var(--primary-400)"
                                                onClick={() => {
                                                    setIsChoiceModalVisible && setIsChoiceModalVisible(true);
                                                }}
                                            >
                                                creating a new Workspace.
                                            </Button>
                                        </div>
                                    ) : null}
                                </>
                            }
                        />
                    )}
                </Table>
            ) : (
                <SkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                </SkeletonWrapper>
            )}
        </div>
    );
};
export const StyledAction = styled.div`
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 2;

    .block-actions-expander {
        border-radius: 20px;
        padding: 3px;
        background-color: white;
        box-shadow: 0 7px 9px 0 rgb(0 0 0 / 19%), 0 17px 50px 0 rgb(0 0 0 / 15%);
        margin-left: calc(100% - 24px);
        cursor: pointer;

        &.hidden {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        }
    }
`;
export const WrapperStyled = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    padding: 7px;
    background-color: white;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 19%), 0 17px 50px 0 rgb(0 0 0 / 15%);
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    margin-top: 8px;

    .button {
        padding: 5px 8px;
        border-radius: 20px;
        opacity: 0.6;
        font-size: 12px;
        line-height: 16px;
        transition: all 0.2s ease-in-out;
        margin: 2px 0;
        height: auto;

        &:hover {
            cursor: pointer;
            opacity: 1;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        }
        &.with-icon.left-icon {
            .children {
                img {
                    margin-right: 7px;
                }
            }
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    &.hidden {
        display: none;
    }
`;
const StyledTableItem = styled(TableRow)`
    > div {
        display: flex;
        p {
            align-items: center;
            display: flex;
            font-size: 12px;
            justify-content: flex-start;
            color: var(--black-60);
        }
        span {
            color: var(--black-75);
            font-size: 10px;
            line-height: 17px;
        }
        &.workspace-company {
            align-items: start;
            p {
                flex-direction: column;
                align-items: flex-start;
                &.name {
                    font-weight: 600;
                }
                &.company {
                    color: var(--black-75);
                }
            }
        }
        &.workspace-status {
            flex-direction: column;
        }
        &.workspace-published {
            span {
                display: inline-flex;
                align-items: center;
                img {
                    height: 12px;
                    margin-right: 8px;
                    width: 12px;
                }
            }
        }
        &.workspace-views {
            img {
                margin-right: 8px;
            }
        }
    }

    .profile-img {
        border-radius: 50px;
        height: 32px;
        margin-right: 8px;
        width: 32px;
        object-fit: cover;
    }
`;

export default WorkspaceTable;
