import { FunctionComponent, useState, useRef } from 'react';
import styled from 'styled-components';
import PlayIcon from '@Assets/icons/sharedView/play-circle.svg';
import PlayIconSmall from '@Assets/icons/play-circle-small.svg';
import ReactPlayer from 'react-player';

interface Props {
    wrapperClassName?: string;
    videoClassName?: string;
    videoUrl?: string;
    smallPlayIcon?: boolean;
    onPlay?: Function;
    height?: string;
}
const Video: FunctionComponent<Props> = ({
    wrapperClassName,
    videoClassName,
    videoUrl,
    smallPlayIcon,
    onPlay = () => {
        return;
    },
    height = '100%',
}) => {
    const videoRef = useRef<ReactPlayer | null>(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayVideo = (): void => {
        const video = videoRef.current;
        if (!video) return;
        if (!video.props.playing) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    };
    return (
        <StyledVideoWrapper
            className={`${wrapperClassName} ${isPlaying ? 'playing' : 'not-playing'}`.trim()}
            onClick={handlePlayVideo}
        >
            <ReactPlayer
                style={{ borderRadius: 5 }}
                ref={videoRef}
                url={videoUrl}
                playing={isPlaying}
                controls={isPlaying ? true : false}
                width="100%"
                height={height}
                className={videoClassName}
                config={{
                    file: {
                        attributes: {
                            crossOrigin: 'true',
                        },
                    },
                }}
                onPlay={onPlay()}
                onEnded={() => setIsPlaying(false)}
                playsinline={true}
            />
            <img src={smallPlayIcon ? PlayIconSmall : PlayIcon} alt="play icon" className="cursor-pointer" />
        </StyledVideoWrapper>
    );
};
const StyledVideoWrapper = styled.div`
    position: relative;
    width: 100%;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.18);
        z-index: 1;
    }
    img {
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
        bottom: 50%;
    }
    &.playing {
        &:after {
            visibility: hidden;
        }
        img {
            visibility: hidden;
        }
    }
`;
export default Video;
