import { FunctionComponent } from 'react';
import styled from 'styled-components';
import CloseIcon from '@Assets/icons/close.svg';
import attachSize from '@Helpers/size';

interface Props {
    className?: string | '';
    modalVisible: boolean;
    closeModal: Function;
    children: React.ReactNode;
    size?: number | string;
    title?: string | any;
    closeIcon?: any;
    withHeader?: boolean;
}

const Modal: FunctionComponent<Props> = ({
    className,
    modalVisible,
    closeModal,
    children,
    size,
    title,
    closeIcon,
    withHeader = true,
}) => {
    return (
        <StyledModal
            className={`${className ? className : ''} ${modalVisible ? 'visible' : ''}`}
            closeModal={closeModal}
            size={size}
            modalVisible={modalVisible}
        >
            <div className="modal-backdrop" onClick={() => closeModal()} />
            <div className="modal-wrapper">
                {withHeader ? (
                    <div className="modal-header">
                        {title ? <div>{title}</div> : null}
                        <img
                            alt="close button"
                            className="cursor-pointer"
                            onClick={() => closeModal()}
                            src={closeIcon ? closeIcon : CloseIcon}
                        />
                    </div>
                ) : null}

                {children}
            </div>
        </StyledModal>
    );
};

const StyledModal = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    div {
        &.modal-backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(170, 170, 170, 0.5);
            backdrop-filter: blur(2px);
            opacity: 0.85;
        }
    }

    .modal-wrapper {
        background-color: var(--background-grey);
        border-radius: 8px;
        z-index: 1;
        padding: 0 27px 30px 27px;
        max-height: 95vh;
        overflow: auto;
        width: ${(props) => attachSize(props.size)};

        @media only screen and (max-width: 768px) {
            margin: 0 10px;
            width: 100%;
        }

        .modal-header {
            display: flex;
            align-items: center;
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 1;
            padding: 25px 0 25px 0;
            border-bottom: 1px solid var(--black-75);
            margin-bottom: 20px;
            background-color: var(--background-grey);

            div {
                text-align: start;
                flex: 1;
            }
        }
    }
`;
export default Modal;
