import { FunctionComponent, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { BlockType, MediaBlock } from '@/types/workspace';
import { ObjectKeys } from '@/types/objectKeys';
import nanogen from '@Helpers/nanogen';
import { isValidUrl } from '@Helpers/regex';
import useLoading from '@Hooks/useLoading';
import Communication from '@Communication';
import SelectComponent, { SelectOptionType, SelectComponentTheme } from '@Components/select';
import Input, { InputComponentType, InputComponentTheme } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import Tooltip from '@Components/tooltip';
import InfoIcon from '@Assets/icons/settings/profile/info.svg';
import { TwoButtonsStyle } from '../../../../pages/workspaces/editor/sidebar/styledComponents';
import Anything from '@Assets/icons/embed/anything.svg';
import Docs from '@Assets/icons/embed/docs.svg';
import Sheets from '@Assets/icons/embed/sheets.svg';
import Slides from '@Assets/icons/embed/slides.svg';
import Forms from '@Assets/icons/embed/forms.svg';
import YouTube from '@Assets/icons/embed/youtube.svg';
import Vimeo from '@Assets/icons/embed/vimeo.svg';
import Figma from '@Assets/icons/embed/figma.svg';
import Loom from '@Assets/icons/embed/loom.svg';
import AirTable from '@Assets/icons/embed/airtable.svg';
import TypeForm from '@Assets/icons/embed/typeform.svg';
interface Props {
    addItem?: Function;
    onUpdate: Function;
    setEmbedModal: Dispatch<SetStateAction<boolean>>;
    blockType: string;
    isBlockEmpty?: Function;
    deleteBlock: Function;
    mediaBlock?: MediaBlock;
}
const EmbedFormModal: FunctionComponent<Props> = ({
    addItem,
    onUpdate,
    setEmbedModal,
    isBlockEmpty,
    deleteBlock,
    mediaBlock,
    blockType,
}) => {
    const { showLoading, hideLoading } = useLoading();

    const sources = [
        {
            value: 'anything',
            label: 'Anything',
            icon: Anything,
        },
        {
            value: 'google-docs',
            label: 'Google Docs',
            icon: Docs,
        },
        {
            value: 'google-sheets',
            label: 'Google Sheets',
            icon: Sheets,
        },
        {
            value: 'google-slides',
            label: 'Google Slides',
            icon: Slides,
        },
        {
            value: 'google-forms',
            label: 'Google Forms',
            icon: Forms,
        },
        {
            value: 'youtube',
            label: 'YouTube',
            icon: YouTube,
        },
        {
            value: 'vimeo',
            label: 'Vimeo',
            icon: Vimeo,
        },
        {
            value: 'figma',
            label: 'Figma',
            icon: Figma,
        },
        {
            value: 'loom',
            label: 'Loom',
            icon: Loom,
        },
        {
            value: 'airtable',
            label: 'Airtable',
            icon: AirTable,
        },
        {
            value: 'typeform',
            label: 'Typeform',
            icon: TypeForm,
        },
    ];

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        selectedSource: { value: '', label: '', icon: '' },
        embedLink: '',
        title: '',
    });

    const handleAddEmbedFile = (): void => {
        setLoading(true);
        showLoading();
        if (formData.selectedSource !== null && formData?.selectedSource?.value !== 'anything') {
            const id = nanogen();
            Communication.createLinkPreviewThumbnail(formData.embedLink, id, 'false').then((res: ObjectKeys) => {
                if (formData.selectedSource !== null && addItem) {
                    const thumbnail = res.data;
                    addItem({
                        id: nanogen(),
                        type: BlockType.Embed,
                        content: {
                            name: formData.title,
                            url: formData.embedLink,
                            thumbnail: thumbnail,
                            type: (formData.selectedSource as SelectOptionType).value,
                        },
                        options: {},
                    });
                    hideLoading();
                }
            });
        } else {
            if (formData.selectedSource !== null && addItem) {
                addItem({
                    id: nanogen(),
                    type: BlockType.Embed,
                    content: {
                        name: formData.title,
                        url: formData.embedLink,
                        thumbnail: null,
                        type: (formData?.selectedSource as SelectOptionType).value,
                    },
                    options: {},
                });
                hideLoading();
            }
        }
        onUpdate();
        setFormData({ selectedSource: { value: '', label: '', icon: '' }, embedLink: '', title: '' });
        setEmbedModal(false);
        setLoading(false);
    };

    const getEmbedInputLabel = (sourceOptions: SelectOptionType | null): string => {
        switch (sourceOptions?.value) {
            case 'anything':
                return 'HTML';
            case sourceOptions?.value:
                return sourceOptions?.label;
            default:
                return '';
        }
    };

    const getEmbedInputTooltip = (sourceOptions: SelectOptionType | null): string => {
        switch (sourceOptions?.value) {
            case 'anything':
                return 'Add any embeddable content directly into your workspace.';
            case 'google-docs':
                return 'Make sure your sharing permissions are set to Anyone with the link.';
            case 'google-sheets':
                return 'Make sure your sharing permissions are set to Anyone with the link.';
            case 'google-slides':
                return 'Make sure your sharing permissions are set to Anyone with the link.';
            case 'google-forms':
                return 'Make sure your sharing permissions are set to Anyone with the link.';
            case 'youtube':
                return 'Embed a YouTube video directly into your workspace.';
            case 'vimeo':
                return 'Make sure the video is set to "Public".';
            case 'loom':
                return 'Make sure the Loom video is set to anyone with the link can view.';
            case 'airtable':
                return 'Make sure to enable shared base link (share publicly) in Airtable.';
            case 'typeform':
                return 'In Typeform, click “Share” and copy the link.';
            case 'figma':
                return 'Make sure your Figma file is viewable to anyone with the link.';
            default:
                return '';
        }
    };

    const getEmbedInputPlaceholder = (sourceOptions: SelectOptionType | null): string => {
        switch (sourceOptions?.value) {
            case 'anything':
                return 'Paste code';
            case 'google-docs':
                return 'Paste Google Doc link';
            case 'google-sheets':
                return 'Paste Google Sheet link';
            case 'google-slides':
                return 'Paste Google Slide link';
            case 'google-forms':
                return 'Paste Google Form link';
            case 'youtube':
                return 'Paste YouTube link';
            case 'vimeo':
                return 'Paste Vimeo link';
            case 'loom':
                return 'Paste Loom link';
            case 'airtable':
                return 'Paste Airtable link';
            case 'typeform':
                return 'Paste Typeform link';
            case 'figma':
                return 'Paste Figma link';
            default:
                return 'Paste link';
        }
    };

    return (
        <StyledEmbedModal>
            <SelectComponent
                theme={SelectComponentTheme.Light}
                label="Source"
                placeholder="Choose source"
                onChange={(value: any) => {
                    setFormData({ ...formData, selectedSource: value });
                }}
                value={
                    formData.selectedSource.value !== ''
                        ? {
                              label: formData.selectedSource.label,
                              value: formData.selectedSource.value,
                              icon: formData.selectedSource.icon,
                          }
                        : null
                }
                optionList={sources}
                isClearable={false}
                isSearchable={true}
            />
            <Input
                theme={InputComponentTheme.Light}
                label={
                    formData?.selectedSource.value !== '' ? (
                        <div className="custom-label">
                            Embed {getEmbedInputLabel(formData.selectedSource)}
                            <Tooltip tooltipText={getEmbedInputTooltip(formData.selectedSource)} width="157px">
                                <img alt="info" src={InfoIcon} />
                            </Tooltip>
                        </div>
                    ) : (
                        'Embed link'
                    )
                }
                placeholder={formData.selectedSource ? getEmbedInputPlaceholder(formData.selectedSource) : 'Paste link'}
                type={InputComponentType.Text}
                onChange={(value: string) => setFormData({ ...formData, embedLink: value })}
                value={formData.embedLink}
                size="100%"
                errorMessage={
                    formData.selectedSource.value !== 'anything'
                        ? formData.embedLink
                            ? isValidUrl(formData.embedLink)
                                ? ''
                                : 'Please provide valid link'
                            : ''
                        : ''
                }
            />

            <Input
                theme={InputComponentTheme.Light}
                label="Title"
                placeholder="Add title"
                type={InputComponentType.Text}
                onChange={(value: string) => setFormData({ ...formData, title: value })}
                value={formData.title}
                size="100%"
            />
            <TwoButtonsStyle>
                <Button
                    className="clickable"
                    theme={ButtonTheme.primary}
                    onClick={() => handleAddEmbedFile()}
                    isLoading={loading}
                    disabled={
                        formData.selectedSource.value === '' ||
                        formData.embedLink === '' ||
                        (formData.selectedSource.value !== 'anything' && !isValidUrl(formData.embedLink))
                    }
                >
                    Save
                </Button>
                <Button
                    theme={ButtonTheme.naked}
                    onClick={() => {
                        setEmbedModal(false);
                        setFormData({ selectedSource: { value: '', label: '', icon: '' }, embedLink: '', title: '' });
                        if (blockType === 'media') {
                            if (!mediaBlock?.content?.embed) void deleteBlock();
                        } else {
                            if (isBlockEmpty && isBlockEmpty()) void deleteBlock();
                        }
                    }}
                >
                    Cancel
                </Button>
            </TwoButtonsStyle>
        </StyledEmbedModal>
    );
};
const StyledEmbedModal = styled.div`
    .custom-label {
        display: flex;
        align-items: center;
        > span {
            display: flex;
            margin-left: 5px;
        }
    }
    .option-with-icon {
        img {
            width: 25px;
            height: 25px;
        }
    }
`;
export default EmbedFormModal;
