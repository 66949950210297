import React from 'react';
import styled from 'styled-components';
import Button from '@Components/button';
import useDialogueState from '@Store/dialogue';

interface Props {
    className?: string;
}

const Dialogue: React.FunctionComponent<Props> = ({ className }) => {
    const { visible, title, description, buttons } = useDialogueState();

    return (
        <DialogueMain className={`dialogue ${className ?? ''} ${visible ? 'visible' : ''}`}>
            <div className="dialogue-backdrop" />
            <DialogueWrapper>
                <StyledPrompt>{title}</StyledPrompt>
                {description ? <p>{description}</p> : <></>}
                <div className="dialogue-button-wrapper">
                    {buttons?.map((button) => {
                        return (
                            <Button key={button.text} theme={button.theme} onClick={() => button.callback()}>
                                {button.text}
                            </Button>
                        );
                    })}
                </div>
            </DialogueWrapper>
        </DialogueMain>
    );
};

const DialogueMain = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .dialogue-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(170, 170, 170, 0.5);
        backdrop-filter: blur(2px);
    }

    .dialogue-button-wrapper {
        display: flex;
        justify-content: space-evenly;
        gap: 10px;
    }
`;

const DialogueWrapper = styled.div`
    background-color: white;
    position: absolute;
    border-radius: 8px;
    padding: 24px 18px;
    max-width: 400px;
    min-width: 300px;

    p {
        padding-bottom: 26px;
        text-align: center;
        color: var(--black-60);
    }

    .button {
        width: 100%;
    }
`;

const StyledPrompt = styled.h2`
    text-align: center;
    font-size: 18px;
    padding-bottom: 22px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--black-75);
    line-height: normal;
`;
export default Dialogue;
