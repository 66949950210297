import { FunctionComponent, ChangeEvent, MouseEvent, useState, useRef } from 'react';
import styled from 'styled-components';
import DefaultIcon from '@Assets/icons/workspaces/image.svg';
import ChangeImageIcon from '@Assets/icons/actions/change.svg';
import DeleteIcon from '@Assets/icons/actions/delete.svg';
import EditIcon from '@Assets/icons/actions/edit.svg';
import { ButtonTheme } from '@Components/button';
import Tooltip from '@Components/tooltip';
import useDialogue from '@Hooks/useDialogue';
import attachSize from '@Helpers/size';
import useOutsideClick from '@Hooks//useOutsideClick';

interface Props {
    src?: string | null;
    className?: string;
    size?: number | string;
    defaultImage?: string;
    isClearable?: boolean;
    onChange?: Function;
    onRemove?: Function;
    tooltipText?: string;
    tooltipWidth?: string;
}

const PictureUpload: FunctionComponent<Props> = ({
    size,
    defaultImage,
    src,
    isClearable = true,
    onChange = () => {
        return;
    },
    onRemove = () => {
        return;
    },
    tooltipText,
    tooltipWidth,
}) => {
    const { setDialogue, closeDialogue } = useDialogue();
    const ref = useRef<HTMLDivElement>(null);

    const [isMenuActive, setIsMenuActive] = useState(false);

    const { handleClickOutside } = useOutsideClick(ref, setIsMenuActive);

    const inputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
            onChange(e.target.files[0]);
        }
    };

    const fileInputClick = (
        e: MouseEvent<HTMLInputElement> & {
            target: HTMLInputElement;
        },
    ): void => {
        e.target.value = '';
    };

    return (
        <StyledUserForm size={size}>
            <div className="profile-image-wrapper">
                <div className="profile-picture">
                    {src ? (
                        <img crossOrigin="anonymous" src={src} alt="profile" />
                    ) : (
                        <img src={defaultImage ?? DefaultIcon} alt="profile" />
                    )}
                    <div className="edit-image" onClick={() => setIsMenuActive(!isMenuActive)}>
                        <img src={EditIcon} alt="icon" />
                    </div>
                </div>
                <ContextMenu
                    className={isMenuActive ? 'visible' : ''}
                    ref={ref}
                    onClick={handleClickOutside}
                    size={size}
                >
                    <Tooltip
                        tooltipText={
                            tooltipText
                                ? tooltipText
                                : 'The optimal file size for images is 200 KB, and max-width of 800 pixels. Keeping images between these perimeters will ensure they load properly on SyncPage'
                        }
                        width={tooltipWidth ? tooltipWidth : '133px'}
                        tooltipPositionBottom
                        fontSize="12px"
                        padding="12px"
                        borderRadius="8px"
                    >
                        <div className="upload-context-body">
                            <label htmlFor={'profile-picture-input'}>
                                <img src={ChangeImageIcon} alt="icon" />
                                <p>Change image</p>
                            </label>
                            {isClearable ? (
                                <label
                                    onClick={() =>
                                        setDialogue({
                                            title: 'Remove picture?',
                                            buttons: [
                                                {
                                                    theme: ButtonTheme.danger,
                                                    text: 'Remove',
                                                    callback: () => {
                                                        onRemove();
                                                        closeDialogue();
                                                    },
                                                },
                                                {
                                                    theme: ButtonTheme.naked,
                                                    text: 'Cancel',
                                                    callback: () => {
                                                        closeDialogue();
                                                    },
                                                },
                                            ],
                                        })
                                    }
                                >
                                    <img src={DeleteIcon} alt="icon" />
                                    <p>Remove image</p>
                                </label>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Tooltip>
                </ContextMenu>

                <input
                    id="profile-picture-input"
                    accept="image/*"
                    type="file"
                    onChange={inputChange}
                    onClick={fileInputClick}
                />
            </div>
        </StyledUserForm>
    );
};

const StyledUserForm = styled.div<{ size?: number | string }>`
    border-radius: 8px;

    h2 {
        margin-bottom: 30px;

        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    .input {
        margin-bottom: 10px;
    }


    .profile-update-button {
        margin-top: 40px;
    }

    .profile-image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .profile-picture {
            width: ${(props) => attachSize(props.size)};
            height: ${(props) => attachSize(props.size)};
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            object-fit: contain;
            transition: all 0.3s ease-in-out;
            border-radius: 8px;
            position: relative;
            img {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &:before {
                content:"";
                background: linear-gradient(315deg, #338E8A 1%, rgba(133, 187, 185, 0.50) 53.09%);
                opacity: 0;
                width: ${(props) => attachSize(props.size)};
                height: ${(props) => attachSize(props.size)};
                border-radius: 8px;
                position: absolute;
                transition: all 0.3s ease-in-out;
                filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.20));
            }

            &:hover {
               &:before {
                    opacity:1;
               } 
            }
            .edit-image {
                position: absolute;
                bottom: -5px;
                right: -5px;
                background: var(--white);
                border-radius: 50%;
                filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08));
                width:24px;
                height:24px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor:pointer;
                img {
                    width: auto;
                    height: auto;
                }
            }
        }

        &:hover .overlay{
            opacity: 1;
            pointer-events: all;
    }

    input[type='file'] {
        display: none;
    }
`;

export const ContextMenu = styled.div<{ size?: number | string }>`
    background-color: var(--white);
    border-radius: 20px;
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
    margin-top: 23px;
    transition: 0.3s all ease-in-out;
    opacity: 0;
    visibility: hidden;
    top: ${(props) => attachSize(props.size)};
    position: absolute;
    z-index: 1;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
    .upload-context-body {
        box-sizing: border-box;
        padding: 6px 4px;

        label {
            display: flex;
            align-items: center;
            padding: 5px;
            white-space: nowrap;
            transition: 0.3s all ease-in-out;
            &:not(:last-child) {
                margin-bottom: 4px;
            }

            img {
                margin-right: 10px;
                background: var(--black-75);
                border-radius: 50%;
                padding: 3px;
                width: 24px;
                transition: 0.3s all ease-in-out;
            }

            p {
                font-size: 12px;
                color: var(--black-75);
            }

            &:hover {
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
                border-radius: 20px;
                cursor: pointer;
                p {
                    color: var(--black-60);
                }
                img {
                    background: var(--black-60);
                }
            }
        }
    }
`;

export default PictureUpload;
