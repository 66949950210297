import fetch from './fetch';
import routes from './routes';
import {
    CreateWorkspaceDto,
    DeleteWorkspaceFilesDto,
    UpdateSharedWorkspaceInfo,
    WorkspaceContentDto,
    WorkspaceUpdateDto,
} from '@Global/dtos/workspace.dto';

export default class Workspace {
    public getSharedView(invite: string): any {
        return fetch(
            {
                method: 'get',
                url: routes.workspace.sharedView,
                headers: { 'Content-Type': 'application/json' },
                params: { invite },
            },
            false,
        );
    }
    public getProtectedSharedView(
        invite: string,
        token?: string,
        sharedViewToken?: string | null,
        pin?: string | null,
    ): any {
        return fetch(
            {
                method: 'get',
                url: routes.workspace.protectedSharedView,
                headers: { 'Content-Type': 'application/json' },
                params: { invite, token, sharedViewToken, pin },
            },
            true,
        );
    }
    public sendSharedViewInvite(invite: string, email: string, scroll?: string): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.protectedSharedView,
            headers: { 'Content-Type': 'application/json' },
            data: { invite, email, scroll },
        });
    }
    public getSharedWorkspaceInfo(id: string): any {
        return fetch({
            method: 'get',
            url: routes.workspace.getShareWorkspaceInfo(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public updateSharedWorkspaceInfo(id: string, data: UpdateSharedWorkspaceInfo): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.updateShareWorkspaceInfo(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public getAllWorkspaces(): any {
        return fetch({
            method: 'get',
            url: routes.workspace.all,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getAllArchivedWorkspaces(): any {
        return fetch({
            method: 'get',
            url: routes.workspace.archived,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getWorkspace(id: string): any {
        return fetch({
            method: 'get',
            url: routes.workspace.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public updateWorkspaceContent(id: string, content: WorkspaceContentDto): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.single(id),
            headers: { 'Content-Type': 'application/json' },
            data: { content },
        });
    }

    public uploadWorkspaceWelcomeVideo(id: string, formData: FormData): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.welcomeVideo(id),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }

    public getWorkspaceWelcomeVideo(id: string): any {
        return fetch({
            method: 'get',
            url: routes.workspace.welcomeVideo(id),
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public removeWorkspaceWelcomeVideo(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.workspace.welcomeVideo(id),
        });
    }

    public updateWorkspace(id: string, data: WorkspaceUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public deleteWorkspaceFiles(data: DeleteWorkspaceFilesDto): any {
        return fetch({
            method: 'delete',
            url: routes.workspace.files,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public publishWorkspace(id: string): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.publish(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public concealWorkspace(id: string): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.conceal(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public archiveWorkspace(id: string): any {
        return fetch({
            method: 'patch',
            url: routes.workspace.archive(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public createWorkspace(data: CreateWorkspaceDto): any {
        return fetch({
            method: 'post',
            url: routes.workspace.all,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public duplicateWorkspace(id: string): any {
        return fetch({
            method: 'post',
            url: routes.workspace.duplicate(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public deleteWorkspace(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.workspace.single(id),
        });
    }
}
