import fetch from '@/communication/fetch';
import routes from '@/communication/routes';
import {
    CreateInfoRoomDto,
    DeleteInfoRoomFilesDto,
    InfoRoomContentDto,
    InfoRoomUpdateDto,
} from '@Global/dtos/inforoom.dto';

export default class InfoRoom {
    public getInfoRoomList(): any {
        return fetch({
            method: 'get',
            url: routes.infoRoom.all,
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public getInfoRoom(id: string): any {
        return fetch({
            method: 'get',
            url: routes.infoRoom.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public createInfoRoom(data: CreateInfoRoomDto): any {
        return fetch({
            method: 'post',
            url: routes.infoRoom.all,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public updateInfoRoom(id: string, data: InfoRoomUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.infoRoom.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public updateInfoRoomData(id: string, content: InfoRoomContentDto): any {
        return fetch({
            method: 'patch',
            url: routes.infoRoom.single(id),
            headers: { 'Content-Type': 'application/json' },
            data: { content },
        });
    }

    public deleteInfoRoom(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.infoRoom.single(id),
        });
    }

    public deleteInfoRoomFiles(data: DeleteInfoRoomFilesDto): any {
        return fetch({
            method: 'delete',
            url: routes.infoRoom.files,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
}
