import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import styled from 'styled-components';
import './table.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import useDialogue from '@Hooks/useDialogue';
import communication from '@Communication';
import useGlobalState from '@Store/global';
import { InfoRoomDto } from '@Global/dtos/inforoom.dto';
import CircleLetter from '@Components/circleLetter';
import {
    SkeletonWrapper,
    Table,
    TableBody,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableSkeleton,
    TableSkeletonWrapper,
} from '@Components/table';
import NoItems from '@Components/noItems';
import Button, { ButtonTheme } from '@Components/button';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import { StyledAction, WrapperStyled } from '../../workspaces/table';
import EditIconInactive from '@Assets/icons/settings/inactive/edit.svg';
import EditIconActive from '@Assets/icons/settings/active/edit.svg';
interface Props {
    setCreateInfoRoomModalVisibility?: Dispatch<SetStateAction<boolean>>;
}
const InfoRoomTable: FunctionComponent<Props> = ({ setCreateInfoRoomModalVisibility }) => {
    const navigate = useNavigate();
    const { activeActionElement } = useGlobalState();
    const { addToast } = useToasts();
    const { setDialogue, closeDialogue } = useDialogue();

    const [infoRoom, setInfoRoom] = useState<InfoRoomDto[] | null>(null);
    const [hoveredBtn, setHoveredBtn] = useState('');

    useEffect(() => {
        communication.getInfoRoomList().then((res: any) => {
            setInfoRoom(res?.data);
        });
    }, []);

    const goToInfoRoom = (id: string): void => {
        navigate(`/inforooms/${id}`);
    };

    const getNamesCircle = (inforoom: InfoRoomDto): string | null => {
        const nameInitial = inforoom.user?.firstName?.[0] ?? '';
        const lastNameInitial = inforoom.user?.lastName?.[0] ?? '';
        return nameInitial.trim() || lastNameInitial.trim() ? `${nameInitial}${lastNameInitial}` : null;
    };

    const getNames = (inforoom: InfoRoomDto): string => {
        return inforoom.user?.firstName && inforoom.user?.lastName
            ? inforoom.user?.firstName + ' ' + inforoom.user?.lastName
            : '';
    };

    return (
        <div className="all-rooms-page">
            {infoRoom ? (
                <Table className={`${infoRoom?.length === 0 ? 'no-items' : ''}`.trim()}>
                    {infoRoom?.length !== 0 ? (
                        <>
                            <TableHeader columns={6}>
                                <TableHeaderItem>Info Room</TableHeaderItem>
                                <TableHeaderItem>Creator</TableHeaderItem>
                                <TableHeaderItem>Used in</TableHeaderItem>
                                <TableHeaderItem>Date created</TableHeaderItem>
                                <TableHeaderItem>Last updated</TableHeaderItem>
                                <TableHeaderItem>Actions</TableHeaderItem>
                            </TableHeader>
                            <TableBody>
                                {infoRoom?.map((room) => {
                                    return (
                                        <StyledTableItem
                                            columns={6}
                                            key={room?.id}
                                            className="inforoom table-item"
                                            onClick={() => goToInfoRoom(room.id)}
                                        >
                                            <p>{room?.name}</p>
                                            <p>
                                                {room?.user?.properties?.profilePicture ? (
                                                    <img
                                                        className="profile-img"
                                                        crossOrigin="anonymous"
                                                        src={`${process.env.REACT_APP_API_URL}/users/me/picture/${room?.user?.properties?.profilePicture}`}
                                                        alt="profile"
                                                    />
                                                ) : getNamesCircle(room) ? (
                                                    <CircleLetter
                                                        bgColor="var(--primary-500)"
                                                        letter={getNamesCircle(room)}
                                                        withTooltip
                                                        tooltipText={getNames(room)}
                                                    />
                                                ) : null}

                                                {getNames(room)}
                                            </p>
                                            <p>
                                                {room?.timesUsed} {room?.timesUsed === 1 ? 'workspace' : 'workspaces'}
                                            </p>
                                            <p>
                                                {room?.createdAt ? moment(room?.createdAt)?.format('DD/MM/YYYY') : '-'}
                                            </p>
                                            <p>
                                                {room?.updatedAt ? moment(room?.updatedAt)?.format('DD/MM/YYYY') : '-'}
                                            </p>
                                            <p>
                                                <StyledAction
                                                    className={`block-actions ${
                                                        activeActionElement === room.id ? 'active' : ''
                                                    }`}
                                                >
                                                    <img
                                                        id={`actions-${room.id}`}
                                                        className={`block-actions-expander ${
                                                            activeActionElement !== room.id ? 'hidden' : ''
                                                        }`}
                                                        src={MoreDotsIcon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === room.id ? null : room.id,
                                                            });
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <WrapperStyled
                                                        className={`block-actions-body ${
                                                            activeActionElement !== room.id ? 'hidden' : ''
                                                        }`}
                                                    >
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'delete'
                                                                    ? DeleteIconActive
                                                                    : DeleteIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('delete')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setDialogue({
                                                                    description:
                                                                        'Info room will be deleted. This is irreversible operation.',
                                                                    buttons: [
                                                                        {
                                                                            theme: ButtonTheme.danger,
                                                                            text: 'Delete',
                                                                            callback: () => {
                                                                                communication
                                                                                    .deleteInfoRoom(room.id)
                                                                                    .then(() => {
                                                                                        communication
                                                                                            .getInfoRoomList()
                                                                                            .then((res: any) => {
                                                                                                setInfoRoom(res?.data);
                                                                                            });
                                                                                        addToast('Info room deleted!', {
                                                                                            appearance: 'success',
                                                                                        });
                                                                                    })
                                                                                    .catch(() => {
                                                                                        addToast(
                                                                                            'Info room can not be deleted at this point!',
                                                                                            {
                                                                                                appearance: 'error',
                                                                                            },
                                                                                        );
                                                                                    });
                                                                                closeDialogue();
                                                                            },
                                                                        },
                                                                        {
                                                                            theme: ButtonTheme.naked,
                                                                            text: 'Cancel',
                                                                            callback: () => {
                                                                                closeDialogue();
                                                                            },
                                                                        },
                                                                    ],
                                                                });
                                                            }}
                                                        >
                                                            Delete Info room
                                                        </Button>
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'edit'
                                                                    ? EditIconActive
                                                                    : EditIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('edit')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                goToInfoRoom(room.id);
                                                            }}
                                                        >
                                                            Edit Info room
                                                        </Button>
                                                    </WrapperStyled>
                                                </StyledAction>
                                            </p>
                                        </StyledTableItem>
                                    );
                                })}
                            </TableBody>
                        </>
                    ) : (
                        <NoItems
                            content={
                                <>
                                    <p>You don’t have any Info rooms yet.</p>
                                    <div>
                                        <p>Start with</p>
                                        <Button
                                            theme={ButtonTheme.link}
                                            color="var(--primary-400)"
                                            onClick={() => {
                                                setCreateInfoRoomModalVisibility &&
                                                    setCreateInfoRoomModalVisibility(true);
                                            }}
                                        >
                                            creating a new Info room.
                                        </Button>
                                    </div>
                                </>
                            }
                        />
                    )}
                </Table>
            ) : (
                <Table>
                    <SkeletonWrapper>
                        <TableSkeletonWrapper>
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        </TableSkeletonWrapper>
                        <TableSkeletonWrapper>
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        </TableSkeletonWrapper>
                        <TableSkeletonWrapper>
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        </TableSkeletonWrapper>
                        <TableSkeletonWrapper>
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        </TableSkeletonWrapper>
                    </SkeletonWrapper>
                </Table>
            )}
        </div>
    );
};

const StyledTableItem = styled(TableRow)`
    p {
        align-items: center;
        color: var(--black-60);
        font-size: 12px;
        display: flex;

        img {
            &.arrow-rotate-true {
                transform: rotate(180deg);
            }
        }

        .profile-img {
            border-radius: 50px;
            height: 33px;
            margin-right: 10px;
            width: 33px;
            object-fit: cover;
        }
    }
`;

export default InfoRoomTable;
