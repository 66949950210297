import { FunctionComponent } from 'react';
import styled from 'styled-components';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import useGlobalState from '@Store/global';

interface Props {
    elementId: string;
    className?: string;
    onClick?: Function;
    items: BlockActionItem[];
}

export type BlockActionItem = {
    icon: any;
    title: string;
    onClick: Function;
    disabled?: boolean;
    separated?: boolean;
    upload?: boolean;
};

const BlockActions: FunctionComponent<Props> = ({
    elementId,
    className,
    onClick = () => {
        return;
    },
    items,
}) => {
    const { activeActionElement } = useGlobalState();

    return (
        <StyledAction className={`block-actions ${activeActionElement === elementId ? 'active' : ''}`}>
            <img
                id={`actions-${elementId}`}
                className={`block-actions-expander ${activeActionElement !== elementId ? 'hidden' : ''}`}
                src={MoreDotsIcon}
                onClick={() =>
                    useGlobalState.setState({
                        activeActionElement: activeActionElement === elementId ? null : elementId,
                    })
                }
                alt="icon"
            />
            <WrapperStyled
                className={`block-actions-body ${activeActionElement !== elementId ? 'hidden' : ''} ${
                    className ? className : ''
                }`}
                onClick={() => onClick()}
            >
                {items
                    .filter((item) => !item.separated)
                    .map((item, index) => (
                        <div
                            key={`${item.title}-${index}`}
                            className={`block-action-item ${item.disabled ? 'disabled' : ''}`}
                            onClick={() => {
                                useGlobalState.setState({ activeActionElement: null });
                                item.onClick();
                            }}
                        >
                            <img className="block-icon" title={item.title} src={item.icon} alt="icon" />
                            <div className="block-action-item-title">{item.title}</div>
                        </div>
                    ))}
                {items.filter((item) => item.separated).length > 0 && <hr />}
                {items
                    .filter((item) => item.separated)
                    .map((item, index) => (
                        <div
                            key={`${item.title}-${index}`}
                            className={`block-action-item ${item.disabled ? 'disabled' : ''}`}
                            onClick={() => {
                                useGlobalState.setState({ activeActionElement: null });
                                item.onClick();
                            }}
                        >
                            <img className="block-icon" title={item.title} src={item.icon} alt="icon" />
                            <div className="block-action-item-title">{item.title}</div>
                        </div>
                    ))}
            </WrapperStyled>
        </StyledAction>
    );
};

const StyledAction = styled.div`
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 2;

    .block-actions-expander {
        border-radius: 20px;
        padding: 3px;
        background-color: white;
        box-shadow: 0 7px 9px 0 rgb(0 0 0 / 19%), 0 17px 50px 0 rgb(0 0 0 / 15%);
        margin-left: calc(100% - 24px);
        margin-bottom: 8px;
        cursor: pointer;

        &.hidden {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        }
    }
`;

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px;
    background-color: white;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 19%), 0 17px 50px 0 rgb(0 0 0 / 15%);
    border-radius: 20px;
    transition: all 0.2s ease-in-out;

    hr {
        margin: 6px auto;
        background-color: var(--black-75);
        width: 90%;
    }

    .block-action-item {
        padding: 5px 8px;
        border-radius: 20px;
        opacity: 0.6;
        display: flex;
        font-size: 12px;
        line-height: 16px;
        transition: all 0.2s ease-in-out;
        margin: 2px 0;

        img {
            margin-right: 8px;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &.hidden {
        display: none;
    }
`;

export default BlockActions;
