import { FunctionComponent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { isValidEmail, isValidPassword } from '@Helpers/regex';
import Communication from '@Communication';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import AuthStyle from '@Components/style';
import Logo from '@Assets/icons/logo.svg';
import GoogleIcon from '@Assets/icons/google.svg';
import { getGoogleUrl } from '@Helpers/getGoogleUrl';

const Login: FunctionComponent = () => {
    const { addToast } = useToasts();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const handleLogin = (): void => {
        setShowValidationMessage(true);

        if (isValidPassword(password) && isValidEmail(email)) {
            setLoading(true);
            Communication.login(email, password)
                .then(async (res: any) => {
                    setLoading(false);
                    localStorage.setItem('token', res?.data?.token);
                    localStorage.setItem('status', res?.data?.status);
                    navigate('/');
                })
                .catch((error: any) => {
                    if (error) {
                        setLoading(false);
                        setShowValidationMessage(false);
                        addToast('Username or password are invalid! Please check the entered data.', {
                            appearance: 'error',
                        });
                    }
                });
        }
    };

    const locationData = useLocation();
    const from = ((locationData.state as any)?.from?.pathname as string) || '/';

    const redirectGoogleAuth = (): void => {
        setLoadingGoogle(true);
        location.href = getGoogleUrl(from);
    };

    return (
        <AuthStyle>
            <img src={Logo} alt="logo"></img>
            <h3 style={{ margin: '0 0 30px 0' }}>Welcome back!</h3>
            <form>
                <StyledInput
                    type={InputComponentType.Email}
                    placeholder="Email Address"
                    value={email}
                    onChange={(value: string) => setEmail(value)}
                    errorMessage={
                        showValidationMessage
                            ? isValidEmail(email) || email === ''
                                ? ''
                                : 'Please provide valid email!'
                            : ''
                    }
                    size="100%"
                    onEnter={handleLogin}
                />

                <StyledInput
                    type={InputComponentType.Password}
                    placeholder="Password"
                    value={password}
                    onChange={(value: string) => setPassword(value)}
                    errorMessage={
                        showValidationMessage
                            ? isValidPassword(password) || password === ''
                                ? ''
                                : 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                            : ''
                    }
                    size="100%"
                    onEnter={handleLogin}
                />
            </form>
            <ForgotPasswordBlock>
                <Link to="/forgot-password">
                    <span> Recover Password</span>
                </Link>
            </ForgotPasswordBlock>
            <RegisterBlock>
                Don’t have an account?
                <Link to="/sign">
                    <span> Sign Up</span>
                </Link>
            </RegisterBlock>
            <ButtonsBlock>
                <Button
                    theme={ButtonTheme.primary}
                    size={200}
                    disabled={password === '' || email === ''}
                    onClick={handleLogin}
                    isLoading={loading}
                >
                    Log in
                </Button>
                <Button
                    className="google-btn"
                    theme={ButtonTheme.naked}
                    leftIcon={GoogleIcon}
                    size={200}
                    onClick={redirectGoogleAuth}
                    isLoading={loadingGoogle}
                >
                    Log in with Google
                </Button>
            </ButtonsBlock>
        </AuthStyle>
    );
};

const StyledInput = styled(Input)`
    margin: 10px 0;
`;

const ForgotPasswordBlock = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 48px;
    margin-top: 12px;
    span {
        font-size: 14px;
        line-height: 16.94px;
        color: var(--primary-400);
    }
`;

const RegisterBlock = styled.div`
    margin-bottom: 40px;
    color: var(--black-75);
    font-size: 14px;
`;

const ButtonsBlock = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    button {
        &.google-btn.with-icon.left-icon {
            .children {
                img {
                    margin-right: 5px;
                    width: 27px;
                    max-height: none;
                    max-width: none;
                    padding-bottom: 0;
                }
            }
        }
    }
`;

export default Login;
