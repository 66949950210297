import { ActionPlanCreateDto, ActionPlanUpdateDto } from '@/global/dtos/actionPlan.dto';
import fetch from './fetch';
import routes from './routes';

export default class Account {
    public createActionPlan(data: ActionPlanCreateDto): any {
        return fetch({
            method: 'post',
            url: routes.actionPlans.all,
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public updateActionPlan(id: string, data: ActionPlanUpdateDto): any {
        return fetch({
            method: 'patch',
            url: routes.actionPlans.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public deleteActionPlan(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.actionPlans.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
}
