import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import communication from '@Communication';
import { SectionType } from '@/types/workspace';
import 'react-loading-skeleton/dist/skeleton.css';
import useWindowSize from '@Hooks/useResize';
import EditorBase from '@Components/editor/base';
import useEditorStore from '@Store/editor';
import Sidebar from '@/pages/infoRooms/sidebar';
import { InfoRoomDto } from '@Global/dtos/inforoom.dto';
import styled from 'styled-components';
import { EditorType } from '@/types/editor';
import { EditorMobile } from '../../workspaces/editor';

const InfoRoomEditor: FunctionComponent = () => {
    const { roomId } = useParams();
    const { setActiveElement, setEditorData } = useEditorStore();
    const size = useWindowSize();

    const [updatedAt, setUpdatedAt] = useState(null);
    const [infoRoomData, setInfoRoomData] = useState<InfoRoomDto | null>(null);
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 900);

    useEffect(() => {
        setIsTabletWidth(!!size.width && size.width < 900);
    }, [size.width]);

    const updateInfoRoom = async (data: { title: string; sections: SectionType[] }): Promise<void> => {
        const res = await communication.updateInfoRoomData(roomId as string, data);
        setUpdatedAt(res.data.updatedAt);
    };

    // FIRST EFFECT WHEN PAGE LOADS
    useEffect(() => {
        if (roomId) {
            communication.getInfoRoom(roomId).then((res: any) => {
                setActiveElement(null);
                setEditorData(roomId, res.data.content?.title, res.data.content?.sections, EditorType.INFO_ROOM);
                setUpdatedAt(res.data.updatedAt);
                setInfoRoomData(res.data as InfoRoomDto);
            });
        }
    }, []);

    return (
        <>
            {infoRoomData && <Sidebar data={infoRoomData} className="editor-sidebar" />}
            {/* BASE */}
            {!isTabletWidth ? (
                <StyledEditorBase
                    className="info-room-editor"
                    updatedAt={updatedAt}
                    update={updateInfoRoom}
                    backNavigation="/inforooms"
                />
            ) : (
                <EditorMobile className="editor-mobile">
                    <p>Your browser is too small. Resize it to be at least 900px wide to edit content.</p>
                </EditorMobile>
            )}
        </>
    );
};

const StyledEditorBase = styled(EditorBase)`
    height: calc(100vh - 68px);
`;

export default InfoRoomEditor;
