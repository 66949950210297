import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    className?: string;
    children?: string | any;
}

const Sidebar: FunctionComponent<Props> = ({ className, children }) => {
    return <StyledSidebar className={`sidebar  ${className ? className : ''}`.trim()}>{children}</StyledSidebar>;
};

const StyledSidebar = styled.div`
    position: relative;
    background-color: white;
    box-sizing: border-box;
    float: right;
    flex-direction: column;
    display: flex;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    width: 380px;
    border-left: 1px solid var(--black-50);
    padding: 37px 24px;
    overflow-y: auto;
    .form-wrapper {
        padding: 5px;
        flex: 1;
        overflow: auto;

        .input,
        select {
            margin: 16px 0;
        }

        textarea {
            height: 200px;
        }
    }
    &:before {
        opacity: 0;
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(170, 170, 170, 0.9);
        z-index: 1;
        transition: all 0.3s ease-in-out;
    }

    &.overlay-visible {
        overflow: hidden;

        &:before {
            opacity: 1;
            pointer-events: all;
        }
    }
`;
export default Sidebar;
