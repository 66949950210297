import { create } from 'zustand';
import { SectionType } from '@/types/workspace';
import { EditorType } from '@/types/editor';

interface EditorState {
    id: string;
    title: string;
    sections: SectionType[];
    type: EditorType;
    activeSectionIndex: number;
    activeSectionId: string | null;
    activeElementId: string | null;
    setActiveElement: (id: string | null) => void;
    setActiveSectionIndex: (index: number) => void;
    setActiveSectionId: (id: string | null) => void;
    updateSections: (sections: SectionType[]) => void;
    getEditorData: () => { id: string; title: string; sections: SectionType[] };
    setEditorData: (id: string, title: string, sections: SectionType[], type: EditorType) => void;
}

const useEditorStore = create<EditorState>((set, get) => ({
    id: '',
    title: '',
    type: EditorType.WORKSPACE,
    sections: [],
    activeSectionIndex: 0,
    activeElementId: null,
    activeSectionId: null,
    setActiveElement: (id) => set({ activeElementId: id }),
    setActiveSectionId: (id) => set({ activeSectionId: id }),
    setActiveSectionIndex: (index) => set({ activeSectionIndex: index }),
    updateSections: (sections) => set({ sections }),
    getEditorData: () => ({ id: get().id, title: get().title, sections: get().sections }),
    setEditorData: (id, title, sections, type) => set({ id, title, sections, type }),
}));

export default useEditorStore;
