import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { returnToLogin } from '@Helpers/auth';
import Communication from '@Communication';
import Button, { ButtonTheme } from '@Components/button';
import { CommonStyledPopup } from '@Components/popup/styledPopup';

const Confirm: React.FunctionComponent = () => {
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isError, setIsError] = useState(false);
    const [backUrl, setBackUrl] = useState('');
    const [popupVisible] = useState(true);

    const confirmAction = (): void => {
        const token = urlParams.get('token') ?? '';
        if (!token) {
            setIsError(true);
        }
        Communication.confirmUser(token)
            .then((res: any) => {
                setIsConfirmed(true);
                if (res.status === 200) {
                    const url = new URL(`${document.location.origin}/register`);

                    url.searchParams.append('token', res.data.token);
                    url.searchParams.append('status', res.data.status);
                    setBackUrl(url.toString());
                }
            })
            .catch((e: Error) => {
                if (e) {
                    setIsError(true);
                }
            });
    };

    useEffect(() => {
        confirmAction();
    }, []);

    return (
        <StyledPage className="page info">
            <CommonStyledPopup isVisible={popupVisible}>
                {isConfirmed ? (
                    <>
                        <div className="title">
                            <h3>Your account is confirmed! Thank you.</h3>
                        </div>
                        <Button
                            theme={ButtonTheme.primary}
                            size={250}
                            onClick={() => {
                                window.location.href = backUrl;
                            }}
                        >
                            Return back to the platform
                        </Button>
                    </>
                ) : isError ? (
                    <>
                        <div className="title">
                            <h3>Ups, something is not right! </h3>
                        </div>
                        <div className="description">
                            <p>Token is not correct or missing.</p>
                        </div>
                        <Button theme={ButtonTheme.primary} size={250} onClick={() => returnToLogin()}>
                            Return back to login
                        </Button>
                    </>
                ) : (
                    <>
                        <div className="title">
                            <h3>Please wait... </h3>
                        </div>
                    </>
                )}
            </CommonStyledPopup>
        </StyledPage>
    );
};

const StyledPage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button {
        margin-top: 27px;
    }
`;

export default Confirm;
