import React, { useEffect, useState } from 'react';
import communication from '@Communication';
import { AccountSidebarTabs, CompanySidebarSections, ContactSidebarSections } from '../sidebar';
import { AssociatedContactDto } from '@Global/dtos/contact.dto';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import Button, { ButtonTheme } from '../../../../components/button';
import useContactStore from '../../../../store/contact';
import useCompanyStore from '../../../../store/company';
import { AssociatedCompanyDto } from '@Global/dtos/company.dto';
import contacts from '@Assets/icons/accountsSidebar/users.svg';
import companies from '@Assets/icons/accountsSidebar/organization.svg';
import SelectComponent from '@Components/select';
import AddIcon from '@Assets/icons/workspaces/add-circle.svg';
import CompanyIcon from '@Assets/icons/accounts/company.svg';
import ListCard from '../../../../components/listCard';
import { StyledTitle, StyledHeader, TwoButtonsStyle } from '../../../workspaces/editor/sidebar/styledComponents';
import AccountsImageName from '../accountsImageName';

interface Props {
    activeTab: number;
    updateConnectedContacts: Function;
    updateConnectedCompanies: Function;
    loading: boolean;
    changeSection: Function;
}

const AssociatedAccounts: React.FunctionComponent<Props> = ({
    activeTab,
    updateConnectedContacts,
    updateConnectedCompanies,
    changeSection,
    loading,
}) => {
    const [contactOptionListSearch, setContactOptionListSearch] = useState<Array<AssociatedContactDto>>([]);
    const [companiesOptionListSearch, setCompaniesOptionListSearch] = useState<Array<AssociatedCompanyDto>>([]);

    const { id: companyId, CompanyContacts: companyContacts } = useCompanyStore();
    const { id: contactId, CompanyContacts: contactCompanies } = useContactStore();

    const [selectedContact, setSelectedContact] = useState<AssociatedContactDto | null>(null);
    const [connectedContacts, setConnectedContacts] = useState<Array<AssociatedContactDto>>([]);
    const [selectedCompany, setSelectedCompany] = useState<AssociatedCompanyDto | null>(null);
    const [connectedCompanies, setConnectedCompanies] = useState<Array<AssociatedCompanyDto>>([]);

    const [contactIdsAdd, setContactIdsAdd] = useState<Array<string>>([]);
    const [contactIdsRemove, setContactIdsRemove] = useState<Array<string>>([]);
    const [companyIdsAdd, setCompanyIdsAdd] = useState<Array<string>>([]);
    const [companyIdsRemove, setCompanyIdsRemove] = useState<Array<string>>([]);

    const [hasChangesCompany, setHasChangesCompany] = useState(false);
    const [hasChangesContact, setHasChangesContact] = useState(false);

    useEffect(() => {
        if (companyIdsAdd.length > 0 || companyIdsRemove.length > 0) {
            setHasChangesCompany(true);
        } else {
            setHasChangesCompany(false);
        }
    }, [companyIdsAdd, companyIdsRemove]);

    useEffect(() => {
        if (contactIdsAdd.length > 0 || contactIdsRemove.length > 0) {
            setHasChangesContact(true);
        } else {
            setHasChangesContact(false);
        }
    }, [contactIdsAdd, contactIdsRemove]);

    useEffect(() => {
        communication.getAllCompanies().then((res: any) => {
            const connectedCompanyList = contactCompanies?.map((contact) => {
                return {
                    id: contact.company.id,
                    name: contact.company.name ?? '',
                    email: contact.company.email ?? '',
                    website: contact.company.website,
                    value: contact.company.name ?? '',
                    label: contact.company.name ?? '',
                };
            });
            setConnectedCompanies([...(connectedCompanyList ?? [])]);
            const connectedCompanyIds = connectedCompanyList?.map((company: { id: string }) => company.id);
            const filteredCompaniesOptionList = res.data?.filter(
                (item: AssociatedCompanyDto) => !connectedCompanyIds?.includes(item.id),
            );
            const filteredCompanyOptionListMap = filteredCompaniesOptionList.map((item: AssociatedCompanyDto) => {
                return {
                    ...item,
                    value: item.name,
                    label: `${item.name}`,
                };
            });
            setCompaniesOptionListSearch(filteredCompanyOptionListMap);
        });

        communication.getAllContacts().then((res: any) => {
            const connectedContactList = companyContacts?.map((company) => {
                return {
                    id: company.contact.id,
                    firstName: company.contact.firstName,
                    lastName: company.contact.lastName !== null ? company.contact.lastName : '',
                    name: `${company.contact.firstName} ${
                        company.contact.lastName !== null ? company.contact.lastName : ''
                    }`.trim(),
                    email: company.contact.email,
                    value: `${company.contact.firstName} ${
                        company.contact.lastName !== null ? company.contact.lastName : ''
                    }`.trim(),
                    label: `${company.contact.firstName} ${
                        company.contact.lastName !== null ? company.contact.lastName : ''
                    }`.trim(),
                };
            });
            setConnectedContacts(connectedContactList ?? []);

            const connectedContactIds = connectedContactList?.map((contact: { id: string }) => contact.id);
            const filteredContactOptionList = res.data?.filter(
                (item: AssociatedContactDto) => !connectedContactIds?.includes(item.id),
            );
            const filteredContactOptionListMap = filteredContactOptionList.map((item: AssociatedContactDto) => {
                return {
                    ...item,
                    name: `${item.firstName} ${item.lastName !== null ? item.lastName : ''}`.trim(),
                    value: `${item.firstName} ${item.lastName !== null ? item.lastName : ''}`.trim(),
                    label: `${item.firstName} ${item.lastName !== null ? item.lastName : ''}`.trim(),
                };
            });
            setContactOptionListSearch(filteredContactOptionListMap);
        });
    }, [contactId, companyId, companyContacts, contactCompanies]);

    const addAssociatedContact = (contact: AssociatedContactDto): void => {
        setConnectedContacts((current) => [...current, contact as AssociatedContactDto]);

        const filteredContactOptionList = contactOptionListSearch.filter((item) => item.id !== contact.id);
        setContactOptionListSearch(filteredContactOptionList);

        const contactCompaniesIds = companyContacts?.map((company) => company.contact.id);

        if (!contactCompaniesIds?.includes(contact.id)) {
            setContactIdsAdd((current) => [...current, contact.id]);
        }
        if (contactCompaniesIds?.includes(contact.id)) {
            const filteredContacts = contactIdsRemove.filter((item) => item !== contact.id);
            setContactIdsRemove(filteredContacts);
        }
        setSelectedContact(null);
    };

    const addAssociatedCompany = (company: AssociatedCompanyDto): void => {
        setConnectedCompanies((current) => [...current, company as AssociatedCompanyDto]);
        const filteredCompanyOptionList = companiesOptionListSearch.filter((item) => item.id !== company.id);

        setCompaniesOptionListSearch(filteredCompanyOptionList);

        const companyContactsIds = contactCompanies?.map((contact) => contact.company.id);

        if (!companyContactsIds?.includes(company.id)) {
            setCompanyIdsAdd((current) => [...current, company.id]);
        }
        if (companyContactsIds?.includes(company.id)) {
            const filteredCompanies = companyIdsRemove.filter((item) => item !== company.id);
            setCompanyIdsRemove(filteredCompanies);
        }
        setSelectedCompany(null);
    };

    const removeAssociatedContact = (contact: AssociatedContactDto): void => {
        const filteredContactOptionList = connectedContacts.filter((item) => item.id !== contact.id);
        setConnectedContacts(filteredContactOptionList);

        setContactOptionListSearch((current) => [...current, contact as AssociatedContactDto]);

        const contactCompaniesIds = companyContacts?.map((company) => company.contact.id);

        if (contactCompaniesIds?.includes(contact.id)) {
            setContactIdsRemove((current) => [...current, contact.id]);
        }
        if (!contactCompaniesIds?.includes(contact.id)) {
            const filteredAddIds = contactIdsAdd.filter((item) => item !== contact.id);
            setContactIdsAdd(filteredAddIds);
        }
    };

    const removeAssociatedCompany = (company: AssociatedCompanyDto): void => {
        const filteredCompanyOptionList = connectedCompanies.filter((item) => item.id !== company.id);
        setConnectedCompanies(filteredCompanyOptionList);
        setCompaniesOptionListSearch((current) => [...current, company as AssociatedCompanyDto]);

        const companyContactsIds = contactCompanies?.map((contact) => contact.company.id);

        if (companyContactsIds?.includes(company.id)) {
            setCompanyIdsRemove((current) => [...current, company.id]);
        }
        if (!companyContactsIds?.includes(company.id)) {
            const filteredRemoveIds = companyIdsAdd.filter((item) => item !== company.id);
            setCompanyIdsAdd(filteredRemoveIds);
        }
    };

    const updateContactCompanyRelation = async (): Promise<void> => {
        if (activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)) {
            updateConnectedContacts(contactId, {
                contact: true,
                addRelation: companyIdsAdd,
                removeRelation: companyIdsRemove,
            });
        } else if (activeTab === (AccountSidebarTabs.COMPANY_SIDEBAR as unknown)) {
            updateConnectedCompanies(companyId, {
                company: true,
                addRelation: contactIdsAdd,
                removeRelation: contactIdsRemove,
            });
        }
        setContactIdsAdd([]);
        setContactIdsRemove([]);

        setCompanyIdsAdd([]);
        setCompanyIdsRemove([]);
        return;
    };

    return (
        <>
            {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? (
                <>
                    <StyledHeader onClick={() => changeSection(ContactSidebarSections.CONTACT_MAIN)}>
                        <img src={arrowLeft} alt="icon" />
                        {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Contact' : 'Company'} settings
                    </StyledHeader>
                    <AccountsImageName activeTab={activeTab} />
                    <StyledTitle>
                        <img src={companies} className="front" alt="icon" />
                        Associated companies
                    </StyledTitle>
                    <div className="frame-wrapper">
                        <div className={`account-select-wrapper ${selectedCompany ? 'selected' : ''}`}>
                            <SelectComponent
                                placeholder="Choose new company"
                                optionList={companiesOptionListSearch}
                                value={selectedCompany}
                                onChange={(data) => {
                                    setSelectedCompany(data as unknown as AssociatedCompanyDto);
                                    addAssociatedCompany(data as unknown as AssociatedCompanyDto);
                                }}
                            />
                            <Button
                                theme={ButtonTheme.primary}
                                disabled={!selectedCompany}
                                rightIcon={AddIcon}
                                onClick={() => {
                                    if (selectedCompany) {
                                        addAssociatedCompany(selectedCompany);
                                    }
                                }}
                            ></Button>
                        </div>
                        <div className="items-list">
                            <p>Associated companies:</p>
                            {connectedCompanies.length > 0 ? (
                                connectedCompanies
                                    ?.filter((company) => company.website)
                                    ?.map((company) => {
                                        return (
                                            <ListCard
                                                key={company.email}
                                                title={company.name}
                                                subtitle={company.website}
                                                placeholderLogo={CompanyIcon}
                                                removeCallback={() => removeAssociatedCompany(company)}
                                            />
                                        );
                                    })
                            ) : (
                                <div className="share-empty-list">No one at the moment!</div>
                            )}
                        </div>
                    </div>
                </>
            ) : activeTab === (AccountSidebarTabs.COMPANY_SIDEBAR as unknown) ? (
                <>
                    <StyledHeader onClick={() => changeSection(CompanySidebarSections.COMPANY_MAIN)}>
                        <img src={arrowLeft} alt="icon"></img>
                        {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Contact' : 'Company'} Settings
                    </StyledHeader>
                    <AccountsImageName activeTab={activeTab} />
                    <StyledTitle>
                        <img src={contacts} className="front" alt="icon"></img>
                        {'Associated contacts'}
                    </StyledTitle>
                    <div className="frame-wrapper">
                        <div className={`account-select-wrapper ${selectedContact ? 'selected' : ''}`}>
                            <SelectComponent
                                placeholder="Choose new contact"
                                optionList={contactOptionListSearch}
                                value={selectedContact}
                                onChange={(data) => {
                                    setSelectedContact(data as unknown as AssociatedContactDto);
                                    addAssociatedContact(data as unknown as AssociatedContactDto);
                                }}
                            />
                            <Button
                                theme={ButtonTheme.primary}
                                disabled={!selectedContact}
                                rightIcon={AddIcon}
                                onClick={() => {
                                    if (selectedContact) {
                                        addAssociatedContact(selectedContact);
                                    }
                                }}
                            ></Button>
                        </div>
                        <div className="items-list">
                            <p>Associated contacts:</p>
                            {connectedContacts.length > 0 ? (
                                connectedContacts
                                    ?.filter((contact) => contact.email)
                                    ?.map((contact) => {
                                        return (
                                            <ListCard
                                                key={contact.email}
                                                title={contact.email}
                                                subtitle={contact.name}
                                                // statusImage={AddIcon}
                                                removeCallback={() => removeAssociatedContact(contact)}
                                            />
                                        );
                                    })
                            ) : (
                                <div className="share-empty-list">No one at the moment!</div>
                            )}
                        </div>
                    </div>
                </>
            ) : null}
            {(activeTab === (AccountSidebarTabs.COMPANY_SIDEBAR as unknown)
                ? hasChangesContact
                : hasChangesCompany) && (
                <TwoButtonsStyle>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.primary}
                        onClick={() => updateContactCompanyRelation()}
                        isLoading={loading}
                        disabled={
                            activeTab === (AccountSidebarTabs.COMPANY_SIDEBAR as unknown)
                                ? !hasChangesContact
                                : !hasChangesCompany
                        }
                    >
                        Save
                    </Button>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                                ? changeSection(ContactSidebarSections.CONTACT_MAIN)
                                : changeSection(CompanySidebarSections.COMPANY_MAIN);
                        }}
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            )}
        </>
    );
};
export default AssociatedAccounts;
