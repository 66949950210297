import { FunctionComponent } from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import attachSize from '@Helpers/size';

interface Props {
    type?: string;
    id?: string;
    value?: string;
    name?: string;
    placeholder?: string;
    onChange?: Function;
    onEnter?: Function;
    readOnly?: boolean;
    timeHasTwoInputs?: boolean;
    size?: number;
    error?: boolean;
    errorMessage?: string;
    label?: string;
    isDisabled?: boolean;
    maxLength?: number;
}

const TextArea: FunctionComponent<Props> = ({
    type,
    id,
    value,
    name,
    placeholder,
    label,
    onChange = Function,
    error,
    size,
    maxLength,
}) => {
    return (
        <StyledWrapper className="input" size={size}>
            <>
                <StyledInputContainer>
                    {label ? <label>{label}</label> : <></>}
                    <StyledInput
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        value={value}
                        id={id}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        error={error}
                        maxLength={maxLength}
                    />
                </StyledInputContainer>
            </>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div<Props>`
    justify-content: center;
    outline-color: var(--grey);
    min-width: ${(props) => attachSize(props.size)};
}`;

const StyledInput = styled.textarea<Props>`
    background-color: ${(props) => (!props.error ? 'var(--background-grey)' : 'var(--red-background-error)')};
    outline-color: ${(props) => (!props.error ? 'var(--background-grey)' : 'var(--red-150)')};
    font-family: inherit;
    border-radius: 5px;
    border-style: hidden;
    font-size: 14px;
    text-align-last: left;
    padding: 16px 18px;
    display: flex;
    resize: vertical;
    &:focus {
        filter: drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1));
        background-color: var(--white);
        outline: none;
    }
`;

const StyledInputContainer = styled.div<Props>`
    margin-bottom: ${(props) => (!props.error ? '10px 10px 20px 10px' : '10px 10px 5px 10px')};
    text-align-last: left;
    display: flex;
    margin-inline: auto;
    outline-color: ${(props) => (!props.error ? 'var(--background-grey)' : 'var(--red-150)')};
    font-family: inherit;
    border-radius: 5px;
    border-style: hidden;
    font-size: 16px;
    flex-direction: column;

    label {
        padding: 8px 0px;
        color: var(--black-75);
        font-size: 12px;
    }
`;

export default TextArea;
