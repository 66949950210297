import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { WorkspaceDto } from '@Global/dtos/workspace.dto';
import Communication from '@Communication';
import Button, { ButtonTheme } from '@Components/button';
import useEditorStore from '@Store/editor';
import useWorkspaceStore from '@Store/workspace';
import useDialogue from '@Hooks/useDialogue';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import CloneIconActive from '@Assets/icons/settings/active/clone.svg';
import CloneIconInactive from '@Assets/icons/settings/inactive/clone.svg';
import ArchiveIconActive from '@Assets/icons/settings/active/archive.svg';
import ArchiveIconInactive from '@Assets/icons/settings/inactive/archive.svg';
import UnPublishIconActive from '@Assets/icons/settings/active/eye-hide.svg';
import UnPublishIconInactive from '@Assets/icons/settings/inactive/eye-hide.svg';
import EditIconInactive from '@Assets/icons/settings/inactive/edit.svg';
import EditIconActive from '@Assets/icons/settings/active/edit.svg';
interface Props {
    selectedWorkspaceId?: string;
    isSelectedPublished?: {};
    isSelectedArchived?: boolean;
    setAllWorkspaces?: Dispatch<SetStateAction<WorkspaceDto[] | null>>;
    setFilteredWorkspaces?: Dispatch<SetStateAction<WorkspaceDto[] | null>>;
    activeTab?: number;
}
const WorkspaceActions: FunctionComponent<Props> = ({
    selectedWorkspaceId,
    isSelectedPublished,
    isSelectedArchived,
    setAllWorkspaces,
    setFilteredWorkspaces,
    activeTab,
}) => {
    const { workspaceId } = useParams();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const { updatePublishStatus, isPublished, isArchived } = useWorkspaceStore();
    const { id } = useEditorStore();
    const { setDialogue, closeDialogue } = useDialogue();

    const [currentWorkspaceId, setCurrentWorkspaceId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hoveredBtn, setHoveredBtn] = useState('');

    useEffect(() => {
        if (selectedWorkspaceId) {
            setCurrentWorkspaceId(selectedWorkspaceId);
        } else workspaceId && setCurrentWorkspaceId(workspaceId);
    }, [selectedWorkspaceId, workspaceId]);

    const updateWorkspaces = (active: number | undefined): void => {
        if (active === 1) {
            Communication.getAllWorkspaces().then((res: any) => {
                setAllWorkspaces && setAllWorkspaces(res?.data);
                setFilteredWorkspaces && setFilteredWorkspaces(res?.data);
            });
        } else
            Communication.getAllArchivedWorkspaces().then((res: any) => {
                setAllWorkspaces && setAllWorkspaces(res?.data);
                setFilteredWorkspaces && setFilteredWorkspaces(res?.data);
            });
    };

    return (
        <>
            <Button
                theme={ButtonTheme.link}
                leftIcon={hoveredBtn === 'unPublish' ? UnPublishIconActive : UnPublishIconInactive}
                onMouseEnter={() => setHoveredBtn('unPublish')}
                onMouseLeave={() => setHoveredBtn('')}
                disabled={isPublished ? !isPublished : !isSelectedPublished}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsLoading(true);
                    Communication.concealWorkspace(currentWorkspaceId as string)
                        .then(() => {
                            setIsLoading(false);
                            updateWorkspaces(activeTab);
                            addToast('Workspace successfully unpublished!', {
                                appearance: 'success',
                            });
                            updatePublishStatus(false);
                        })
                        .catch(() => {
                            setIsLoading(false);
                            addToast('Unable to unpublish workspace. Please try again.', {
                                appearance: 'error',
                            });
                        });
                }}
            >
                Unpublish Workspace
            </Button>
            <Button
                theme={ButtonTheme.link}
                leftIcon={hoveredBtn === 'clone' ? CloneIconActive : CloneIconInactive}
                isLoading={isLoading}
                onMouseEnter={() => setHoveredBtn('clone')}
                onMouseLeave={() => setHoveredBtn('')}
                onClick={(e) => {
                    e.stopPropagation();
                    setDialogue({
                        title: 'Duplicate this workspace?',
                        buttons: [
                            {
                                theme: ButtonTheme.primary,
                                text: 'Duplicate',
                                callback: () => {
                                    setIsLoading(true);
                                    Communication.duplicateWorkspace(currentWorkspaceId as string)
                                        .then(() => {
                                            setIsLoading(false);
                                            updateWorkspaces(activeTab);
                                            navigate('/workspaces');
                                            addToast('Workspace successfully cloned!', {
                                                appearance: 'success',
                                            });
                                        })
                                        .catch(() => {
                                            setIsLoading(false);
                                            addToast('Unable to clone workspace. Please try again.', {
                                                appearance: 'error',
                                            });
                                        });
                                    closeDialogue();
                                },
                            },
                            {
                                theme: ButtonTheme.naked,
                                text: 'Cancel',
                                callback: () => {
                                    closeDialogue();
                                },
                            },
                        ],
                    });
                }}
            >
                Clone Workspace
            </Button>
            <Button
                theme={ButtonTheme.link}
                leftIcon={hoveredBtn === 'archive' ? ArchiveIconActive : ArchiveIconInactive}
                isLoading={isLoading}
                onMouseEnter={() => setHoveredBtn('archive')}
                onMouseLeave={() => setHoveredBtn('')}
                disabled={isArchived ? isArchived : isSelectedArchived}
                onClick={(e) => {
                    e.stopPropagation();
                    setDialogue({
                        title: 'Archive this workspace?',
                        buttons: [
                            {
                                theme: ButtonTheme.primary,
                                text: 'Archive',
                                callback: () => {
                                    setIsLoading(true);
                                    Communication.archiveWorkspace(currentWorkspaceId as string)
                                        .then(() => {
                                            setIsLoading(false);
                                            updateWorkspaces(activeTab);
                                            addToast('Workspace successfully archived!', {
                                                appearance: 'success',
                                            });
                                            updatePublishStatus(false);
                                        })
                                        .catch(() => {
                                            setIsLoading(false);
                                            addToast('Unable to archive workspace. Please try again.', {
                                                appearance: 'error',
                                            });
                                        });
                                    closeDialogue();
                                },
                            },
                            {
                                theme: ButtonTheme.naked,
                                text: 'Cancel',
                                callback: () => {
                                    closeDialogue();
                                },
                            },
                        ],
                    });
                }}
            >
                Archive Workspace
            </Button>
            <Button
                theme={ButtonTheme.link}
                leftIcon={hoveredBtn === 'delete' ? DeleteIconActive : DeleteIconInactive}
                isLoading={isLoading}
                onMouseEnter={() => setHoveredBtn('delete')}
                onMouseLeave={() => setHoveredBtn('')}
                onClick={(e) => {
                    e.stopPropagation();
                    setDialogue({
                        title: 'Delete this workspace',
                        description: 'This workspace will be deleted forever and you won’t be able to restore it.',
                        buttons: [
                            {
                                theme: ButtonTheme.danger,
                                text: 'Delete',
                                callback: () => {
                                    setIsLoading(true);
                                    Communication.deleteWorkspace(id ? id : currentWorkspaceId)
                                        .then(() => {
                                            setIsLoading(false);
                                            updateWorkspaces(activeTab);
                                            navigate('/workspaces');
                                            addToast('Workspace successfully deleted!', {
                                                appearance: 'success',
                                            });
                                        })
                                        .catch(() => {
                                            addToast('Workspace can not be deleted at this point!', {
                                                appearance: 'error',
                                            });
                                            setIsLoading(false);
                                        });
                                    closeDialogue();
                                },
                            },
                            {
                                theme: ButtonTheme.naked,
                                text: 'Cancel',
                                callback: () => {
                                    closeDialogue();
                                },
                            },
                        ],
                    });
                }}
            >
                Delete Workspace
            </Button>

            {selectedWorkspaceId ? (
                <Button
                    theme={ButtonTheme.link}
                    leftIcon={hoveredBtn === 'edit' ? EditIconActive : EditIconInactive}
                    isLoading={isLoading}
                    onMouseEnter={() => setHoveredBtn('edit')}
                    onMouseLeave={() => setHoveredBtn('')}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/workspaces/${selectedWorkspaceId}`);
                    }}
                >
                    Edit Workspace
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default WorkspaceActions;
