import { FunctionComponent, useState, useEffect } from 'react';
import Phone from '@Assets/icons/settings/phone.svg';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import { TwoButtonsStyle, MainWrapper, PositionStyle, StyledHeader, StyledTitle } from '../styledComponents';
import SelectComponent, { SelectOptionType } from '@Components/select';
import Button, { ButtonTheme } from '@Components/button';
import useUserStore from '@Store/user';
import useWorkspaceStore from '@Store/workspace';
import communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import useEditorStore from '@Store/editor';
import { UserDto } from '@/global/dtos/user.dto';

interface Props {
    isActive: boolean;
    changePage: Function;
}

const PointsOfContact: FunctionComponent<Props> = ({ isActive, changePage }) => {
    const { usersList } = useUserStore();
    const { id, title, sections } = useEditorStore();
    const { sidePanelContent, updatePublishStatus } = useWorkspaceStore();
    const { addToast, removeAllToasts } = useToasts();
    const [isLoading, setIsLoading] = useState(false);

    const users = usersList.map((user) => ({
        value: user.id,
        label:
            user.firstName === '' && user.lastName === ''
                ? user.email
                : `${user.firstName} ${user.lastName ? user.lastName : ''}`,
    }));

    const [selectedUsers, setSelectedUsers] = useState<SelectOptionType[]>(users);
    const [hasChanges, setHasChanges] = useState(false);

    const arrayEquals = (a: string[], b: string[]): boolean => {
        return (
            Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
        );
    };

    useEffect(() => {
        communication.getAllUsers().then((res: any) => {
            const readyUsersList = res.data.filter((user: UserDto) => user.status === 'READY');
            useUserStore.setState({
                usersList: readyUsersList ?? [],
            });
        });
    }, []);

    useEffect(() => {
        const mappedUsers = usersList
            .filter((ul) => sidePanelContent.pointsOfContact.contacts.map((c) => c.id).includes(ul.id))
            .map((userL) => ({
                value: userL.id,
                label:
                    userL.firstName === '' && userL.lastName === ''
                        ? userL.email
                        : `${userL.firstName} ${userL.lastName ? userL.lastName : ''}`,
            }));
        setSelectedUsers(mappedUsers);
    }, [usersList]);

    useEffect(() => {
        if (
            !arrayEquals(
                sidePanelContent.pointsOfContact.contacts.map((contact) => {
                    return contact.id;
                }),
                selectedUsers.map((user) => {
                    return user.value;
                }),
            )
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [selectedUsers]);

    const update = (): void => {
        setIsLoading(true);
        removeAllToasts();
        const tmpData = sidePanelContent;
        tmpData.pointsOfContact.contacts = selectedUsers.map((c) => ({
            id: c.value,
            name: c.label,
            email: usersList.find((us) => us.id === c.value)?.email,
            properties: usersList.find((us) => us.id === c.value)?.properties,
        }));
        communication
            .updateWorkspaceContent(id, { title, sections, sidePanelContent: tmpData })
            .then(() => {
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                useWorkspaceStore.setState({
                    sidePanelContent: tmpData,
                });
                setHasChanges(false);
                setIsLoading(false);
                updatePublishStatus(false);
            })
            .catch(() => {
                addToast('Something went wrong! Please check entered data.', {
                    appearance: 'error',
                });
                setIsLoading(false);
            });
    };

    return (
        <MainWrapper>
            {isActive ? (
                <>
                    <StyledHeader onClick={() => changePage(0)}>
                        <img src={arrowLeft} alt="icon"></img>
                        Settings
                    </StyledHeader>
                    <StyledTitle>
                        <img src={Phone} alt="icon" />
                        Points of contact
                    </StyledTitle>
                    <PositionStyle>
                        <SelectComponent
                            label="Points of contact"
                            placeholder="Choose"
                            value={selectedUsers}
                            onChange={(data) => setSelectedUsers(data as SelectOptionType[])}
                            optionList={users}
                            isMulti={true}
                            isClearable={true}
                        />
                    </PositionStyle>
                    {hasChanges && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                isLoading={isLoading}
                                onClick={() => {
                                    update();
                                }}
                            >
                                Save
                            </Button>
                            <Button className="clickable" theme={ButtonTheme.naked} onClick={() => changePage(0)}>
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                </>
            ) : (
                <></>
            )}
        </MainWrapper>
    );
};

export default PointsOfContact;
