import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StatisticDto } from '@Global/dtos/analytics.dto';
import communication from '@Communication';
import useGlobalState from '@Store/global';
import hamburgerIcon from '@Assets/icons/settings/hamburger.svg';

interface Props {
    workspaceId?: string;
    workspaceName?: string;
}

const AnalyticsInfo: FunctionComponent<Props> = ({ workspaceId, workspaceName }) => {
    const [statisticData, setStatisticData] = useState<StatisticDto | null>(null);

    useEffect(() => {
        if (workspaceId) {
            communication.getStatisticByWSId(workspaceId).then((res: any) => {
                setStatisticData(res.data);
            });
        }
    }, [workspaceId]);

    return (
        <StyledAnalyticsInfo>
            <StyledHeader>
                <AnalyticsTitle>Insights</AnalyticsTitle>
                <StyledAction onClick={() => useGlobalState.setState({ workspaceContextMenu: true })}>
                    <img src={hamburgerIcon} alt="icon"></img>
                </StyledAction>
            </StyledHeader>
            <AnalyticsContent>
                <div>
                    <h3>{statisticData?.views}</h3>
                    <p>Views</p>
                </div>
                <div>
                    <h3>{statisticData?.downloads}</h3>
                    <p>Downloads</p>
                </div>
                <div>
                    <h3>{statisticData?.shares}</h3>
                    <p>Shares</p>
                </div>
                <div>
                    <h3>{statisticData?.clicks}</h3>
                    <p>Clicks</p>
                </div>
            </AnalyticsContent>
            <AnalyticsViewAll>
                <StyledLink to={`/analytics/workspace/${workspaceId}?name=${workspaceName}`}>View All</StyledLink>
            </AnalyticsViewAll>
        </StyledAnalyticsInfo>
    );
};
const StyledAnalyticsInfo = styled.div`
    margin-bottom: 42px;
`;
const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;
const StyledAction = styled.div`
    cursor: pointer;
    transition: 0.2s ease-in-out all;

    &:active {
        transform: scale(0.9);
    }

    &:hover {
        filter: drop-shadow(0 0 8px #00726d);
    }
`;
const AnalyticsTitle = styled.div`
    margin-bottom: 38px;
`;

const AnalyticsContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 14px;
    div {
        padding: 14px 12px;
        text-align: center;
    }
    h3 {
        color: var(--primary-400);
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 8px;
    }
    p {
        color: var(--black-60);
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
    }
`;

const AnalyticsViewAll = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:before {
        content: '';
        flex: 1;
        background: var(--black-20);
        height: 1px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    border-radius: 15px;
    background: var(--black-20);
    color: var(--black-60);
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 2px 18px;
`;
export default AnalyticsInfo;
