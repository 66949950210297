import styled from 'styled-components';
import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { InputComponentIconPosition, InputComponentType } from '@Components/input';
import SearchIcon from '@Assets/icons/search-normal.svg';
import SelectComponent, { SelectOptionType } from '@Components/select';
import ContentLibraryArea from '@Components/library';
import { FileRes } from '@/types/file';
import { StyledSearch, StyledFilters, StyledWrapper, StyledSelectWrapper } from '@/pages/workspaces';

interface Props {
    className?: string;
    onSelect?: Function;
    searchTerm?: string;
    globalFiles?: FileRes[] | null;
    onDelete?: Function;
    preview?: boolean;
    setUploadModal?: Dispatch<SetStateAction<boolean>>;
}

const ContentLibrary: FunctionComponent<Props> = ({
    className,
    onSelect,
    searchTerm,
    globalFiles,
    onDelete,
    preview = false,
    setUploadModal,
}) => {
    const [searchValue, setSearchValue] = useState(searchTerm ?? '');
    const [files, setFiles] = useState<FileRes[]>([]);
    const [previewTemp, setPreviewTemp] = useState(preview);
    const [extensions, setExtensions] = useState<SelectOptionType[]>([]);
    const [categories, setCategories] = useState<SelectOptionType[]>([]);
    const [filterType, setFilterType] = useState<SelectOptionType | null>(null);
    const [filterCategory, setFilterCategory] = useState<SelectOptionType | null>(null);

    useEffect(() => {
        if (globalFiles) {
            const extensionOptions: SelectOptionType[] = [];
            const categoriesOptions: SelectOptionType[] = [];
            for (const file of globalFiles) {
                if (!extensionOptions.find((ex) => ex.value === file.type)) {
                    extensionOptions.push({ value: file.type, label: file.type.toUpperCase() });
                }
                if (file.category && !categoriesOptions.find((category) => category.value === file.category?.id)) {
                    categoriesOptions.push({ value: file.category!.id, label: file.category!.name });
                }
            }
            setExtensions(extensionOptions);
            setCategories(categoriesOptions);
            setFiles(globalFiles);
        }
    }, [globalFiles]);

    useEffect(() => {
        setPreviewTemp(preview);
    }, [preview]);

    useEffect(() => {
        if (globalFiles) {
            if (filterType || filterCategory) {
                setFiles(
                    globalFiles
                        ?.filter((file) => (filterType ? file.type === filterType?.value : true))
                        .filter((file) => (filterCategory ? file.category?.id === filterCategory.value : true)),
                );
            } else {
                setFiles(globalFiles);
            }
        }
    }, [filterType, filterCategory]);

    return (
        <StyledContentLibrary className={`library-content ${className ?? ''}`}>
            <StyledWrapper>
                <StyledFilters>
                    <StyledSearch
                        placeholder="Search library"
                        type={InputComponentType.Text}
                        value={searchValue}
                        icon={SearchIcon}
                        size={366}
                        iconPosition={InputComponentIconPosition.Right}
                        onChange={(value: string) => setSearchValue(value)}
                    ></StyledSearch>
                    <StyledSelectWrapper>
                        <StyledSelectComponent
                            optionList={extensions}
                            value={filterType}
                            placeholder="Filter by type"
                            isClearable={true}
                            isSearchable={false}
                            onChange={(selectedOption) => {
                                setFilterType(selectedOption as SelectOptionType);
                            }}
                        ></StyledSelectComponent>
                        <StyledSelectComponent
                            optionList={categories}
                            value={filterCategory}
                            placeholder="Filter by category"
                            isClearable={true}
                            isSearchable={false}
                            onChange={(selectedOption) => {
                                setFilterCategory(selectedOption as SelectOptionType);
                            }}
                        ></StyledSelectComponent>
                    </StyledSelectWrapper>
                </StyledFilters>
                <ContentLibraryArea
                    searchTerm={searchValue}
                    onSelect={onSelect}
                    globalFiles={files}
                    preview={previewTemp}
                    setUploadModal={setUploadModal}
                    onDelete={() => {
                        setFilterType(null);
                        setFilterCategory(null);
                        onDelete ? onDelete() : null;
                    }}
                ></ContentLibraryArea>
            </StyledWrapper>
        </StyledContentLibrary>
    );
};

const StyledContentLibrary = styled.div`
    min-height: 200px;
`;

const StyledSelectComponent = styled(SelectComponent)`
    margin-left: 12px;
    .react-select__menu {
        z-index: 2;
    }
`;

export default ContentLibrary;
