import { create } from 'zustand';
import { UserDto, UserProperties } from '@Global/dtos/user.dto';
import RoleCodes from '@Global/roles';

interface UserState {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    properties?: UserProperties;
    activeUserRoles: RoleCodes[];
    usersList: UserDto[];

    getUserData: () => {
        id: string;
        firstName: string | undefined;
        lastName: string | undefined;
        email: string | undefined;
        properties: UserProperties | undefined;
        activeUserRoles?: RoleCodes[];
        usersList: UserDto[];
    };

    setUserData: (user: {
        id?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        properties?: UserProperties;
        activeUserRoles?: RoleCodes[];
        usersList?: UserDto[];
    }) => void;
}

const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    properties: undefined,
    activeUserRoles: [],
    usersList: [],
};

const useUserStore = create<UserState>((set, get) => ({
    ...initialState,
    getUserData: () => ({
        id: get().id,
        firstName: get().firstName,
        lastName: get().lastName,
        email: get().email,
        properties: get().properties,
        activeUserRoles: get().activeUserRoles,
        usersList: get().usersList,
    }),

    setUserData: (user: {
        id?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        properties?: UserProperties;
        activeUserRoles?: RoleCodes[];
        usersList?: UserDto[];
    }) => set(user),
}));

export default useUserStore;
