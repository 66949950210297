import { FunctionComponent } from 'react';
import ReactPlayer from 'react-player';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import NewTabIcon from '@Assets/icons/sharedView/new-tab.svg';
import { EmbedShelfItem } from '../../types/workspace';
interface Props {
    selectedItemData: EmbedShelfItem | any;
    blockType?: string;
}

const EmbedContent: FunctionComponent<Props> = ({ selectedItemData, blockType }) => {
    const getEmbedLink = (link: string): string => {
        if (link?.includes('loom.com')) {
            return link?.replace('share', 'embed');
        } else if (link?.includes('airtable.com')) {
            return link?.slice(0, 21) + 'embed/' + link?.slice(21);
        } else if (link?.includes('figma.com')) {
            return 'https://www.figma.com/embed?embed_host=astra&url=' + link;
        }
        return link;
    };

    const openNewTab = (url: string, urlType: string): void => {
        if (urlType === 'anything') {
            const win = window.open();
            win?.document?.write(url);
        } else window.open(url, '_blank', 'noreferrer');
    };

    return (
        <>
            {JSON.stringify(selectedItemData) !== '{}' ? (
                <StyledEmbedContent>
                    {selectedItemData?.content?.type === 'youtube' || selectedItemData?.content?.type === 'vimeo' ? (
                        <ReactPlayer
                            style={{ borderRadius: 5 }}
                            url={selectedItemData?.content?.url}
                            width="100%"
                            playsinline={true}
                        />
                    ) : selectedItemData?.content?.type === 'anything' ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: selectedItemData?.content?.url,
                            }}
                        />
                    ) : selectedItemData?.content?.type === 'google-docs' ||
                      selectedItemData?.content?.type === 'google-sheets' ||
                      selectedItemData?.content?.type === 'google-slides' ||
                      selectedItemData?.content?.type === 'google-forms' ||
                      selectedItemData?.content?.type === 'airtable' ||
                      selectedItemData?.content?.type === 'typeform' ||
                      selectedItemData?.content?.type === 'figma' ||
                      selectedItemData?.content?.type === 'loom' ? (
                        <StyledFrame>
                            <iframe
                                src={getEmbedLink(selectedItemData?.content?.url)}
                                width="100%"
                                height="100%"
                                allowFullScreen
                            />
                        </StyledFrame>
                    ) : (
                        <></>
                    )}
                    {blockType !== 'media' && (
                        <StyledActions>
                            <div
                                className="cursor-pointer"
                                onClick={() =>
                                    openNewTab(selectedItemData?.content?.url, selectedItemData?.content?.type)
                                }
                            >
                                <img alt="new tab icon" src={NewTabIcon} />
                                Open
                            </div>
                        </StyledActions>
                    )}
                </StyledEmbedContent>
            ) : (
                <Skeleton style={{ marginBottom: '5px' }} height={400} width="100%" />
            )}
        </>
    );
};

const StyledEmbedContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const StyledFrame = styled.div`
    height: calc(100vh - 126px);
    display: flex;
    justify-content: center;
    margin: 0 20px;
    iframe {
        flex: 1;
        border: none;
    }
`;
export const StyledActions = styled.div`
    margin-top: 20px;
    position: sticky;
    padding: 15px 0px;
    bottom: 0;
    background-color: var(--background-grey);
    border-top: 1px solid var(--black-75);
    display: flex;
    align-items: center;
    gap: 10px;

    > div,
    a {
        display: inline-flex;
        align-items: center;
        color: var(--black-75);
        transition: all 0.3s ease-in-out 0s;
        border-radius: 4px;
        padding: 10px;
        border-radius: 20px;
        text-decoration: none;
        font-size: 14px;
        img {
            margin-right: 8px;
        }
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px;
        }
    }
`;
export default EmbedContent;
