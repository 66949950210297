import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useWindowSize from '@Hooks/useResize';
import communication from '@Communication';
import Modal from '@Components/modal';
import Button, { ButtonTheme } from '@Components/button';
import Input, { InputComponentType, InputComponentTheme } from '@Components/input';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';
import InfoRoomTable from './table';
import SubscriptionModal from '../subscriptionPlans/subscriptionModal';

const InfoRooms: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { addToast, removeAllToasts } = useToasts();
    const size = useWindowSize();

    const [createInfoRoomModalVisibility, setCreateInfoRoomModalVisibility] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);
    const [subscriptionModalVisibility, setSubscriptionModalVisibility] = useState(false);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    const createNewInfoRoom = async (): Promise<void> => {
        removeAllToasts();
        try {
            const response = await communication.createInfoRoom({
                name: roomName,
            });
            setCreateInfoRoomModalVisibility(false);
            setRoomName('');
            navigate(`/inforooms/${response.data.id}`);
            addToast('Info room created!', {
                appearance: 'success',
            });
        } catch (e: any) {
            setRoomName('');
            const subscriptionError = e.response.data?.code === 1035;
            if (subscriptionError) {
                setSubscriptionModalVisibility(true);
                setCreateInfoRoomModalVisibility(false);
            } else {
                const isExisting = e.response.data?.code === 1020;
                addToast(
                    isExisting
                        ? 'Please create a unique info room name!'
                        : 'Unable to create info room. Please try again.',
                    {
                        appearance: 'error',
                    },
                );
            }
        }
    };

    return (
        <>
            <SubscriptionModal
                modalVisible={subscriptionModalVisibility}
                setModalVisible={setSubscriptionModalVisibility}
                closeModal={() => {
                    setSubscriptionModalVisibility(false);
                    setRoomName('');
                }}
            ></SubscriptionModal>
            <Modal
                modalVisible={createInfoRoomModalVisibility}
                closeModal={() => {
                    setCreateInfoRoomModalVisibility(false);
                    setRoomName('');
                }}
                size={500}
                title="Create new Info Room"
            >
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Input
                            type={InputComponentType.Text}
                            theme={InputComponentTheme.Light}
                            size="100%"
                            value={roomName}
                            onChange={(value: string) => setRoomName(value)}
                            placeholder="Info room name"
                            onEnter={
                                roomName !== ''
                                    ? createNewInfoRoom
                                    : () => {
                                          return;
                                      }
                            }
                        />
                        <Button
                            theme={ButtonTheme.primary}
                            onClick={createNewInfoRoom}
                            disabled={roomName === ''}
                            size={160}
                        >
                            Create
                        </Button>
                    </div>
                </StyledNewBlockModal>
            </Modal>
            <StyledPage className="page inforooms">
                <div className="top-bar">
                    <h3>Info rooms</h3>
                    <Button
                        theme={ButtonTheme.primary}
                        rightIcon={AddIcon}
                        size={isMobileWidth ? 80 : 200}
                        onClick={() => setCreateInfoRoomModalVisibility(true)}
                    >
                        {isMobileWidth ? 'New' : 'New info room'}
                    </Button>
                </div>
                <InfoRoomTable setCreateInfoRoomModalVisibility={setCreateInfoRoomModalVisibility}></InfoRoomTable>
            </StyledPage>
        </>
    );
};

const StyledPage = styled.div``;

const StyledNewBlockModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        .input {
            margin: 10px 0;
        }

        .button {
            margin-top: 26px;
        }
    }
`;

export default InfoRooms;
