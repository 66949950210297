import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Input, { InputComponentType } from '@Components/input';
import { InfoRoomDto } from '@Global/dtos/inforoom.dto';
import Button, { ButtonTheme } from '@Components/button';
import TextArea from '@Components/input/textArea';
import Sidebar from '@Components/sidebar';
import communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import Communication from '@Communication';
import useDialogue from '@Hooks/useDialogue';
import useOutsideClick from '@Hooks//useOutsideClick';
import useEditorStore from '@Store/editor';
import ManageSearch from '@Assets/icons/settings/manage-search.svg';
import HamburgerIcon from '@Assets/icons/settings/hamburger.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import CloseIcon from '@Assets/icons/close.svg';
import { ContextMenu, StyledHeader } from '../../workspaces/editor/sidebar/settings';
import { TwoButtonsStyle } from '../../workspaces/editor/sidebar/styledComponents';
interface InfoRoomSidebarProps {
    data: InfoRoomDto;
    className?: string;
}

const InfoRoomSidebar: FunctionComponent<InfoRoomSidebarProps> = ({ data, className }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { id } = useEditorStore();
    const navigate = useNavigate();
    const { addToast, removeAllToasts } = useToasts();
    const { setDialogue, closeDialogue } = useDialogue();

    const [name, setName] = useState(data.name);
    const [description, setDescription] = useState(data.description);
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [hoveredBtn, setHoveredBtn] = useState(false);
    const [infoRoomContextMenu, setInfoRoomContextMenu] = useState(false);

    const { handleClickOutside } = useOutsideClick(ref, setInfoRoomContextMenu);

    useEffect(() => {
        if (name !== data.name || data?.description !== description) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [name, description]);

    const update = (): void => {
        setLoading(true);
        removeAllToasts();
        communication
            .updateInfoRoom(data.id, { name, description })
            .then(() => {
                setLoading(false);
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                setHasChanges(false);
            })
            .catch(() => {
                setLoading(false);
                addToast('Something went wrong! Please check entered data.', {
                    appearance: 'error',
                });
            });
    };

    return (
        <Sidebar className={`${infoRoomContextMenu ? 'overlay-visible' : ''} ${className ?? className}`.trim()}>
            <StyledTitle>
                <div>
                    <img src={ManageSearch} alt="icon" />
                    Info room details
                </div>
                <img
                    src={HamburgerIcon}
                    alt="icon"
                    className="cursor-pointer"
                    onClick={() => setInfoRoomContextMenu(!infoRoomContextMenu)}
                />
            </StyledTitle>
            <ContextMenu
                className={`${infoRoomContextMenu ? '' : 'hidden'}`.trim()}
                ref={ref}
                onClick={handleClickOutside}
            >
                <StyledHeader>
                    <div>Settings</div>
                    <img
                        src={CloseIcon}
                        alt="icon"
                        className="cursor-pointer"
                        onClick={() => setInfoRoomContextMenu(false)}
                    />
                </StyledHeader>

                <Button
                    theme={ButtonTheme.link}
                    leftIcon={hoveredBtn ? DeleteIconActive : DeleteIconInactive}
                    onMouseEnter={() => setHoveredBtn(true)}
                    onMouseLeave={() => setHoveredBtn(false)}
                    onClick={() => {
                        setDialogue({
                            description: 'Info room will be deleted. This is irreversible operation.',
                            buttons: [
                                {
                                    theme: ButtonTheme.danger,
                                    text: 'Delete',
                                    callback: () => {
                                        setLoading(true);
                                        Communication.deleteInfoRoom(id)
                                            .then(() => {
                                                setLoading(false);
                                                navigate('/inforooms');
                                                addToast('Info room deleted!', { appearance: 'success' });
                                            })
                                            .catch(() => {
                                                addToast('Info room can not be deleted at this point!', {
                                                    appearance: 'error',
                                                });
                                                setLoading(false);
                                            });
                                        closeDialogue();
                                    },
                                },
                                {
                                    theme: ButtonTheme.naked,
                                    text: 'Cancel',
                                    callback: () => {
                                        closeDialogue();
                                    },
                                },
                            ],
                        });
                    }}
                >
                    Delete Info room
                </Button>
            </ContextMenu>
            <div className="form-wrapper">
                <Input
                    label="Info room name"
                    placeholder="Info room name"
                    type={InputComponentType.Text}
                    onChange={(value: string) => setName(value)}
                    value={name}
                    size="100%"
                />
                <TextArea
                    label="Description"
                    value={description}
                    onChange={(value: string) => setDescription(value)}
                    placeholder="Info room description"
                />
                <Input
                    label="Date created"
                    type={InputComponentType.Date}
                    isDisabled={true}
                    value={data.createdAt.toString()}
                    dateFormat="dd MMMM yyyy (HH:mm)"
                    size="100%"
                />
                <Input
                    label="Last updated"
                    type={InputComponentType.Date}
                    isDisabled={true}
                    dateFormat="dd MMMM yyyy (HH:mm)"
                    size="100%"
                    value={data.updatedAt.toString()}
                />
            </div>
            {hasChanges && (
                <TwoButtonsStyle>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.primary}
                        onClick={() => update()}
                        isLoading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            setName(data.name);
                            setDescription(data.description);
                        }}
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            )}
        </Sidebar>
    );
};

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    > div {
        display: flex;
        align-items: center;

        img {
            margin-right: 12px;
        }
    }
`;

export default InfoRoomSidebar;
