import { useState, useEffect } from 'react';
import { CategoryType, CategoryDto } from '@/global/dtos/categories.dto';
import useWindowSize from '@Hooks/useResize';
import AddNewCategory from '@Components/addNewCategory';
import CategoriesTable from '@Components/categoriesTable';
import Button, { ButtonTheme } from '@Components/button';
import AddIcon from '@Assets/icons/workspaces/add-circle-white.svg';

const FilesSettings: React.FunctionComponent = () => {
    const size = useWindowSize();

    const [categories, setCategories] = useState<CategoryDto[] | null>(null);
    const [createCategoryModalVisibility, setCreateCategoryModalVisibility] = useState(false);
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 600);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 600);
    }, [size.width]);

    return (
        <>
            <AddNewCategory
                type={CategoryType.FILE}
                createCategoryModalVisibility={createCategoryModalVisibility}
                setCreateCategoryModalVisibility={setCreateCategoryModalVisibility}
                setCategories={setCategories}
            />
            <div className="page workspaces">
                <div className="top-bar">
                    <h3>Files settings</h3>
                    <Button
                        theme={ButtonTheme.primary}
                        size={isMobileWidth ? 80 : 200}
                        rightIcon={AddIcon}
                        onClick={() => setCreateCategoryModalVisibility(true)}
                    >
                        {isMobileWidth ? 'New' : 'Add new category'}
                    </Button>
                </div>
                <CategoriesTable
                    type={CategoryType.FILE}
                    setCategories={setCategories}
                    categories={categories}
                    setCreateCategoryModalVisibility={setCreateCategoryModalVisibility}
                ></CategoriesTable>
            </div>
        </>
    );
};

export default FilesSettings;
