export type DirectorRegisterDto = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
};

export type UserRegisterDto = {
    password: string;
    firstName: string;
    lastName: string;
};

export type UserDto = {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    status?: string;
    createdAt: Date;
    updatedAt: Date;
    enabled: boolean;
    properties: UserProperties;
    UserRoles: [
        {
            id: string;
            userId: string;
            roleId: number;
        },
    ];
};

export type CreateUserDto = {
    firstName: string;
    lastName: string;
    email: string;
    role: number;
};

export type InviteUserDto = {
    email: string;
};

export type ChangeRoleDto = {
    role: number | undefined;
};

export enum UserStatus {
    NEW = 'NEW', // When the user is just created (not confirmed)
    CONFIRMED = 'CONFIRMED', // User is confirmed through email
    READY = 'READY', // Finalized
}

export type UserProperties = {
    profilePicture?: string;
    title?: string;
    contact?: {
        email?: string;
        phone?: string;
        linkedIn?: string;
        chatLink?: string;
        calendarLink?: string;
    };
};

export enum UserContactType {
    EMAIL = 'EMAIL',
    LINKEDIN = 'LINKEDIN',
}
