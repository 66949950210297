import { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';
import useDialogue from '@Hooks/useDialogue';
import Button, { ButtonTheme } from '@Components/button';
import Input, { InputComponentType, InputComponentTheme } from '@Components/input';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableSkeleton,
    TableSkeletonWrapper,
    SkeletonWrapper,
} from '@Components/table';
import communication from '@Communication';
import Modal from '@Components/modal';
import NoItems from '@Components/noItems';
import { CategoryDto, CategoryType } from '@/global/dtos/categories.dto';
import EditIcon from '@Assets/icons/actions/edit.svg';
import DeleteDropdownIcon from '@Assets/icons/actions/delete.svg';
import EditDropdownIcon from '@Assets/icons/actions/change.svg';
import { TwoButtonsStyle } from '../addNewCategory';
import './index.scss';

interface Props {
    type: CategoryType;
    setCategories: Dispatch<SetStateAction<CategoryDto[] | null>>;
    categories: CategoryDto[] | null;
    setCreateCategoryModalVisibility?: Dispatch<SetStateAction<boolean>>;
}

const TemplatesCategoryTable: React.FunctionComponent<Props> = ({
    type,
    setCategories,
    categories,
    setCreateCategoryModalVisibility,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [clickedCategory, setClickedCategory] = useState({
        id: '',
        name: '',
        categoryProtected: true,
    });
    const [clickedOutside, setClickedOutside] = useState(true);

    const [updateCategoryModalVisibility, setUpdateCategoryModalVisibility] = useState(false);

    // const [categoryProtected, setCategoryProtected] = useState(false);
    const { addToast, removeAllToasts } = useToasts();

    const { setDialogue, closeDialogue } = useDialogue();
    const [hasChanges, setHasChanges] = useState(false);
    const [tmpName, setTmpName] = useState(clickedCategory.name);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    }, []);

    useEffect(() => {
        communication.getAllCategories(type).then((res: any) => {
            setCategories(res?.data);
        });
    }, []);

    useEffect(() => {
        if (clickedCategory.name !== tmpName) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [tmpName]);

    const handleClickOutside = (event: MouseEvent): void => {
        if (!ref?.current?.contains(event.target as HTMLDivElement)) {
            setClickedOutside(true);
        }
    };
    const deleteCategory = async (id: string): Promise<void> => {
        removeAllToasts();
        try {
            await communication.deleteCategory(id);
            await communication.getAllCategories(type).then((res: any) => {
                setCategories(res?.data);
            });
            addToast('Category deleted!', {
                appearance: 'success',
            });
        } catch (e: any) {
            const isUsed = e.response.data?.code === 1028;
            addToast(
                isUsed ? `Selected category is being used in some ${type.toLowerCase()}!` : 'Something went wrong!',
                {
                    appearance: 'error',
                },
            );
        }
    };

    const editCategory = async (id: string): Promise<void> => {
        removeAllToasts();
        try {
            await communication
                .updateCategory(id, {
                    name: tmpName,
                    protected: clickedCategory.categoryProtected,
                    type: type,
                })
                .then((res: any) => {
                    setClickedCategory(res);
                    setHasChanges(false);
                });
            communication.getAllCategories(type).then((res: any) => {
                setCategories(res?.data);
            });
            setUpdateCategoryModalVisibility(false);
            addToast('Your changes have been successfully saved!', {
                appearance: 'success',
            });
        } catch (e: any) {
            if (e.response.data?.code === 1027) {
                addToast('Please create a unique category name!', {
                    appearance: 'error',
                });
            } else {
                addToast('Something went wrong. Please try again.', {
                    appearance: 'error',
                });
            }
        }
    };

    const showMenu = (key: string): void => {
        const category = categories?.find((cat) => cat.id === key);

        if (category) {
            const categoryTmp = { ...clickedCategory };
            (categoryTmp.id = category?.id),
                (categoryTmp.name = category.name),
                (categoryTmp.categoryProtected = category.protected),
                setClickedCategory(categoryTmp);
            setTmpName(categoryTmp.name);
        }

        setClickedOutside(false);
    };

    // const canCreateProtected = (): boolean => {
    //     return (
    //         activeUserRoles.includes(RoleCodes.SUPER_ADMIN) ||
    //         activeUserRoles.includes(RoleCodes.DIRECTOR) ||
    //         activeUserRoles.includes(RoleCodes.ADMIN) ||
    //         activeUserRoles.includes(RoleCodes.MANAGER)
    //     );
    // };

    return (
        <>
            <Modal
                modalVisible={updateCategoryModalVisibility}
                closeModal={() => setUpdateCategoryModalVisibility(false)}
                size={500}
                title={`Edit ${type.toLowerCase()} category`}
            >
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Input
                            type={InputComponentType.Text}
                            theme={InputComponentTheme.Light}
                            size="100%"
                            value={tmpName}
                            onChange={(value: string) => setTmpName(value)}
                            placeholder="Category name"
                        />
                        {/* <>
                            {canCreateProtected() && (
                                <ToggleSwitch
                                    label="Protected"
                                    checked={clickedCategory.categoryProtected}
                                    onChange={(value: boolean) =>
                                        setClickedCategory({ ...clickedCategory, categoryProtected: !value })
                                    }
                                ></ToggleSwitch>
                            )}
                        </> */}
                        <TwoButtonsStyle>
                            <Button
                                theme={ButtonTheme.primary}
                                onClick={() => editCategory(clickedCategory.id)}
                                disabled={tmpName === '' || !hasChanges}
                                size={160}
                            >
                                Save
                            </Button>
                            <Button
                                theme={ButtonTheme.naked}
                                onClick={() => setUpdateCategoryModalVisibility(false)}
                                size={160}
                            >
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    </div>
                </StyledNewBlockModal>
            </Modal>
            <TableWrapper className="all-categories-page">
                {categories ? (
                    <Table className={`${categories?.length === 0 ? 'no-items' : ''}`.trim()}>
                        {categories?.length !== 0 ? (
                            <>
                                <TableHeader columns={3}>
                                    <TableHeaderItem>{`${type.toLowerCase()} categories`}</TableHeaderItem>
                                    <TableHeaderItem>Times used</TableHeaderItem>
                                    <TableHeaderItem className="action-button">Action</TableHeaderItem>
                                </TableHeader>

                                <TableBody>
                                    {categories?.map((category) => {
                                        return (
                                            <StyledTableItem
                                                columns={3}
                                                key={category?.id}
                                                className="categories table-item"
                                            >
                                                <p>{category?.name}</p>
                                                <p>{category?.timesUsed}</p>
                                                <div className="action-button" onClick={() => showMenu(category.id)}>
                                                    <img
                                                        draggable="false"
                                                        src={EditIcon}
                                                        alt="notification icon"
                                                        className="notification"
                                                    />
                                                    {clickedCategory.id === category.id && !clickedOutside ? (
                                                        <ContextMenu
                                                            ref={ref}
                                                            onClick={(e: MouseEvent | any) => {
                                                                handleClickOutside(e);
                                                            }}
                                                        >
                                                            <ul>
                                                                <li
                                                                    onClick={() =>
                                                                        setDialogue({
                                                                            buttons: [
                                                                                {
                                                                                    theme: ButtonTheme.danger,
                                                                                    text: 'Delete',
                                                                                    callback: () => {
                                                                                        deleteCategory(category.id);
                                                                                        closeDialogue();
                                                                                    },
                                                                                },
                                                                                {
                                                                                    theme: ButtonTheme.naked,
                                                                                    text: 'Cancel',
                                                                                    callback: () => {
                                                                                        closeDialogue();
                                                                                    },
                                                                                },
                                                                            ],
                                                                        })
                                                                    }
                                                                >
                                                                    <img src={DeleteDropdownIcon} alt="icon" />
                                                                    <p>Delete</p>
                                                                </li>
                                                                <li
                                                                    onClick={() =>
                                                                        setUpdateCategoryModalVisibility(true)
                                                                    }
                                                                >
                                                                    <img src={EditDropdownIcon} alt="icon" />
                                                                    <p>Edit</p>
                                                                </li>
                                                            </ul>
                                                        </ContextMenu>
                                                    ) : null}
                                                </div>
                                            </StyledTableItem>
                                        );
                                    })}
                                </TableBody>
                            </>
                        ) : (
                            <NoItems
                                content={
                                    <>
                                        <p>You don’t have any Categories yet.</p>
                                        <div>
                                            <p>Start with</p>
                                            <Button
                                                theme={ButtonTheme.link}
                                                color="var(--primary-400)"
                                                onClick={() => {
                                                    setCreateCategoryModalVisibility &&
                                                        setCreateCategoryModalVisibility(true);
                                                }}
                                            >
                                                creating a new one.
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                        )}
                    </Table>
                ) : (
                    <Table>
                        <SkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                        </SkeletonWrapper>
                    </Table>
                )}
            </TableWrapper>
        </>
    );
};
const TableWrapper = styled.div`
    .action-button {
        display: flex;
        justify-content: end;
        img {
            width: 20px;
        }
    }
`;
const StyledTableItem = styled(TableRow)`
    position: relative;

    p {
        align-items: center;
        display: flex;
        font-size: 12px;
        justify-content: flex-start;
    }
`;

export const ContextMenu = styled.div`
    position: absolute;
    background-color: var(--white);
    border-radius: 20px;
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
    top: 0;
    right: 0;
    z-index: 1;

    ul {
        box-sizing: border-box;
        padding: 5px;
        margin: 0;
        list-style: none;
    }
    ul li {
        transition: 0.2s all ease-in-out;
        padding: 5px 8px;
        white-space: nowrap;
        font-size: 12px;
        color: var(--black-75);
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
        img {
            margin-right: 10px;
            background: var(--black-75);
            border-radius: 50%;
            padding: 3px;
            width: 24px;
            transition: 0.3s all ease-in-out;
        }
    }
    /* hover */
    ul li:hover {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        cursor: pointer;
        p {
            color: var(--black-60);
        }
        img {
            background: var(--black-60);
        }
    }
`;

export const StyledNewBlockModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        .input {
            margin: 10px 0;
        }
    }
`;

export default TemplatesCategoryTable;
