import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobalState from '@Store/global';
import Logo from '@Assets/icons/logo.svg';
import LogoSmall from '@Assets/icons/logo-small.svg';
import NotificationIcon from '@Assets/icons/navbar/notification.svg';
import UserIcon from '@Assets/icons/navbar/profile-circle.svg';
import ProfileIcon from '@Assets/icons/navbar/user-white.svg';
import LogoutIcon from '@Assets/icons/navbar/logout.svg';
import ArrowLeftIcon from '@Assets/icons/navbar/arrow-left.svg';
import ArrowRightIcon from '@Assets/icons/navbar/arrow-right.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useUserStore from '@Store/user';
import './index.scss';
import Communication from '@Communication';

const HorizontalBar: FunctionComponent = () => {
    const navigate = useNavigate();
    const { properties } = useUserStore();
    const { userContextMenu, isNavbarCollapsed } = useGlobalState();
    const [contextOpenedTime, setContextOpenedTime] = useState(Date.now());

    return (
        <div className="horizontal-navbar">
            <ArrowLogo className={isNavbarCollapsed ? 'collapsed' : ''}>
                <StyledLogo src={isNavbarCollapsed ? LogoSmall : Logo} onClick={() => navigate('/workspaces')} />
                <div className="arrow-icon">
                    <img
                        className="clickable"
                        src={isNavbarCollapsed ? ArrowRightIcon : ArrowLeftIcon}
                        alt="icon"
                        onClick={() => useGlobalState.setState({ isNavbarCollapsed: !isNavbarCollapsed })}
                    ></img>
                </div>
            </ArrowLogo>
            <div className="navbar-action">
                <img
                    draggable="false"
                    src={NotificationIcon}
                    alt="notification icon"
                    className="notification cursor-pointer"
                />
                <img
                    draggable="false"
                    crossOrigin="anonymous"
                    src={
                        properties?.profilePicture
                            ? `${process.env.REACT_APP_API_URL}/users/me/picture/${properties.profilePicture}`
                            : UserIcon
                    }
                    alt="user"
                    className="clickable"
                    id="navbar-profile-image"
                    onMouseUp={() => {
                        useGlobalState.setState({ userContextMenu: true });
                        setContextOpenedTime(Date.now());
                    }}
                />
            </div>
            <UserContextMenu
                className={`user-context-menu ${userContextMenu ? 'visible' : ''}`}
                onMouseLeave={() => {
                    if (userContextMenu && Date.now() - contextOpenedTime > 400) {
                        useGlobalState.setState({ userContextMenu: false });
                    }
                }}
            >
                <UserContextLink
                    to="/profile"
                    onClick={() => useGlobalState.setState({ userContextMenu: false, activeNavbarLink: '/profile' })}
                >
                    <img src={ProfileIcon} alt="icon" />
                    <p>User profile</p>
                </UserContextLink>
                <UserContextLink
                    to="/"
                    onClick={() => {
                        Communication.logout().then(() => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('status');
                            window.location.href = document.location.origin;
                        });
                    }}
                >
                    <img src={LogoutIcon} alt="icon" />
                    <p>Logout</p>
                </UserContextLink>
            </UserContextMenu>
        </div>
    );
};
const ArrowLogo = styled.div`
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &.collapsed {
        div {
            &.arrow-icon {
                margin-left: 20px;
            }
        }
    }
    div {
        &.arrow-icon {
            transition: all 0.3s ease-in-out;
            margin-left: 70px;
            // Media Queries
            @media only screen and (max-width: 768px) {
                margin-left: 20px;
            }
        }
    }
`;

const StyledLogo = styled.img`
    cursor: pointer;
`;

const UserContextMenu = styled.div`
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    right: 20px;
    top: 0;
    background-color: var(--white);
    border-radius: 20px;
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
    transition: 0.3s all ease-in-out;
    padding: 5px;

    &.visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        right: 20px;
        top: 70px;
    }
`;

const UserContextLink = styled(Link)`
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    transition: 0.3s all ease-in-out;
    &:not(:last-child) {
        margin-bottom: 4px;
    }
    &:hover {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);

        cursor: pointer;
        p {
            color: var(--black-60);
        }
        img {
            background: var(--black-60);
        }
    }
    p {
        font-size: 14px;
        color: var(--black-75);
    }
    img {
        margin-right: 10px;
        background: var(--black-75);
        border-radius: 50%;
        padding: 5px;
        width: 24px;
        transition: 0.3s all ease-in-out;
    }
`;

export default HorizontalBar;
