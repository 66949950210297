import styled from 'styled-components';
import Popup from '@Components/popup/index';

export const CommonStyledPopup = styled(Popup)`
    .modal-wrapper {
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        width: 572px;
        transition: all 0.4s ease-in-out;
        transform: scale3d(0.8, 0.8, 1);
        > div {
            display: flex;
            align-items: center;
            &.title {
                justify-content: space-between;
                padding-bottom: 24px;
                margin-bottom: 18px;
                border-bottom: 1px solid var(--black-20);
            }
            &.description {
                img {
                    margin-right: 12px;
                }
            }
        }
        p {
            font-size: 16px;
            color: var(--black-60);
        }

        h3 {
            line-height: normal;
            font-size: 18px;
        }

        img {
            transition: all 2s ease-in-out;
        }
    }

    &.visible {
        .modal-wrapper {
            transform: scale3d(1, 1, 1);

            img {
                animation-name: pulse;
                animation-duration: 0.4s;
                animation-delay: 0.7s;
                animation-iteration-count: 2;
                animation-timing-function: ease-in-out;
            }
        }
    }

    @keyframes pulse {
        0% {
            transform: scale3d(1, 1, 1);
        }
        50% {
            transform: scale3d(1.2, 1.2, 1);
        }
        100% {
            transform: scale3d(1, 1, 1);
        }
    }
`;
