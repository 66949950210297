import { FunctionComponent, useEffect, useState } from 'react';
import communication from '@Communication';
import styled from 'styled-components';
import { dispatchEvent, EventName } from '@Helpers/analytics';
import { MediaBlock } from '@/types/workspace';
import Video from '@Components/video';
import EmbedContent from '../embedContent';

interface Props {
    link: string;
    type: string;
    workspaceId: string | null;
    isProtected?: boolean;
    mediaBlock?: MediaBlock;
}

const BannerBlockItem: FunctionComponent<Props> = ({ link, type, workspaceId, isProtected, mediaBlock }) => {
    const swToken = localStorage.getItem('sharedViewToken') ?? undefined;
    const token = localStorage.getItem('token') ?? undefined;

    const [fileUrl, setFileUrl] = useState<string | undefined>('');

    useEffect(() => {
        if (link) {
            communication.downloadFile(link).then((res: any) => {
                if (res?.status === 200) {
                    const resType = res.headers['content-type'];
                    const blob = new Blob([res.data], {
                        type: resType,
                    });
                    setFileUrl(URL.createObjectURL(blob));
                }
            });
        }
    }, [link]);

    return (
        <StyledImageContainer>
            {fileUrl ? (
                type === 'image' ? (
                    <StyledImage src={fileUrl} />
                ) : type === 'pdf' ? (
                    <StyledOverlayIframe
                        src={fileUrl + '#toolbar=0'}
                        style={{ flex: 1 }}
                        allowFullScreen
                        width="100%"
                        height="600px"
                    />
                ) : (
                    <Video
                        videoUrl={fileUrl}
                        onPlay={() => {
                            dispatchEvent(
                                EventName.SharedPageClick,
                                workspaceId,
                                {
                                    page: 'sharedView',
                                    isProtected,
                                },
                                swToken,
                                token,
                            );
                        }}
                    />
                )
            ) : (
                <EmbedContent selectedItemData={mediaBlock} blockType="media" />
            )}
        </StyledImageContainer>
    );
};

const StyledImageContainer = styled.div`
    height: fit-content;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

const StyledImage = styled.img`
    width: 100%;
    border-radius: 5px;
`;

const StyledOverlayIframe = styled.iframe`
    border-radius: 8px;
    border: 10px solid rgba(170, 170, 170, 0.2);
`;

export default BannerBlockItem;
