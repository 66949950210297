import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const Table = styled.div`
    background-color: white;
    border-radius: 4px;
    min-width: 600px;
    position: inherit;
    max-width: 1600px;
    margin: auto;
    &.no-items {
        @media only screen and (max-width: 850px) {
            min-width: auto;
        }
    }
`;

export const TableBody = styled.div`
    .selected-item {
        box-shadow: 0px 0px 6px rgba(0, 114, 109, 0.1);
        background: rgba(0, 114, 109, 0.1);
    }
    .block-actions {
        position: static;
        z-index: auto;
        .block-actions-expander {
            margin-left: 0;
        }
        .block-actions-body {
            position: absolute;
        }
    }
`;

export const TableRow = styled.div<HeaderProps>`
    display: grid;
    gap: 10px;
    box-sizing: border-box;
    padding: 14px 20px;
    align-items: center;
    transition: all ease 0.5s;
    grid-template-columns: ${(props) => {
        if (props.customColumns) return props.customColumns;
        let columnString = '';
        for (let i = 0; i < props.columns; i++) {
            columnString += '1fr ';
        }
        return columnString;
    }};

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 6px rgba(0, 114, 109, 0.1);
        background: rgba(0, 114, 109, 0.1);
    }
`;
interface HeaderProps {
    columns: number;
    customColumns?: string;
}

export const TableHeader = styled.div<HeaderProps>`
    display: grid;
    gap: 10px;
    grid-template-columns: ${(props) => {
        if (props.customColumns) return props.customColumns;
        let columnString = '';
        for (let i = 0; i < props.columns; i++) {
            columnString += '1fr ';
        }
        return columnString;
    }};
    box-sizing: border-box;
    padding: 14px 20px;
`;

export const TableHeaderItem = styled.h4`
    color: var(--black-60);
    height: 18px;
    text-align: start;

    img {
        &.arrow-rotate-true {
            transform: rotate(180deg);
        }
    }
`;
export const SkeletonWrapper = styled.div`
    padding: 14px 0;
`;
export const TableSkeletonWrapper = styled.div`
    display: flex;
    gap: 5px;
    margin: 14px 20px;
    span {
        width: 100%;
    }
`;

export const TableSkeleton = styled(Skeleton)`
    width: 100%;
    height: 30px;
`;
