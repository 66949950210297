import { TableBlock } from '@/types/workspace';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
    tableData: TableBlock;
    className?: string | '';
}

const TableBlockItem: FunctionComponent<Props> = ({ tableData, className }) => {
    return (
        <TableWrapper key={tableData.id}>
            <StyledTable className={`table ${className || ''}`}>
                <tbody>
                    {tableData.content.rows.map((row) => (
                        <tr key={row.id}>
                            {row.cells.map((cell) => (
                                <td key={cell.id} style={{ backgroundColor: cell.style }}>
                                    <div className="cell-container">
                                        <div
                                            className="cell-body"
                                            dangerouslySetInnerHTML={{
                                                __html: cell.value,
                                            }}
                                        ></div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    width: 100%;
    margin: 20px 0;
    // Media Queries
    @media only screen and (max-width: 500px) {
        overflow: auto;
    }
`;

const StyledTable = styled.table`
    background-color: var(--black-50);
    border-spacing: 1px;
    position: relative;
    table-layout: fixed;
    border-radius: 8px;
    width: 100%;
    // Media Queries
    @media only screen and (max-width: 500px) {
        width: auto;
    }
    tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
    }
    tr:first-child td:first-child {
        border-top-left-radius: 8px;
    }

    tr:first-child td:last-child {
        border-top-right-radius: 8px;
    }

    td {
        min-width: 100px;

        .cell-container {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .cell-body {
            height: 100%;
        }
    }

    tbody {
        tr {
            td {
                line-height: 1.42;
                height: 100%;
                outline: none;
                padding: 17px 20px;
                tab-size: 4;
                -moz-tab-size: 4;
                text-align: left;
                white-space: pre-wrap;
                word-wrap: break-word;

                .cell-body {
                    font-family: 'Open Sans', sans-serif;
                    text-align: left;
                    -webkit-align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    box-sizing: border-box;
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            position: relative;
            background-color: white !important;
            color: var(--black-60);
            transition: 0.3s box-shadow ease-in-out;
        }
    }
`;

export default TableBlockItem;
