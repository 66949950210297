import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

const SectionsSkeleton: FunctionComponent = () => {
    return (
        <>
            <Skeleton style={{ marginBottom: '20px' }} height={40} width="100%" />
            <Skeleton style={{ marginBottom: '20px' }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: '30px' }} height={150} width="100%" />
            <Skeleton style={{ marginBottom: '20px' }} height={40} width="100%" />
            <Skeleton style={{ marginBottom: '20px' }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: '30px' }} height={150} width="100%" />
        </>
    );
};

export default SectionsSkeleton;
