// SHARE MODAL
import styled from 'styled-components';

export const StyledNewBlockModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &.new-block {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;

// SHARE MODAL
export const StyledSharedModal = styled.div`
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .link-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;

        .input {
            text-align-last: left;
            outline-color: red !important;
        }
        .icon {
            top: 48px;
        }

        .button {
            margin-left: 10px;
            height: 40px;
            padding: 0 12px;
        }
    }

    .button-grid {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .select {
        margin-bottom: 20px;
        margin-top: 0;
    }

    .styledInput {
        text-align-last: left;
        display: flex;
        outline-color: var(--primary-400);
    }

    .users-list {
        height: 200px;
        overflow-y: auto;
        margin-bottom: 20px;
        > p {
            color: var(--black-75);
            font-size: 12px;
            padding: 8px 0;
        }
        .all-users {
            padding: 0px 3px;
        }
        .share-empty-list {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80%;
            color: var(--black-100);
            user-select: none;
            font-size: 14px;
        }

        .user {
            width: 100%;
            font-size: 15px;
            margin-top: 10px;
            align-items: center;
            color: var(--black-200);
            display: flex;

            .circle-letter {
                background: #00635f;
                background: var(--primary-500);
                border-radius: 50px;
                color: var(--white);
                font-size: 12px;
                height: 25px;
                left: 624px;
                margin-right: 10px;
                padding-top: 7px;
                text-align: center;
                top: 445px;
                width: 25px;
                min-width: 25px;
                min-height: 25px;
            }

            .img-set {
                display: flex;
                margin-left: auto;
                margin-right: 15px;
                cursor: pointer;

                .sent {
                    float: right;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
                .remove {
                    float: right;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
`;

// INVITE USER MODAL

export const StyledInviteUserModal = styled.div`
    .top {
        display: flex;
    }

    button {
        margin-top: 36px;
    }

    p {
        margin-bottom: 20px;
    }
`;
