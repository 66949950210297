import React from 'react';
import { Outlet } from 'react-router-dom';

const Public: React.FunctionComponent = () => {
    return (
        <>
            <div className="body public">
                <Outlet />
            </div>
        </>
    );
};

export default Public;
