import fetch from './fetch';
import routes from './routes';

export default class Auth {
    public generateToken(email: string): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            url: routes.auth.generateToken,
            data: { email: email },
        });
    }

    public resetPassword(token: string, password: string): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url: routes.auth.resetPassword,
            params: {
                token,
            },
            data: { password: password },
        });
    }
    public login(email: string, password: string): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url: routes.auth.login,
            data: { email: email, password: password },
        });
    }
    public logout(): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url: routes.auth.logout,
        });
    }
    public resendToken(email: string): any {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            url: routes.auth.resendToken,
            data: { email: email },
        });
    }
}
