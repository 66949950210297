export enum RoleCodes {
    SUPER_ADMIN = 1,
    DIRECTOR = 2,
    ADMIN = 3,
    MANAGER = 4,
    USER = 5,
    REGISTERED_GUEST = 6,
    GUEST = 7,
}

export const RoleTitle: { [key: number]: string } = {
    1: 'Super',
    2: 'Owner',
    3: 'Admin',
    4: 'Manager',
    5: 'User',
    6: 'Guest',
    7: 'Unknown',
};

export default RoleCodes;
