export enum BlockType {
    Text = 'text',
    Shelf = 'shelf',
    Table = 'table',
    Media = 'media',
    File = 'file',
    Link = 'link',
    GoogleDrive = 'gdrive',
    Embed = 'embed',
}

export type SectionType = {
    id: string;
    type: string;
    content: {
        items: Block[];
    };
    options: {
        title: string;
        visible: boolean;
    };
};

export type Block = TextBlock | TableBlock | ShelfBlock | MediaBlock;

export type TextBlock = {
    id: string;
    type: string;
    content: {
        value: string;
    };
    options: {};
};

export type MediaBlock = {
    id: string;
    type: string;
    content: {
        id?: string;
        link?: string;
        embed?: any;
        type:
            | 'pdf'
            | 'video'
            | 'image'
            | 'embed'
            | 'anything'
            | 'google-docs'
            | 'google-sheets'
            | 'google-slides'
            | 'google-forms'
            | 'youtube'
            | 'vimeo'
            | 'loom'
            | 'airtable'
            | 'typeform'
            | 'figma';
        global?: boolean;
    };
    options: {};
};

export type TableCell = {
    id: string;
    columnId: string;
    value: string;
    style: any;
};

export type TableRow = {
    id: string;
    cells: TableCell[];
};
export type TableBlock = {
    id: string;
    type: string;
    content: {
        rows: TableRow[];
    };
    options: {};
};

export type ShelfBlock = {
    id: string;
    type: string;
    content: {
        items: ShelfItem[];
    };
    options: {};
};

export type BannerBlock = {
    id: string;
    type: string;
    content: {
        id: string;
        link: string;
    };
    options: {};
};

export type ShelfItem = FileShelfItem | LinkShelfItem | GDriveShelfItem | EmbedShelfItem;

export type FileShelfItem = {
    id: string;
    type: string;
    content: {
        name: string;
        description: string;
        type: string;
        link: string;
        thumbnail?: string;
    };
    options: {
        global?: boolean;
    };
};

export type LinkShelfItem = {
    id: string;
    type: string;
    content: {
        name: string;
        url: string;
        thumbnail?: string;
    };
    options: {};
};

export type GDriveShelfItem = {
    id: string;
    type: string;
    content: {
        googleId: string;
        name: string;
        embedUrl: string;
        icon: string;
        type: string;
        mimeType: string;
        url: string;
        thumbnail?: string;
    };
    options: {};
};
export type EmbedShelfItem = {
    id: string;
    type: string;
    content: {
        name: string;
        url: string;
        thumbnail?: string;
        type?: string;
    };
    options: {};
};

export type InfoRoomType = {
    id: string;
    name: string;
};

export type AssociatedCompanyType = {
    id: string;
    name: string;
    logoUrl?: string;
    profilePicture?: string;
};

export type AssociatedContactType = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePicture?: string;
};

export type SidePanelContentType = {
    pointsOfContact: {
        contacts: {
            id: string;
            name: string;
        }[];
    };
    actionPlan: string;
    welcomeMessage: string;
    welcomeVideo: string;
};

export type ContactsDetailsType = {
    name: string;
    title: string;
    profilePicture: string;
    contact: {
        chatLink: string;
        email: string;
        linkedIn: string;
        phone: string;
        calendarLink: string;
    };
};

export type ConnectedWorkspace = {
    id: string;
    name: string;
};

export type AssociatedCategoryType = {
    id: string;
    name: string;
};

export type AssociatedActionPlanType = {
    id: string;
    name: string;
    content: {
        title: string;
        items: {
            id: string;
            label: string;
            checked: boolean;
        }[];
    };
};
