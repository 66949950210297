import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FileRes } from '@/types/file';
import BlockActions, { BlockActionItem } from '@Components/editor/actionWrapper';
import DeleteIcon from '@Assets/icons/workspaces/delete-circle.svg';
import DefaultIcon from '@Assets/icons/files/other.svg';
import VideoIcon from '@Assets/icons/files/video.svg';
import PlayIconSmall from '@Assets/icons/play-circle-small.svg';
import useGlobalState from '@Store/global';
import EditIcon from '@Assets/icons/actions/edit.svg';
import NewTabIcon from '@Assets/icons/sharedView/new-tab.svg';
import LinkIcon from '@Assets/icons/files/link.svg';

interface Props {
    id?: string;
    className?: string;
    fileItem: FileRes;
    preview?: boolean;
    onClick?: Function;
    onDelete: Function;
}

const LibraryTile: FunctionComponent<Props> = ({
    id,
    className,
    fileItem,
    preview,
    onClick = () => {
        return;
    },
    onDelete,
}) => {
    const [active, setActive] = useState(false);
    const { activeActionElement } = useGlobalState();

    const isVideo = (type: string): boolean => {
        return ['MP4', 'MOV', 'AVI'].includes(type.toUpperCase());
    };

    const actions: BlockActionItem[] = [
        {
            icon: DeleteIcon,
            title: 'Delete',
            separated: false,
            onClick: () => onDelete(),
        },
        {
            icon: EditIcon,
            title: 'Edit',
            separated: false,
            onClick: () => onClick(),
        },
    ];

    if (fileItem.type === 'link') {
        actions.push({
            icon: NewTabIcon,
            title: 'Open link',
            separated: false,
            onClick: () => {
                window.open(fileItem.link, '_blank');
            },
        });
    }

    return (
        <StyledLibraryItem
            id={`${id ? id : ''}`}
            className={`library-item ${active ? 'active' : ''} ${className ? className : ''}`}
            onMouseLeave={() => {
                if (activeActionElement === fileItem.id) {
                    useGlobalState.setState({ activeActionElement: null });
                }
                setActive(false);
            }}
            onMouseEnter={() => setActive(true)}
        >
            <div className="library-tile">
                <div className="tile-thumbnail">
                    <div className="tile-extension">{fileItem.type.toUpperCase()}</div>
                    {fileItem.thumbnail ? (
                        <div
                            className={`tile-image-wrapper ${isVideo(fileItem.type) ? 'video' : ''}`.trim()}
                            onClick={() => onClick()}
                        >
                            <img
                                className="tile-image"
                                src={`${process.env.REACT_APP_API_URL}/files/thumbnail/${fileItem.thumbnail}`}
                                crossOrigin="anonymous"
                                alt="tile"
                            />
                            {isVideo(fileItem.type) ? (
                                <img src={PlayIconSmall} alt="play icon" className="play-icon cursor-pointer" />
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <img
                            alt="tile"
                            className="placeholder-image"
                            onClick={() => onClick()}
                            src={isVideo(fileItem.type) ? VideoIcon : fileItem.type === 'link' ? LinkIcon : DefaultIcon}
                        />
                    )}

                    {preview && <BlockActions elementId={fileItem.id} items={actions} />}
                </div>

                <div className="file-name">{fileItem.name}</div>
            </div>
        </StyledLibraryItem>
    );
};

const StyledLibraryItem = styled.div`
    .library-tile {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin: auto;
        width: 138px;
        border-radius: 7px;
        transition: box-shadow 0.3s ease-in-out;

        .tile-thumbnail {
            width: 138px;
            height: 138px;
            background-color: var(--background-grey);
            border-radius: 8px;
            .tile-image-wrapper {
                width: 100%;
                height: 100%;
            }
            .tile-image {
                width: 138px;
                height: 138px;
                border-radius: 8px;
                object-fit: cover;
            }

            .placeholder-image {
                width: 138px;
                height: 138px;
                object-fit: scale-down;
            }
            .video {
                position: relative;
                height: 138px;
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 8px;
                    background: rgba(0, 0, 0, 0.18);
                }
                img {
                    &.play-icon {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 50%);
                        bottom: 50%;
                        z-index: 1;
                    }
                }
            }
            .block-action-item:nth-of-type(3) {
                img {
                    width: 15px;
                }
            }
        }

        .tile-extension {
            background-color: var(--black-250);
            color: white;
            position: absolute;
            top: 8px;
            left: 8px;
            font-size: 11px;
            padding: 3px 5px;
            border-radius: 5px;
            font-weight: 700;
            z-index: 1;
        }

        &.active {
            .block-actions {
                opacity: 1;
                pointer-events: all;
            }
        }

        &:hover {
            .block-actions {
                opacity: 1;
                pointer-events: all;
            }
        }

        .file-name {
            color: var(--black-250);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 8px;
        }

        .google-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            box-sizing: border-box;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24), 0 5px 17px 0 rgba(0, 0, 0, 0.19);
        }

        &:active {
            user-select: none;
        }
    }
`;

export default LibraryTile;
