import { FunctionComponent, useState, useEffect } from 'react';
import useOrganizationStore from '@Store/organization';
import styled from 'styled-components';
import Input, { InputComponentType } from '@Components/input';
import communication from '@/communication';
import Button, { ButtonTheme } from '@Components/button';
import { useToasts } from 'react-toast-notifications';
import { isValidEmail, isValidUrl } from '@Helpers/regex';
import PictureUpload from '@/components/pictureUpload';
import CompanyIcon from '@Assets/icons/settings/company/company-logo.svg';
import ApartmentIcon from '@Assets/icons/settings/company/apartment.svg';
import { fixUrl } from '@Helpers/url';
import { LeftBlock, RightBlock, Title, BlockTwoInputs, ActionBlock } from '../userProfile';

const OrganizationInfo: FunctionComponent = () => {
    const { id, name, email, address, website, city, country, logo } = useOrganizationStore();
    const [initCompanyData, setInitCompanyData] = useState({
        id: '',
        name: name,
        email: email,
        website: website,
        domain: '',
        address: address,
        city: city,
        country: country,
        logo: logo,
    });
    const [loading, setLoading] = useState(false);
    const { addToast, removeAllToasts } = useToasts();
    const [hasChanges, setHasChanges] = useState(false);
    //const [tempEmail, setTempEmail] = useState<string | undefined>();

    useEffect(() => {
        if (
            name !== initCompanyData.name ||
            email !== initCompanyData.email ||
            city !== initCompanyData.city ||
            country !== initCompanyData.country ||
            address !== initCompanyData.address ||
            website !== initCompanyData.website
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [name, email, city, country, address, website]);

    useEffect(() => {
        setLoading(true);
        communication.getOrganization().then((res: any) => {
            useOrganizationStore.setState(res?.data);
            setInitCompanyData(res.data);
            setLoading(false);
        });
    }, [logo]);

    const updateOrganization = (): void => {
        removeAllToasts();
        setLoading(true);
        communication
            .updateOrganization({
                name,
                email,
                website: website ? (fixUrl(website) as string) : null,
                address,
                city,
                country,
                logo,
            })
            .then((res: any) => {
                setInitCompanyData(res.data);
                setLoading(false);
                addToast('Organization info successfully updated.', {
                    appearance: 'success',
                });
                setHasChanges(false);
            })
            .catch((error: any) => {
                setLoading(false);
                if (error.request.status === 400) {
                    addToast('Unable to update organization info. Please try again.', {
                        appearance: 'error',
                    });
                }
            });
    };

    const uploadPicture = (fileObject: File): void => {
        if (fileObject) {
            setLoading(true);
            const formData = new FormData();
            formData.append('files', fileObject);
            communication
                .uploadLogoPicture(id, formData)
                .then((res: any) => {
                    setLoading(false);
                    useOrganizationStore.setState({ logo: res.logo });
                })
                .catch((err: Error) => {
                    setLoading(false);
                    console.error(err);
                });
        }
        return;
    };

    const removePicture = (): void => {
        setLoading(true);
        communication
            .removeLogoPicture(id)
            .then((res: any) => {
                setLoading(false);
                useOrganizationStore.setState({ logo: res.logo });
            })
            .catch((err: Error) => {
                setLoading(false);
                console.error(err);
            });
    };

    return (
        <Page className="page account-page">
            <LeftBlock>
                <PictureUpload
                    src={logo ? `${process.env.REACT_APP_API_URL}/organizations/logo/${id}` : null}
                    size={100}
                    defaultImage={CompanyIcon}
                    onChange={uploadPicture}
                    isClearable={!!logo}
                    onRemove={() => removePicture()}
                    tooltipText={
                        'The optimal file size for company images is 200 KB, and max-width of 800 pixels. Keeping images between these perimeters will ensure they load properly on SyncPage'
                    }
                />
            </LeftBlock>
            <RightBlock>
                <StyledForm>
                    <Title>
                        <p>Organization information</p>
                        <img alt="Apartment" src={ApartmentIcon} />
                    </Title>
                    <BlockTwoInputs>
                        <Input
                            type={InputComponentType.Text}
                            value={name}
                            label="Name"
                            size="100%"
                            errorMessage={name === '' ? 'Please provide valid name' : ''}
                            onChange={(v: string) => useOrganizationStore.setState({ name: v })}
                        />
                        <Input
                            type={InputComponentType.Email}
                            value={email ? email : ''}
                            label="Company email"
                            size="100%"
                            errorMessage={email ? (isValidEmail(email) ? '' : 'Please provide valid email') : ''}
                            onChange={(v: string) => useOrganizationStore.setState({ email: v === '' ? null : v })}
                        />
                        {/* <Input type={InputComponentType.Text} value={domain} label="Domain" size="100%" isDisabled={true} /> */}
                    </BlockTwoInputs>
                    <BlockTwoInputs>
                        <Input
                            type={InputComponentType.Text}
                            value={city ? city : ''}
                            label="City"
                            size="100%"
                            onChange={(v: string) => useOrganizationStore.setState({ city: v === '' ? null : v })}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={country ? country : ''}
                            label="Country"
                            size="100%"
                            onChange={(v: string) => useOrganizationStore.setState({ country: v === '' ? null : v })}
                        />
                    </BlockTwoInputs>
                    <Input
                        type={InputComponentType.Text}
                        value={address ? address : ''}
                        label="Address"
                        size="100%"
                        onChange={(v: string) => useOrganizationStore.setState({ address: v === '' ? null : v })}
                    />
                    <Input
                        type={InputComponentType.Url}
                        value={website ? website : ''}
                        label="Webiste"
                        errorMessage={website ? (isValidUrl(website) ? '' : 'Please provide valid link') : ''}
                        size="300px"
                        onChange={(v: string) => useOrganizationStore.setState({ website: v === '' ? null : v })}
                    />
                </StyledForm>

                {hasChanges && (
                    <ActionBlock>
                        <Button
                            theme={ButtonTheme.naked}
                            size={127}
                            onClick={() =>
                                useOrganizationStore.setState({
                                    email: initCompanyData?.email,
                                    city: initCompanyData?.city,
                                    country: initCompanyData?.country,
                                    address: initCompanyData?.address,
                                    website: initCompanyData?.website,
                                })
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            className="profile-update-button"
                            theme={ButtonTheme.primary}
                            disabled={website ? !isValidUrl(website) : false || email ? !isValidEmail(email) : false}
                            isLoading={loading}
                            size={127}
                            onClick={() => updateOrganization()}
                        >
                            Save
                        </Button>
                    </ActionBlock>
                )}
            </RightBlock>
        </Page>
    );
};

const Page = styled.div`
    display: flex;
    gap: 20px;
    max-width: 900px;
    /* Media Queries */
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const StyledForm = styled.div`
    background-color: white;
    padding: 26px 24px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 24px;

    .input {
        margin-bottom: 10px;
    }
`;

export default OrganizationInfo;
