import { BlockType, FileShelfItem, LinkShelfItem, ShelfBlock, ShelfItem } from '@/types/workspace';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FileRes } from '@/types/file';
import { StyledNewBlockModal } from '@Components/editor/base/styled';
import Modal from '@Components/modal';
import { ObjectKeys } from '@/types/objectKeys';
import ContentLibrary from '@/pages/library/content';
import BlockOption from '../card';
import GlobalHotkeys from '@Global/keypress';
import useEditorStore from '@Store/editor';
import Direction from '@Global/direction';
import Button, { ButtonTheme } from '@Components/button';
import Communication from '@Communication';
import Input, { InputComponentType } from '@Components/input';
import nanogen from '@Helpers/nanogen';
import useDrivePicker from 'react-google-drive-picker';
import { EditorType } from '@/types/editor';
import { useToasts } from 'react-toast-notifications';
import useLoading from '@Hooks/useLoading';
import BlockActions, { BlockActionItem } from '@Components/editor/actionWrapper';
import ShelfTile from '@Components/editor/shelf/tile';
import Tooltip from '@Components/tooltip';
import UploadFile from '@Components/uploadFile';
import useGlobalState from '@Store/global';
import { ShelfCommand } from '@Global/dtos/workspace.dto';
import SubscriptionModal from '@/pages/subscriptionPlans/subscriptionModal';
import LibraryIcon from '@Assets/icons/workspaces/library.svg';
import UploadIcon from '@Assets/icons/workspaces/upload.svg';
import LinkIcon from '@Assets/icons/workspaces/link.svg';
import CloudIcon from '@Assets/icons/workspaces/cloud.svg';
import AddIcon from '@Assets/icons/workspaces/new-file.svg';
import ArrowDown from '@Assets/icons/workspaces/arrow-down-circle.svg';
import ArrowUp from '@Assets/icons/workspaces/arrow-up-circle.svg';
import DeleteIcon from '@Assets/icons/workspaces/delete-circle.svg';
import EmbedIcon from '@Assets/icons/workspaces/embed.svg';
import ChangeThumbnailModal from './changeThumbnailModal';
import EmbedFormModal from './embedFormModal';
import AddLinkModal from './addLinkModal';

interface Props {
    className?: string | '';
    onUpdate?: Function;
    onDelete?: Function; // Triggers when block is destroyed
    shelfData: ShelfBlock;
    activeSection: number;
}

const ShelfBlockElement: FunctionComponent<Props> = ({
    className,
    shelfData,
    activeSection,
    onUpdate = () => {
        return;
    },
    onDelete = () => {
        return;
    },
}) => {
    const { id, sections, type: editorType, activeElementId, setActiveElement } = useEditorStore();
    const shelfRef = useRef<HTMLInputElement | null>(null);
    const { shelfCommand } = useGlobalState();
    const { addToast } = useToasts();
    const [openPicker] = useDrivePicker();
    const { showLoading, hideLoading } = useLoading();

    const [shelfModal, setShelfModal] = useState(false);
    const [libraryModal, setLibraryModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [linkModal, setLinkModal] = useState(false);
    const [embedModal, setEmbedModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [changeThumbnailModal, setChangeThumbnailModal] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [subscriptionModalVisibility, setSubscriptionModalVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [renameItemIndex, setRenameItemIndex] = useState<number | null>(null);
    const [changeItemIndex, setChangeItemIndex] = useState<number | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [files, setFiles] = useState<FileRes[]>([]);
    const [selfItem, setShelfItem] = useState<any>({});

    const uploadFile = (): void => {
        const fileFromStore = file;
        if (fileFromStore) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('files', fileFromStore);
            formData.append('name', name);
            switch (editorType) {
                case EditorType.WORKSPACE:
                    formData.append('workspaceId', id);
                    break;
                case EditorType.INFO_ROOM:
                    formData.append('infoRoomId', id);
                    break;
                case EditorType.TEMPLATE:
                    formData.append('templateId', id);
                    break;
            }
            if (description) {
                formData.append('description', description);
            }
            Communication.uploadFile(formData)
                .then((res: ObjectKeys) => {
                    const resFile = res.data as FileRes;
                    addItem({
                        id: resFile.id,
                        type: BlockType.File,
                        content: {
                            name: resFile.name,
                            description: resFile.description,
                            type: resFile.type,
                            link: resFile.link,
                            thumbnail: resFile.thumbnail,
                        },
                        options: {
                            global: false,
                        },
                    });
                    setIsLoading(false);
                    setUploadModal(false);
                    setName('');
                    setDescription('');
                    setFile(null);
                    onUpdate();
                })
                .catch((error: ObjectKeys) => {
                    setUploadModal(false);
                    setIsLoading(false);
                    setName('');
                    setDescription('');
                    setFile(null);
                    if (error.response.data?.code === 1035) setSubscriptionModalVisibility(true);
                });
        }
        return;
    };

    const addItem = (item: ShelfItem): void => {
        const sectionsTmp = [...sections];
        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === shelfData.id) as
            | ShelfBlock
            | undefined;

        if (block) {
            const existingItem = block.content.items.find((i) => i.id === item.id);
            if (!existingItem) {
                block.content.items.push(item);
                useEditorStore.setState({ sections: sectionsTmp });
            }
            onUpdate();
        }
    };

    const renameItem = (): void => {
        const sectionsTmp = [...sections];
        const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === shelfData.id) as
            | ShelfBlock
            | undefined;

        if (block && renameItemIndex !== null) {
            block.content.items[renameItemIndex].content.name = name;
            useEditorStore.setState({ sections: sectionsTmp });
        }
        setRenameModal(false);
        setName('');
        onUpdate();
    };

    const changeFileThumbnail = (): void => {
        if (file !== null) {
            const sectionsTmp = [...sections];
            const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === shelfData.id) as
                | ShelfBlock
                | undefined;

            const formData = new FormData();
            formData.append('files', file);
            if (block && changeItemIndex !== null) {
                formData.append('itemId', block.content.items[changeItemIndex].id);
                formData.append('type', block.content.items[changeItemIndex].type === 'link' ? 'LINK' : 'EMBED');
                formData.append('global', 'false');
            }
            if (!selfItem?.content?.thumbnail) {
                Communication.uploadThumbnail(formData)
                    .then(async (res: ObjectKeys) => {
                        if (block && changeItemIndex !== null) {
                            block.content.items[changeItemIndex].content.thumbnail = res.data;
                            useEditorStore.setState({ sections: sectionsTmp });
                        }
                        onUpdate();
                        setFile(null);
                        setShelfItem('');
                        setChangeThumbnailModal(false);
                    })
                    .catch((error: ObjectKeys) => {
                        if (error) {
                            addToast('Could not change thumbnail. Please try again.', {
                                appearance: 'error',
                            });
                            setFile(null);
                            setChangeThumbnailModal(false);
                        }
                    });
            } else {
                Communication.updateThumbnail(selfItem?.content?.thumbnail, formData)
                    .then((res: ObjectKeys) => {
                        if (res) {
                            if (block && changeItemIndex !== null) {
                                block.content.items[changeItemIndex].content.thumbnail = res.data;
                                useEditorStore.setState({ sections: sectionsTmp });
                            }
                        }
                    })
                    .then(() => {
                        onUpdate();
                        setFile(null);
                        setShelfItem('');
                        setChangeThumbnailModal(false);
                    })
                    .catch((error: ObjectKeys) => {
                        if (error) {
                            addToast('Could not change thumbnail. Please try again.', {
                                appearance: 'error',
                            });
                            setFile(null);
                            setChangeThumbnailModal(false);
                        }
                    });
            }
        }
    };
    // console.log(file);
    const deleteItem = (item: ShelfItem, index: number): void => {
        const remove = (): void => {
            const sectionsTmp = [...sections];

            const block = sectionsTmp[activeSection].content.items.find((bl) => bl.id === shelfData.id) as
                | ShelfBlock
                | undefined;

            if (block) {
                block.content.items.splice(index, 1);
                useEditorStore.setState({ sections: sectionsTmp });
            }
            onUpdate();
        };

        showLoading();
        if (item.type === BlockType.File && !(item as FileShelfItem).options.global) {
            Communication.deleteFile(item.id)
                .then(() => {
                    remove();
                    hideLoading();
                })
                .catch(() => {
                    hideLoading();
                });
        } else if (item.type === BlockType.Link && item.content.thumbnail) {
            Communication.removeThumbnail(item.content.thumbnail)
                .then(() => {
                    remove();
                    hideLoading();
                })
                .catch(() => {
                    hideLoading();
                });
        } else {
            remove();
            hideLoading();
        }
    };

    const isBlockEmpty = (): boolean => {
        const block = sections[activeSection].content.items.find((bl) => bl.id === shelfData.id) as
            | ShelfBlock
            | undefined;
        return block?.content.items.length === 0;
    };

    /**
     * Move selected block in given direction
     * @param direction
     */
    const moveBlock = (direction: Direction): void => {
        const sectionsTmp = [...sections];
        const blocks = sectionsTmp[activeSection].content.items;
        const index = blocks.findIndex((bl) => bl.id === shelfData.id);
        if (
            (direction === Direction.down && index !== -1 && index < blocks.length - 1) ||
            (direction === Direction.up && index > 0)
        ) {
            const dString = JSON.stringify(blocks[index]);
            const dString2 = JSON.stringify(blocks[direction === Direction.down ? index + 1 : index - 1]);
            blocks[index] = JSON.parse(dString2);
            blocks[direction === Direction.down ? index + 1 : index - 1] = JSON.parse(dString);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onUpdate();
    };

    /*
     * Delete selected block from the section
     */
    const deleteBlock = async (): Promise<void> => {
        const sectionsTmp = [...sections];
        const index = sectionsTmp[activeSection].content.items.findIndex((bl) => bl.id === shelfData.id);
        if (index !== -1) {
            showLoading();
            switch (editorType) {
                case EditorType.WORKSPACE:
                    await Communication.deleteWorkspaceFiles({
                        workspaceId: id,
                        sectionId: sectionsTmp[activeSection].id,
                        shelfId: shelfData.id,
                    });
                    break;
                case EditorType.TEMPLATE:
                    await Communication.deleteTemplateFiles({
                        templateId: id,
                        sectionId: sectionsTmp[activeSection].id,
                        shelfId: shelfData.id,
                    });
                    break;
                case EditorType.INFO_ROOM:
                    await Communication.deleteInfoRoomFiles({
                        infoRoomId: id,
                        sectionId: sectionsTmp[activeSection].id,
                        shelfId: shelfData.id,
                    });
                    break;
            }
            hideLoading();
            sectionsTmp[activeSection].content.items.splice(index, 1);
            useEditorStore.setState({ sections: sectionsTmp });
        }
        onDelete();
    };

    const focus = (): void => {
        setActiveElement(shelfData.id);
        setIsActive(true);
    };

    const loseFocus = (): void => {
        if (!shelfModal && !libraryModal && !uploadModal) {
            setIsActive(false);
            shelfRef.current?.blur();
        }
    };
    const handleOpenPicker = (): void => {
        openPicker({
            clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string,
            developerKey: process.env.REACT_APP_GOOGLE_DRIVE_DEVELOPER_KEY as string,
            showUploadView: false,
            setIncludeFolders: false,
            showUploadFolders: false,
            customScopes: ['https://www.googleapis.com/auth/drive.readonly'],
            supportDrives: false,
            multiselect: false,
            callbackFunction: async (data) => {
                if (data.action === 'cancel') {
                    //User clicked cancel/close button
                    if (isBlockEmpty()) void deleteBlock();
                }
                if (data.action === 'picked') {
                    fetch(
                        `https://www.googleapis.com/drive/v3/files/${data.docs[0].id}?fields=permissions,shared&key=${process.env.REACT_APP_GOOGLE_DRIVE_DEVELOPER_KEY}`,
                    ).then((response) => {
                        response.json().then((d) => {
                            if (d.error) {
                                addToast('File is not sharable. Please choose only files that are shared', {
                                    appearance: 'error',
                                });
                            } else {
                                addItem({
                                    id: nanogen(),
                                    type: BlockType.GoogleDrive,
                                    content: {
                                        googleId: data.docs[0].id,
                                        name: data.docs[0].name,
                                        embedUrl: data.docs[0].embedUrl,
                                        icon: data.docs[0].iconUrl,
                                        type: data.docs[0].type,
                                        mimeType: data.docs[0].mimeType,
                                        url: `https://drive.google.com/uc?id=${data.docs[0].id}`,
                                    },
                                    options: {},
                                });
                                onUpdate();
                            }
                        });
                    });
                }
            },
        });
    };

    useEffect(() => {
        activeElementId === shelfData.id ? focus() : loseFocus();
    }, [activeElementId]);

    useEffect(() => {
        Communication.getAllFilesAndLinks().then((res: ObjectKeys) => {
            setFiles(res.data);
        });
        GlobalHotkeys.add(`shelf-${shelfData.id}/escape`, 'Escape', () => {
            setShelfModal(false);
            setLibraryModal(false);
            setUploadModal(false);
            setLinkModal(false);
            setEmbedModal(false);
        });
    }, []);

    useEffect(() => {
        if (activeElementId === shelfData.id) {
            switch (shelfCommand) {
                case ShelfCommand.OPEN_LIBRARY:
                    setLibraryModal(true);
                    break;
                case ShelfCommand.OPEN_UPLOAD:
                    setUploadModal(true);
                    break;
                case ShelfCommand.OPEN_LINK:
                    setLinkModal(true);
                    break;
                case ShelfCommand.OPEN_EMBED:
                    setEmbedModal(true);
                    break;
                case ShelfCommand.OPEN_DRIVE:
                    handleOpenPicker();
                    break;
            }

            useGlobalState.setState({ shelfCommand: null });
        }
    }, [shelfCommand]);

    const actions: BlockActionItem[] = [
        {
            icon: ArrowUp,
            title: 'Move Up',
            onClick: () => moveBlock(Direction.up),
        },
        {
            icon: ArrowDown,
            title: 'Move Down',
            onClick: () => moveBlock(Direction.down),
        },
        {
            icon: DeleteIcon,
            title: 'Delete',
            separated: true,
            onClick: () => deleteBlock(),
        },
    ];

    return (
        <>
            <SubscriptionModal
                modalVisible={subscriptionModalVisibility}
                setModalVisible={setSubscriptionModalVisibility}
                closeModal={() => {
                    setSubscriptionModalVisibility(false);
                }}
            ></SubscriptionModal>
            {/*MODAL FOR SHELF BLOCK*/}
            <Modal modalVisible={shelfModal} closeModal={() => setShelfModal(false)} title="Add files">
                <StyledNewBlockModal>
                    <div className="modal-content">
                        <Tooltip tooltipText="Add content from your Library." width="157px">
                            <BlockOption
                                imageSrc={LibraryIcon}
                                onClick={() => {
                                    setShelfModal(false);
                                    setLibraryModal(true);
                                }}
                                title="From Library"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Upload a file from your computer." width="157px">
                            <BlockOption
                                imageSrc={UploadIcon}
                                onClick={() => {
                                    setFile(null);
                                    setShelfModal(false);
                                    setUploadModal(true);
                                }}
                                title="Upload a file"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Add links to other websites." width="157px">
                            <BlockOption
                                imageSrc={LinkIcon}
                                onClick={() => {
                                    setShelfModal(false);
                                    setLinkModal(true);
                                }}
                                title="External link"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Add content from your Google Drive." width="157px">
                            <BlockOption
                                imageSrc={CloudIcon}
                                onClick={() => {
                                    setShelfModal(false);
                                    handleOpenPicker();
                                }}
                                title="Choose from Drive"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Embed content from other sites." width="157px">
                            <BlockOption
                                imageSrc={EmbedIcon}
                                onClick={() => {
                                    setShelfModal(false);
                                    setEmbedModal(true);
                                }}
                                title="Embed content"
                            />
                        </Tooltip>
                    </div>
                </StyledNewBlockModal>
            </Modal>
            <Modal
                className="add-from-library-modal"
                modalVisible={libraryModal}
                size="75%"
                closeModal={() => {
                    setLibraryModal(false);
                    if (isBlockEmpty()) void deleteBlock();
                }}
                title="Add from library"
            >
                <ContentLibrary
                    globalFiles={files}
                    onSelect={(item: FileRes) => {
                        let data = {};
                        switch (editorType) {
                            case EditorType.WORKSPACE:
                                data = { ...data, workspaceId: id };
                                break;
                            case EditorType.INFO_ROOM:
                                data = { ...data, infoRoom: id };
                                break;
                            case EditorType.TEMPLATE:
                                data = { ...data, templateId: id };
                                break;
                        }
                        if (item.type !== 'link') {
                            Communication.copyFromLibrary(item.id, data).then((res: any) => {
                                const newFileBlock: FileShelfItem = {
                                    id: res.data.id,
                                    type: BlockType.File,
                                    content: {
                                        name: item.name,
                                        description: item.description,
                                        type: item.type,
                                        link: res.data.link,
                                        thumbnail: res.data.thumbnail,
                                    },
                                    options: {
                                        global: false,
                                    },
                                };
                                addItem(newFileBlock);
                            });
                        } else {
                            Communication.copyLinkFromLibrary(item.id, data).then((res: any) => {
                                const newLinkBlock: LinkShelfItem = {
                                    id: res.data.id,
                                    type: BlockType.Link,
                                    content: {
                                        name: item.name,
                                        url: item.link,
                                        thumbnail: res.data.thumbnail,
                                    },
                                    options: {
                                        global: false,
                                    },
                                };
                                addItem(newLinkBlock);
                            });
                        }

                        setLibraryModal(false);
                    }}
                />
            </Modal>
            <Modal
                modalVisible={uploadModal}
                size={500}
                closeModal={() => {
                    if (!isLoading) {
                        setUploadModal(false);
                        setFile(null);
                        setName('');
                        setDescription('');
                        if (isBlockEmpty()) void deleteBlock();
                    }
                }}
                title="Upload a file"
            >
                <UploadWrapper>
                    <UploadFile file={file} isLoading={isLoading} setFile={setFile} />
                    <Input
                        type={InputComponentType.Text}
                        placeholder="Title"
                        onChange={(value: string) => setName(value)}
                        value={name}
                        onEnter={uploadFile}
                        size="100%"
                    />
                    <Button
                        theme={ButtonTheme.primary}
                        onClick={() => uploadFile()}
                        disabled={!file}
                        isLoading={isLoading}
                        size={160}
                    >
                        Upload
                    </Button>
                </UploadWrapper>
            </Modal>
            <Modal
                modalVisible={embedModal}
                className="embed-form-modal"
                size={500}
                closeModal={() => {
                    setEmbedModal(false);
                    if (isBlockEmpty()) void deleteBlock();
                }}
                title="Embed content"
            >
                <EmbedFormModal
                    addItem={addItem}
                    blockType="shelf"
                    onUpdate={onUpdate}
                    setEmbedModal={setEmbedModal}
                    isBlockEmpty={isBlockEmpty}
                    deleteBlock={deleteBlock}
                />
            </Modal>
            <Modal
                modalVisible={linkModal}
                size={500}
                closeModal={() => {
                    setLinkModal(false);
                    if (isBlockEmpty()) void deleteBlock();
                }}
                title="Add link"
            >
                <AddLinkModal
                    addItem={addItem}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setName={setName}
                    name={name}
                    onUpdate={onUpdate}
                    setLinkModal={setLinkModal}
                />
            </Modal>

            <Modal
                modalVisible={renameModal}
                size={500}
                closeModal={() => {
                    setRenameModal(false);
                    setRenameItemIndex(null);
                }}
                title="Rename file"
            >
                <LinkWrapper>
                    <Input
                        className="link-input"
                        type={InputComponentType.Text}
                        placeholder="Title"
                        onChange={(value: string) => setName(value)}
                        value={name}
                        size="100%"
                        onEnter={renameItem}
                    />
                    <Button theme={ButtonTheme.primary} isLoading={isLoading} onClick={() => renameItem()} size={160}>
                        Rename
                    </Button>
                </LinkWrapper>
            </Modal>
            <Modal
                modalVisible={changeThumbnailModal}
                size={500}
                closeModal={() => {
                    setChangeThumbnailModal(false);
                    setChangeItemIndex(null);
                    setFile(null);
                }}
                title="Change thumbnail"
            >
                <ChangeThumbnailModal
                    changeFileThumbnail={changeFileThumbnail}
                    file={file}
                    isLoading={isLoading}
                    setFile={setFile}
                />
            </Modal>

            <ShelfBlockStyled
                className={`shelf-block-${shelfData.id} editor-block ${isActive ? 'active' : ''} ${className ?? ''}`}
                ref={shelfRef}
                onMouseUp={() => focus()}
            >
                {shelfData.content.items.map((shelfItem, index) => {
                    return (
                        <ShelfTile
                            shelfItem={shelfItem}
                            key={shelfItem.id}
                            onDelete={() => deleteItem(shelfItem, index)}
                            onRename={() => {
                                setRenameItemIndex(index);
                                setName(shelfItem.content.name);
                                setRenameModal(true);
                            }}
                            onChange={() => {
                                setShelfItem(shelfItem);
                                setChangeItemIndex(index);
                                setChangeThumbnailModal(true);
                            }}
                        />
                    );
                })}

                <StyledAddItemTile
                    onClick={() => {
                        setShelfModal(true);
                        focus();
                    }}
                    className="add-tile clickable"
                >
                    <div className="shelf-tile">
                        <div className="tile-image">
                            <img src={AddIcon} alt="icon" />
                        </div>
                        <div className="tile-text">Add new file</div>
                    </div>
                </StyledAddItemTile>

                <BlockActions elementId={shelfData.id} items={actions} />
            </ShelfBlockStyled>
        </>
    );
};

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .link-input {
        margin-bottom: 18px;

        input {
            background-color: white;
        }
    }

    .link-input:nth-child(2) {
        margin-bottom: 36px;
    }

    .button {
        margin-right: auto;
    }
`;

export const UploadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        margin-bottom: 36px;
        width: 100%;
        background-color: white;
    }

    button {
        margin-right: auto;
    }
`;

const ShelfBlockStyled = styled.div`
    padding: 26px;
    box-sizing: border-box;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 20px;
    margin-top: 50px;
    position: relative;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    .block-actions {
        opacity: 0;
        pointer-events: none;
    }

    &.active {
        > .block-actions {
            opacity: 1;
            pointer-events: all;
        }

        .add-tile {
            opacity: 1;
        }
    }

    &:hover {
        position: relative;
        z-index: 1;

        > .block-actions {
            opacity: 1;
            pointer-events: all;
        }

        .add-tile {
            opacity: 1;
        }
    }

    &:focus {
        cursor: text;
        position: relative;
        z-index: 1;
    }
`;

const StyledAddItemTile = styled.div`
    .tile-image {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        height: 138px;
        width: 138px;
        margin: auto;
        box-shadow: 0 2px 8px 0 rgba(82, 82, 82, 0.16);
    }

    .tile-text {
        color: var(--black-250);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }

    min-height: 177px;
    transition: 0.3s all ease-in-out;
    opacity: 0;
`;

export default ShelfBlockElement;
