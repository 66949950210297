import fetch from './fetch';
import routes from './routes';

export default class Files {
    public getAllFiles(): any {
        return fetch({
            method: 'get',
            url: routes.file.all,
        });
    }

    public getAllFilesAndLinks(): any {
        return fetch({
            method: 'get',
            url: routes.file.withLinks,
        });
    }

    public deleteFile(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.file.single(id),
        });
    }

    public deleteMediaFile(link: string): any {
        return fetch({
            method: 'delete',
            url: routes.file.mediaFile(link),
        });
    }

    public uploadFile(formData: FormData): any {
        return fetch({
            method: 'post',
            url: routes.file.all,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }
    public downloadFile(link: string): any {
        return fetch({
            method: 'get',
            url: routes.file.fileDownload(link),
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public getFile(id: string): any {
        return fetch({
            method: 'get',
            url: routes.file.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public updateFile(id: string, data: any): any {
        return fetch({
            method: 'patch',
            url: routes.file.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }

    public uploadThumbnail(formData: FormData): any {
        return fetch({
            method: 'post',
            url: routes.file.thumbnail,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }
    public removeThumbnail(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.file.thumbnailRemove(id),
        });
    }

    public createLinkPreviewThumbnail(url: string, itemId: string, global: string): any {
        return fetch({
            method: 'post',
            url: routes.file.linkThumbnail,
            headers: { 'Content-Type': 'application/json' },
            data: { url, itemId, global },
        });
    }
    public updateThumbnail(link: string, formData: FormData): any {
        return fetch({
            method: 'patch',
            url: routes.file.updateThumbnail(link),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    }

    public copyFromLibrary(
        fileId: string,
        data: { workspaceId?: string; templateId?: string; infoRoomId?: string },
    ): any {
        return fetch({
            method: 'patch',
            url: routes.file.library(fileId),
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
        });
    }
}
