import fetch from '@/communication/fetch';
import routes from '@/communication/routes';

export enum EventName {
    SharedPageVisit = 'SHARED_PAGE_VISIT',
    WorkspaceVisit = 'WORKSPACE_VISIT',
    InfoRoomVisit = 'INFO_ROOM_VISIT',
    SharedPageClick = 'SHARED_PAGE_CLICK',
    SharedPageShare = 'SHARED_PAGE_SHARE',
    FileDownload = 'FILE_DOWNLOAD',
    FileDownloadInfoRoom = 'FILE_DOWNLOAD_INFO_ROOM',
    FileOpen = 'FILE_OPEN',
    FileOpenInfoRoom = 'FILE_OPEN_INFO_ROOM',
    LinkOpen = 'LINK_OPEN',
    LinkOpenInfoRoom = 'LINK_OPEN_INFO_ROOM',
    CopiedShareLink = 'COPIED_SHARE_LINK',
}

export const dispatchEvent = (
    name: EventName,
    workspaceId?: string | null,
    data?: object,
    swToken?: string,
    userToken?: string,
): any => {
    return void fetch(
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            url: routes.analytics.event,
            data: { name, data, workspaceId, swToken, userToken },
        },
        false,
    );
};
