import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const ActivityCardSkeleton: FunctionComponent = () => {
    return (
        <>
            <StyledSkeletonWrapper>
                <div>
                    <div>
                        <Skeleton circle style={{ marginBottom: '10px' }} height={32} width={32} />
                        <Skeleton style={{ marginBottom: '10px' }} height={20} width={150} />
                    </div>
                    <Skeleton style={{ marginBottom: '10px' }} height={20} width="30%" />
                </div>
                <div>
                    <Skeleton style={{ marginBottom: '30px' }} height={20} width="80%" />
                    <Skeleton style={{ marginBottom: '30px' }} height={20} width="20%" />
                </div>
            </StyledSkeletonWrapper>
            <StyledSkeletonWrapper>
                <div>
                    <div>
                        <Skeleton circle style={{ marginBottom: '10px' }} height={32} width={32} />
                        <Skeleton style={{ marginBottom: '10px' }} height={20} width={150} />
                    </div>
                    <Skeleton style={{ marginBottom: '10px' }} height={20} width="30%" />
                </div>
                <div>
                    <Skeleton style={{ marginBottom: '30px' }} height={20} width="80%" />
                    <Skeleton style={{ marginBottom: '30px' }} height={20} width="20%" />
                </div>
            </StyledSkeletonWrapper>
            <StyledSkeletonWrapper>
                <div>
                    <div>
                        <Skeleton circle style={{ marginBottom: '10px' }} height={32} width={32} />
                        <Skeleton style={{ marginBottom: '10px' }} height={20} width={150} />
                    </div>
                    <Skeleton style={{ marginBottom: '10px' }} height={20} width="30%" />
                </div>
                <div>
                    <Skeleton style={{ marginBottom: '30px' }} height={20} width="80%" />
                    <Skeleton style={{ marginBottom: '30px' }} height={20} width="20%" />
                </div>
            </StyledSkeletonWrapper>
        </>
    );
};

const StyledSkeletonWrapper = styled.div`
    > div {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        > div {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        > span {
            width: 100%;

            &:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
`;
export default ActivityCardSkeleton;
