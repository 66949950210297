import { create } from 'zustand';
import { settingsNavbarData } from '@Components/navbar/data';
import { MediaCommand, ShelfCommand } from '@Global/dtos/workspace.dto';

interface GlobalState {
    userContextMenu: boolean;
    workspaceContextMenu: boolean;
    accountContextMenu: boolean;
    isNavbarCollapsed: boolean;
    activeNavbarLink: string;
    loadingVisible: boolean;
    loadingCaption: string | null;
    getIsSettingsNavbar: () => boolean;
    metaTitle: string;
    metaSlogan: string;
    metaDescription: string;
    activeActionElement: string | null;
    shelfCommand: ShelfCommand | null;
    mediaCommand: MediaCommand | null;
}

const useGlobalState = create<GlobalState>((_set, get) => ({
    userContextMenu: false,
    workspaceContextMenu: false,
    accountContextMenu: false,
    isNavbarCollapsed: false,
    loadingVisible: false,
    loadingCaption: null,
    activeNavbarLink: '/',
    metaTitle: 'SyncPage',
    metaSlogan: 'SyncPage: Where Offers Meet Opportunities',
    metaDescription:
        'Craft personalized proposals, centralize your communications, and track performance with real-time analytics, all in one place. Elevate your business connections and embrace a new era of successful opportunities with SyncPage!',
    activeActionElement: null,
    shelfCommand: null,
    mediaCommand: null,
    getIsSettingsNavbar: () => {
        const routes = settingsNavbarData
            .map((group) => {
                return group.links.map((link) => {
                    return link.url;
                });
            })
            .flat();
        return routes.includes(get().activeNavbarLink);
    },
}));

export default useGlobalState;
