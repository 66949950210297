import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TemplateDto } from '@Global/dtos/template.dto';
import { CategoryType, CategoryDto } from '@/global/dtos/categories.dto';
import Communication from '@Communication';
import useDialogue from '@Hooks/useDialogue';
import useOutsideClick from '@Hooks//useOutsideClick';
import useEditorStore from '@Store/editor';
import SelectComponent, { SelectOptionType } from '@Components/select';
import Input, { InputComponentType } from '@Components/input';
import Button, { ButtonTheme } from '@Components/button';
import ManageSearch from '@Assets/icons/settings/manage-search.svg';
import HamburgerIcon from '@Assets/icons/settings/hamburger.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import CloseIcon from '@Assets/icons/close.svg';
import { StyledTitle } from '../../infoRooms/sidebar';
import { TwoButtonsStyle } from '../../workspaces/editor/sidebar/styledComponents';
import { ContextMenu, StyledHeader } from '../../workspaces/editor/sidebar/settings';
import TextArea from '@/components/input/textArea';
import Sidebar from '@Components/sidebar';
interface TemplateSidebarProps {
    data: TemplateDto;
    className?: string;
}

const TemplateSidebar: FunctionComponent<TemplateSidebarProps> = ({ data, className }) => {
    const { id } = useEditorStore();
    const navigate = useNavigate();
    const { addToast, removeAllToasts } = useToasts();
    const { setDialogue, closeDialogue } = useDialogue();
    const ref = useRef<HTMLDivElement>(null);

    const [name, setName] = useState(data.name);
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState<string | null>(data.description);
    const [categories, setCategories] = useState<CategoryDto[]>([]);
    const [category, setCategory] = useState<SelectOptionType | null>(
        data.category
            ? {
                  value: data.category.id,
                  label: data.category.name,
              }
            : null,
    );
    const [hasChanges, setHasChanges] = useState(false);
    const [hoveredBtn, setHoveredBtn] = useState(false);
    const [templateContextMenu, setTemplateContextMenu] = useState(false);

    const { handleClickOutside } = useOutsideClick(ref, setTemplateContextMenu);

    useEffect(() => {
        Communication.getAllCategories(CategoryType.TEMPLATE).then((res: any) => {
            setCategories(res?.data);
        });
    }, []);

    useEffect(() => {
        if (name !== data.name || description !== data?.description || data?.category?.id !== category?.value) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [name, description, category?.value]);

    const categoryList = categories.map((c) => ({
        value: c.id,
        label: c.name,
    }));

    const update = (): void => {
        setLoading(true);
        removeAllToasts();
        Communication.updateTemplate(data.id, { name, description, categoryId: category?.value })
            .then(() => {
                setLoading(false);
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                setHasChanges(false);
            })
            .catch(() => {
                setLoading(false);
                addToast('Something went wrong! Please check entered data.', {
                    appearance: 'error',
                });
            });
    };

    return (
        <Sidebar className={`${templateContextMenu ? 'overlay-visible' : ''} ${className ?? className}`.trim()}>
            <StyledTitle>
                <div>
                    <img src={ManageSearch} alt="icon" />
                    Template details
                </div>
                <img
                    src={HamburgerIcon}
                    alt="icon"
                    className="cursor-pointer"
                    onClick={() => setTemplateContextMenu(!templateContextMenu)}
                />
            </StyledTitle>
            <ContextMenu
                className={`${templateContextMenu ? '' : 'hidden'}`.trim()}
                ref={ref}
                onClick={handleClickOutside}
            >
                <StyledHeader>
                    <div>Settings</div>
                    <img
                        src={CloseIcon}
                        alt="icon"
                        className="cursor-pointer"
                        onClick={() => setTemplateContextMenu(false)}
                    />
                </StyledHeader>
                <Button
                    theme={ButtonTheme.link}
                    leftIcon={hoveredBtn ? DeleteIconActive : DeleteIconInactive}
                    onMouseEnter={() => setHoveredBtn(true)}
                    onMouseLeave={() => setHoveredBtn(false)}
                    onClick={() => {
                        setDialogue({
                            description: 'Template will be deleted. This is irreversible operation.',
                            buttons: [
                                {
                                    theme: ButtonTheme.danger,
                                    text: 'Delete',
                                    callback: () => {
                                        setLoading(true);
                                        Communication.deleteTemplate(id)
                                            .then(() => {
                                                setLoading(false);
                                                navigate('/templates');
                                                addToast('Template deleted!', { appearance: 'success' });
                                            })
                                            .catch(() => {
                                                addToast('Template can not be deleted at this point!', {
                                                    appearance: 'error',
                                                });
                                                setLoading(false);
                                            });
                                        closeDialogue();
                                    },
                                },
                                {
                                    theme: ButtonTheme.naked,
                                    text: 'Cancel',
                                    callback: () => {
                                        closeDialogue();
                                    },
                                },
                            ],
                        });
                    }}
                >
                    Delete template
                </Button>
            </ContextMenu>
            <div className="form-wrapper">
                <Input
                    label="Template name"
                    placeholder="Template name"
                    type={InputComponentType.Text}
                    onChange={(value: string) => setName(value)}
                    value={name}
                    size="100%"
                />
                <TextArea
                    label="Description"
                    value={description ?? ''}
                    onChange={(value: string) => setDescription(value === '' ? null : value)}
                    placeholder="Template description"
                />
                <SelectComponent
                    label="Category"
                    placeholder="Select category"
                    onChange={(value) => {
                        setCategory(value as SelectOptionType);
                    }}
                    value={category}
                    optionList={categoryList}
                    isClearable={true}
                />
                <Input
                    label="Date created"
                    type={InputComponentType.Date}
                    isDisabled={true}
                    value={data.createdAt.toString()}
                    dateFormat="dd MMMM yyyy (HH:mm)"
                    size="100%"
                />
                <Input
                    label="Last updated"
                    type={InputComponentType.Date}
                    isDisabled={true}
                    dateFormat="dd MMMM yyyy (HH:mm)"
                    size="100%"
                    value={data.updatedAt.toString()}
                />
            </div>
            {hasChanges && (
                <TwoButtonsStyle>
                    <Button
                        className="clickable"
                        theme={ButtonTheme.primary}
                        onClick={() => update()}
                        isLoading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            setName(data.name);
                            setDescription(data.description);
                            setCategory(
                                data.category
                                    ? {
                                          value: data.category.id,
                                          label: data.category.name,
                                      }
                                    : null,
                            );
                        }}
                    >
                        Cancel
                    </Button>
                </TwoButtonsStyle>
            )}
        </Sidebar>
    );
};
export default TemplateSidebar;
