import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import communication from '@Communication';
import { TemplateDto } from '@Global/dtos/template.dto';
import Button, { ButtonTheme } from '@/components/button';
import moment from 'moment';

interface Props {
    createNewWorkspace?: Function;
    setIsTemplatePreviewVisible?: Function;
    templateId?: string | null;
}

const TemplatePreview: FunctionComponent<Props> = ({ createNewWorkspace, templateId, setIsTemplatePreviewVisible }) => {
    const [template, setTemplate] = useState<TemplateDto>();

    useEffect(() => {
        if (templateId) {
            communication.getTemplate(templateId).then((res: any) => {
                setTemplate(res.data);
            });
        }
    }, [templateId]);

    return (
        <StyledBlock>
            <StyledTemplateDetails>
                <h3>{template?.name}</h3>
                <p>
                    <span>{template?.description}</span>
                </p>
                <p>
                    <StyledSpan>Date created: </StyledSpan>
                    <span>{template?.createdAt ? moment(template?.createdAt)?.format('DD/MM/YYYY') : '-'}</span>
                </p>
                <p>
                    <StyledSpan>Created by: </StyledSpan>
                    <span>{`${
                        template?.creator.firstName
                            ? template?.creator.firstName + ' ' + template?.creator.lastName
                            : template?.creator.email
                    }`}</span>
                </p>
                <StyledActions>
                    <Button
                        onClick={() => {
                            if (createNewWorkspace) createNewWorkspace(templateId);
                        }}
                        size={208}
                    >
                        Use this template
                    </Button>
                    <Button
                        theme={ButtonTheme.naked}
                        onClick={() => {
                            if (setIsTemplatePreviewVisible) setIsTemplatePreviewVisible(false);
                        }}
                        size={208}
                    >
                        Cancel
                    </Button>
                </StyledActions>
            </StyledTemplateDetails>
            <StyledFrameContainer>
                <StyledIframeHelper>
                    <iframe id="myiframe" src={`/templates/preview/${templateId}`} />
                </StyledIframeHelper>
            </StyledFrameContainer>
        </StyledBlock>
    );
};

const StyledBlock = styled.div`
    color: var(--black-60);
    font-style: normal;
    display: flex;
    justify-content: space-between;
    h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 24px;
    }
    span {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
    }
    p {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
    }
`;
const StyledTemplateDetails = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 220px;
`;
const StyledActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: auto;
`;
const StyledIframeHelper = styled.div`
    width: 100%;
    height: 200%;
    transform: scale(0.5);
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    #myiframe {
        overflow: scroll;
        border: 0;
        width: 900px;
        height: 100%;
    }
`;
const StyledFrameContainer = styled.div`
    width: 456px;
    height: 494px;
    display: flex;
    justify-content: center;
    margin: 0 20px;
    overflow: visible;
`;

const StyledSpan = styled.span`
    color: #afafb7;
`;
export default TemplatePreview;
