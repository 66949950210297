import { getAuthState, returnToLogin } from './auth';
import useUserStore from '../store/user';
import communication from '@Communication';
import { UserStatus } from '@Global/dtos/user.dto';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';

const initFetch = async (): Promise<void> => {
    const state = getAuthState();
    if (state && state.status === UserStatus.READY) {
        const userRes = await communication.getLoggedUser();
        const usersRes = await communication.getAllUsers();
        const companiesRes = await communication.getAllCompanies();
        const contactsRes = await communication.getAllContacts();
        useUserStore.setState({
            id: userRes?.data.id,
            firstName: userRes?.data.firstName,
            lastName: userRes?.data.lastName,
            email: userRes?.data.email,
            properties: userRes?.data.properties,
            activeUserRoles: userRes?.data?.roles,
            usersList: usersRes?.data ?? [],
        });
        useCompanyStore.setState({
            allCompanies: companiesRes?.data ?? [],
        });
        useContactStore.setState({
            allContacts: contactsRes?.data ?? [],
        });
    } else if (state && state.status === UserStatus.CONFIRMED) {
        window.location.href = `${document.location.origin}/register?token=${state.token}`;
    } else {
        returnToLogin();
    }
};

export default initFetch;
