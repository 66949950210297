import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import Video from '@Components/video';
interface Props {
    welcomeMessage?: string | null;
    companyProfilePicture?: string | null;
    companyLogo?: string | null;
    welcomeVideoUrl?: string | null;
    welcomeVideo?: string | null;
    companyId?: string | null;
    companyName?: string | null;
}
const WelcomeSection: FunctionComponent<Props> = ({
    welcomeMessage,
    companyProfilePicture,
    companyLogo,
    welcomeVideoUrl,
    welcomeVideo,
    companyId,
    companyName,
}) => {
    return (
        <>
            {(welcomeMessage || companyProfilePicture || companyLogo || welcomeVideo) && (
                <StyledWelcomeContainer className={`${welcomeMessage ? 'start' : ''}`}>
                    <StyledWelcomeMessage className={`${welcomeVideoUrl ? 'half' : ''}`}>
                        <StyledLogoAndName>
                            {companyProfilePicture && companyId ? (
                                <StyledCompanyLogo
                                    crossOrigin="anonymous"
                                    src={`${process.env.REACT_APP_API_URL}/accounts/company/picture/${companyId}`}
                                />
                            ) : companyLogo ? (
                                <StyledCompanyLogo crossOrigin="anonymous" src={companyLogo} />
                            ) : null}
                            {companyName && <h1>Welcome, {companyName}</h1>}
                        </StyledLogoAndName>
                        {welcomeMessage && (
                            <div>
                                <p>{welcomeMessage}</p>
                            </div>
                        )}
                    </StyledWelcomeMessage>
                    {welcomeVideo ? (
                        welcomeVideoUrl ? (
                            <StyledWelcomeVideo className={`${welcomeMessage ? 'half' : ''}`}>
                                <Video
                                    videoUrl={welcomeVideoUrl}
                                    wrapperClassName={`${companyName || welcomeMessage ? '' : 'full-width'}`.trim()}
                                    smallPlayIcon={companyName || welcomeMessage ? true : false}
                                    height={'100%'}
                                />
                            </StyledWelcomeVideo>
                        ) : (
                            <StyledSkeletonWrapper
                                className={`${companyName || welcomeMessage ? '' : 'full-width'}`.trim()}
                            >
                                <Skeleton />
                            </StyledSkeletonWrapper>
                        )
                    ) : (
                        <></>
                    )}
                </StyledWelcomeContainer>
            )}
        </>
    );
};
const StyledWelcomeContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: var(--white);
    border-radius: 8px;
    margin: 0px 0 16px 0;
    padding: 40px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));

    /* Media Queries */
    @media only screen and (max-width: 768px) {
        padding: 15px;
        flex-direction: column;
    }
    &.start {
        align-items: flex-start;
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: var(--black-300);

        /* Media Queries */
        @media only screen and (max-width: 768px) {
            overflow-wrap: break-word;
        }
    }
`;

const StyledWelcomeMessage = styled.div`
    display: flex;
    flex-direction: column;
    &.half {
        max-width: 50%;
        /* Media Queries */
        @media only screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
    p {
        font-size: 16px;
        line-height: 22.4px;
        color: var(--black-100);
        /* Media Queries */
        @media only screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 21px;
            overflow-wrap: break-word;
        }
    }
`;

const StyledLogoAndName = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const StyledWelcomeVideo = styled.div`
    &.half {
        max-width: 50%;
        @media only screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
    .full-width {
        width: 100%;
    }
    video {
        border-radius: 5px;
        display: block;
    }
`;
const StyledCompanyLogo = styled.img`
    margin-right: 25px;
    height: 40px;
    object-fit: contain;
    min-width: 40px;
    max-width: 200px;
    min-height: 60px;
    border-radius: 5px;
`;
const StyledSkeletonWrapper = styled.div`
    height: 200px;
    width: 50%;
    /* Media Queries */
    @media only screen and (max-width: 900px) {
        height: 150px;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    &.full-width {
        width: 100%;
        height: 500px;
        /* Media Queries */
        @media only screen and (max-width: 1000px) {
            height: 300px;
        }
        @media only screen and (max-width: 600px) {
            height: 200px;
        }
    }

    span {
        width: 100%;
        height: 100%;
    }
`;
export default WelcomeSection;
