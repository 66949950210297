import { FunctionComponent, useEffect } from 'react';
import GlobalHotkeys from '@Global/keypress';
import { ContactSidebarSections, CompanySidebarSections, AccountSidebarTabs } from '../../sidebar/sidebar';
import AccountDetails from '../details';
import AccountPictureUpload from '../picture';
import AssociatedWorkspaces from '../associatedWorkspaces';
import AssociatedAccounts from '../associatedAccounts';
import AccountMainSidebar from '../sidebarMainPage';
import { StyledFrame } from '../styledComponents';
import close from '@Assets/icons/accountsSidebar/close.svg';

interface Props {
    sidebarVisible: boolean;
    closeSidebar: Function;
    activeTab: number;
    activeSection: number;
    setActiveSection: Function;
    sendCompanyData: Function;
    sendContactData: Function;
    deleteContact: Function;
    deleteCompany: Function;
    updateCompanyPicture: (image: File) => void;
    removeCompanyPicture: Function;
    updateContactPicture: (image: File) => void;
    removeContactPicture: Function;
    loading: boolean;
    setLoading: Function;
    file: File | null;
    setFile: Function;
    updateAssociatedCompanyWorkspaces: Function;
    updateAssociatedContactWorkspaces: Function;
    updateConnectedContacts: Function;
    updateConnectedCompanies: Function;
}

const AccountsSidebarFrame: FunctionComponent<Props> = ({
    activeTab,
    sidebarVisible,
    closeSidebar,
    activeSection,
    setActiveSection,
    updateCompanyPicture,
    removeCompanyPicture,
    updateContactPicture,
    removeContactPicture,
    sendCompanyData,
    sendContactData,
    file,
    setFile,
    updateAssociatedCompanyWorkspaces,
    updateAssociatedContactWorkspaces,
    updateConnectedContacts,
    updateConnectedCompanies,
    loading,
    deleteContact,
    deleteCompany,
}) => {
    const changeSection = (sectionSet: number): void => {
        setActiveSection(sectionSet);
    };

    const setMainPage = (): void => {
        setActiveSection(0);
    };

    useEffect(() => {
        GlobalHotkeys.add('workspaces/sidebar/escape1', 'Escape', () => setMainPage());
    }, []);

    return (
        <StyledFrame className={`${sidebarVisible ? '' : 'collapsed'}`.trim()}>
            <span className="modal-close" onClick={() => closeSidebar()}>
                <img src={close} alt="close" />
            </span>
            {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? (
                activeSection === ContactSidebarSections.CONTACT_MAIN ? (
                    <AccountMainSidebar
                        closeSidebar={closeSidebar}
                        sidebarVisible={sidebarVisible}
                        activeTab={activeTab}
                        setSection={setActiveSection}
                        deleteContact={deleteContact}
                        deleteCompany={deleteCompany}
                    />
                ) : activeSection === (ContactSidebarSections.CONTACT_DETAILS as unknown) ? (
                    <AccountDetails
                        changeSection={changeSection}
                        activeTab={activeTab}
                        loading={loading}
                        sendContactData={sendContactData}
                        sendCompanyData={sendCompanyData}
                    />
                ) : activeSection === ContactSidebarSections.CONTACT_PROFILE_PICTURE ? (
                    <AccountPictureUpload
                        activeTab={activeTab}
                        changeSection={setActiveSection}
                        loading={loading}
                        removeCompanyPicture={removeCompanyPicture}
                        removeContactPicture={removeContactPicture}
                        updateCompanyPicture={updateCompanyPicture}
                        updateContactPicture={updateContactPicture}
                        file={file}
                        setFile={setFile}
                    />
                ) : activeSection === ContactSidebarSections.CONTACT_ASSOCIATED_WORKSPACES ? (
                    <AssociatedWorkspaces
                        activeTab={activeTab}
                        changeSection={setActiveSection}
                        updateAssociatedCompanyWorkspaces={updateAssociatedCompanyWorkspaces}
                        updateAssociatedContactWorkspaces={updateAssociatedContactWorkspaces}
                        loading={loading}
                    />
                ) : activeSection === ContactSidebarSections.CONTACT_ASSOCIATED_COMPANIES ? (
                    <AssociatedAccounts
                        activeTab={activeTab}
                        updateConnectedContacts={updateConnectedContacts}
                        updateConnectedCompanies={updateConnectedCompanies}
                        loading={loading}
                        changeSection={changeSection}
                    />
                ) : (
                    <></>
                )
            ) : activeTab === (AccountSidebarTabs.COMPANY_SIDEBAR as unknown) ? (
                activeSection === CompanySidebarSections.COMPANY_MAIN ? (
                    <AccountMainSidebar
                        closeSidebar={closeSidebar}
                        sidebarVisible={sidebarVisible}
                        activeTab={activeTab}
                        setSection={setActiveSection}
                        deleteContact={deleteContact}
                        deleteCompany={deleteCompany}
                    />
                ) : activeSection === CompanySidebarSections.COMPANY_DETAILS ? (
                    <AccountDetails
                        changeSection={changeSection}
                        activeTab={activeTab}
                        loading={loading}
                        sendContactData={changeSection}
                        sendCompanyData={sendCompanyData}
                    />
                ) : activeSection === CompanySidebarSections.COMPANY_LOGO ? (
                    <AccountPictureUpload
                        activeTab={activeTab}
                        changeSection={setActiveSection}
                        loading={loading}
                        removeCompanyPicture={removeCompanyPicture}
                        removeContactPicture={removeContactPicture}
                        updateCompanyPicture={updateCompanyPicture}
                        updateContactPicture={updateContactPicture}
                        file={file}
                        setFile={setFile}
                    />
                ) : activeSection === CompanySidebarSections.COMPANY_ASSOCIATED_WORKSPACES ? (
                    <AssociatedWorkspaces
                        activeTab={activeTab}
                        changeSection={setActiveSection}
                        updateAssociatedCompanyWorkspaces={updateAssociatedCompanyWorkspaces}
                        updateAssociatedContactWorkspaces={updateAssociatedContactWorkspaces}
                        loading={loading}
                    />
                ) : activeSection === CompanySidebarSections.COMPANY_ASSOCIATED_CONTACTS ? (
                    <AssociatedAccounts
                        activeTab={activeTab}
                        updateConnectedContacts={updateConnectedContacts}
                        updateConnectedCompanies={updateConnectedCompanies}
                        loading={loading}
                        changeSection={changeSection}
                    />
                ) : (
                    <></>
                )
            ) : null}
        </StyledFrame>
    );
};

export default AccountsSidebarFrame;
