import { FunctionComponent } from 'react';
import styled from 'styled-components';
import ErrorIcon from '@Assets/icons/error.svg';

const Error: FunctionComponent = () => {
    return (
        <div className="page error-page">
            <StyledWrapper>
                <img src={ErrorIcon} alt="info icon" />
                <h3>Page not found!</h3>
                <p>Something went wrong. Try again!</p>
            </StyledWrapper>
        </div>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    h3 {
        margin-bottom: 5px;
    }
    img {
        margin-bottom: 20px;
    }
    p {
        color: var(--black-60);
        font-size: 16px;
        text-align: center;
    }
`;
export default Error;
