import { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { WorkspaceDto } from '@Global/dtos/workspace.dto';
import CircleLetter from '@Components/circleLetter';
import NoItems from '@Components/noItems';
import { TableSkeleton, TableSkeletonWrapper, SkeletonWrapper } from '@Components/table';
import noWorkspaceIcon from '@Assets/icons/workspaces/no-workspace.svg';
import arrowDown from '@Assets/icons/analytics/arrow-down.svg';
import arrowDownInactive from '@Assets/icons/analytics/arrow-down-inactive.svg';
import arrowUp from '@Assets/icons/analytics/arrow-up.svg';
import arrowUpInactive from '@Assets/icons/analytics/arrow-up-inactive.svg';
interface Props {
    workspaces?: WorkspaceDto[];
    setFilteredWorkspaces: Dispatch<SetStateAction<WorkspaceDto[] | null>>;
}

const WorkspacesTable: FunctionComponent<Props> = ({ workspaces, setFilteredWorkspaces }) => {
    const navigate = useNavigate();

    const goToTheWorkspace = (id: string, name: string): void => {
        navigate(`/analytics/workspace/${id}?name=${name}`);
    };

    const getNamesCircle = (workspace: WorkspaceDto, property: 'contact' | 'user'): string | null => {
        const nameInitial = workspace[property]?.firstName?.[0]?.toUpperCase() ?? '';
        const lastNameInitial = workspace[property]?.lastName?.[0]
            ? workspace[property]?.lastName?.[0].toUpperCase()
            : '';
        return nameInitial.trim() || lastNameInitial?.trim() ? `${nameInitial}${lastNameInitial}` : null;
    };

    const getNames = (workspace: WorkspaceDto, property: 'contact' | 'user'): string => {
        return workspace[property]?.firstName && workspace[property]?.lastName
            ? workspace[property]?.firstName + ' ' + workspace[property]?.lastName
            : workspace[property]?.firstName ?? '';
    };

    const sortWorkspaces = (sortOrder: string, sortType: string): void => {
        if (workspaces) {
            const ascSort = [...workspaces]?.sort((a, b) => {
                if (sortType === 'views') {
                    return a?.views - b?.views;
                } else {
                    return a?.clicks - b?.clicks;
                }
            });
            const dscSort = [...workspaces]?.sort((a, b) => {
                if (sortType === 'views') {
                    return b?.views - a?.views;
                } else {
                    return b?.clicks - a?.clicks;
                }
            });
            if (sortOrder === 'asc') {
                setFilteredWorkspaces(ascSort);
            } else {
                setFilteredWorkspaces(dscSort);
            }
        }
    };

    return (
        <Table className={`${workspaces?.length === 0 ? 'no-items' : ''}`.trim()}>
            {workspaces ? (
                <div>
                    {workspaces?.length !== 0 ? (
                        <>
                            <TableHeader>
                                <p>Workspace name</p>
                                <p>Workspace Owner</p>
                                <p className="center with-arrows">
                                    Views
                                    <span>
                                        <img
                                            src={arrowUp}
                                            onMouseOver={(e) => (e.currentTarget.src = arrowUpInactive)}
                                            onMouseOut={(e) => (e.currentTarget.src = arrowUp)}
                                            alt="arrow"
                                            onClick={() => sortWorkspaces('dsc', 'views')}
                                            className="cursor-pointer"
                                        />
                                        <img
                                            src={arrowDown}
                                            onMouseOver={(e) => (e.currentTarget.src = arrowDownInactive)}
                                            onMouseOut={(e) => (e.currentTarget.src = arrowDown)}
                                            alt="arrow"
                                            onClick={() => sortWorkspaces('asc', 'views')}
                                            className="cursor-pointer"
                                        />
                                    </span>
                                </p>
                                <p className="center with-arrows">
                                    Clicks
                                    <span>
                                        <img
                                            src={arrowUp}
                                            onMouseOver={(e) => (e.currentTarget.src = arrowUpInactive)}
                                            onMouseOut={(e) => (e.currentTarget.src = arrowUp)}
                                            alt="arrow"
                                            onClick={() => sortWorkspaces('dsc', 'clicks')}
                                            className="cursor-pointer"
                                        />
                                        <img
                                            src={arrowDown}
                                            onMouseOver={(e) => (e.currentTarget.src = arrowDownInactive)}
                                            onMouseOut={(e) => (e.currentTarget.src = arrowDown)}
                                            alt="arrow"
                                            onClick={() => sortWorkspaces('asc', 'clicks')}
                                            className="cursor-pointer"
                                        />
                                    </span>
                                </p>
                            </TableHeader>
                            <TableBody>
                                {workspaces?.map((workspace) => {
                                    return (
                                        <TableRow
                                            key={workspace?.id}
                                            className="workspace table-item"
                                            onClick={() => goToTheWorkspace(workspace.id, workspace.name)}
                                        >
                                            <div className="workspace-company">
                                                {workspace?.company ? (
                                                    <img
                                                        className="profile-img"
                                                        crossOrigin="anonymous"
                                                        src={
                                                            workspace.company.profilePicture
                                                                ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${workspace.company.id}`
                                                                : workspace.company.logoUrl
                                                        }
                                                        alt="profile"
                                                    />
                                                ) : (
                                                    <img
                                                        className="profile-img"
                                                        crossOrigin="anonymous"
                                                        src={noWorkspaceIcon}
                                                        alt="no workspace"
                                                    />
                                                )}
                                                <p className="name">{workspace?.name}</p>
                                            </div>
                                            <div>
                                                {workspace?.user?.properties?.profilePicture ? (
                                                    <img
                                                        className="profile-img"
                                                        crossOrigin="anonymous"
                                                        src={`${process.env.REACT_APP_API_URL}/users/me/picture/${workspace?.user?.properties?.profilePicture}`}
                                                        alt="profile"
                                                    />
                                                ) : getNamesCircle(workspace, 'user') ? (
                                                    <CircleLetter
                                                        bgColor="var(--primary-500)"
                                                        letter={getNamesCircle(workspace, 'user')}
                                                        withTooltip
                                                        tooltipText={getNames(workspace, 'user')}
                                                        width="24px"
                                                        height="24px"
                                                    />
                                                ) : null}
                                                <p>{getNames(workspace, 'user')}</p>
                                            </div>
                                            <div className="center">
                                                <p>{workspace?.views}</p>
                                            </div>
                                            <div className="center">
                                                <p>{workspace?.clicks}</p>
                                            </div>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </>
                    ) : (
                        <NoItems
                            content={
                                <>
                                    <p>No Workspaces to display.</p>
                                </>
                            }
                        />
                    )}
                </div>
            ) : (
                <SkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton />
                    </TableSkeletonWrapper>
                </SkeletonWrapper>
            )}
        </Table>
    );
};
const Table = styled.div`
    .center {
        display: flex;
        justify-content: center;
    }
    .letter {
        span {
            font-size: 11px;
        }
    }
    p {
        color: var(--black-60);
    }
`;
const TableHeader = styled.div`
    display: grid;
    gap: 10px;
    box-sizing: border-box;
    align-items: center;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    padding: 8px 24px;
    text-transform: uppercase;
    background: var(--black-20);
    overflow-x: auto;
    p {
        font-size: 12px;
        letter-spacing: 0.48px;
        font-weight: 600;
        line-height: normal;
    }
    .with-arrows {
        align-items: center;
        span {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }
`;

const TableBody = styled.div`
    padding: 0 12px 12px 12px;
    max-height: 500px;
    overflow-y: auto;
`;
const TableRow = styled.div`
    display: grid;
    gap: 10px;
    box-sizing: border-box;
    align-items: center;
    padding: 8px 12px;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    border-radius: 8px;
    cursor: pointer;
    &:nth-child(even) {
        background: var(--black-20);
    }
    > div {
        display: flex;
        p {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            color: var(--black-60);
            letter-spacing: -0.24px;
            font-size: 12px;
        }
    }

    .profile-img {
        border-radius: 50px;
        height: 24px;
        margin-right: 12px;
        width: 24px;
        object-fit: cover;
    }
`;

export default WorkspacesTable;
