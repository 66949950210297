import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RecentActivityDto, StatisticDto } from '@Global/dtos/analytics.dto';
import communication from '@Communication';
import BackLink from '@Components/backLink';
import NoItems from '@Components/noItems';
import EditDocIcon from '@Assets/icons/analytics/edit-document.svg';
import QuickReferenceIcon from '@Assets/icons/analytics/quick-reference.svg';
import DownloadIcon from '@Assets/icons/analytics/download.svg';
import ShareIcon from '@Assets/icons/analytics/share.svg';
import NumberCard from '../numberCard';
import ActivityCard from '../activityCard';
import { StyledPage, StyledContent, StyledLeftColumn, StyledRightColumn } from '..';
import Chart from './chart';
import ActivityCardSkeleton from '../activityCard/activityCardSkeleton';

const WorkspaceAnalytics: FunctionComponent = () => {
    const { workspaceId } = useParams();
    const navigate = useNavigate();
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);

    const [recentActivity, setRecentActivity] = useState<RecentActivityDto[] | null>(null);
    const [statistic, setStatistic] = useState<StatisticDto | null>(null);

    useEffect(() => {
        if (workspaceId) {
            communication.getActivityByWSId(workspaceId).then((res: any) => {
                setRecentActivity(res?.data);
            });
        }
    }, [workspaceId]);

    useEffect(() => {
        if (workspaceId) {
            communication.getStatisticByWSId(workspaceId).then((res: any) => {
                setStatistic(res?.data);
            });
        }
    }, [workspaceId]);

    return (
        <StyledPage className="page workspace-analytics">
            <BackLink
                className="back-link"
                text="Back"
                onClick={() => {
                    navigate('/analytics');
                }}
            />
            <h3>{urlParams?.get('name')} performance</h3>
            <StyledContent>
                <StyledLeftColumn>
                    <h4>Recent Activity</h4>
                    {recentActivity ? (
                        recentActivity?.length !== 0 ? (
                            recentActivity?.map((activity: RecentActivityDto, index: number) => {
                                return (
                                    <div key={index}>
                                        <ActivityCard
                                            userTitle={activity.userEmail ? activity?.userEmail : 'Guest user'}
                                            cardType={activity?.name}
                                            cratedAt={activity?.createdAt}
                                            workspaceName={activity?.Workspaces?.name}
                                            content={activity?.content}
                                            workspaceId={activity?.Workspaces?.id}
                                        />
                                    </div>
                                );
                            })
                        ) : (
                            <NoItems
                                content={
                                    <>
                                        <p>You don’t have any Activities yet.</p>
                                    </>
                                }
                            />
                        )
                    ) : (
                        <ActivityCardSkeleton />
                    )}
                </StyledLeftColumn>
                <StyledRightColumn>
                    <CardsWrapper>
                        <NumberCard title="Total views" icon={EditDocIcon} number={statistic?.views} />
                        <NumberCard title="Total clicks" icon={QuickReferenceIcon} number={statistic?.clicks} />
                        <NumberCard title="Total downloads" icon={DownloadIcon} number={statistic?.downloads} />
                        <NumberCard title="Total Shares" icon={ShareIcon} number={statistic?.shares} />
                    </CardsWrapper>
                    <Chart workspaceId={workspaceId} />
                </StyledRightColumn>
            </StyledContent>
        </StyledPage>
    );
};

const CardsWrapper = styled.div`
    display: grid;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    grid-template-columns: 1fr 1fr;
`;

export default WorkspaceAnalytics;
