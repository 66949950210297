import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { BlockType, FileShelfItem, GDriveShelfItem, LinkShelfItem, ShelfItem } from '@/types/workspace';
import { GoogleDriveTypes } from '@/types/file';
import BlockActions, { BlockActionItem } from '@Components/editor/actionWrapper';
import RenameIcon from '@Assets/icons/workspaces/rename.svg';
import DeleteIcon from '@Assets/icons/workspaces/delete-circle.svg';
import DefaultIcon from '@Assets/icons/files/other.svg';
import EditIcon from '@Assets/icons/actions/edit.svg';
import VideoIcon from '@Assets/icons/files/video.svg';
import LinkIcon from '@Assets/icons/files/link.svg';
import PlayIconSmall from '@Assets/icons/play-circle-small.svg';
import EmbedIcon from '@Assets/icons/workspaces/embed.svg';
import useGlobalState from '@Store/global';
import { isValidUrl } from '@Helpers/regex';

interface Props {
    id?: string;
    className?: string;
    shelfItem: ShelfItem;
    onClick?: Function;
    onRename: Function;
    onDelete: Function;
    onChange: Function;
}

const ShelfTile: FunctionComponent<Props> = ({
    id,
    className,
    shelfItem,
    onClick = () => {
        return;
    },
    onDelete,
    onRename,
    onChange,
}) => {
    const [active, setActive] = useState(false);
    const { activeActionElement } = useGlobalState();

    const getFileType = (item: ShelfItem): string => {
        switch (item.type) {
            case BlockType.File:
                return (item as FileShelfItem).content.type;
            case BlockType.GoogleDrive:
                // eslint-disable-next-line no-case-declarations
                const content = (item as GDriveShelfItem).content;
                // eslint-disable-next-line no-case-declarations
                const isApplication = content.mimeType.includes('application');
                // eslint-disable-next-line no-case-declarations
                const isVideo = content.mimeType.includes('video');
                // eslint-disable-next-line no-case-declarations
                const type = isApplication
                    ? GoogleDriveTypes[content.mimeType.toString()]
                    : isVideo
                    ? GoogleDriveTypes[content.mimeType.toString()]
                    : content.mimeType.split('/')[1];
                return type || '?';
            default:
                return item.type;
        }
    };

    const isVideo = (type: string): boolean => {
        return ['MP4', 'MOV', 'AVI'].includes(type.toUpperCase());
    };

    const actions: BlockActionItem[] = [
        {
            icon: RenameIcon,
            title: 'Rename file',
            onClick: () => onRename(),
        },
        {
            icon: EditIcon,
            title: 'Change thumbnail',
            onClick: () => onChange(),
        },
        {
            icon: DeleteIcon,
            title: 'Remove',
            separated: true,
            onClick: () => onDelete(),
        },
    ];

    return (
        <StyledShelfItem
            id={`${id ? id : ''}`}
            className={`shelf-item ${active ? 'active' : ''} ${className ? className : ''}`}
            onMouseLeave={() => {
                if (activeActionElement === shelfItem.id) {
                    useGlobalState.setState({ activeActionElement: null });
                }
                setActive(false);
            }}
            onMouseEnter={() => setActive(true)}
        >
            <div className="shelf-tile">
                <div className="tile-thumbnail">
                    <div className="tile-extension">{getFileType(shelfItem).toUpperCase()}</div>
                    {(shelfItem as FileShelfItem).content.thumbnail ? (
                        <div className={`${isVideo(getFileType(shelfItem)) ? 'video' : ''}`.trim()}>
                            <img
                                className="tile-image"
                                onClick={() => onClick()}
                                src={
                                    shelfItem.type === BlockType.Link &&
                                    isValidUrl((shelfItem as FileShelfItem).content.thumbnail as string)
                                        ? (shelfItem as LinkShelfItem).content.thumbnail
                                        : `${process.env.REACT_APP_API_URL}/files/thumbnail/${
                                              (shelfItem as FileShelfItem).content.thumbnail
                                          }`
                                }
                                crossOrigin="anonymous"
                                alt="tile"
                            />
                            {isVideo(getFileType(shelfItem)) ? (
                                <img src={PlayIconSmall} alt="play icon" className="play-icon cursor-pointer" />
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <img
                            alt="tile"
                            onClick={() => onClick()}
                            className="placeholder-image"
                            src={
                                isVideo(getFileType(shelfItem))
                                    ? VideoIcon
                                    : getFileType(shelfItem) === 'link'
                                    ? LinkIcon
                                    : getFileType(shelfItem) === 'embed'
                                    ? EmbedIcon
                                    : DefaultIcon
                            }
                        />
                    )}

                    <BlockActions elementId={shelfItem.id} items={actions} />
                </div>

                <div className="file-name">{shelfItem.content.name}</div>

                {shelfItem.type === BlockType.GoogleDrive && (
                    <img
                        className="google-icon"
                        src={(shelfItem as GDriveShelfItem).content.icon}
                        alt="Google Drive icon"
                    />
                )}
            </div>
        </StyledShelfItem>
    );
};

const StyledShelfItem = styled.div`
    .shelf-tile {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin: auto;
        width: 138px;
        border-radius: 7px;
        transition: box-shadow 0.3s ease-in-out;

        .tile-thumbnail {
            width: 138px;
            height: 138px;
            background-color: var(--background-grey);
            border-radius: 8px;

            .tile-image {
                width: 138px;
                height: 138px;
                border-radius: 8px;
                object-fit: cover;
            }

            .placeholder-image {
                width: 138px;
                height: 138px;
                object-fit: scale-down;
            }
        }
        .video {
            position: relative;
            height: 138px;
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 8px;
                background: rgba(0, 0, 0, 0.18);
            }
            img {
                &.play-icon {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 50%);
                    bottom: 50%;
                    z-index: 1;
                }
            }
        }
        .tile-extension {
            background-color: var(--black-250);
            color: white;
            position: absolute;
            top: 8px;
            left: 8px;
            font-size: 11px;
            padding: 3px 5px;
            border-radius: 5px;
            font-weight: 700;
            z-index: 1;
        }

        &.active {
            .block-actions {
                opacity: 1;
                pointer-events: all;
            }
        }

        &:hover {
            .block-actions {
                opacity: 1;
                pointer-events: all;
            }
        }

        .file-name {
            color: var(--black-250);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 8px;
        }

        .google-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            box-sizing: border-box;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24), 0 5px 17px 0 rgba(0, 0, 0, 0.19);
        }

        &:active {
            user-select: none;
        }
    }
`;

export default ShelfTile;
