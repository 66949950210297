import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const RequestsSkeleton: FunctionComponent = () => {
    return (
        <>
            <StyledSkeletonWrapper>
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="80%" />
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                </div>
                <Skeleton style={{ marginBottom: '30px' }} height={25} width="30%" />
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                </div>
            </StyledSkeletonWrapper>
            <StyledSkeletonWrapper>
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="80%" />
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                </div>
                <Skeleton style={{ marginBottom: '30px' }} height={25} width="30%" />
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                </div>
            </StyledSkeletonWrapper>
            <StyledSkeletonWrapper>
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="80%" />
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                </div>
                <Skeleton style={{ marginBottom: '30px' }} height={25} width="30%" />
                <div>
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                    <Skeleton style={{ marginBottom: '10px' }} height={25} width="20%" />
                </div>
            </StyledSkeletonWrapper>
        </>
    );
};

const StyledSkeletonWrapper = styled.div`
    > div {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        > span {
            width: 100%;

            &:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
`;
export default RequestsSkeleton;
