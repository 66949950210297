import { useEffect, useRef, useState, SetStateAction, Dispatch } from 'react';
import './table.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { UserDto } from '@Global/dtos/user.dto';
import RoleCodes, { RoleTitle } from '../../../global/roles';
import EditIcon from '@Assets/icons/actions/edit.svg';
import styled from 'styled-components';
import useUserStore from '@Store/user';
import communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import ChangeUserRoleForm from '../changeUserRoleForm';
import { SelectOptionType } from '@Components/select';
import useDialogue from '@Hooks/useDialogue';
import NoItems from '@Components/noItems';
import Button, { ButtonTheme } from '@Components/button';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableSkeleton,
    TableSkeletonWrapper,
} from '@Components/table';
import SubscriptionModal from '@/pages/subscriptionPlans/subscriptionModal';
interface Props {
    setInviteUserForm?: Dispatch<SetStateAction<boolean>>;
    usersList?: UserDto[] | null;
    setUsersList: Dispatch<SetStateAction<UserDto[] | null>>;
}
const UsersTable: React.FunctionComponent<Props> = ({ setInviteUserForm, usersList, setUsersList }) => {
    const { id: loggedUserId } = useUserStore();
    const { activeUserRoles } = useUserStore();
    const { addToast } = useToasts();
    const { setDialogue, closeDialogue } = useDialogue();
    const ref = useRef<HTMLDivElement>(null);

    const [clickedUser, setClickedUser] = useState('');
    const [clickedOutside, setClickedOutside] = useState(true);
    const [roleCode, setRoleCode] = useState<number>();
    const [showChangeRoleForm, setChangeRoleForm] = useState(false);
    const [currentUser, setCurrentUser] = useState<UserDto>({} as UserDto);
    const [subscriptionModalVisibility, setSubscriptionModalVisibility] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    }, []);

    useEffect(() => {
        getUsersList();
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    }, []);

    const handleClickOutside = (event: MouseEvent): void => {
        if (!ref?.current?.contains(event.target as HTMLDivElement)) {
            setClickedOutside(true);
        }
    };

    const getNames = (user: UserDto): string => {
        if (user.firstName && user.lastName) {
            return `${user.firstName} ${user.lastName}`;
        } else return '';
    };

    const handleRoles = (data: SelectOptionType): void => {
        const value = Number(data.value);
        setRoleCode(value);
    };

    const showChangeRoleFunction = (chosenRoleCode: number): void => {
        setChangeRoleForm(!showChangeRoleForm);
        setRoleCode(chosenRoleCode);
    };

    const changeUserRoles = (user: UserDto): void => {
        const roles = user.UserRoles;
        const initialCode = roles[0].roleId;
        setClickedOutside(true);
        setCurrentUser(user);
        showChangeRoleFunction(initialCode);
    };

    const showMenu = (key: string): void => {
        setClickedUser(key);
        setClickedOutside(false);
    };

    // const getRoles = (user: UserDto): Array<[string, string]> => {
    //     const roles: Array<[string, string]> = new Array<[string, string]>();
    //     if (user.UserRoles) {
    //         user.UserRoles.forEach((roleObject) => {
    //             const index = Object.values(RoleCodes).indexOf(roleObject.roleId as unknown as RoleCodes);
    //             const role = Object.keys(RoleCodes)[index].replace('_', ' ');
    //             const rollMarks = role
    //                 .split(' ')
    //                 .map((word) => word.charAt(0))
    //                 .join('');
    //
    //             roles.push([role, rollMarks]);
    //         });
    //     }
    //     return roles;
    // };

    const changeRole: any = async () => {
        const pushComm = communication.changeUserRole(
            {
                role: roleCode,
            },
            currentUser.id,
        );

        await pushComm
            .then((res: any) => {
                setChangeRoleForm(false);
                addToast('Role successfully changed.', {
                    appearance: 'success',
                });
                const userIndex = usersList?.findIndex((user) => user.id === res.data.id);
                if (usersList && userIndex && userIndex !== -1) {
                    const updatedUsers = [
                        ...usersList.slice(0, userIndex),
                        res.data,
                        ...usersList.slice(userIndex + 1),
                    ];

                    useUserStore.setState({
                        usersList: updatedUsers ?? [],
                    });
                }
                getUsersList();
            })
            .catch((error: any) => {
                if (error) {
                    addToast('Unable to change role. Please try again.', {
                        appearance: 'error',
                    });
                }
            });
    };

    const removeUser: any = async (id: string) => {
        const pushComm = communication.deleteUser(id);
        await pushComm
            .then(() => {
                const updatedUsers = usersList?.filter((user) => user.id !== id);
                useUserStore.setState({
                    usersList: updatedUsers ?? [],
                });
                addToast('User successfully removed.', {
                    appearance: 'success',
                });
                getUsersList();
            })
            .catch((error: any) => {
                if (error) {
                    addToast('Unable to remove user. Please try again.', {
                        appearance: 'error',
                    });
                }
            });
    };

    const activateDeactivateUser: any = async (id: string, status: boolean) => {
        const pushComm = communication.changeUserActivity(id, { status: status });
        await pushComm
            .then(() => {
                setClickedOutside(true);
                addToast(status === true ? 'User successfully activated.' : 'User successfully deactivated.', {
                    appearance: 'success',
                });
                if (usersList) {
                    const userIndex = usersList?.findIndex((user) => user.id === id);
                    usersList[userIndex].enabled = status;
                    useUserStore.setState({
                        usersList: usersList ?? [],
                    });
                }
            })
            .catch((error: any) => {
                const subscriptionError = error.response.data?.code === 1035;
                if (subscriptionError) {
                    setSubscriptionModalVisibility(true);
                } else {
                    addToast(
                        status === true
                            ? 'Unable to activate user. Please try again.'
                            : 'Unable to deactivate user. Please try again.',
                        {
                            appearance: 'error',
                        },
                    );
                }
            });
    };

    const getUsersList: any = async () => {
        communication.getAllUsers(true).then((res: any) => {
            setUsersList && setUsersList(res.data);
        });
    };

    const cancelInvitation: any = async (email: string) => {
        await communication
            .deleteInvitation(email)
            .then(() => {
                setClickedOutside(true);
                addToast('Invitation successfully canceled.', {
                    appearance: 'success',
                });
                getUsersList();
            })
            .catch((error: any) => {
                if (error) {
                    addToast('Unable to cancel invitation. Please try again.', {
                        appearance: 'error',
                    });
                }
            });
    };

    const canChange = (user: UserDto): boolean => {
        return (
            (activeUserRoles.includes(RoleCodes.SUPER_ADMIN) ||
                activeUserRoles.includes(RoleCodes.DIRECTOR) ||
                activeUserRoles.includes(RoleCodes.ADMIN)) &&
            user.id !== loggedUserId &&
            user.UserRoles[0].roleId !== RoleCodes.DIRECTOR
        );
    };

    return (
        <>
            <SubscriptionModal
                modalVisible={subscriptionModalVisibility}
                setModalVisible={setSubscriptionModalVisibility}
                closeModal={() => {
                    setSubscriptionModalVisibility(false);
                }}
            ></SubscriptionModal>

            {showChangeRoleForm && (
                <ChangeUserRoleForm
                    visibility={showChangeRoleForm}
                    formManipulation={showChangeRoleFunction}
                    roleCode={roleCode}
                    onChange={handleRoles}
                    saveRoles={changeRole}
                ></ChangeUserRoleForm>
            )}

            <div className="all-user-page">
                <Table className={`${usersList?.length === 0 ? 'no-items' : ''}`.trim()}>
                    {usersList ? (
                        usersList?.length !== 0 ? (
                            <>
                                <TableHeader columns={5}>
                                    <TableHeaderItem>Name</TableHeaderItem>
                                    <TableHeaderItem>Email</TableHeaderItem>
                                    <TableHeaderItem>User role</TableHeaderItem>
                                    <TableHeaderItem>Status</TableHeaderItem>
                                    <TableHeaderItem></TableHeaderItem>
                                </TableHeader>
                                <TableBody>
                                    {usersList?.map((user) => {
                                        return (
                                            <StyledTableItem
                                                columns={5}
                                                key={user?.id}
                                                className={`user table-item ${!user.enabled ? 'inactive' : ''}`.trim()}
                                            >
                                                <p>{getNames(user) !== '' ? getNames(user) : '-'}</p>
                                                <p>{user?.email}</p>
                                                <p>
                                                    <span>{RoleTitle[user.UserRoles[0].roleId]}</span>
                                                </p>
                                                <p>{user?.status === 'NEW' ? 'INVITATION SENT' : user?.status}</p>
                                                {canChange(user) ? (
                                                    <div
                                                        className="user-action-button"
                                                        onClick={() => showMenu(user.id)}
                                                    >
                                                        <img
                                                            draggable="false"
                                                            src={EditIcon}
                                                            alt="notification icon"
                                                            className="notification"
                                                        />
                                                        {clickedUser === user.id && !clickedOutside ? (
                                                            <ContextMenu
                                                                ref={ref}
                                                                onClick={(e: MouseEvent | any) => {
                                                                    handleClickOutside(e);
                                                                }}
                                                            >
                                                                {user.status === 'NEW' ? (
                                                                    <ul>
                                                                        <li
                                                                            onClick={() =>
                                                                                setDialogue({
                                                                                    buttons: [
                                                                                        {
                                                                                            theme: ButtonTheme.danger,
                                                                                            text: 'Delete',
                                                                                            callback: () => {
                                                                                                cancelInvitation(
                                                                                                    user.email,
                                                                                                );
                                                                                                closeDialogue();
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            theme: ButtonTheme.naked,
                                                                                            text: 'Cancel',
                                                                                            callback: () => {
                                                                                                closeDialogue();
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                })
                                                                            }
                                                                        >
                                                                            <p>Cancel invitation</p>
                                                                        </li>
                                                                    </ul>
                                                                ) : (
                                                                    <ul>
                                                                        {user.enabled ? (
                                                                            <li
                                                                                onClick={() =>
                                                                                    activateDeactivateUser(
                                                                                        user.id,
                                                                                        false,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <p>Deactivate</p>
                                                                            </li>
                                                                        ) : (
                                                                            <li
                                                                                onClick={() =>
                                                                                    activateDeactivateUser(
                                                                                        user.id,
                                                                                        true,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <p>Activate</p>
                                                                            </li>
                                                                        )}
                                                                        <li onClick={() => changeUserRoles(user)}>
                                                                            <p>Change role</p>
                                                                        </li>
                                                                        <li
                                                                            onClick={() =>
                                                                                setDialogue({
                                                                                    buttons: [
                                                                                        {
                                                                                            theme: ButtonTheme.danger,
                                                                                            text: 'Remove',
                                                                                            callback: () => {
                                                                                                removeUser(user.id);
                                                                                                closeDialogue();
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            theme: ButtonTheme.naked,
                                                                                            text: 'Cancel',
                                                                                            callback: () => {
                                                                                                closeDialogue();
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                })
                                                                            }
                                                                        >
                                                                            <p>Remove</p>
                                                                        </li>
                                                                    </ul>
                                                                )}
                                                            </ContextMenu>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </StyledTableItem>
                                        );
                                    })}
                                </TableBody>
                            </>
                        ) : (
                            <NoItems
                                content={
                                    <>
                                        <p>You don’t have any Users yet.</p>
                                        <div>
                                            <p>Start with</p>
                                            <Button
                                                theme={ButtonTheme.link}
                                                color="var(--primary-400)"
                                                onClick={() => {
                                                    setInviteUserForm && setInviteUserForm(true);
                                                }}
                                            >
                                                inviting a User.
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                        )
                    ) : (
                        <div className="all-user-page-skeleton">
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                                <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                                <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                                <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                            <TableSkeletonWrapper>
                                <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                                <TableSkeleton /> <TableSkeleton />
                            </TableSkeletonWrapper>
                        </div>
                    )}
                </Table>
            </div>
        </>
    );
};

export const StyledTableItem = styled(TableRow)`
    position: relative;

    &.inactive {
        p {
            color: var(--black-100) !important;
        }
    }

    span {
        background-color: var(--primary-400);
        padding: 3px 7px;
        border-radius: 20px;
        color: var(--white);
        font-size: 12px;
        min-width: 25px;
        text-align: center;
    }

    p {
        align-items: center;
        display: flex;
        font-size: 12px;
    }

    .user-action-button {
        display: flex;
        justify-content: flex-end;

        img {
            height: 20px;
            width: 20px;
        }
    }
`;

export const ContextMenu = styled.div`
    position: absolute;
    background-color: var(--white);
    border-radius: 20px;
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
    top: 0;
    right: 0;
    z-index: 1;

    ul {
        box-sizing: border-box;
        padding: 5px;
        margin: 0;
        list-style: none;
    }
    ul li {
        transition: 0.2s all ease-in-out;
        padding: 5px 8px;
        white-space: nowrap;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
    /* hover */
    ul li:hover {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        cursor: pointer;
        p {
            color: var(--black-60);
        }
    }
`;

export default UsersTable;
