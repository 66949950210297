import React from 'react';
import useCompanyStore from '../../../../store/company';
import useContactStore from '../../../../store/contact';
import profile from '@Assets/icons/accountsSidebar/user.svg';
import logo from '@Assets/icons/accountsSidebar/companies/logo.svg';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import CompanyIcon from '@Assets/icons/settings/company/company-logo.svg';
import { AccountSidebarTabs, CompanySidebarSections, ContactSidebarSections } from '../../sidebar/sidebar';
import { StyledProfilePhotoForm } from '../styledComponents';
import PictureUpload from '@Components/pictureUpload';
import { StyledTitle, StyledHeader } from '../../../workspaces/editor/sidebar/styledComponents';
import UserIcon from '@Assets/icons/settings/profile/user.svg';
import AccountsImageName from '../accountsImageName';
interface Props {
    activeTab: number;
    changeSection: Function;
    loading: boolean;
    removeCompanyPicture: Function;
    removeContactPicture: Function;
    updateCompanyPicture: (image: File) => void;
    updateContactPicture: (image: File) => void;
    file: File | null;
    setFile: Function;
}

const AccountPictureUpload: React.FunctionComponent<Props> = ({
    activeTab,
    changeSection,
    updateCompanyPicture,
    updateContactPicture,
    removeCompanyPicture,
    removeContactPicture,
}) => {
    const { id: companyId, profilePicture: companyImage, logoUrl } = useCompanyStore();
    const { id: contactId, profilePicture: contactImage } = useContactStore();

    return (
        <>
            <StyledHeader
                onClick={() => {
                    activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown)
                        ? changeSection(ContactSidebarSections.CONTACT_MAIN)
                        : changeSection(CompanySidebarSections.COMPANY_MAIN);
                }}
            >
                <img src={arrowLeft} alt="icon"></img>
                {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Contact' : 'Company'} settings
            </StyledHeader>
            <AccountsImageName activeTab={activeTab} />
            <StyledTitle>
                {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? (
                    <img src={profile} className="front" alt="icon"></img>
                ) : (
                    <img src={logo} className="front" alt="icon"></img>
                )}

                {activeTab === (AccountSidebarTabs.CONTACT_SIDEBAR as unknown) ? 'Profile picture' : 'Logo'}
            </StyledTitle>

            <StyledProfilePhotoForm>
                {activeTab === AccountSidebarTabs.COMPANY_SIDEBAR ? (
                    <PictureUpload
                        defaultImage={CompanyIcon}
                        key={companyImage}
                        size={300}
                        src={
                            companyImage
                                ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${companyId}`
                                : logoUrl
                                ? logoUrl
                                : null
                        }
                        isClearable={!!companyImage}
                        onRemove={() => removeCompanyPicture()}
                        onChange={(image: File) => updateCompanyPicture(image)}
                        tooltipText={
                            'The optimal file size for company images is 200 KB, and max-width of 800 pixels. Keeping images between these perimeters will ensure they load properly on SyncPage'
                        }
                        tooltipWidth="200px"
                    />
                ) : activeTab === AccountSidebarTabs.CONTACT_SIDEBAR ? (
                    <PictureUpload
                        defaultImage={UserIcon}
                        key={contactImage}
                        size={300}
                        src={
                            contactImage
                                ? `${process.env.REACT_APP_API_URL}/accounts/contact/picture/${contactId}`
                                : null
                        }
                        onRemove={() => removeContactPicture()}
                        onChange={(image: File) => updateContactPicture(image)}
                        tooltipText={
                            'The optimal file size for profile images is 200 KB, and max-width of 800 pixels. Keeping images between these perimeters will ensure they load properly on SyncPage'
                        }
                        tooltipWidth="200px"
                    />
                ) : null}
            </StyledProfilePhotoForm>
        </>
    );
};

export default AccountPictureUpload;
