import { create } from 'zustand';
import {
    AssociatedActionPlanType,
    AssociatedCategoryType,
    AssociatedCompanyType,
    AssociatedContactType,
    InfoRoomType,
    SidePanelContentType,
} from '@/types/workspace';

interface WorkspaceState {
    id?: string;
    name: string;
    isPublished: boolean;
    isArchived: boolean;
    associatedCompany: AssociatedCompanyType | null;
    associatedContact: AssociatedContactType | null;
    associatedCategory: AssociatedCategoryType | null;
    owner: AssociatedContactType;
    infoRoom: InfoRoomType | null;
    associatedActionPlan: AssociatedActionPlanType | null;
    sidePanelContent: SidePanelContentType;
    dateCreated: string;
    lastUpdated: string;
    updatePublishStatus: (status: boolean) => void;
    getWorkspaceData: () => {
        id?: string;
        name: string;
        isPublished: boolean;
        associatedCompany: AssociatedCompanyType | null;
        associatedContact: AssociatedContactType | null;
        associatedCategory: AssociatedCategoryType | null;
        owner: AssociatedContactType;
        infoRoom: InfoRoomType | null;
        associatedActionPlan: AssociatedActionPlanType | null;
        sidePanelContent: SidePanelContentType;
        dateCreated: string;
        lastUpdated: string;
    };
    setWorkspaceData: (
        id: string,
        name: string,
        isPublished: boolean,
        isArchived: boolean,
        associatedCompany: AssociatedCompanyType | null,
        associatedContact: AssociatedContactType | null,
        associatedCategory: AssociatedCategoryType | null,
        owner: AssociatedContactType,
        infoRoom: InfoRoomType | null,
        associatedActionPlan: AssociatedActionPlanType | null,
        sidePanelContent: SidePanelContentType,
        dateCreated: string,
        lastUpdated: string,
    ) => void;
}

const useWorkspaceStore = create<WorkspaceState>((set, get) => ({
    id: '',
    name: '',
    isPublished: false,
    isArchived: false,
    associatedCompany: null,
    associatedContact: null,
    owner: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
    },
    associatedCategory: null,
    infoRoom: {
        id: '',
        name: '',
    },
    associatedActionPlan: null,
    sidePanelContent: {
        pointsOfContact: {
            contacts: [],
        },
        actionPlan: '',
        welcomeMessage: '',
        welcomeVideo: '',
    },
    dateCreated: '',
    lastUpdated: '',

    updatePublishStatus: (status) => set({ isPublished: status }),
    getWorkspaceData: () => ({
        id: get().id,
        name: get().name,
        isPublished: get().isPublished,
        associatedCompany: get().associatedCompany,
        associatedContact: get().associatedContact,
        associatedCategory: get().associatedCategory,
        owner: get().owner,
        infoRoom: get().infoRoom,
        associatedActionPlan: get().associatedActionPlan,
        sidePanelContent: get().sidePanelContent,
        dateCreated: get().dateCreated,
        lastUpdated: get().lastUpdated,
    }),
    setWorkspaceData: (
        id: string,
        name: string,
        isPublished: boolean,
        isArchived: boolean,
        associatedCompany: AssociatedCompanyType | null,
        associatedContact: AssociatedContactType | null,
        associatedCategory: AssociatedCategoryType | null,
        owner: AssociatedContactType,
        infoRoom: InfoRoomType | null,
        associatedActionPlan: AssociatedActionPlanType | null,
        sidePanelContent: SidePanelContentType,
        dateCreated: string,
        lastUpdated: string,
    ) =>
        set({
            id,
            name,
            isPublished,
            isArchived,
            associatedCompany,
            associatedContact,
            associatedCategory,
            owner,
            infoRoom,
            associatedActionPlan,
            sidePanelContent,
            dateCreated,
            lastUpdated,
        }),
}));

export default useWorkspaceStore;
