import { FunctionComponent, useEffect, useState } from 'react';
import communication from '@/communication';
import styled from 'styled-components';
import SubscriptionPlansCard from './subscriptionPlanCard';

const plans = [
    {
        title: 'Free',
        planName: 'basic',
        description: 'The basics you need to get started with SyncPage, free forever',
        benefits: [
            '5 active Workspaces',
            'Up to 5 users',
            'Content Library (10 uploads)',
            'Limited Workspace files uploads',
            'Info Room page',
            'Templates',
            'Action plans',
            'Analytics',
            'Basic Support',
        ],
    },
    {
        title: '55$ user/month',
        planName: 'business',
        description: 'Unlimited usage with full access, control and unlimited content',
        benefits: [
            'Unlimited Workspaces',
            'Unlimited Users',
            'Unlimited Content Library',
            'Unlimited Info rooms',
            'Templates',
            'Action plans',
            'Analytics',
            'Integrations',
            'Dedicated Customer Success',
        ],
    },
    {
        title: 'Contact us',
        planName: 'premium',
        description: 'Customize the solution to empower your organization to win more deals',
        listTitle: 'Everything in business plus:',
        benefits: ['White label design', 'Custom domain', 'Custom integrations'],
    },
];

const SubscriptionPlans: FunctionComponent = () => {
    const [planName, setPlanName] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        communication.getSubscription().then((res: any) => {
            setPlanName(res.data.toLowerCase());
        });
    }, []);

    return (
        <div className="page">
            <StyledPageContainer>
                <>
                    <StyledPageTitle>
                        <h4>
                            You are on the <b>{planName} Plan.</b>
                        </h4>
                        <h4>Upgrade your account to get access to unlimited features.</h4>
                    </StyledPageTitle>

                    <StyledTabsContainer>
                        <StyledTabsHeader
                            className={`${activeTab === 0 ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab(0);
                            }}
                        >
                            <p>Billed Yearly</p>
                        </StyledTabsHeader>
                        <StyledTabsHeader
                            className={`${activeTab === 1 ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab(1);
                            }}
                        >
                            <p>Billed Monthly</p>
                        </StyledTabsHeader>
                    </StyledTabsContainer>

                    <StyledCardsContainer>
                        {plans.map((plan, index) => {
                            return (
                                <div key={index}>
                                    <SubscriptionPlansCard
                                        title={
                                            plan.planName !== 'business'
                                                ? plan.title
                                                : activeTab === 0
                                                ? '55$ user/month'
                                                : '60$ user/month'
                                        }
                                        planName={plan.planName}
                                        description={plan.description}
                                        listTitle={plan.listTitle}
                                        benefits={plan.benefits}
                                        duration={activeTab === 0 ? 'year' : 'month'}
                                        setPlanName={setPlanName}
                                        disabled={planName === plan.planName}
                                    ></SubscriptionPlansCard>
                                </div>
                            );
                        })}
                    </StyledCardsContainer>
                </>
            </StyledPageContainer>
        </div>
    );
};

const StyledPageContainer = styled.div`
    background-color: var(--white);
    max-width: 1083px;
    margin: auto;
    box-sizing: border-box;
    padding: 84px 60px 34px 60px;
    display: flex;
    flex-direction: column;
    gap: 43px;
    /* Media Queries */
    @media only screen and (max-width: 900px) {
        padding: 24px;
    }
`;
const StyledCardsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 25px;
    /* Media Queries */
    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
`;
const StyledPageTitle = styled.div`
    h4 {
        b {
            text-transform: capitalize;
        }
        color: var(--black-60);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
    }
`;
const StyledTabsContainer = styled.div`
    display: flex;
    gap: 25px;
    margin: auto;
`;
const StyledTabsHeader = styled.div`
    p {
        color: var(--black-100);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        cursor: pointer;
    }
    &.active {
        p {
            color: var(--black-300);
            font-weight: 700;
        }
    }
`;
export default SubscriptionPlans;
