import { FunctionComponent } from 'react';
import colors from '@Global/colors';
import styled from 'styled-components';
import BounceLoader from 'react-spinners/BounceLoader';
import useGlobalState from '@Store/global';

interface Props {
    className?: string;
}

const Loading: FunctionComponent<Props> = ({ className }) => {
    const { loadingVisible, loadingCaption } = useGlobalState();
    return (
        <DialogueMain className={`global-loading ${className ?? ''} ${loadingVisible ? 'visible' : ''}`}>
            <div className="loading-backdrop" />
            <DialogueWrapper>
                <BounceLoader color={colors.black60} size={100} aria-label="Loading Spinner" data-testid="loader" />
                {loadingCaption && <p>{loadingCaption}</p>}
            </DialogueWrapper>
        </DialogueMain>
    );
};

const DialogueMain = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    transition: 0.6s opacity ease-in-out;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .loading-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(39, 37, 37, 0.4);
    }
`;

const DialogueWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-top: 20px;
        font-size: 14pt;
    }
`;

export default Loading;
