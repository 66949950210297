import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalHotkeys from '@Global/keypress';
import * as Sentry from '@sentry/react';

Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // enabled: false,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', 'https://syncpage.io', 'https://stg.syncpage.io'],
        }),
        new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
GlobalHotkeys.init();

reportWebVitals();
