import { getAuthState, returnToLogin } from '@Helpers/auth';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { UserStatus } from '@Global/dtos/user.dto';

const fetch: any = async (options: AxiosRequestConfig, isPrivate = true) => {
    try {
        const state = getAuthState();
        if (isPrivate && state?.status === UserStatus.READY) {
            const token = `Bearer ${state.token}`;
            options.headers = options.headers || {};
            options.headers.Authorization = token;
            options.withCredentials = true;
            return await axios(options);
        } else {
            options.headers = options.headers || {};
            return await axios(options);
        }
    } catch (error) {
        if ((error as AxiosError).response?.status === 401 && isPrivate) {
            localStorage.removeItem('token');
            localStorage.removeItem('status');
            returnToLogin();
        }
        throw error as AxiosError;
    }
};

export default fetch;
