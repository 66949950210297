import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { SectionType } from '@/types/workspace';
import Input, { InputComponentTheme, InputComponentType } from '@Components/input';
import Modal from '@Components/modal';
import { isValidEmail } from '@/functions/regex';
// import SearchIcon from '@Assets/icons/search-normal.svg';
import SentEmailIcon from '@Assets/icons/mark-email.svg';
import CheckIcon from '@Assets/icons/sharedView/check-circle.svg';
import communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import Button, { ButtonTheme } from '@Components/button';
import { dispatchEvent, EventName } from '@Helpers/analytics';
import { useParams } from 'react-router-dom';

interface Props {
    isProtected: boolean;
    organizationLogo?: string | null;
    organizationId?: string | null;
    organizationName?: string | null;
    infoRoomSections?: SectionType[] | null;
    setActiveTab: Function;
    activeTab: number;
    swToken?: string;
    token?: string;
    workspaceId: string | null;
}

const HorizontalNavbar: FunctionComponent<Props> = ({
    isProtected,
    organizationLogo,
    organizationId,
    organizationName,
    infoRoomSections,
    setActiveTab,
    activeTab,
    swToken,
    token,
    workspaceId,
}) => {
    const { addToast, removeAllToasts } = useToasts();
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [shareEmail, setShareEmail] = useState('');
    // const [searchValue, setSearchValue] = useState('');
    const [sharedModalVisible, setSharedModalVisible] = useState(false);
    const [emailError, setEmailError] = useState<boolean>(false);

    const { invite } = useParams();

    const copyLink = (): void => {
        removeAllToasts();
        navigator.clipboard.writeText(window.location.href).then();
        addToast('Link copied', { appearance: 'success' });
        dispatchEvent(EventName.CopiedShareLink, workspaceId, { page: 'sharedView', isProtected }, swToken, token);
    };

    const sendShareRequest = (sharedEmail: string): void => {
        setEmailError(true);
        if (isValidEmail(shareEmail) && shareEmail !== '') {
            const sharedViewToken = localStorage.getItem('sharedViewToken');
            communication
                .createRequest({
                    type: 'share',
                    data: { inviteToken: invite, emailToShareWith: sharedEmail },
                    sharedViewToken: sharedViewToken ?? undefined,
                    authToken: token ?? undefined,
                })
                .then((res: any) => {
                    if (res?.status === 200) {
                        setIsShareModalVisible(false);
                        setSharedModalVisible(true);
                        dispatchEvent(
                            EventName.SharedPageShare,
                            workspaceId,
                            { page: 'sharedView', isProtected },
                            swToken,
                            token,
                        );
                        setShareEmail('');
                        setEmailError(false);
                    }
                })
                .catch((error: any) => {
                    if (error) {
                        setEmailError(false);
                        setShareEmail('');
                    }
                });
        }
    };

    return (
        <>
            <Modal
                modalVisible={isShareModalVisible}
                closeModal={() => {
                    setIsShareModalVisible(false);
                    setEmailError(false);
                    setShareEmail('');
                }}
                size={500}
                title={`${isProtected ? 'Invite' : 'Share this Workspace'}`}
            >
                <StyledModal>
                    <div className="modal-content">
                        {isProtected ? (
                            <>
                                <Input
                                    type={InputComponentType.Email}
                                    value={shareEmail}
                                    label="Email of a person you want to share this SyncPage with"
                                    size="100%"
                                    theme={InputComponentTheme.Light}
                                    errorMessage={
                                        emailError
                                            ? isValidEmail(shareEmail) || shareEmail === ''
                                                ? ''
                                                : 'Please provide valid email'
                                            : ''
                                    }
                                    placeholder="Add email"
                                    onChange={(v: string) => setShareEmail(v)}
                                    onEnter={() => sendShareRequest(shareEmail)}
                                />
                                {/* <Input
                                    type={InputComponentType.Text}
                                    value={shareName ? shareName : ''}
                                    label="Name"
                                    size="100%"
                                    onChange={(v: string) => setShareName(v === '' ? null : v)}
                                /> */}
                                <Button
                                    theme={ButtonTheme.primary}
                                    onClick={() => sendShareRequest(shareEmail)}
                                    size={200}
                                    disabled={shareEmail === ''}
                                >
                                    Invite
                                </Button>
                            </>
                        ) : (
                            <>
                                <Input
                                    type={InputComponentType.Text}
                                    value={window.location.href}
                                    theme={InputComponentTheme.Light}
                                    size="100%"
                                    label="Workspace link"
                                    onIconPress={() => copyLink()}
                                />
                                <Button
                                    theme={ButtonTheme.primary}
                                    onClick={() => {
                                        copyLink();
                                        setIsShareModalVisible(false);
                                    }}
                                    size={200}
                                >
                                    Copy link
                                </Button>
                            </>
                        )}
                    </div>
                </StyledModal>
            </Modal>
            <Modal
                modalVisible={sharedModalVisible}
                closeModal={() => null}
                size={500}
                title="Done!"
                closeIcon={CheckIcon}
            >
                <StyledSharedWorkspaceContainer>
                    <img crossOrigin="anonymous" src={SentEmailIcon}></img>
                    <p>A request to share this workspace with {shareEmail} has been sent successfully.</p>
                </StyledSharedWorkspaceContainer>

                <Button
                    theme={ButtonTheme.primary}
                    onClick={() => {
                        setSharedModalVisible(false);
                    }}
                    size={100}
                >
                    Ok
                </Button>
            </Modal>
            <div className="horizontal-navbar shared-view-navbar">
                <div className="menu-text-icon horizontal-navbar-item">
                    {organizationLogo ? (
                        <StyledCompanyLogo
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_API_URL}/organizations/logo/${organizationId}`}
                        />
                    ) : (
                        <h3>{organizationName}</h3>
                    )}
                </div>

                {/* <div className="content-action-container">
                    <div className="content-action-search">
                        <StyledInput
                            className="search"
                            placeholder="Search something"
                            type={InputComponentType.Text}
                            value={searchValue}
                            icon={SearchIcon}
                            iconPosition={InputComponentIconPosition.Left}
                            onChange={(value: string) => setSearchValue(value)}
                            size="100%"
                        ></StyledInput>
                    </div>
                </div> */}
                <div className="action-block">
                    {infoRoomSections && (
                        <StyledTabsContainer>
                            <div
                                className={`${
                                    activeTab === 0 ? 'horizontal-navbar-item selected' : 'horizontal-navbar-item'
                                }`}
                                onClick={() => {
                                    dispatchEvent(
                                        EventName.SharedPageClick,
                                        workspaceId,
                                        { page: 'sharedView', isProtected },
                                        swToken,
                                        token,
                                    );
                                    setActiveTab(0);
                                }}
                            >
                                <span>Workspace</span>
                            </div>
                            <div
                                className={`${
                                    activeTab === 1 ? 'horizontal-navbar-item selected' : 'horizontal-navbar-item'
                                }`}
                                onClick={() => {
                                    dispatchEvent(
                                        EventName.SharedPageClick,
                                        workspaceId,
                                        { page: 'sharedView', isProtected },
                                        swToken,
                                        token,
                                    );
                                    setActiveTab(1);
                                }}
                            >
                                <span>Info Room</span>
                            </div>
                        </StyledTabsContainer>
                    )}
                    <div className="horizontal-navbar-item">
                        <Button
                            theme={ButtonTheme.primary}
                            onClick={() => {
                                setIsShareModalVisible(true);
                            }}
                            size={100}
                        >
                            Share
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

// const StyledInput = styled(Input)`
//     margin: 10px 0;
// `;

const StyledCompanyLogo = styled.img`
    margin-right: 10px;
    height: 60px;
    max-width: 130px;
    object-fit: contain;
`;

const StyledTabsContainer = styled.div`
    display: flex;
    height: 100%;
    align-self: center;
    margin-left: auto;
    .horizontal-navbar-item {
        position: relative;
        &:after {
            content: '';
            border-radius: 6px;
            background: transparent;
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: -1px;
        }

        &.selected {
            color: var(--primary-400);
            &:after {
                background: var(--primary-400);
            }
        }
    }
`;

const StyledModal = styled.div`
    .modal-header {
        h5 {
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .modal-content {
        .input {
            margin: 10px 0;
        }

        .button {
            // margin: 30px calc(100% - 320px) 0 calc(100% - 320px);
            margin-top: 25px;
        }
    }
`;

const StyledSharedWorkspaceContainer = styled.div`
    display: flex;
    gap: 15px;
    margin: 15px 0;
`;

export default HorizontalNavbar;
