export type CreateCategoryDto = {
    name: string;
    type: CategoryType;
    protected: boolean;
    factory: boolean;
};

export enum CategoryType {
    WORKSPACE = 'WORKSPACE',
    FILE = 'FILE',
    TEMPLATE = 'TEMPLATE',
}

export type CategoryDto = {
    id: string;
    name: string;
    type: CategoryType;
    protected: boolean;
    factory: boolean;
    timesUsed?: number | 0;
};
