import { FunctionComponent, useEffect, useState } from 'react';
import Info from '@Assets/icons/settings/info.svg';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import { TwoButtonsStyle, MainWrapper, PositionStyle, StyledHeader, StyledTitle } from '../styledComponents';
import SelectComponent, { SelectOptionType } from '@Components/select';
import Button, { ButtonTheme } from '@Components/button';
import communication from '@Communication';
import { InfoRoomDto } from '@Global/dtos/inforoom.dto';
import useEditorStore from '@Store/editor';
import { useToasts } from 'react-toast-notifications';
import useWorkspaceStore from '@Store/workspace';

interface Props {
    isActive: boolean;
    changePage: Function;
}

const InfoRoom: FunctionComponent<Props> = ({ isActive, changePage }) => {
    const { infoRoom, updatePublishStatus } = useWorkspaceStore();
    const { id } = useEditorStore();
    const [infoRooms, setInfoRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [workspaceInfoRoom, setWorkspaceInfoRoom] = useState<SelectOptionType | null>(
        infoRoom?.id ? { value: infoRoom?.id, label: infoRoom?.name } : null,
    );
    const { addToast, removeAllToasts } = useToasts();
    const [loaded, setLoaded] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        communication.getInfoRoomList().then((res: any) => {
            const list = res.data;
            setInfoRooms(list.map((ir: InfoRoomDto) => ({ value: ir.id, label: ir.name })));
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (workspaceInfoRoom?.value !== infoRoom?.id) setHasChanges(true);
        else setHasChanges(false);
    }, [workspaceInfoRoom?.value]);

    const update = (): void => {
        setIsLoading(true);
        removeAllToasts();
        communication
            .updateWorkspace(id, { infoRoom: workspaceInfoRoom ? workspaceInfoRoom.value : null })
            .then(() => {
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                useWorkspaceStore.setState({
                    infoRoom: workspaceInfoRoom
                        ? {
                              id: workspaceInfoRoom.value,
                              name: workspaceInfoRoom.label,
                          }
                        : null,
                });
                setHasChanges(false);
                setIsLoading(false);
                updatePublishStatus(false);
            })
            .catch(() => {
                addToast('Something went wrong! Please check entered data.', {
                    appearance: 'error',
                });
                setIsLoading(false);
            });
    };

    return (
        <MainWrapper>
            {isActive && loaded ? (
                <>
                    <StyledHeader onClick={() => changePage(0)}>
                        <img src={arrowLeft} alt="icon"></img>
                        Settings
                    </StyledHeader>
                    <StyledTitle>
                        <img src={Info} alt="icon" />
                        Info room
                    </StyledTitle>
                    <PositionStyle>
                        <SelectComponent
                            label="Info room"
                            placeholder={'No info room selected'}
                            optionList={infoRooms}
                            onChange={(value) => {
                                setWorkspaceInfoRoom(value as SelectOptionType);
                            }}
                            value={workspaceInfoRoom}
                            isClearable={true}
                        />
                    </PositionStyle>
                    {hasChanges && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                isLoading={isLoading}
                                onClick={() => {
                                    update();
                                }}
                            >
                                Save
                            </Button>
                            <Button className="clickable" theme={ButtonTheme.naked} onClick={() => changePage(0)}>
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                </>
            ) : null}
        </MainWrapper>
    );
};

export default InfoRoom;
