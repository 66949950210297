import { FunctionComponent, SetStateAction, Dispatch, useState } from 'react';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import './table.scss';
import styled from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import { TemplateDto } from '@Global/dtos/template.dto';
import useGlobalState from '@Store/global';
import useDialogue from '@Hooks/useDialogue';
import communication from '@Communication';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableSkeleton,
    TableSkeletonWrapper,
    SkeletonWrapper,
} from '@Components/table';
import CircleLetter from '@Components/circleLetter';
import NoItems from '@Components/noItems';
import Button, { ButtonTheme } from '@Components/button';
import MoreDotsIcon from '@Assets/icons/workspaces/more-dots.svg';
import DeleteIconActive from '@Assets/icons/settings/active/delete.svg';
import DeleteIconInactive from '@Assets/icons/settings/inactive/delete.svg';
import EditIconInactive from '@Assets/icons/settings/inactive/edit.svg';
import EditIconActive from '@Assets/icons/settings/active/edit.svg';
import { StyledAction, WrapperStyled } from '../../workspaces/table';

interface Props {
    templates?: TemplateDto[];
    setAllTemplates?: Dispatch<SetStateAction<TemplateDto[] | null>>;
    setCreateTemplateModalVisibility?: Dispatch<SetStateAction<boolean>>;
    setFilteredTemplates?: Dispatch<SetStateAction<TemplateDto[] | null>>;
}

const TemplatesTable: FunctionComponent<Props> = ({
    templates,
    setCreateTemplateModalVisibility,
    setAllTemplates,
    setFilteredTemplates,
}) => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { activeActionElement } = useGlobalState();
    const { setDialogue, closeDialogue } = useDialogue();

    const [hoveredBtn, setHoveredBtn] = useState('');

    const goToTemplate = (id: string): void => {
        navigate(`/templates/${id}`);
    };

    const getNamesCircle = (t: TemplateDto): string | null => {
        const nameInitial = t.creator.firstName?.[0] ?? '';
        const lastNameInitial = t.creator.lastName?.[0] ?? '';
        return nameInitial.trim() || lastNameInitial.trim() ? `${nameInitial}${lastNameInitial}` : null;
    };

    const getNames = (t: TemplateDto): string => {
        return t.creator.firstName + ' ' + t.creator.lastName;
    };

    return (
        <div className="all-templates-page">
            {templates ? (
                <Table className={`${templates.length === 0 ? 'no-items' : ''}`.trim()}>
                    {templates.length !== 0 ? (
                        <>
                            <TableHeader columns={6}>
                                <TableHeaderItem>Template</TableHeaderItem>
                                <TableHeaderItem>Category</TableHeaderItem>
                                <TableHeaderItem>Creator</TableHeaderItem>
                                <TableHeaderItem>Date created</TableHeaderItem>
                                <TableHeaderItem>Last updated</TableHeaderItem>
                                <TableHeaderItem>Actions</TableHeaderItem>
                            </TableHeader>
                            <TableBody>
                                {templates.map((template) => {
                                    return (
                                        <StyledTableItem
                                            columns={6}
                                            key={template.id}
                                            className="template table-item"
                                            onClick={() => goToTemplate(template.id)}
                                        >
                                            <p>{template?.name}</p>
                                            <p>{template?.category?.name ? template?.category?.name : '-'}</p>
                                            <p>
                                                {template?.creator?.properties?.profilePicture ? (
                                                    getNamesCircle(template) ? (
                                                        <img
                                                            className="profile-img"
                                                            crossOrigin="anonymous"
                                                            src={`${process.env.REACT_APP_API_URL}/users/me/picture/${template?.creator?.properties?.profilePicture}`}
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <CircleLetter
                                                            bgColor="var(--primary-500)"
                                                            letter={getNamesCircle(template)}
                                                            withTooltip
                                                            tooltipText={getNames(template)}
                                                        />
                                                    )
                                                ) : null}

                                                {getNames(template)}
                                            </p>
                                            <p>
                                                {template?.createdAt
                                                    ? moment(template?.createdAt)?.format('DD/MM/YYYY')
                                                    : '-'}
                                            </p>
                                            <p>
                                                {template?.updatedAt
                                                    ? moment(template?.updatedAt)?.format('DD/MM/YYYY')
                                                    : '-'}
                                            </p>
                                            <p>
                                                <StyledAction
                                                    className={`block-actions ${
                                                        activeActionElement === template.id ? 'active' : ''
                                                    }`}
                                                >
                                                    <img
                                                        id={`actions-${template.id}`}
                                                        className={`block-actions-expander ${
                                                            activeActionElement !== template.id ? 'hidden' : ''
                                                        }`}
                                                        src={MoreDotsIcon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            useGlobalState.setState({
                                                                activeActionElement:
                                                                    activeActionElement === template.id
                                                                        ? null
                                                                        : template.id,
                                                            });
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <WrapperStyled
                                                        className={`block-actions-body ${
                                                            activeActionElement !== template.id ? 'hidden' : ''
                                                        }`}
                                                    >
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'delete'
                                                                    ? DeleteIconActive
                                                                    : DeleteIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('delete')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setDialogue({
                                                                    description:
                                                                        'Template will be deleted. This is irreversible operation.',
                                                                    buttons: [
                                                                        {
                                                                            theme: ButtonTheme.danger,
                                                                            text: 'Delete',
                                                                            callback: () => {
                                                                                communication
                                                                                    .deleteTemplate(template.id)
                                                                                    .then(() => {
                                                                                        communication
                                                                                            .getAllTemplates()
                                                                                            .then((res: any) => {
                                                                                                setAllTemplates &&
                                                                                                    setAllTemplates(
                                                                                                        res?.data,
                                                                                                    );
                                                                                                setFilteredTemplates &&
                                                                                                    setFilteredTemplates(
                                                                                                        res?.data,
                                                                                                    );
                                                                                            });
                                                                                        addToast('Template deleted!', {
                                                                                            appearance: 'success',
                                                                                        });
                                                                                    })
                                                                                    .catch(() => {
                                                                                        addToast(
                                                                                            'Template can not be deleted at this point!',
                                                                                            {
                                                                                                appearance: 'error',
                                                                                            },
                                                                                        );
                                                                                    });
                                                                                closeDialogue();
                                                                            },
                                                                        },
                                                                        {
                                                                            theme: ButtonTheme.naked,
                                                                            text: 'Cancel',
                                                                            callback: () => {
                                                                                closeDialogue();
                                                                            },
                                                                        },
                                                                    ],
                                                                });
                                                            }}
                                                        >
                                                            Delete template
                                                        </Button>
                                                        <Button
                                                            theme={ButtonTheme.link}
                                                            leftIcon={
                                                                hoveredBtn === 'edit'
                                                                    ? EditIconActive
                                                                    : EditIconInactive
                                                            }
                                                            onMouseEnter={() => setHoveredBtn('edit')}
                                                            onMouseLeave={() => setHoveredBtn('')}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                goToTemplate(template.id);
                                                            }}
                                                        >
                                                            Edit template
                                                        </Button>
                                                    </WrapperStyled>
                                                </StyledAction>
                                            </p>
                                        </StyledTableItem>
                                    );
                                })}
                            </TableBody>
                        </>
                    ) : (
                        <NoItems
                            content={
                                <>
                                    <p>You don’t have any Templates yet.</p>
                                    <div>
                                        <p>Start with</p>
                                        <Button
                                            theme={ButtonTheme.link}
                                            color="var(--primary-400)"
                                            onClick={() => {
                                                setCreateTemplateModalVisibility &&
                                                    setCreateTemplateModalVisibility(true);
                                            }}
                                        >
                                            creating a new Template.
                                        </Button>
                                    </div>
                                </>
                            }
                        />
                    )}
                </Table>
            ) : (
                <SkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                    <TableSkeletonWrapper>
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                        <TableSkeleton /> <TableSkeleton /> <TableSkeleton />
                    </TableSkeletonWrapper>
                </SkeletonWrapper>
            )}
        </div>
    );
};

const StyledTableItem = styled(TableRow)`
    p {
        align-items: center;
        color: var(--black-60);
        display: flex;
        font-size: 12px;

        img {
            &.arrow-rotate-true {
                transform: rotate(180deg);
            }
        }

        .profile-img {
            border-radius: 50px;
            height: 33px;
            margin-right: 10px;
            width: 33px;
            object-fit: cover;
        }
    }
`;

export default TemplatesTable;
