import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { WorkspaceDto } from '@Global/dtos/workspace.dto';
import { RecentActivityDto } from '@Global/dtos/analytics.dto';
import communication from '@Communication';
import { StyledSearch } from '@/pages/workspaces';
import NoItems from '@Components/noItems';
import { InputComponentIconPosition, InputComponentType } from '@Components/input';
import EditDocIcon from '@Assets/icons/analytics/edit-document.svg';
import QuickReferenceIcon from '@Assets/icons/analytics/quick-reference.svg';
import SearchIcon from '@Assets/icons/search-normal.svg';
import NumberCard from './numberCard';
import ActivityCard from './activityCard';
import WorkspaceTable from './table';
import ActivityCardSkeleton from './activityCard/activityCardSkeleton';

const Analytics: FunctionComponent = () => {
    const [searchValue, setSearchValue] = useState('');
    const [filteredWorkspaces, setFilteredWorkspaces] = useState<WorkspaceDto[] | null>(null);
    const [allWorkspaces, setAllWorkspaces] = useState<WorkspaceDto[] | null>(null);
    const [recentActivity, setRecentActivity] = useState<RecentActivityDto[] | null>(null);
    const [totalViews, setTotalViews] = useState(0);

    useEffect(() => {
        communication.getWorkspacesStatistic().then((res: any) => {
            setAllWorkspaces(res?.data);
            setFilteredWorkspaces(res?.data);
            setTotalViews(res?.data.map((d: WorkspaceDto) => d.views).reduce((v1: number, v2: number) => v1 + v2));
        });
    }, []);

    useEffect(() => {
        communication.getActivity().then((res: any) => {
            setRecentActivity(res?.data);
        });
    }, []);

    return (
        <StyledPage className="page analytics">
            <h3>Analytics</h3>
            <StyledContent>
                <StyledLeftColumn>
                    <h4>Recent Activity</h4>
                    {recentActivity ? (
                        recentActivity?.length !== 0 ? (
                            recentActivity?.map((activity: RecentActivityDto, index: number) => {
                                return (
                                    <div key={index}>
                                        <ActivityCard
                                            userTitle={activity.userEmail ? activity?.userEmail : 'Guest user'}
                                            cardType={activity?.name}
                                            cratedAt={activity?.createdAt}
                                            workspaceName={activity?.Workspaces?.name}
                                            content={activity?.content}
                                            workspaceId={activity?.Workspaces?.id}
                                            hasDetailsLink={activity?.Workspaces?.id !== undefined ? true : false}
                                        />
                                    </div>
                                );
                            })
                        ) : (
                            <NoItems
                                content={
                                    <>
                                        <p>You don’t have any Activities yet.</p>
                                    </>
                                }
                            />
                        )
                    ) : (
                        <ActivityCardSkeleton />
                    )}
                </StyledLeftColumn>
                <StyledRightColumn>
                    <CardsWrapper>
                        <NumberCard title="Workspaces created" icon={EditDocIcon} number={allWorkspaces?.length} />
                        <NumberCard title="Total views" icon={QuickReferenceIcon} number={totalViews} />
                    </CardsWrapper>
                    <WorkspacesWrapper>
                        <div className="top">
                            <h4>Workspaces</h4>
                            <StyledSearch
                                placeholder="Search"
                                type={InputComponentType.Text}
                                value={searchValue}
                                icon={SearchIcon}
                                size={200}
                                iconPosition={InputComponentIconPosition.Left}
                                onChange={(value: string) => setSearchValue(value)}
                            />
                        </div>
                        <WorkspaceTable
                            workspaces={filteredWorkspaces?.filter((w) =>
                                w.name.toLowerCase().includes(searchValue.toLowerCase()),
                            )}
                            setFilteredWorkspaces={setFilteredWorkspaces}
                        />
                    </WorkspacesWrapper>
                </StyledRightColumn>
            </StyledContent>
        </StyledPage>
    );
};
export const StyledPage = styled.div`
    max-width: 1600px;
    margin: 0px auto;
    .back-link {
        margin-bottom: 20px;
    }
`;
export const StyledContent = styled.div`
    display: flex;
    align-items: start;
    gap: 24px;
    margin-top: 29px;
    /* Media Queries */
    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
`;
export const StyledLeftColumn = styled.div`
    background-color: var(--white);
    border-radius: 8px;
    padding: 16px 18px;
    width: 50%;
    height: 770px;
    overflow-y: auto;
    > h4 {
        margin-bottom: 35px;
        font-size: 18px;
    }

    /* Media Queries */
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
    @media only screen and (max-width: 600px) {
        height: 400px;
    }
`;
export const StyledRightColumn = styled.div`
    width: 50%;
    /* Media Queries */
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
`;
const CardsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    > div {
        width: 50%;
    }
`;
const WorkspacesWrapper = styled.div`
    border-radius: 8px;
    background: var(--white);
    box-shadow: 0px 1px 6px 0px rgba(217, 217, 217, 0.15);
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 24px;
        h4 {
            font-size: 18px;
        }
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            .input-container {
                margin-top: 10px;
            }
        }
    }
    .input-container {
        input {
            height: 32px;
        }
        .icon {
            top: 7px;
        }
        &:after {
            height: 20px;
            left: 40px;
            right: auto;
        }
    }
`;

export default Analytics;
