import { FunctionComponent, useState, useEffect } from 'react';
import arrowLeft from '@Assets/icons/settings/arrow-left.svg';
import { TwoButtonsStyle, MainWrapper, StyledHeader, StyledTitle } from '../styledComponents';
import Input, { InputComponentType } from '@Components/input';
import SelectComponent, { SelectOptionType } from '@Components/select';
import Button, { ButtonTheme } from '@Components/button';
import styled from 'styled-components';
import useWorkspaceStore from '@Store/workspace';
import useUserStore from '@Store/user';
import useCompanyStore from '@Store/company';
import useContactStore from '@Store/contact';
import communication from '@Communication';
import { useToasts } from 'react-toast-notifications';
import useEditorStore from '@Store/editor';
import { AssociatedContactType } from '@/types/workspace';
import { CategoryDto, CategoryType } from '@/global/dtos/categories.dto';
import ManageSearch from '@Assets/icons/settings/manage-search.svg';

interface Props {
    isActive: boolean;
    changePage: Function;
}

const Details: FunctionComponent<Props> = ({ isActive, changePage }) => {
    const { addToast, removeAllToasts } = useToasts();
    const {
        name,
        associatedCategory,
        owner,
        associatedCompany,
        associatedContact,
        dateCreated,
        lastUpdated,
        updatePublishStatus,
    } = useWorkspaceStore();
    const { id } = useEditorStore();
    const { usersList } = useUserStore();
    const { allCompanies } = useCompanyStore();
    const { allContacts } = useContactStore();
    const [isLoading, setIsLoading] = useState(false);
    const [workspaceName, setWorkspaceName] = useState(name);
    const [workspaceOwner, setWorkspaceOwner] = useState<SelectOptionType>({
        value: owner.id,
        label: `${owner.firstName} ${owner.lastName}`,
    });
    const [hasChanges, setHasChanges] = useState(false);

    const [company, setCompany] = useState<SelectOptionType | null>(
        associatedCompany
            ? {
                  value: associatedCompany.id,
                  label: associatedCompany.name,
              }
            : null,
    );
    const [contact, setContact] = useState<SelectOptionType | null>(
        associatedContact
            ? {
                  value: associatedContact.id,
                  label: `${associatedContact.firstName} ${
                      associatedContact.lastName ? associatedContact.lastName : ''
                  }`,
              }
            : null,
    );
    const [categories, setCategories] = useState<CategoryDto[]>([]);

    const categoryList = categories.map((c) => ({
        value: c.id,
        label: c.name,
    }));
    const [category, setCategory] = useState<SelectOptionType | null>(
        associatedCategory
            ? {
                  value: associatedCategory.id,
                  label: associatedCategory.name,
              }
            : null,
    );

    useEffect(() => {
        if (
            name !== workspaceName ||
            owner?.id !== workspaceOwner?.value ||
            associatedCompany?.id !== company?.value ||
            associatedContact?.id !== contact?.value ||
            associatedCategory?.id !== category?.value
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [workspaceName, workspaceOwner?.value, company?.value, contact?.value, category?.value]);

    useEffect(() => {
        communication.getAllCategories(CategoryType.WORKSPACE).then((res: any) => {
            setCategories(res?.data);
        });
    }, []);

    const ownerList = usersList
        .filter((user) => user.status === 'READY')
        .map((user) => ({
            value: user.id,
            label:
                user.firstName === '' && user.lastName === ''
                    ? user.email
                    : `${user.firstName} ${user.lastName ? user.lastName : ''}`,
        }));

    const companyList = allCompanies.map((c) => ({
        value: c.id,
        label: c.name,
    }));

    const contactList = allContacts.map((user) => ({
        value: user.id,
        label:
            user.firstName === '' && user.lastName === ''
                ? user.email
                : `${user.firstName} ${user.lastName ? user.lastName : ''}`,
    }));

    const update = (): void => {
        removeAllToasts();
        setIsLoading(true);
        communication
            .updateWorkspace(id, {
                name: workspaceName,
                contactId: contact ? contact.value.toString() : null,
                companyId: company ? company.value.toString() : null,
                owner: workspaceOwner.value.toString(),
                categoryId: category ? category.value.toString() : null,
            })
            .then(() => {
                addToast('Your changes have been successfully saved!', {
                    appearance: 'success',
                });
                const selectedCompany = company ? { id: company.value, name: company.label } : null;
                const selectedContact = contact ? allContacts.find((c) => c.id === contact.value) : null;
                const selectedCategory = category ? { id: category.value, name: category.label } : null;
                const selectedOwnerFullName = workspaceOwner?.label?.split(' ');

                const selectedOwner = owner
                    ? {
                          id: workspaceOwner?.value,
                          firstName: selectedOwnerFullName[0],
                          lastName: selectedOwnerFullName[1],
                          email: '',
                      }
                    : undefined;
                useWorkspaceStore.setState({
                    name,
                    associatedCompany: selectedCompany,
                    associatedContact: selectedContact as AssociatedContactType,
                    associatedCategory: selectedCategory,
                    owner: selectedOwner,
                });
                setHasChanges(false);
                setIsLoading(false);
                updatePublishStatus(false);
            })
            .catch(() => {
                addToast('Something went wrong! Please check the entered data.', {
                    appearance: 'error',
                });
                setIsLoading(false);
            });
    };

    return (
        <MainWrapper>
            {isActive ? (
                <>
                    <StyledHeader onClick={() => changePage(0)}>
                        <img src={arrowLeft} alt="icon"></img>
                        Settings
                    </StyledHeader>
                    <StyledTitle>
                        <img src={ManageSearch} alt="icon" />
                        Details
                    </StyledTitle>
                    <StyledBody>
                        <Input
                            label="Workspace name"
                            placeholder="Workspace name"
                            type={InputComponentType.Text}
                            value={workspaceName}
                            onChange={(value: string) => setWorkspaceName(value)}
                            size="100%"
                        />
                        <SelectComponent
                            label="Associated company"
                            placeholder="Select associated company"
                            onChange={(value) => setCompany(value as SelectOptionType)}
                            value={company}
                            optionList={companyList}
                            isClearable={true}
                        />
                        <SelectComponent
                            label="Associated contact"
                            placeholder="Select associated contact"
                            value={contact}
                            onChange={(value) => setContact(value as SelectOptionType)}
                            optionList={contactList}
                            isClearable={true}
                        />
                        <SelectComponent
                            label="Category"
                            placeholder="Select category"
                            optionList={categoryList}
                            onChange={(value) => setCategory(value as SelectOptionType)}
                            value={category}
                            isClearable={true}
                        />
                        <SelectComponent
                            label="Owner"
                            placeholder="Select owner"
                            optionList={ownerList}
                            onChange={(value) => setWorkspaceOwner(value as SelectOptionType)}
                            value={workspaceOwner}
                            isClearable={false}
                        />
                        <Input
                            label="Date created"
                            type={InputComponentType.Date}
                            isDisabled={true}
                            value={dateCreated}
                            dateFormat="dd MMMM yyyy (HH:mm)"
                            size="100%"
                        ></Input>
                        <Input
                            label="Last updated"
                            type={InputComponentType.Date}
                            isDisabled={true}
                            dateFormat="dd MMMM yyyy (HH:mm)"
                            size="100%"
                            value={lastUpdated}
                        ></Input>
                    </StyledBody>
                    {hasChanges && (
                        <TwoButtonsStyle>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                isLoading={isLoading}
                                onClick={() => {
                                    update();
                                }}
                            >
                                Save
                            </Button>
                            <Button className="clickable" theme={ButtonTheme.naked} onClick={() => changePage(0)}>
                                Cancel
                            </Button>
                        </TwoButtonsStyle>
                    )}
                </>
            ) : (
                <></>
            )}
        </MainWrapper>
    );
};

const StyledBody = styled.div`
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    overflow: auto;
`;

export default Details;
