import { createGlobalStyle } from 'styled-components';
import colors from './colors';

// More global styles can be found in
// ../app.scss
const GlobalStyle = createGlobalStyle`
    html {
        --white: ${colors.white};
        --black: ${colors.black};

        --primary-50: ${colors.primary50};
        --primary-100: ${colors.primary100};
        --primary-200: ${colors.primary200};
        --primary-300: ${colors.primary300};
        --primary-400: ${colors.primary400};
        --primary-500: ${colors.primary500};

        --red-150: ${colors.red150};

        --black-20: ${colors.black20};
        --black-50: ${colors.black50};
        --black-60: ${colors.black60};
        --black-75: ${colors.black75};
        --black-100: ${colors.black100};
        --black-200: ${colors.black200};
        --black-250: ${colors.black250};
        --black-300: ${colors.black300};

        --grey: ${colors.grey};
        --grey-10: ${colors.grey10};
        --dark-grey: ${colors.darkGrey};
        --background-grey: ${colors.backgroundGrey};
        --input-grey: ${colors.inputGrey};


        --box-shadow: ${colors.boxShadow};

        overflow: hidden;
        *,
        *::after,
        *::before {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }
        body {
            font-family: 'Open Sans', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 0;
            background-color: var(--background-grey);
        }

        // Titles

        h1,
        h2,
        h3,
        h4,
        h5,p {
            margin: 0;
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
            color:var(--black-60);
        }

        h1 {
            font-size: 42px;
            line-height: 52px;
            @media only screen and (max-width: 600px) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        h2 {
            font-size: 32px;
            line-height: 32px;
            @media only screen and (max-width: 600px) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        h3 {
            font-size: 22px;
            line-height: 32px;
            @media only screen and (max-width: 600px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
        h5 {
            font-size: 16px;
            line-height: 24px;
        }

        p,h6 {
            font-size: 14px;
            line-height: 18px;
        }
        .cursor-pointer {
            cursor:pointer;
        }

        //CKEditor style
        .ck {
            &.ck-balloon-panel {
                &.ck-powered-by-balloon {
                    opacity:0;
                    visibility:hidden;
                }
            }
            &.ck-balloon-panel {
                border-radius: 5px;
                border: 1px solid var(--black-50);
            }   
            &.ck-input {
                &:focus {
                    box-shadow: none;
                    border: 1px solid var(--black-50);
                }
            }
            .ck-button {
                &:active,  &:focus {
                    border: 1px solid var(--black-20);
                    box-shadow: none;
                }
            }
        }

        //Embed form modal 
        .embed-form-modal {
            .modal-wrapper {
                overflow: unset;
            }
        }
        //Add from library modal 
        .add-from-library-modal {
            .modal-wrapper{
                .modal-header {
                    z-index:2;
                }
            }
           
        }
     
    }

`;

export default GlobalStyle;
