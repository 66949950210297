import fetch from '@/communication/fetch';
import routes from '@/communication/routes';
import { CategoryType, CreateCategoryDto } from '@/global/dtos/categories.dto';

export default class Category {
    public getAllCategories(type: CategoryType): any {
        return fetch({
            method: 'get',
            url: routes.categories.all(type),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public createCategory(data: CreateCategoryDto): any {
        return fetch({
            method: 'post',
            url: routes.categories.all(data.type),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
    public deleteCategory(id: string): any {
        return fetch({
            method: 'delete',
            url: routes.categories.single(id),
            headers: { 'Content-Type': 'application/json' },
        });
    }
    public updateCategory(id: string, data: { name?: string; protected: boolean; type: CategoryType }): any {
        return fetch({
            method: 'patch',
            url: routes.categories.single(id),
            headers: { 'Content-Type': 'application/json' },
            data,
        });
    }
}
