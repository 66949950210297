import React from 'react';
import { RequestDto, ShareTypeData } from '@Global/dtos/request.dto';
import moment from 'moment';
import styled from 'styled-components';

interface Props {
    requestData: RequestDto;
}

const ShareRequestBody: React.FunctionComponent<Props> = ({ requestData }) => {
    const requestersName = requestData.user
        ? `${requestData.user.firstName} ${requestData.user.lastName} (${requestData.user.email})`
        : requestData.userEmail;

    const personToBeSharedWith = (requestData.data as ShareTypeData).name
        ? `${(requestData.data as ShareTypeData).name} (${(requestData.data as ShareTypeData).emailToShareWith})`
        : (requestData.data as ShareTypeData).emailToShareWith;

    const workspaceOwner = `${(requestData.data as ShareTypeData).workspaceOwner?.firstName} ${
        (requestData.data as ShareTypeData).workspaceOwner?.lastName
    }`;

    return (
        <>
            <UserDescription>
                <p>
                    User <strong>{requestersName}</strong> wants to share the workspace with{' '}
                    <strong>{personToBeSharedWith}</strong>
                </p>
                <div className="request-time">{moment(requestData.createdAt).format('DD MMMM YYYY (HH:mm)')}</div>
            </UserDescription>
            <OwnerDescription>
                <p>
                    Workspace owner: <strong>{workspaceOwner}</strong>
                </p>
            </OwnerDescription>
        </>
    );
};

const UserDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;
    p {
        font-size: 12px;
    }
    .request-time {
        font-size: 10px;
        color: var(--black-75);
        line-height: normal;
    }
`;

const OwnerDescription = styled.div`
    p {
        font-size: 12px;
    }
`;

export default ShareRequestBody;
