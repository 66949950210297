export type FileRes = {
    id: string;
    description: string;
    name: string;
    type: string;
    organizationId: string;
    workspaceId?: string;
    link: string;
    thumbnail?: string;
    category: { id: string; name: string } | null;
    createdAt: Date;
    updatedAt: Date;
};

export type FileDto = {
    id: string;
    description?: string;
    name: string;
    workspaceId?: string;
    categoryId?: string;
};

export const GoogleDriveTypes: { [key: string]: string } = {
    'application/vnd.google-apps.form': 'form',
    'application/vnd.google-apps.presentation': 'pres',
    'application/vnd.google-apps.drawing': 'draw',
    'application/vnd.jgraph.mxfile': 'drawio',
    'application/vnd.google-apps.spreadsheet': 'spread',
    'application/vnd.google-apps.document': 'doc',
    'application/pdf': 'pdf',
    'video/quicktime': 'mov',
};
