import { FunctionComponent, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ReactQuill, { Quill } from 'react-quill';
import BackLink from '@Components/backLink';
import Button, { ButtonTheme } from '@Components/button';
import colors from '@Global/colors';
import { CreateWorkspaceDto } from '@Global/dtos/workspace.dto';
import useEditorStore from '@Store/editor';
import useUserStore from '@Store/user';
import communication from '@Communication';
import SectionActionWrapper from '@Components/editor/base/actions';
import { BlockType, ShelfBlock, TableBlock, TextBlock, MediaBlock } from '@/types/workspace';
import TextBlockElement from '@Components/editor/text';
import TableBlockElement from '@Components/editor/table';
import Clipboard from '@Components/editor/table/clipboard';
import NewSectionIcon from '@Assets/icons/workspaces/new-section.svg';
import NewSectionIconHover from '@Assets/icons/workspaces/new-section-hover.svg';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import nanogen from '@Helpers/nanogen';
import ShelfBlockElement from '@Components/editor/shelf';
import Section from '@Components/editor/section';
import { StyledNewBlockModal } from '@Components/editor/base/styled';
import BlockOption from '@Components/editor/card';
import TextIcon from '@Assets/icons/workspaces/text.svg';
import TableIcon from '@Assets/icons/workspaces/table.svg';
import LibraryIcon from '@Assets/icons/workspaces/library.svg';
import UploadIcon from '@Assets/icons/workspaces/upload.svg';
import EmbedIcon from '@Assets/icons/workspaces/embed.svg';
import FullSizeMediaIcon from '@Assets/icons/workspaces/full-size-media.svg';
import LinkIcon from '@Assets/icons/workspaces/link.svg';
import CloudIcon from '@Assets/icons/workspaces/cloud.svg';
import Modal from '@Components/modal';
import GlobalHotkeys from '@Global/keypress';
import MediaBlockElement from '@Components/editor/media';
import useWorkspaceStore from '@Store/workspace';
import Communication from '@Communication';
import useGlobalState from '@Store/global';
import { MediaCommand, ShelfCommand } from '@Global/dtos/workspace.dto';
import Tooltip from '@Components/tooltip';
import SubscriptionModal from '@/pages/subscriptionPlans/subscriptionModal';

interface Props {
    updatedAt: string | null;
    update: Function;
    setShareModal?: Function;
    backNavigation: string;
    shareUrl?: string | null;
    className?: string;
}

Quill.register('modules/clipboard', Clipboard, true);

const EditorBase: FunctionComponent<Props> = ({
    updatedAt,
    className,
    setShareModal,
    update,
    backNavigation,
    shareUrl,
}) => {
    const navigate = useNavigate();
    const { id: ownerId } = useUserStore();
    const { addToast } = useToasts();
    const {
        id,
        title,
        sections,
        setActiveElement,
        updateSections,
        activeSectionIndex,
        setActiveSectionIndex,
        getEditorData,
    } = useEditorStore();
    const { isPublished, updatePublishStatus } = useWorkspaceStore();
    const [isSaving, setIsSaving] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [newBlockModal, setNewBlockModal] = useState(false);
    const [isContentUpdated, setIsContentUpdated] = useState(false);
    const [subscriptionModalVisibility, setSubscriptionModalVisibility] = useState(false);

    const publishChanges = (): void => {
        setIsPublishing(true);
        Communication.publishWorkspace(id)
            .then(() => {
                setIsPublishing(false);
                updatePublishStatus(true);
            })
            .catch((error: any) => {
                const subscriptionError = error.response.data?.code === 1035;
                if (subscriptionError) {
                    setSubscriptionModalVisibility(true);
                    setIsPublishing(false);
                    updatePublishStatus(true);
                } else {
                    setIsPublishing(false);
                    updatePublishStatus(true);
                }
            });
    };
    const GoToSharedView = (): void => {
        window.open(`${shareUrl}` as string, '_blank');
    };

    const addNewSection: Function = () => {
        updateSections([
            ...sections,
            {
                id: nanogen(),
                type: 'section',
                content: {
                    items: [],
                },
                options: {
                    title: '',
                    visible: true,
                },
            },
        ]);
    };

    const addNewBlock: Function = (blockType: BlockType) => {
        setNewBlockModal(false);
        const sectionsTmp = [...sections];
        const blockId = nanogen();
        const columnIdFirst = nanogen();
        const columnIdSecond = nanogen();
        const columnIdThird = nanogen();

        switch (blockType) {
            case BlockType.Text: {
                sectionsTmp[activeSectionIndex].content.items.push({
                    id: blockId,
                    type: BlockType.Text,
                    content: {
                        value: '',
                    },
                    options: {},
                });
                updateSections(sectionsTmp);
                setActiveElement(blockId);
                break;
            }
            case BlockType.Table:
                sectionsTmp[activeSectionIndex].content.items.push({
                    id: blockId,
                    type: BlockType.Table,
                    content: {
                        rows: [
                            {
                                id: nanogen(),
                                cells: [
                                    {
                                        id: nanogen(),
                                        columnId: columnIdFirst,
                                        value: '',
                                        style: '',
                                    },
                                    {
                                        id: nanogen(),
                                        columnId: columnIdSecond,
                                        value: '',
                                        style: '',
                                    },
                                    {
                                        id: nanogen(),
                                        columnId: columnIdThird,
                                        value: '',
                                        style: '',
                                    },
                                ],
                            },
                            {
                                id: nanogen(),
                                cells: [
                                    {
                                        id: nanogen(),
                                        columnId: columnIdFirst,
                                        value: '',
                                        style: '',
                                    },
                                    {
                                        id: nanogen(),
                                        columnId: columnIdSecond,
                                        value: '',
                                        style: '',
                                    },
                                    {
                                        id: nanogen(),
                                        columnId: columnIdThird,
                                        value: '',
                                        style: '',
                                    },
                                ],
                            },
                            {
                                id: nanogen(),
                                cells: [
                                    {
                                        id: nanogen(),
                                        columnId: columnIdFirst,
                                        value: '',
                                        style: '',
                                    },
                                    {
                                        id: nanogen(),
                                        columnId: columnIdSecond,
                                        value: '',
                                        style: '',
                                    },
                                    {
                                        id: nanogen(),
                                        columnId: columnIdThird,
                                        value: '',
                                        style: '',
                                    },
                                ],
                            },
                        ],
                    },
                    options: {},
                });
                break;
            case BlockType.Shelf:
                sectionsTmp[activeSectionIndex].content.items.push({
                    id: blockId,
                    type: BlockType.Shelf,
                    content: {
                        items: [],
                    },
                    options: {},
                });
                break;
            case BlockType.Embed:
                sectionsTmp[activeSectionIndex].content.items.push({
                    id: blockId,
                    type: BlockType.Shelf,
                    content: {
                        items: [],
                    },
                    options: {},
                });
                break;
            case BlockType.Media:
                sectionsTmp[activeSectionIndex].content.items.push({
                    id: blockId,
                    type: BlockType.Media,
                    content: {
                        type: 'image',
                    },
                    options: {},
                });
                break;
        }

        updateSections(sectionsTmp);
        setActiveElement(blockId);
    };

    const updateEditorData = async (): Promise<void> => {
        if (!isSaving && updatedAt) {
            setIsSaving(true);
            const editorData = getEditorData();
            await update({ title: editorData.title, sections: editorData.sections }).then(() => {
                setIsContentUpdated(true);
                setTimeout(() => {
                    setIsContentUpdated(false);
                }, 2000);
                setIsSaving(false);
            });
        }
    };

    const createWorkspaceFromTemplate = async (templateId = ''): Promise<void> => {
        try {
            const dataToSend: CreateWorkspaceDto = {
                name: 'Untitled workspace',
                owner: ownerId as string,
            };
            if (templateId) {
                dataToSend.templateId = templateId;
            }
            const response = await communication.createWorkspace(dataToSend);
            navigate(`/workspaces/${response.data.id}`);
        } catch (e) {
            addToast('Unable to create workspace from template. Please try again.', {
                appearance: 'error',
            });
        }
    };
    /**
     * Update section title by providing the content
     * @param content
     */
    const updateSectionTitle = (content: string, index: number): void => {
        const sectionsTmp = [...sections];

        if (activeSectionIndex === index) {
            sectionsTmp[activeSectionIndex].options.title = content;
            updateSections(sectionsTmp);
        }
    };

    useEffect(() => {
        GlobalHotkeys.add(`editor/base-${nanogen()}/escape`, 'Escape', () => {
            setNewBlockModal(false);
            setActiveElement(null);
        });
    }, []);

    return (
        <>
            <SubscriptionModal
                modalVisible={subscriptionModalVisibility}
                setModalVisible={setSubscriptionModalVisibility}
                closeModal={() => {
                    setSubscriptionModalVisibility(false);
                }}
            ></SubscriptionModal>
            <Modal
                modalVisible={newBlockModal}
                closeModal={() => setNewBlockModal(!newBlockModal)}
                title="Add a content block"
            >
                <StyledNewBlockModal>
                    <div className="modal-content new-block">
                        <Tooltip tooltipText="Add a text editor." width="157px" tooltipPositionBottom>
                            <BlockOption
                                imageSrc={TextIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Text);
                                    setNewBlockModal(false);
                                }}
                                title="Text"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Add a table block." width="157px" tooltipPositionBottom>
                            <BlockOption
                                imageSrc={TableIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Table);
                                    setNewBlockModal(false);
                                }}
                                title="Table"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Add content from your Library." width="157px" tooltipPositionBottom>
                            <BlockOption
                                imageSrc={LibraryIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Shelf);
                                    setNewBlockModal(false);
                                    useGlobalState.setState({ shelfCommand: ShelfCommand.OPEN_LIBRARY });
                                }}
                                title="From Library"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Upload a file from your computer." width="157px" tooltipPositionBottom>
                            <BlockOption
                                imageSrc={UploadIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Shelf);
                                    setNewBlockModal(false);
                                    useGlobalState.setState({ shelfCommand: ShelfCommand.OPEN_UPLOAD });
                                }}
                                title="Upload files"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Embed content from other sites." width="157px">
                            <BlockOption
                                imageSrc={EmbedIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Embed);
                                    setNewBlockModal(false);
                                    useGlobalState.setState({ shelfCommand: ShelfCommand.OPEN_EMBED });
                                }}
                                title="Embed content"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Add links to other websites." width="157px">
                            <BlockOption
                                imageSrc={LinkIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Shelf);
                                    setNewBlockModal(false);
                                    useGlobalState.setState({ shelfCommand: ShelfCommand.OPEN_LINK });
                                }}
                                title="External link"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Add content from your Google Drive." width="157px">
                            <BlockOption
                                imageSrc={CloudIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Shelf);
                                    setNewBlockModal(false);
                                    useGlobalState.setState({ shelfCommand: ShelfCommand.OPEN_DRIVE });
                                }}
                                title="Choose from Drive"
                            />
                        </Tooltip>
                        <Tooltip tooltipText="Showcase an image, video, PDF or embedded content." width="157px">
                            <BlockOption
                                imageSrc={FullSizeMediaIcon}
                                onClick={() => {
                                    addNewBlock(BlockType.Media);
                                    setNewBlockModal(false);
                                    useGlobalState.setState({ mediaCommand: MediaCommand.OPEN_MEDIA_MODAL });
                                }}
                                title="Full-size media"
                            />
                        </Tooltip>
                    </div>
                </StyledNewBlockModal>
            </Modal>

            <div className={`page editor-base ${className ?? ''}`.trim()}>
                <div className="top">
                    <StyledBackLinkContainer>
                        <BackLink
                            className="clickable"
                            text="Back"
                            onClick={() => {
                                useGlobalState.setState({ isNavbarCollapsed: false });
                                navigate(backNavigation);
                            }}
                        />
                        <div>
                            {backNavigation.includes('inforooms') || backNavigation.includes('templates') ? (
                                <>{isContentUpdated ? <p className="content-updated-text">Content updated!</p> : ' '}</>
                            ) : (
                                <></>
                            )}
                            {backNavigation.includes('templates') ? (
                                <>
                                    <Button
                                        theme={ButtonTheme.link}
                                        color={colors.primary400}
                                        onClick={() => {
                                            window.open(`/templates/preview/${id}` as string, '_blank');
                                        }}
                                    >
                                        Template preview
                                    </Button>
                                    <Button
                                        theme={ButtonTheme.primary}
                                        onClick={() => {
                                            createWorkspaceFromTemplate(id);
                                        }}
                                    >
                                        Use this template
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </StyledBackLinkContainer>

                    {setShareModal && (
                        <StyledSaveTitle>
                            {isPublished
                                ? 'Everything is published. Check'
                                : 'Recent changes were not published! Check'}
                            <Button theme={ButtonTheme.link} color={colors.primary400} onClick={GoToSharedView}>
                                Shared view.
                            </Button>
                        </StyledSaveTitle>
                    )}

                    {setShareModal && (
                        <>
                            <PublishButton
                                theme={ButtonTheme.primary}
                                className="publish-button"
                                onClick={publishChanges}
                                isLoading={isPublishing}
                                disabled={isPublished}
                                feedback="Done"
                            >
                                Publish
                            </PublishButton>
                            <Button
                                className="clickable"
                                theme={ButtonTheme.primary}
                                onClick={() => {
                                    setShareModal(true);
                                }}
                            >
                                Share
                            </Button>
                        </>
                    )}
                </div>

                {/* CONTENT OF THE WORKSPACE */}
                {updatedAt ? (
                    <div className="content">
                        <StyledInput
                            placeholder="Enter title here"
                            className="editor-name"
                            value={title}
                            onChange={(value) => {
                                useEditorStore.setState({
                                    title: value,
                                });
                            }}
                            onBlur={() => updateEditorData()}
                            modules={{ toolbar: false }}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') (e.target as HTMLInputElement).blur();
                            }}
                            onFocus={() => {
                                setActiveElement('title');
                            }}
                        />
                        {/* SECTION START */}
                        <div className="editor-section">
                            {sections &&
                                sections.map((section, index) => {
                                    return (
                                        <Section
                                            key={section.id}
                                            onClick={() => setActiveSectionIndex(index)}
                                            className={section.options.visible ? 'section' : 'section hidden'}
                                            id={`section-${section.id}`}
                                        >
                                            <SectionBody className="section-body">
                                                <div className="section-title">
                                                    <StyledInput
                                                        placeholder="Untitled section"
                                                        onChange={(value) => updateSectionTitle(value, index)}
                                                        className="subtitle"
                                                        onBlur={() => updateEditorData()}
                                                        modules={{ toolbar: false }}
                                                        value={section.options.title}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Escape')
                                                                (e.target as HTMLInputElement).blur();
                                                        }}
                                                        onFocus={() => {
                                                            setActiveElement('title');
                                                        }}
                                                    />
                                                    <SectionActionWrapper
                                                        section={section}
                                                        onChange={updateEditorData}
                                                    />
                                                </div>

                                                <div className="section-body-content">
                                                    {/* Content of the section mapping */}
                                                    {section.content.items.map((block) => {
                                                        switch (block.type) {
                                                            case BlockType.Text: {
                                                                const textBlock = block as TextBlock;
                                                                return (
                                                                    <TextBlockElement
                                                                        key={textBlock.id}
                                                                        textBlock={textBlock}
                                                                        activeSection={index}
                                                                        onUpdate={updateEditorData}
                                                                        onDelete={updateEditorData}
                                                                    />
                                                                );
                                                            }
                                                            case BlockType.Table: {
                                                                const tableBlock = block as TableBlock;
                                                                return (
                                                                    <TableBlockElement
                                                                        key={tableBlock.id}
                                                                        tableData={tableBlock}
                                                                        activeSection={index}
                                                                        onUpdate={updateEditorData}
                                                                        onDelete={updateEditorData}
                                                                    />
                                                                );
                                                            }
                                                            case BlockType.Shelf: {
                                                                const shelfBlock = block as ShelfBlock;
                                                                return (
                                                                    <ShelfBlockElement
                                                                        key={shelfBlock.id}
                                                                        shelfData={shelfBlock}
                                                                        activeSection={index}
                                                                        onUpdate={updateEditorData}
                                                                        onDelete={updateEditorData}
                                                                    />
                                                                );
                                                            }
                                                            case BlockType.Media: {
                                                                const mediaBlock = block as MediaBlock;
                                                                return (
                                                                    <MediaBlockElement
                                                                        key={mediaBlock.id}
                                                                        mediaBlock={mediaBlock}
                                                                        activeSection={index}
                                                                        onUpdate={updateEditorData}
                                                                        onDelete={updateEditorData}
                                                                    />
                                                                );
                                                            }
                                                            default:
                                                                return null;
                                                        }
                                                    })}
                                                </div>
                                            </SectionBody>

                                            {/* Add new block button and sets index section as active */}
                                            <div
                                                className="new-content-block-wrapper cursor-pointer"
                                                onClick={() => {
                                                    setActiveSectionIndex(index);
                                                    setNewBlockModal(true);
                                                    setActiveElement(null);
                                                }}
                                            >
                                                <div className="new-content-block-button">
                                                    <img src={NewSectionIcon} alt={'add-icon'} />
                                                    <img src={NewSectionIconHover} alt={'add-icon'} />
                                                    <p>Add content</p>
                                                </div>
                                            </div>
                                        </Section>
                                    );
                                })}
                        </div>
                        <div className="action-block">
                            <Button
                                theme={ButtonTheme.naked}
                                size={180}
                                className="cursor-pointer"
                                onClick={() => addNewSection()}
                                leftIcon={NewSectionIcon}
                                hoverIcon={NewSectionIconHover}
                            >
                                Add new section
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="content">
                        <Skeleton style={{ marginBottom: '30px' }} height={36} width="50%" />
                        <Skeleton style={{ marginBottom: '5px' }} height={150} width="100%" />
                        <Skeleton style={{ marginBottom: '5px' }} height={150} width="100%" />
                        <Skeleton style={{ marginBottom: '5px' }} height={150} width="100%" />
                    </div>
                )}
            </div>
        </>
    );
};

const PublishButton = styled(Button)`
    margin: 0 11px 0 28px;
`;

const StyledSaveTitle = styled.div`
    font-size: 14px;
    color: var(--black-200);

    .button {
        display: inline-flex;
        padding: 0 5px;
    }
`;

const StyledBackLinkContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
        display: flex;
        align-items: center;
    }
    .content-updated-text {
        font-size: 14px;
        color: var(--black-200);
        margin-right: 10px;
        color: var(--primary-400);
    }
`;

const StyledInput = styled(ReactQuill)`
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    padding: 5px 10px;
    border: none;
    height: 100%;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;

    &.subtitle {
        width: calc(100% - 140px);
        min-height: 42px;
        .ql-editor {
            font-size: 22px;
            line-height: 32px;
        }
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        span {
            font-size: 22px;
            line-height: 32px;
        }
    }
    &.editor-name {
        margin-bottom: 28px;
        min-height: 52px;
        width: 100%;
        .ql-editor {
            font-size: 28px;
            line-height: 42px;
            font-weight: normal;
        }
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        span {
            font-size: 28px;
            line-height: 42px;
            font-weight: normal;
        }
    }
    &:focus-within {
        background-color: var(--black-50);
        &:hover {
            background-color: var(--black-50);
        }
    }
    &:hover {
        background-color: var(--input-grey);
    }

    .ql-toolbar {
        display: none;
    }

    .ql-container {
        border: none;
        overflow: auto;

        .ql-editor {
            padding: 0;

            &.ql-blank {
                &:before {
                    color: var(--black-75);
                    height: 100%;
                    font-style: normal;
                    left: 0;
                }
            }
        }
    }
`;
const SectionBody = styled.div`
    transition: all ease 0.3s;
    border-radius: 8px 8px 0 0;
    border: 1px dashed rgb(170 170 170 / 30%);
    border-bottom: 1px solid transparent;
    padding: 24px;
`;

export default EditorBase;
