import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import EyeHide from '@Assets/icons/eye/eye-hide.svg';
import EyeShow from '@Assets/icons/eye/eye-show.svg';
import Calendar from '@Assets/icons/calendar.svg';
import DatePicker from 'react-datepicker';
import attachSize from '@Helpers/size';

export enum InputComponentType {
    Date = 'date',
    Password = 'password',
    Text = 'text',
    Email = 'email',
    Url = 'url',
    File = 'file',
}

export enum InputComponentIconPosition {
    Left = 'left',
    Right = 'right',
}
export enum InputComponentTheme {
    Dark = 'dark',
    Light = 'light',
}

interface Props {
    type?: InputComponentType;
    id?: string;
    value?: string;
    name?: string;
    className?: string;
    placeholder?: string;
    onChange?: Function;
    onEnter?: Function;
    readOnly?: boolean;
    size?: number | string;
    errorMessage?: string;
    label?: string | any;
    infoMessage?: string;
    isDisabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    dateFormat?: string;
    iconPosition?: InputComponentIconPosition;
    theme?: InputComponentTheme;
    icon?: any;
    onIconPress?: Function;
    autoComplete?: string;
}

const Input: FunctionComponent<Props> = ({
    type,
    id,
    value,
    name,
    className,
    placeholder,
    size,
    label,
    onChange = () => {
        return;
    },
    onEnter = () => {
        return;
    },
    errorMessage,
    readOnly,
    isDisabled,
    infoMessage,
    minDate,
    maxDate,
    dateFormat,
    iconPosition,
    icon,
    theme,
    onIconPress = () => {
        return;
    },
    autoComplete,
}) => {
    const [startDate, setStartDate] = useState<Date | null>(
        value && type === InputComponentType.Date ? new Date(value) : new Date(),
    );
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isChange, setChange] = useState(false);
    const keyStrokeHandler: React.KeyboardEventHandler<HTMLInputElement> = (
        e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (e.key === 'Enter') {
            setChange(!isChange);
            onEnter();
        }
    };

    const togglePassword = (e: any): void => {
        if (value !== '') {
            setPasswordVisible(!passwordVisible);
        } else {
            e.preventDefault();
        }
    };

    const inputByType = (): JSX.Element => {
        switch (type) {
            case InputComponentType.Date:
                return (
                    <StyledDateContainer size={size} theme={theme} className="input-container">
                        <div className="date-container">
                            {label ? <span className="date-label">{label}</span> : <></>}
                            <div className="datepicker-input-wrapper">
                                <StyledDate
                                    disabled={isDisabled}
                                    selected={startDate}
                                    readOnly={readOnly}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    dateFormat={dateFormat ?? 'dd MMMM yyyy'}
                                    onChange={(date: Date) => setStartDate(date)}
                                    theme={theme}
                                />
                                {isDisabled ? null : (
                                    <img src={Calendar} className="cursor-pointer" alt="calendar icon" />
                                )}
                            </div>
                        </div>
                    </StyledDateContainer>
                );
            case InputComponentType.Password:
                return (
                    <StyledInputContainer
                        iconPosition={iconPosition}
                        size={size}
                        theme={theme}
                        className="input-container"
                    >
                        {label ? <label>{label}</label> : <></>}

                        <div className="input-with-icon">
                            <StyledInput
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                value={value ?? ''}
                                disabled={isDisabled}
                                id={id}
                                type={passwordVisible ? InputComponentType.Text : InputComponentType.Password}
                                name={name}
                                placeholder={placeholder}
                                onKeyUp={keyStrokeHandler}
                                readOnly={readOnly}
                                theme={theme}
                                autoComplete={autoComplete ? autoComplete : 'on'}
                            />
                            <div onClick={(e) => togglePassword(e)} className="cursor-pointer icon">
                                {passwordVisible ? (
                                    <img alt="eye slash icon" src={EyeShow} />
                                ) : (
                                    <img alt="eye icon" src={EyeHide} />
                                )}
                            </div>
                        </div>
                        {errorMessage !== undefined && errorMessage !== '' && (
                            <p className="validation-message">{errorMessage}</p>
                        )}
                        {infoMessage !== undefined && infoMessage !== '' && (
                            <p className="info-message">{infoMessage}</p>
                        )}
                    </StyledInputContainer>
                );
            default:
                return (
                    <StyledInputContainer
                        iconPosition={iconPosition}
                        size={size}
                        label={label}
                        theme={theme}
                        className="input-container"
                    >
                        {label ? <label>{label}</label> : <></>}
                        <StyledInput
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            value={value ?? ''}
                            disabled={isDisabled}
                            id={id}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            onKeyUp={keyStrokeHandler}
                            errorMessage={errorMessage}
                            theme={theme}
                            autoComplete={autoComplete ? autoComplete : 'on'}
                        />
                        {icon && (
                            <div className={`icon ${onIconPress ? 'clickable' : ''}`} onClick={() => onIconPress()}>
                                <img alt="icon" src={icon} />
                            </div>
                        )}
                        {errorMessage && <p className="validation-message">{errorMessage}</p>}
                        {infoMessage && <p className="info-message">{infoMessage}</p>}
                    </StyledInputContainer>
                );
        }
    };

    return (
        <StyledWrapper className={`input ${className ?? ''}`} size={size}>
            {inputByType()}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div<Props>`
    outline-color: var(--grey);
    width: ${(props) => (typeof props.size === 'string' ? props.size : 'fit-content')};
`;

const StyledDate = styled(DatePicker)<Props>`
    font-family: inherit;
    margin-inline: auto;
    background-color: ${(props) =>
        props.theme === InputComponentTheme.Light ? 'var(--white)' : 'var(--background-grey)'};
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    margin: 3px;
    text-align-last: center;
    padding: 10px;
    display: flex;
    border-style: hidden;
`;

const StyledInput = styled.input<Props>`
    border: ${(props) =>
        !props.errorMessage
            ? props.theme === InputComponentTheme.Light
                ? '1px solid var(--white)'
                : '1px solid var(--background-grey)'
            : '1px solid var(--red-150)'};
    outline-color: ${(props) =>
        !props.errorMessage
            ? props.theme === InputComponentTheme.Light
                ? 'var(--white)'
                : 'var(--background-grey)'
            : 'var(--red-150)'};
    background-color: ${(props) =>
        props.theme === InputComponentTheme.Light ? 'var(--white)' : 'var(--background-grey)'};
    font-family: inherit;
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    text-align-last: left;
    padding: 10px;
    display: flex;
    &:focus {
        filter: drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1));
        background-color: var(--white);
        outline: none;
    }
`;

const StyledInputContainer = styled.div<Props>`
    text-align-last: left;
    margin-inline: auto;
    outline-color: ${(props) =>
        !props.errorMessage
            ? props.theme === InputComponentTheme.Light
                ? 'var(--white)'
                : 'var(--background-grey)'
            : 'var(--red-150)'};
    font-family: inherit;
    border-radius: 8px;
    border-style: hidden;
    font-size: 14px;
    line-height: 18px;
    width: ${(props) => attachSize(props.size, 'fit-content')};
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        color: var(--black-75);
        font-size: 12px;
        padding: 8px 0;
    }

    input {
        letter-spacing: -0.3px;
        padding: ${(props) =>
            props.iconPosition
                ? props.iconPosition === InputComponentIconPosition.Left
                    ? '15px 18px 15px 50px'
                    : '15px 50px 15px 18px'
                : '16px 18px'};

        width: 100%;
        color: var(--black-60);
        &::placeholder {
            font-weight: 400;
            color: var(--black-75);
        }
        &:disabled {
            opacity: 0.6;
        }
    }

    .input-with-icon {
        display: flex;
        justify-content: space-between;
        position: relative;

        input {
            flex: 1;
        }
    }

    .icon {
        position: absolute;
        left: ${(props) => (props.iconPosition === InputComponentIconPosition.Left ? '14px' : 'auto')};
        right: ${(props) =>
            props.iconPosition === undefined || props.iconPosition === InputComponentIconPosition.Right
                ? '14px'
                : 'auto'};
        top: ${(props) => (props.label ? '36px' : '15px')};
    }

    .validation-message {
        line-height: 15px;
        font-family: inherit;
        font-size: 10px;
        text-align-last: left;
        margin: 5px 5px 0 5px;
        color: var(--red-150);
        justify-content: space-between;
    }

    .info-message {
        font-size: 10px;
        margin: 5px 5px 0 5px;
        line-height: 10px;
    }

    &.validation-error {
        input {
            border: 1px solid var(--red-150);
            &:focus {
                outline: 1px solid var(--red-150);
            }
        }
    }
`;

const StyledDateContainer = styled(StyledInputContainer)`
    .date-container {
        span {
            &.date-label {
                color: var(--black-75);
                font-size: 12px;
                padding: 8px 0;
                display: flex;
            }
        }
    }
    .datepicker-input-wrapper {
        min-width: ${(props) => attachSize(props.size, 'fit-content')};
        background-color: ${(props) =>
            props.theme === InputComponentTheme.Light ? 'var(--white)' : 'var(--background-grey)'};
        display: flex;
        padding-right: 10px;
        box-sizing: border-box;
        border-radius: 8px;

        img {
            padding-bottom: 5px;
        }

        input {
            background-color: ${(props) =>
                props.theme === InputComponentTheme.Light ? 'var(--white)' : 'var(--background-grey)'};
            outline-color: ${(props) =>
                !props.errorMessage
                    ? props.theme === InputComponentTheme.Light
                        ? 'var(--white)'
                        : 'var(--background-grey)'
                    : 'var(--red-150)'};
            font-family: inherit;
            border-radius: 8px;
            border-style: hidden;
            font-size: 14px;
            line-height: 18px;
            text-align-last: left;
        }

        .react-datepicker__input-container {
            width: 99%;
        }

        .react-datepicker {
            border: 1px solid black;
            height: 260px;
            text-align-last: center;

            .react-datepicker__triangle {
                &:before,
                &:after {
                    display: none;
                    border-bottom-color: black;
                }
            }
            .react-datepicker__navigation {
                span {
                    &:before {
                        border-color: black;
                    }
                }
            }
            .react-datepicker__header {
                .react-datepicker__current-month {
                    color: black;
                }
                .react-datepicker__day-name {
                    color: black;
                }
            }
            .react-datepicker__month {
                .react-datepicker__week {
                    .react-datepicker__day {
                        &:hover {
                            background: var(--grey);
                            color: black;
                        }
                    }
                    .react-datepicker__day--selected,
                    .react-datepicker__day--keyboard-selected {
                        background: var(--grey);
                        color: black;
                    }
                }
            }
        }
    }
`;

export default Input;
