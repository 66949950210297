import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import useWorkspaceStore from '@Store/workspace';
import { AssociatedContactType } from '@/types/workspace';
import { PayloadWorkspaceDto } from '@Global/dtos/workspace.dto';
import communication from '@Communication';
import CircleLetter from '@Components/circleLetter';

const WorkspaceInfo: FunctionComponent = () => {
    const { associatedCompany, owner, associatedContact, id } = useWorkspaceStore();

    const [workspaceStatus, setWorkspaceStatus] = useState<PayloadWorkspaceDto>();

    useEffect(() => {
        if (id) {
            communication.getSharedWorkspaceInfo(id).then((res: any) => {
                setWorkspaceStatus(res?.data?.payload);
            });
        }
    }, [id]);

    const getNamesCircle = (associatedCont: AssociatedContactType): string | null => {
        const nameInitial = associatedCont?.firstName?.[0]?.toUpperCase() ?? '';
        const lastNameInitial = associatedCont?.lastName?.[0]?.toUpperCase() ?? '';
        return nameInitial.trim() || lastNameInitial.trim()
            ? `${nameInitial} ${lastNameInitial}`
            : associatedCont?.email?.[0];
    };

    return (
        <StyledWorkspaceInfo>
            <WorkspaceInfoTitle>Workspace info</WorkspaceInfoTitle>
            {associatedCompany ? (
                <WorkspaceInfoItem>
                    <p>Associated company: </p>
                    {associatedCompany?.name ? (
                        associatedCompany.logoUrl || associatedCompany.profilePicture ? (
                            <img
                                className="profile-img"
                                crossOrigin="anonymous"
                                src={
                                    associatedCompany.profilePicture
                                        ? `${process.env.REACT_APP_API_URL}/accounts/company/picture/${associatedCompany.id}`
                                        : associatedCompany.logoUrl
                                }
                                alt="company logo"
                            />
                        ) : (
                            <CircleLetter
                                letter={associatedCompany?.name[0]}
                                withTooltip
                                tooltipText={associatedCompany?.name}
                                width="28px"
                                height="28px"
                            />
                        )
                    ) : null}
                    <p className="name">{associatedCompany?.name ? associatedCompany?.name : ''}</p>
                </WorkspaceInfoItem>
            ) : (
                <></>
            )}
            {associatedContact ? (
                <WorkspaceInfoItem>
                    <p>Associated contact: </p>
                    {associatedContact?.firstName ? (
                        associatedContact.profilePicture ? (
                            <img
                                className="profile-img"
                                crossOrigin="anonymous"
                                src={`${process.env.REACT_APP_API_URL}/accounts/company/picture/${associatedContact.id}`}
                                alt="profile"
                            />
                        ) : (
                            <CircleLetter
                                letter={getNamesCircle(associatedContact)}
                                withTooltip
                                tooltipText={
                                    associatedContact?.firstName +
                                    ' ' +
                                    (associatedContact?.lastName ? associatedContact?.lastName : '')
                                }
                            />
                        )
                    ) : null}

                    <p className="name">
                        {associatedContact?.firstName}{' '}
                        {associatedContact?.lastName ? associatedContact?.lastName[0] + '.' : ''}
                    </p>
                </WorkspaceInfoItem>
            ) : (
                <></>
            )}
            {owner ? (
                <WorkspaceInfoItem>
                    <p>Workspace owner: </p>
                    {owner?.profilePicture ? (
                        <img
                            className="profile-img"
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_API_URL}/users/me/picture/${owner?.profilePicture}`}
                            alt="profile"
                        />
                    ) : (
                        <CircleLetter
                            letter={getNamesCircle(owner)}
                            withTooltip
                            tooltipText={owner?.firstName + ' ' + owner?.lastName ? owner?.lastName : ''}
                        />
                    )}
                    <p className="name">
                        {owner?.firstName} {owner?.lastName ? owner?.lastName[0] + '.' : owner?.email}
                    </p>
                </WorkspaceInfoItem>
            ) : (
                <></>
            )}
            <WorkspaceInfoItem>
                <p>Shared with: </p>
                <div className="shared-with">
                    {workspaceStatus?.restricted ? (
                        <p>{workspaceStatus?.users?.length} contacts</p>
                    ) : (
                        <p>Anyone with a link</p>
                    )}
                </div>
            </WorkspaceInfoItem>
        </StyledWorkspaceInfo>
    );
};
const StyledWorkspaceInfo = styled.div`
    border-bottom: 1px solid var(--black-20);
    padding-bottom: 42px;
    margin-bottom: 42px;
`;
const WorkspaceInfoTitle = styled.div`
    margin-bottom: 24px;
`;
const WorkspaceInfoItem = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 18px;
    }
    .profile-img {
        border-radius: 50px;
        height: 28px;
        margin: 0 10px;
        width: 28px;
        object-fit: cover;
    }
    p {
        color: var(--black-75);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        &.name {
            color: var(--black-60);
        }
    }
    .letter {
        margin-left: 10px;
    }
    .shared-with {
        p {
            margin-left: 10px;
        }
    }
`;
export default WorkspaceInfo;
