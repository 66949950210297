import { UserStatus } from '@Global/dtos/user.dto';

export const getAuthState = (): { status: string | null; token: string } | null => {
    const params = new URLSearchParams(document.location.search);
    const urlToken = params.get('token');
    const status = params.get('status');
    const localToken = localStorage.getItem('token');
    const localStatus = localStorage.getItem('status');
    if (urlToken) {
        if (status === UserStatus.READY) {
            localStorage.setItem('token', urlToken);
            window.location.href = document.location.origin;
        }
        return {
            status,
            token: urlToken,
        };
    } else if (localToken) {
        return {
            status: localStatus ?? 'READY',
            token: localToken,
        };
    }
    return null;
};

export const returnToLogin = (caller?: string): void => {
    if (caller) alert(caller);
    window.location.href = '/login';
    // window.location.href = `${process.env.REACT_APP_API_URL}/login?redirect=${window.location.origin}`;
};
