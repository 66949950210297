import { FunctionComponent, useState, useEffect, ReactElement } from 'react';
import moment from 'moment';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import styled from 'styled-components';
import communication from '@Communication';
import NoItems from '@Components/noItems';

interface Props {
    workspaceId?: string;
}
interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<any>;
}

const Chart: FunctionComponent<Props> = ({ workspaceId }) => {
    const [views, setViews] = useState([]);

    useEffect(() => {
        if (workspaceId) {
            communication.getViewsByWSId(workspaceId).then((res: any) => {
                const result = res?.data.map((d: { views: number; date: string }) => {
                    return { views: d.views, date: moment(d.date).format('DD MMM') };
                });
                setViews(result);
            });
        }
    }, [workspaceId]);

    const CustomTooltip: FunctionComponent<CustomTooltipProps> = ({ active, payload }): ReactElement => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div>
                        {payload.map((pld: any, index: number) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ lineHeight: '25px', marginRight: '5px', fontWeight: '600' }}>
                                    {pld?.value}
                                </p>
                                <span style={{ fontSize: '9px', letterSpacing: '-0.18px' }}>
                                    {Number(pld?.value) > 1 ? 'Views' : 'View'}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return <></>;
    };
    return (
        <StyledChartWrapper>
            <h4>Workspace performance</h4>
            {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel placerat justo. Donec ultricies
                est
            </p> */}
            <StyledChart>
                {views.length !== 0 ? (
                    <ResponsiveContainer width="100%" aspect={3}>
                        <AreaChart
                            width={500}
                            height={400}
                            data={views}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 1" />
                            <XAxis dataKey="date" axisLine={false} tickLine={false} />
                            <YAxis dataKey="views" axisLine={false} tickLine={false} />
                            <Tooltip
                                wrapperStyle={{
                                    borderRadius: '8px',
                                    background: '#FFF',
                                    boxShadow: '0px 1px 6px 0px rgba(170, 170, 170, 0.50)',
                                    border: 'none',
                                    padding: '6px 8px',
                                    height: '36px',
                                }}
                                content={<CustomTooltip active={undefined} payload={undefined} />}
                            />
                            <Area
                                type="monotone"
                                dataKey="views"
                                stroke="#00A39C"
                                fill="#00A39C"
                                fillOpacity={0.4}
                                strokeWidth={2}
                                baseLine={3}
                                activeDot={{ strokeWidth: 0, r: 5 }}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                ) : (
                    <NoItems
                        content={
                            <>
                                <p>You don’t have any Views yet.</p>
                            </>
                        }
                    />
                )}
            </StyledChart>
        </StyledChartWrapper>
    );
};
const StyledChartWrapper = styled.div`
    border-radius: 8px;
    background: var(--white);
    box-shadow: 0px 1px 6px 0px rgba(217, 217, 217, 0.15);
    padding-bottom: 19px;
    h4 {
        padding: 17px 24px;
        border-bottom: 1px solid var(--black-20);
    }
    > p {
        line-height: 19.6px;
        letter-spacing: -0.28px;
        color: #afafb7;
        padding: 17px 24px 0 24px;
    }
    tspan {
        color: var(--black-60);
        letter-spacing: -0.28px;
        font-size: 14px;
    }
`;
const StyledChart = styled.div`
    border-radius: 8px;
    background: var(--white);
    box-shadow: 0px 1px 6px 0px rgba(217, 217, 217, 0.25);
    padding: 37px 15px;
    margin: 17px 19px 0 19px;
`;
export default Chart;
