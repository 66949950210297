import styled from 'styled-components';

export const StyledHeader = styled.div`
    display: block;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 25px;
    text-align: center;
    cursor: pointer;
    height: 70px;
    padding-top: 20px;
    img {
        &.front {
            margin-right: 10px;
            position: relative;
            /* Adjust these values accordingly */
            top: 5px;
        }
        &.back {
            padding-right: 10px;
            float: right;
            position: relative;
            /* Adjust these values accordingly */
            top: 5px;
            left: 5px;
        }
    }
`;

export const StyledFrame = styled.div`
    position: fixed;
    background-color: white;
    box-sizing: border-box;
    right: 0;
    flex-direction: column;
    display: flex;
    height: calc(100% - 30px);
    width: 380px;
    border-left: 1px solid #eaeaea;
    transition: all 0.3s ease-in-out;
    padding: 37px 24px;
    margin-left: 24px;
    z-index: 1;

    /* Media Queries */
    @media only screen and (max-width: 500px) {
        width: 280px;
    }
    &.collapsed {
        width: 0;
        opacity: 0;
        overflow: hidden;
        height: 0;
    }

    button {
        float: right;
    }

    .modal-close {
        position: absolute;
        top: 6px;
        left: -13px;
        z-index: 2;
        cursor: pointer;
        user-select: none;
    }

    .frame-wrapper {
        padding: 5px;
        flex: 1;
        overflow-y: auto;

        .input {
            margin: 16px 0;
        }

        textarea {
            height: 200px;
        }

        .account-select-wrapper {
            display: flex;

            .button {
                height: 40px;
                width: 0;
                opacity: 0;
                padding: 0;
                border: 0;
                transition: all 0.3s ease-in-out;
            }

            .select {
                transition: 0.3s all ease-in-out;
                flex: 1;
            }

            &.selected {
                .button {
                    width: 50px;
                    opacity: 1;
                    border: 2px solid #00726d;
                    margin-left: 10px;
                }
            }
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        p {
            font-size: 16px;
        }
    }

    .modal-header {
        h5 {
            margin-bottom: 35px;
            text-align: center;
        }
        p {
            text-align: center;
            margin-bottom: 35px;
        }
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .button-grid {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        .children {
            width: 100px;
            column-gap: 20px;
            img {
                filter: invert(31%) sepia(14%) saturate(4331%) hue-rotate(138deg) brightness(99%) contrast(101%);
            }
            gap: 20px;
        }
    }
    .select {
        margin-bottom: 20px;
        margin-top: 0;
    }

    .input {
        width: 100%;
        margin-top: 20px;
        text-align-last: left;
        display: flex;
        outline-color: red !important;
    }

    .styledInput {
        text-align-last: left;
        display: flex;
        outline-color: var(--primary-400);
    }

    .items-list {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 400px;
        overflow-y: auto;
        padding: 0px 5px;

        p {
            font-size: 12px;
            color: var(--black-75);
            margin-bottom: 8px;
        }

        .share-empty-list {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80%;
            color: var(--black-100);
            user-select: none;
            font-size: 14px;
        }

        .item {
            width: 100%;
            font-size: 15px;
            margin-top: 10px;
            align-items: center;
            color: var(--black-200);
            display: flex;

            .circle-letter {
                background: #00635f;
                background: var(--primary-500);
                border-radius: 50px;
                color: var(--white);
                font-size: 12px;
                height: 25px;
                left: 624px;
                margin-right: 10px;
                padding-top: 7px;
                text-align: center;
                top: 445px;
                width: 25px;
                min-width: 25px;
                min-height: 25px;
            }

            .img-set {
                display: flex;
                margin-left: auto;
                margin-right: 15px;
                cursor: pointer;

                .sent {
                    float: right;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
                .remove {
                    float: right;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
`;

export const PositionStyle = styled.div`
    margin-top: 25px;
    padding: 10px;
    flex: 1;

    label {
        margin-bottom: 10px;
    }

    textarea {
        height: 200px;
    }
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledProfilePhotoForm = styled.div`
    background-color: white;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    // height: fit-content;

    h2 {
        margin-bottom: 30px;

        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    .input {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .profile-update-button {
        margin-top: 40px;
    }

    input[type='file'] {
        display: none;
        margin-top: 30px;
    }

    .upload-label {
        border: 2px solid #00726d;
        background-color: #00726d;
        color: white;
        font-size: 16px;
        line-height: 24px;
        padding: 9px 16px;
        border-radius: 5px;
        min-width: auto;
        cursor: pointer;
        transition: ease all 0.3s;
        text-align: center;
        margin-top: 30px;
        width: 100%;

        &:hover {
            box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .selected-file {
        margin: 10px 0;
        color: var(--black-75);
        min-height: 20px;
        padding: 6px;
        border-radius: 5px;
        text-align: center;
        overflow-wrap: break-word;

        &.full {
            color: var(--primary-400);
        }
    }
`;
export const OptionsWrapper = styled.div`
    flex: 1;
    overflow: auto;
`;

export const ContextMenu = styled.div`
    position: absolute;
    background-color: var(--white);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--black-200);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--black-50);
    top: 70px;
    right: 20px;
    left: 20px;
    padding: 20px 20px 0 20px;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    .button {
        margin-bottom: 20px;
        width: 100%;
    }

    &.hidden {
        pointer-events: none;
        opacity: 0;
        top: 40px;
    }
`;
